import * as React from "react";
import {
  connect,
} from "react-redux";
import * as moment from "moment-timezone";
import {
  Avatar,
  Button,
  Chip,
  Typography,
} from "@material-ui/core";
import {
  Alarm,
  LocationOn,
  Schedule,
  Today,
  Update,
} from "@material-ui/icons";

import {
  IOpportunityWithRelations,
  TimePeriod,
} from "@models/opportunity";
import EditLocationModal from "../../components/modals/edit.location.modal";
import EditTimeModal from "../../components/modals/edit.time.modal";
import EditWorkingHoursModal from "../../components/modals/edit.workingHours.modal";
import EditStartDateModal from "../../components/modals/edit.startDate.modal";
import EditCloseDateModal from "../../components/modals/edit.closeDate.modal";

interface IProps {
  classes: any;
  editMode: boolean;
  opportunity: IOpportunityWithRelations;
  editOpportunity: IOpportunityWithRelations | null;
  commit: () => void;
  undo: () => void;
  onChange: (field: string, value: any) => void;
}

class KeyInformationComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      editLocationModal: false,
      editTimeModal: false,
      editSkillsModal: false,
      editWorkingHoursModal: false,
      editStartDateModal: false,
      editCloseDateModal: false,
    };
  }

  /* Handle location modal */
  public editLocationModalOpen = () => {
    this.setState({
      editLocationModal: true,
    });
  };

  public editLocationModalClose = (withUpdate) => () => {
    this.setState({
      editLocationModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  /* Handle time-period modal */
  public editTimeModalOpen = () => {
    this.setState({
      editTimeModal: true,
    });
  };

  public editTimeModalClose = (withUpdate) => () => {
    this.setState({
      editTimeModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  /* Handle working-hours modal */
  public editWorkingHoursModalOpen = () => {
    this.setState({
      editWorkingHoursModal: true,
    });
  };

  public editWorkingHoursModalClose = (withUpdate) => () => {
    this.setState({
      editWorkingHoursModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  /* Handle start-date modal */
  public editStartDateModalOpen = () => {
    this.setState({
      editStartDateModal: true,
    });
  };

  public editStartDateModalClose = (withUpdate) => () => {
    this.setState({
      editStartDateModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  /* Handle close-date modal */
  public editCloseDateModalOpen = () => {
    this.setState({
      editCloseDateModal: true,
    });
  };

  public editCloseDateModalClose = (withUpdate) => () => {
    this.setState({
      editCloseDateModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  public updateEditOpportunity = (field, value) => {
    const {
      onChange,
    } = this.props;
    onChange(field, value);
  };

  render() {
    const {
      classes,
      editMode,
      editOpportunity,
      opportunity,
    } = this.props;

    const {
      editLocationModal,
      editTimeModal,
      editWorkingHoursModal,
      editStartDateModal,
      editCloseDateModal,
    } = this.state;

    return editMode && editOpportunity ? (
      <div className={classes.keyInformation}>
        <EditLocationModal
          open={editLocationModal}
          onClose={this.editLocationModalClose}
          opportunity={editOpportunity}
          onChange={(value) => {
            if (value && Object.keys(value).length) {
              this.updateEditOpportunity("location", value);
            }
          }}
        />

        <EditTimeModal
          open={editTimeModal}
          onClose={this.editTimeModalClose}
          opportunity={editOpportunity}
          onChange={(values) => this.updateEditOpportunity("attendance_types", values)}
        />

        <EditWorkingHoursModal
          open={editWorkingHoursModal}
          onClose={this.editWorkingHoursModalClose}
          opportunity={editOpportunity}
          onChange={(value) => this.updateEditOpportunity("hours_per_week", value)}
        />

        <EditStartDateModal
          open={editStartDateModal}
          onClose={this.editStartDateModalClose}
          opportunity={editOpportunity}
          onChange={(value) => this.updateEditOpportunity("date_start", value)}
        />

        <EditCloseDateModal
          open={editCloseDateModal}
          onClose={this.editCloseDateModalClose}
          opportunity={editOpportunity}
          onChange={(value) => this.updateEditOpportunity("date_close", value)}
        />

        {
          editOpportunity.location ? (
            <Typography>
              <LocationOn className={classes.editIcon}/>
              <Typography className={classes.editValue}>
                {editOpportunity.location.suburb}
              </Typography>
              <Button
                className={classes.updateButton}
                onClick={this.editLocationModalOpen}
              >
                  UPDATE
              </Button>
            </Typography>
          ) : null
        }

        {
          editOpportunity.attendance_types.length ? (
            <Typography>
              <Schedule className={classes.editIcon}/>
              <Typography
                className={classes.editValue}
                style={{
                  display: "inline",
                }}
              >
                {editOpportunity.attendance_types.map((period: TimePeriod): string => period.name).join(", ")}
              </Typography>
              <Button
                className={classes.updateButton}
                onClick={this.editTimeModalOpen}
              >
                  UPDATE
              </Button>
            </Typography>
          ) : null
        }

        <Typography>
          <Update className={classes.editIcon}/>
          <Typography className={classes.editValue}>
            {`${editOpportunity.hours_per_week} Hours`}
          </Typography>
          <Button
            className={classes.updateButton}
            onClick={this.editWorkingHoursModalOpen}
          >
              UPDATE
          </Button>
        </Typography>

        <Typography>
          <Today className={classes.editIcon}/>
          <Typography className={classes.editValue}>
            {moment(editOpportunity.date_start).format("Do MMMM YYYY")}
          </Typography>
          <Button
            className={classes.updateButton}
            onClick={this.editStartDateModalOpen}
          >
              UPDATE
          </Button>
        </Typography>

        <Typography
          component="p"
          className={classes.editValue}
        >
          <Alarm className={classes.editIcon}/>

          <Typography
            component="span"
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "0.1rem",
              maxWidth: "160px",
            }}
          >
            {`Application closes ${moment(editOpportunity.date_close).fromNow()}`}
          </Typography>

          <Button
            className={classes.updateButton}
            style={{
              display: "inline-block",
            }}
            onClick={this.editCloseDateModalOpen}
          >
              UPDATE
          </Button>
        </Typography>
      </div>
    ) :
      (
        <div className={classes.keyInformation}>
          {
            opportunity.location ? (
              <Chip
                avatar={
                  <Avatar className={classes.chipAvatar}>
                    <LocationOn/>
                  </Avatar>
                }
                clickable={false}
                label={opportunity.location.suburb}
                className={classes.chip}
              />
            ) : null
          }

          {
            opportunity.attendance_types.length ? (
              <Chip
                avatar={
                  <Avatar className={classes.chipAvatar}>
                    <Schedule/>
                  </Avatar>
                }
                clickable={false}
                label={opportunity.attendance_types.map((period: TimePeriod): string => period.name).join(", ")}
                className={classes.chip}
              />
            ) : null
          }

          <Chip
            avatar={
              <Avatar className={classes.chipAvatar}>
                <Update/>
              </Avatar>
            }
            clickable={false}
            label={`${opportunity.hours_per_week} Hours`}
            className={classes.chip}
          />

          <Chip
            avatar={
              <Avatar className={classes.chipAvatar}>
                <Today/>
              </Avatar>
            }
            clickable={false}
            label={moment(opportunity.date_start).format("Do MMMM YYYY")}
            className={classes.chip}
          />

          <Typography
            component="p"
            style={{
              marginTop: "8px",
            }}
          >
            <Alarm style={{
              verticalAlign: "middle",
            }}/>

            <Typography
              component="span"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "8px",
              }}
            >
              {`Application closes ${moment(opportunity.date_close).fromNow()}`}
            </Typography>
          </Typography>
        </div>
      );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.data || null,
  };
};

const KeyInformation = connect(
  mapStateToProps,
)(KeyInformationComponent);

export default KeyInformation;
