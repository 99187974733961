import * as React from "react";
import {
  connect,
} from "react-redux";

import {
  updateFilters,
} from "@actions/opportunity";
import {
  Button,
  Input,
  Menu,
} from "@material-ui/core";
import {
  FilterList,
  Search,
} from "@material-ui/icons";

import {
  FilterIconButton,
  MenuItemGradient,
  SearchContainer,
  Toolbar,
} from "./styled";

export interface IFilterProps {
  filterOptions?: any;
  onlyActive?: boolean;
  dispatch?: any;
}

export interface IFilterState {
  anchorEl: any;
  filterOptions: IFilterOptions;
}

interface IFilterOptions {
  active: boolean;
  closed: boolean;
  opportunityTypes: number[];
  searchString: string;
}


// TODO use the DataLoader here
const oppTypes: any[] = [
  {
    id: 1,
    name: "Work experience",
  },
  {
    id: 2,
    name: "Structured W/E",
  },
  {
    id: 3,
    name: "Job Shadow",
  },
  {
    id: 4,
    name: "Paid Work",
  },
  {
    id: 5,
    name: "Traineeship",
  },
  {
    id: 6,
    name: "Apprenticeship",
  },
];

class FilterToolbarComponent extends React.Component<IFilterProps, IFilterState> {
  public constructor(props) {
    super(props);
    const initialState: IFilterState = {
      anchorEl: null,
      filterOptions: {
        active: false,
        closed: false,
        opportunityTypes: [],
        searchString: "",
      },
    };
    this.state = initialState;
  }

  private setActive = (elementId: number): void => {
    const {
      dispatch,
    } = this.props;

    const active = elementId === 1 && !this.state.filterOptions.active;
    const closed = elementId === 2 && !this.state.filterOptions.closed;
    this.setState({
      ...this.state,
      filterOptions: {
        ...this.state.filterOptions,
        active,
        closed,
      },
    }, (): void => {
      dispatch(updateFilters(this.state.filterOptions));
    });
  };

  public openMenu = (event: any): void => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  public closeMenu = (): void => {
    this.setState({
      anchorEl: null,
    });
  };
  public filterSelect = (id: number): void => {
    const existsAtIndex: number = this.state.filterOptions.opportunityTypes.indexOf(id);

    const {
      dispatch,
    } = this.props;

    const newState = {
      ...this.state,
    };
    if (existsAtIndex !== -1) {
      newState.filterOptions.opportunityTypes.splice(existsAtIndex, 1);
    } else {
      newState.filterOptions.opportunityTypes.push(id);
    }
    this.setState(newState, (): void => {
      dispatch(updateFilters(this.state.filterOptions));
    });
  };

  public onSearchChange = (e: any): void => {
    const {
      dispatch,
    } = this.props;

    this.setState({
      ...this.state,
      filterOptions: {
        ...this.state.filterOptions,
        searchString: e.target.value,
      },
    }, (): void => {
      dispatch(updateFilters(this.state.filterOptions));
    });
  };

  public render() {
    const
      {
        anchorEl, filterOptions,
      }:
        { anchorEl: any, filterOptions: IFilterOptions } =
        this.state;
    const {
      opportunityTypes,
    }: IFilterOptions = filterOptions;

    return (
      <Toolbar>
        {!this.props.onlyActive && (
          <React.Fragment>
            <Button color="default" style={{
              marginRight: "1.0rem",
            }} onClick={() => this.setActive(1)}>
              ACTIVE
            </Button>
            <Button onClick={() => this.setActive(2)}>
              CLOSED
            </Button>
          </React.Fragment>
        )}
        <SearchContainer style={this.props.onlyActive ? {
          marginLeft: 0,
        } : {}}>
          <Search style={{
            marginRight: "0.5rem",
          }}/>
          <Input
            placeholder="SEARCH"
            disableUnderline
            onChange={this.onSearchChange}
          />
        </SearchContainer>
        <FilterIconButton onClick={this.openMenu} style={this.props.onlyActive ? {
          marginLeft: "auto",
        } : {}}>
          <FilterList style={{
            marginRight: "0.5rem",
          }}/>
          FILTER
        </FilterIconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.closeMenu}
        >
          {oppTypes.map((option: any): any => (
            <MenuItemGradient
              id={option.id}
              key={option.id}
              selected={opportunityTypes.includes(option.id)}
              onClick={() => this.filterSelect(option.id)}
            >
              {option.name}
            </MenuItemGradient>
          ))}
        </Menu>
      </Toolbar>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    filterOptions: state.opportunities.filterOptions,
  };
};

export const FilterToolbar = connect(
  mapStateToProps,
)(FilterToolbarComponent);
