import * as React from "react";
import {
  gql,
} from "apollo-boost";
// Styles
import {
  Button,
  Theme,
  withStyles,
} from "@material-ui/core";
// Components
import LayoutComponent from "@components/Layout";
import MainPanel from "@components/MainPanel";
import {
  Loading,
} from "@components/Loading/Loading";
import CreateOrganisationUserData from "@components/User/CreateOrganisationUser/CreateOrganistionUserData";
import AddPartnershipsDialogData
  from "@components/Organisation/Partnerships/AddPartnershipsDialog/AddPartnershipsDialogData";
import SchoolPartnershipsTable from "@components/Organisation/Partnerships/SchoolPartnershipsTable";

//
// Styles
//

const styles = (theme: Theme) => ({
  button: {
    marginBottom: theme.spacing.unit,
  },
});

//
// Constants
//

export const adminOrganisationFragments = {
  organisation: gql`
        fragment AdminOrganistion on Organisation {
            id
            name
        }
    `,
};

//
// Interfaces
//

interface IProps {
  loading: boolean;
  organisation?: any;
  createOrganisationUserDialogOpen: boolean;
  setCreateOrganisationUserDialogOpen: (open: boolean) => void;
  addPartnershipDialogOpen: boolean;
  setAddPartnershipDialogOpen: (open: boolean) => void;
}

interface IExtendedProps extends IProps {
  classes: {
    button: any;
  },
}

/**
 * Layout for the admin organisation page
 * TODO: add graphql types. https://grandshake.atlassian.net/browse/WA-455
 */
class AdminOrganisationLayout extends React.Component<IExtendedProps> {
  /**
   * Render
   * @return {React.Component}
   */
  render() {
    const {
      classes,
      loading,
      organisation,
      createOrganisationUserDialogOpen,
      setCreateOrganisationUserDialogOpen,
      addPartnershipDialogOpen,
      setAddPartnershipDialogOpen,
    } = this.props;

    const title: string | React.ReactElement =
      organisation != null ?
        organisation.name :
        loading ? null :
          "Not Found";

    return (
      <LayoutComponent>
        <MainPanel title={title}>
          {loading ? <Loading/> : undefined}

          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            disabled={organisation == null}
            onClick={() => setCreateOrganisationUserDialogOpen(true)}
          >
            Add User
          </Button>

          <Button
            className={classes.button}
            color="primary"
            disabled={organisation == null}
            onClick={() => setAddPartnershipDialogOpen(true)}
          >
            Add Partnership
          </Button>

          {
            organisation &&
            <>
              <CreateOrganisationUserData
                organisation={organisation}
                open={createOrganisationUserDialogOpen}
                onClose={() => setCreateOrganisationUserDialogOpen(false)}
              />

              <AddPartnershipsDialogData
                organisation={organisation}
                open={addPartnershipDialogOpen}
                onClose={() => setAddPartnershipDialogOpen(false)}
              />

              <SchoolPartnershipsTable
                loading={loading}
                schoolData={organisation.schoolPartnerList}
              />
            </>
          }
        </MainPanel>
      </LayoutComponent>
    );
  }
}

export default withStyles(styles)(AdminOrganisationLayout);
