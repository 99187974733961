import * as Joi from "@hapi/joi";

export interface ISkill {
  id: number;
  name: string;
}

export const SkillSchema = Joi.object().keys({
  id: Joi.number().min(1),
  name: Joi.string().min(1).max(255),
});
