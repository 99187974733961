import {
  createMuiTheme,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  red,
} from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2626F7",
    },
    secondary: red,
  },
});

export const styles = (t: Theme): any => createStyles({
  paper: {
    position: "relative",
    padding: "24px",
    [theme.breakpoints.only("xs")]: {
      borderRadius: "0",
      boxShadow: "none",
      paddingTop: 0,
    },
  },
  paperAppendix: {
    height: "10px",
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  paperUploadVideoButton: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    textAlign: "center",
  },
  videoUploadButton: {
    backgroundColor: "#FFD300",
  },
  chip: {
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.87)",
    marginRight: t.spacing.unit,
    marginBottom: t.spacing.unit,
  },
  chipAvatar: {
    backgroundColor: "#FFFFFF",
  },

  divider: {
    margin: "24px 0",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },

  description: {
    color: "#000000",
    opacity: 0.6,
    marginTop: t.spacing.unit,
  },
  keyInformation: {
    marginTop: t.spacing.unit * 3,
    marginBottom: t.spacing.unit * 2,
    [theme.breakpoints.only("xs")]: {
      marginTop: t.spacing.unit,
    },
  },
  actionButtons: {
    marginTop: t.spacing.unit * 3,
  },
  actionButton: {
    boxShadow: "none",
    background: "#FFD300",
    marginRight: t.spacing.unit,
    marginBottom: t.spacing.unit,
    [theme.breakpoints.only("xs")]: {
      width: "48px",
      height: "48px",
    },
  },

  organisationBlock: {
    marginBottom: t.spacing.unit * 2,
    [theme.breakpoints.only("xs")]: {
      marginTop: "12px",
      marginBottom: 0,
    },
  },
  organisationName: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: t.spacing.unit,
      fontSize: "16px",
    },
  },
  organisationDescription: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  opportunityPosted: {
    display: "none",
    fontSize: "16px",
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
  },

  lastGridItem: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "-23px",
      width: "100%",
      paddingBottom: "0 !important",
    },
  },
  detailsSection: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: "12px",
    },
  },

  editIcon: {
    marginRight: "1rem",
    display: "inline-block",
    verticalAlign: "middle",
    color: "rgba(0, 0, 0, 0.38)",
  },
  editValue: {
    display: "inline-block",
    verticalAlign: "middle",
    color: "rgba(0, 0, 0, 0.87)",
    marginRight: "2rem",
  },
  updateButton: {
    color: "rgba(0, 0, 0, 0.38)",
  },
});
