import {
  createContact as createNewContact,
} from "@utils/api";
import {
  Dispatch,
} from "redux";
import {
  newAlert,
  setLoading,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";

export const FETCH_CONTACTS: string = "FETCH_CONTACTS";
export const ADD_CONTACT: string = "ADD_CONTACT";

export const createContact = (data): any => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    await createNewContact(data);
    dispatch(setLoading(false));
  } catch (e) {
    console.error(e);

    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "An error occured when creating contact",
    }));
  }
};
