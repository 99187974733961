import styled from "styled-components";

export const Layout: any = styled.div`
  flex: 1;
  background: ${(props: any) => props.background};
  min-height: 100vh;

  @media (max-width: 600px) {
    background: #FFF;
  }
`;
