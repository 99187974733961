import {
  Typography,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";
import * as React from "react";
import styled from "styled-components";
// Data
import {
  formDataDefault,
} from "./index";

const SignupFormPage3 = styled.section``;

const ScrollerSection = styled.section`
  margin: 1rem 0;
  max-height:310px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
const LegalLink = styled.a`
  text-decoration: underline;
  color: ${grey[800]};
  :active {
    color: ${grey[800]};
  }
  padding: 0 0.3rem;
`;

interface IProps {
  formErrors: any;
  formData: any;
  onChange: (e: any) => any;
}

class SignupFormPage3Component extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        schoolId: null,
        schoolName: "",
        ...formDataDefault,
      },
      ...props,
    };
  }

  public render() {
    return (
      <SignupFormPage3>
        <Typography variant="headline" gutterBottom>
          First up...
        </Typography>
        <Typography variant="subheading">
          Terms of use and Privacy
        </Typography>
        <Typography></Typography>
        <ScrollerSection>
          <Typography style={{
            marginBottom: "1rem",
            color: grey[800],
          }}>
            To continue, please agree to our
            <LegalLink href="https://grandshake.co/legal" target="_blank">
              Terms of use
            </LegalLink>
            and
            <LegalLink href="https://grandshake.co/privacy/" target="_blank">
              Privacy &
              cookie policy.
            </LegalLink>
            We use cookies for functional and analytical purposes.
          </Typography>
        </ScrollerSection>

      </SignupFormPage3>
    );
  }
}

export default SignupFormPage3Component;
