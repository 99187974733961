import {
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  withTheme,
} from "@material-ui/core/styles";
import * as React from "react";

import styled from "styled-components";

const ButtonWrapper: any = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonProgress: any = withTheme()(styled(CircularProgress)`
  color: ${(props: any) => props.theme.palette.primary[500]};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
` as any);

interface IProps {
  children: React.ReactNode;
  color: "inherit" | "primary" | "secondary" | "default";
  disabled: boolean;
  loading: boolean;
  onClick: (e: any) => any;
  size?: "small" | "medium" | "large";
  style?: any;
  variant: "text" | "flat" | "outlined" | "contained" | "raised" | "fab";
}

class ProgressButtonComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      size: "medium",
      ...props,
    };
  }

  public render() {
    const {
      children, disabled, loading, ...otherProps
    } = this.state;
    const isDisabled = loading || disabled;
    return (
      <ButtonWrapper>
        <Button
          {...otherProps}
          disabled={isDisabled}
        >
          {children}
        </Button>
        {loading && <ButtonProgress size={24}/>}
      </ButtonWrapper>
    );
  }
}

export default ProgressButtonComponent;
