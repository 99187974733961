import * as React from "react";
// Styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  ChevronLeft,
  Close,
  Delete,
  OpenInNew,
  Save,
} from "@material-ui/icons";
import {
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  styles,
  theme,
} from "./styles";
import {
  GoBack,
  HeaderText,
} from "./styled";
// Models
import {
  IOpportunityType,
} from "@models/opportunity";

export const getOpportunityGradient = (opportunityType) => {
  switch (opportunityType) {
  case 2:
    return ["#F12711", "#F5AF19"];
  case 3:
    return ["#00C6FF", "#0072FF"];
  case 4:
    return ["#12C2E9", "#C471ED", "#F64F59"];
  case 5:
    return ["#F2994A", "#F2C94C"];
  case 6:
    return ["#A8FF78", "#78FFD6"];
  case 7:
  case 8:
  case 9:
  case 10:
  case 11:
  case 12:
  case 13:
  case 14:
    return ["#F857A6", "#FF5858"];
  default: // 1 as well
    return ["#8E2DE2", "#4A00E0"];
  }
};

interface IProps {
  goBack: () => void;
  deleteOpportunity: () => void;
  editMode: boolean; // Indicates whether or not the opportunity page is in edit mode
  cancelEditMode: () => void;
  opportunityTypes: IOpportunityType[];
  canReviewApplications?: boolean;
  reviewMode: boolean;
  reviewApplications: () => void;
  saveChanges: () => void;
  canSubmitInterest?: boolean;
  studentSubmittedInterest?: boolean;
  submitInterest: () => void;
}

interface IPropsWithStyles extends IProps {
  classes: any;
}

class RootBar extends React.Component<IPropsWithStyles, any> {
  state = {
    open: false,
    name: "",
    tooltip: "",
  };

  public openOpportunityTypeTooltip = (name, tooltip) => () => {
    if (tooltip) {
      this.setState({
        open: true,
        name,
        tooltip,
      });
    }
  };

  public onClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      editMode,
      classes,
      reviewMode,
      opportunityTypes = [],
      reviewApplications,
      canSubmitInterest,
      studentSubmittedInterest,
      canReviewApplications,

      // methods
      goBack,
      cancelEditMode,
      deleteOpportunity,
      saveChanges,
      submitInterest,
    } = this.props;

    const {
      open,
      name,
      tooltip,
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.rootBar}>
          <Dialog
            open={open}
            onClose={this.onClose}
          >
            <DialogTitle id="alert-dialog-title">
              {name}
            </DialogTitle>
            <DialogContent style={{
              minWidth: "320px",
            }}>
              <DialogContentText id="alert-dialog-description">
                {tooltip}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" autoFocus onClick={this.onClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <div
            className={classes.pageHeader}
          >
            <Button
              className={classes.backToListing}
              onClick={goBack}
            >
              <ChevronLeft style={{
                marginRight: "0.5rem",
              }}/>
              <GoBack>
                Go Back
              </GoBack>
            </Button>
            <Typography
              variant="headline"
              component="p"
              className={classes.pageHeaderText}
            >
              <HeaderText>
                {reviewMode ? "Applications" : "Opportunity"}
              </HeaderText>
              <HeaderText mobile>
                {editMode ? "Edit Opportunity" : (reviewMode ? "Applications" : "Opportunity")}
              </HeaderText>
            </Typography>
          </div>
          {
            (!reviewMode && !editMode) ? (
              <div
                className={classes.subHeader}
              >
                {
                  opportunityTypes.slice(0, 2).map(({
                    id, name, tooltip,
                  }) => (
                    <Button
                      key={id}
                      variant="extendedFab"
                      className={classes.opportunityTypeButton}
                      style={{
                        background: `linear-gradient(${getOpportunityGradient(id).join(",")})`,
                      }}
                      onClick={this.openOpportunityTypeTooltip(name, tooltip)}
                    >
                      <OpenInNew className={classes.callToOpen}/>
                      <Typography
                        component="span"
                        style={{
                          marginLeft: "8px",
                          color: "#FFFFFF",
                        }}
                      >
                        {name}
                      </Typography>
                    </Button>
                  ))
                }

                {
                  canReviewApplications ? (
                    <Button
                      variant="contained"
                      className={classes.rootButton}
                      onClick={reviewApplications}
                    >
                      Review application
                    </Button>
                  ) : null
                }

                {
                  canSubmitInterest ? (
                    <Button
                      variant="contained"
                      className={classes.rootButton}
                      disabled={studentSubmittedInterest}
                      onClick={submitInterest}
                    >
                      {studentSubmittedInterest ? "Applied" : "Submit interest"}
                    </Button>
                  ) : null
                }
              </div>
            ) : null
          }
          {
            editMode ? (
              <div>
                <Button
                  className={classes.cancel}
                  onClick={cancelEditMode}
                >
                  <Close
                    style={{
                      verticalAlign: "middle",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    component="span"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    CANCEL
                  </Typography>
                </Button>

                <Button
                  className={classes.remove}
                  onClick={deleteOpportunity}
                >
                  <Delete
                    style={{
                      verticalAlign: "middle",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    component="span"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    REMOVE
                  </Typography>
                </Button>

                { /* TODO position: absolute is temporary stuff */}
                <Button
                  className={classes.save}
                  onClick={saveChanges}
                >
                  <Save
                    color="primary"
                    style={{
                      verticalAlign: "middle",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    color="inherit"
                    component="span"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    SAVE
                  </Typography>
                </Button>
              </div>
            ) : null
          }
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(RootBar);
