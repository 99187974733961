import * as React from "react";
import {
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import {
  History,
} from "history";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import {
  useQuery,
} from "@apollo/react-hooks";
import {
  gql,
} from "apollo-boost";
// Utils
import {
  hasSchoolRole,
  useRoles,
} from "@utils/roles";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  ERROR_COULD_NOT_FIND_STUDENTS,
} from "@models/student";
// Components
import {
  AlertLevel,
} from "@components/global.model";
import StudentsPageLayout from "@pages/students/StudentsPageLayout";
import {
  studentsPageTableFragments,
} from "@pages/students/components/StudentsTable";
import {
  Loading,
} from "@components/Loading/Loading";

declare interface IProps {
}

interface IMatchParams {
}

interface ExtendedIProps extends IProps, RouteComponentProps<IMatchParams> {
  dispatch: Dispatch;
  history: History;
  location: any;
}

const GET_STUDENT_LIST_QUERY = gql`
    query StudentsPageGetStudentList
    {
        getStudentList {
            ...StudentsTableStudent
        }
    }
    ${studentsPageTableFragments.student}
`;

/*
 * Functional Component that is responsible for:
 * - fetching list of students
 * - Error handling via snackbars
 * TODO: Generate types using apollo codegen
 */
const StudentsPageData: React.FC<ExtendedIProps> = (props: ExtendedIProps): React.ReactElement<ExtendedIProps> => {
  const {
    dispatch,
  } = props;

  const goToDashboard = () => {
    props.history.push("/");
  };

  const {
    loading,
    error,
    data,
  } = useQuery(GET_STUDENT_LIST_QUERY, {
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR_COULD_NOT_FIND_STUDENTS,
        },
      ));
    },
  });

  // Get current user roles
  const {
    roleList,
    loading: rolesLoading,
  } = useRoles();

  if (rolesLoading) return (<Loading/>);

  // redirect non-school users
  if (!hasSchoolRole(roleList)) goToDashboard();

  const studentList: any = !error && !loading ? data.getStudentList : null;

  const onStudentClick = (studentId: string) => {
    props.history.push({
      pathname: `/students/${studentId}`,
      state: {
        viaStudentsPage: true,
      },
    },
    );
  };

  return (
    <StudentsPageLayout
      loading={loading}
      studentList={studentList}
      onStudentClick={onStudentClick}
      onBackClick={goToDashboard}
    />
  );
};

export const StudentsPage = withRouter(connect()(StudentsPageData));
