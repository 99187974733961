import * as React from "react";
import {
  Button,
  Chip,
  Grid,
  Popover,
  Typography,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import {
  isArray,
  isObject,
  uniq,
} from "lodash";

import OptionButtons from "./styled";
import Modal from "./Modal";

const styles = (theme): any => ({
  paper: {
    padding: theme.spacing.unit,
    [theme.breakpoints.up("md")]: {
      maxWidth: "600px",
    },
  },
  popover: {
    pointerEvents: "none",
  },
});

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Color = "default" | "primary" | "secondary";

interface IOption {
  name: string;
  tooltip?: string;
}

interface IProps {
  classes?: any;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
  multiple?: boolean;
  color: Color;
  options: IOption[] | any;
  size?: "small" | "medium" | "large" | undefined;
  onChange: (e: any) => any;
  disabled?: boolean;
  selected: number | string | any[];
  confirmationRequired: boolean;
}

class OptionButtonsComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      hoverChanged: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      ...props,
    });
  }

  public onChange = (value) => {
    value = (parseInt(value, 10));
    const {
      multiple, selected, onChange,
    } = this.state;

    if (multiple) {
      const selectedArray = Array.isArray(selected) ? selected : [selected];
      const existsInArray = selectedArray.indexOf(value);
      if (existsInArray !== -1) {
        selectedArray.splice(existsInArray, 1);
        onChange(selectedArray);
      } else {
        onChange(uniq(selectedArray.concat([value])));
      }
    } else if (this.isSelected(value)) {
      onChange(null);
    } else {
      onChange(value);
    }
  };

  public isSelected = (value) => {
    const {
      multiple, selected,
    } = this.state;

    if (multiple) {
      const selectedArray = Array.isArray(selected) ? selected : [selected];
      return (selectedArray || []).indexOf(parseInt(value, 10)) > -1 ||
        (selectedArray || []).indexOf(value) > -1;
    }

    return value === selected || parseInt(value, 10) == parseInt(selected, 10);
  };

  public onModalShow = (value, title, tooltip) => {
    this.setState({
      hoverEl: value,
      hoverTitle: title,
      hoverTooltip: tooltip,
    });
  };
  public handlePopoverOpen = (event, title, tooltip?) => {
    // check option has tooltop before setting state
    if (event && tooltip) {
      this.setState({
        hoverEl: event.currentTarget,
        hoverChanged: true,
        hoverTitle: title,
        hoverTooltip: tooltip,
      });
    }
  };

  public handlePopoverClose = (selected) => {
    const {
      hoverEl,
    } = this.state;

    if (hoverEl) {
      if (selected) {
        this.onChange(hoverEl);
      }

      this.setState({
        hoverEl: null,
        hoverChanged: true,
      });
    }
  };

  public renderOptions = () => {
    const {
      options,
    } = this.state;

    if (isArray(options)) {
      return options.map((option) => this.renderItem({
        ...option,
        label: option.name,
      }));
    }

    if (isObject(options)) {
      return Object.keys(options)
        .map((key) => this.renderItem({
          ...options[key],
          label: options[key].name,
          value: key,
        }));
    }
  };

  public renderItem = ({
    label, id, tooltip = null,
  }) => {
    const {
      confirmationRequired,
    } = this.state;
    const isSelected = this.isSelected(id || label);
    const size = this.state.size || "large";
    const disabled = this.state.disabled || false;
    let color = this.state.color || "default";
    if (isSelected) {
      color = "primary";
    }
    return (
      <Grid item key={id}>
        <Chip
          clickable
          size={size}
          label={label}
          color={color}
          disabled={disabled && !isSelected}
          onClick={() => !(confirmationRequired && tooltip) ? this.onChange(id) : this.onModalShow(id, label, tooltip)}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => !(confirmationRequired && tooltip) && this.handlePopoverOpen(e, label, tooltip)}
          onMouseLeave={(e) => !(confirmationRequired && tooltip) && this.handlePopoverClose(e)}
        />
      </Grid>
    );
  };

  public render() {
    const {
      classes, confirmationRequired,
    } = this.props;
    const {
      hoverEl, hoverTitle, hoverTooltip,
    } = this.state;
    const open = Boolean(hoverEl);

    return (
      <React.Fragment>
        <OptionButtons>
          <Grid container spacing={8}>
            {this.renderOptions()}
          </Grid>
        </OptionButtons>
        {
          confirmationRequired && hoverTooltip !== null ? (
            <Modal
              open={open}
              title={hoverTitle}
              content={hoverTooltip}
              handleClose={this.handlePopoverClose}
            />
          ) : (
            <Popover
              id={"mouse-over-popover"}
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={hoverEl}
              anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "left",
                vertical: "top",
              }}
              onClose={this.handlePopoverClose}
              disableRestoreFocus
            >
              <Typography>
                {hoverTooltip + " "}
                <Button
                  size="small"
                  href="https://www.qld.gov.au/jobs/education/volunteering/experience"
                  target="_blank">
                  Find out more here
                </Button>
              </Typography>
            </Popover>
          )
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OptionButtonsComponent);
