import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import OptionButtons from "@components/OptionButtons";

interface IProps {
  open: boolean;
  onClose: (e: any) => any;
  onChange: (e: any) => any;
  data: any;
  opportunity: any;
}

class EditTimeModalComponent extends React.Component<IProps, any> {
  public updateSelected = (values): void => {
    this.props.onChange(
      values.map((attendance) => ({
        id: parseInt(attendance.id, 10),
        name: attendance.name,
      })),
    );
  };

  public render() {
    const {
      data,
      opportunity,
      open,
      onClose,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update time period
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below, enter the new time periods for the opportunity.
          </DialogContentText>

          <OptionButtons
            selected={opportunity ? opportunity.attendance_types.map((time) => time.id) : []}
            options={data.opportunity ? data.opportunity.attendance_types : {}}
            onChange={this.updateSelected}
            multiple
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onClose(true)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.data || null,
  };
};

const EditTimeModal = connect(
  mapStateToProps,
)(EditTimeModalComponent);

export default EditTimeModal;
