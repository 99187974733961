import {
  get,
} from "lodash";
import {
  Auth,
} from "aws-amplify";
import {
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import {
  CodeDeliveryDetailsType,
} from "aws-sdk/clients/cognitoidentityserviceprovider";
import {
  client,
} from "../graphql";
// Utils
import {
  history,
} from "@utils/history";
// Models
import {
  IAuthenticatedUser,
  setUser,
} from "@models/user";
import {
  store,
} from "@models/store";
// Actions
import {
  authLogoutAction,
} from "@actions/global";

export const signIn = async (username: string, password: string): Promise<CognitoUser> => {
  return await Auth.signIn(username.toLowerCase(), password);
};

export const getCurrentUserInfo = async (): Promise<IAuthenticatedUser> => {
  const user = await Auth.currentUserInfo();

  await store.dispatch(setUser(user));

  return user;
};

export const forgotPassword = async (email: string): Promise<CodeDeliveryDetailsType> => {
  return await Auth.forgotPassword(email.toLowerCase());
};

export const forgotPasswordSubmit = async (email: string, code: string, newPassword: string): Promise<any> => {
  return await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
};

export const confirmSignUp = async (email: string, code: string): Promise<string> => {
  return await Auth.confirmSignUp(email.toLowerCase(), code);
};

export const getCurrentSession = async (): Promise<CognitoUserSession> => {
  return await Auth.currentSession();
};

export const getAccessToken = async (): Promise<string | null> => {
  try {
    const currentSession = await getCurrentSession();
    return get(currentSession, "idToken.jwtToken", null);
  } catch (e) {
    return null;
  }
};

export const resetPassword = async (oldPassword: string, newPassword: string) => {
  const user: any = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user.toLowerCase(), oldPassword, newPassword);
};

/**
 * Clears authentication tokens, clears cached data, returns user to login screen
 */
export const signOut = async () => {
  await Auth.signOut();

  history.push("/signin");

  // Clear caches
  await store.dispatch(authLogoutAction());
  await client.resetStore();
};
