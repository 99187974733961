import * as React from "react";
import {
  Redirect,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import styled from "styled-components";

import {
  CircularProgress,
} from "@material-ui/core";
import {
  IAuthenticatedUser,
} from "@models/user";
import {
  getCurrentUserInfo,
} from "@utils/auth";

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  children: React.ComponentType;
  user: IAuthenticatedUser | null;
}

interface IState {
  loading: boolean;
  notAuthorised: boolean;
}

/**
 * This class fetches the current user info from Cognito and updates the user data in redux
 */
class UserLoaderComponent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.user == null,
      notAuthorised: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    try {
      // Get user data
      await getCurrentUserInfo();
    } catch (e) {
      console.error(e);

      await this.setState({
        notAuthorised: true,
      });
    } finally {
      await this.setState({
        loading: false,
      });
    }
  }

  private renderLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress size={64}/>
      </LoadingContainer>
    );
  };

  public renderRedirection = () => {
    return <Redirect to="/signin"/>;
  };

  public render() {
    const {
      children,
    } = this.props;

    const {
      loading,
      notAuthorised,
    } = this.state;

    if (loading) {
      return this.renderLoading();
    }

    if (notAuthorised) {
      return this.renderRedirection();
    }

    return children;
  }
}

/**
 * Passes in the user as props
 * @param state
 */
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};


export const UserLoader = connect(
  mapStateToProps,
)(UserLoaderComponent);

/**
 * With user is a HOC that wraps the given component with the User Loader class defined above.
 * It passes the current user that is saved in the redux store
 * @param component
 */
export const withUser = (component: any): React.ComponentClass => {
  const Component = connect(
    mapStateToProps,
  )(component);

  return class WithUser extends React.Component {
    public render() {
      return (
        <UserLoader>
          <Component/>
        </UserLoader>
      );
    }
  };
};
