import * as React from "react";
import {
  RouteComponentProps,
  withRouter,
} from "react-router";
// Utils
import {
  ROUTE_ADMIN_DASHBOARD,
} from "@utils/routes";
// Components
import Nav from "@components/Nav/NavLayout";

interface IProps extends RouteComponentProps {}

/*
 * Functional Component that is responsible for:
 * - providing data for the nav component
 */
const NavDataComponent: React.FC<IProps> = (props: IProps): React.ReactElement<IProps> => {
  const showAdminNav: boolean = props.location.pathname.startsWith(ROUTE_ADMIN_DASHBOARD);
  return (
    <Nav showAdminNav={showAdminNav}/>
  );
};

export const NavData = withRouter(NavDataComponent);
