import * as React from "react";
// Styles
import {
  Button,
  Theme,
  withStyles,
} from "@material-ui/core";
import {
  Link,
} from "@utils/link";
import {
  HideOnSmall,
  NavActions,
} from "../styled";
import {
  Business,
  School,
  Person,
  Dashboard,
  Home,
} from "@material-ui/icons";
// Utils
import {
  ROUTE_ADMIN_ORGANISATIONS,
  ROUTE_ADMIN_SCHOOLS,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_DASHBOARD,
} from "@utils/routes";

const styles = (theme: Theme): any => ({
  navIcon: {
    marginRight: theme.spacing.unit,
    opacity: 0.38,
    color: "#000DFF",
  },
  navButton: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: 400,
  },
});

interface IProps {
  classes: {
    navIcon: any;
    navButton: any;
  }
}

/**
 * Default Nav Bar: to be used for the main app
 */
class AdminNavActions extends React.Component<IProps> {
  /**
   * Render
   * @return {React.Component}
   */
  render() {
    const {
      classes,
    } = this.props;

    return (
      <NavActions>
        <Button
          className={classes.navButton}
          component={Link(ROUTE_ADMIN_DASHBOARD)}
        >
          <Dashboard className={classes.navIcon}/>
          <HideOnSmall>
            DASHBOARD
          </HideOnSmall>
        </Button>

        <Button
          className={classes.navButton}
          component={Link(ROUTE_ADMIN_ORGANISATIONS)}
        >
          <Business className={classes.navIcon}/>
          <HideOnSmall>
            ORGANISATIONS
          </HideOnSmall>
        </Button>

        <Button
          className={classes.navButton}
          component={Link(ROUTE_ADMIN_SCHOOLS)}
        >
          <School className={classes.navIcon}/>
          <HideOnSmall>
            SCHOOLS
          </HideOnSmall>
        </Button>

        <Button
          className={classes.navButton}
          component={Link(ROUTE_ADMIN_USERS)}
        >
          <Person className={classes.navIcon}/>
          <HideOnSmall>
            USERS
          </HideOnSmall>
        </Button>

        <Button
          className={classes.navButton}
          component={Link(ROUTE_DASHBOARD)}
        >
          <Home className={classes.navIcon}/>
          <HideOnSmall>
            HOME
          </HideOnSmall>
        </Button>

      </NavActions>
    );
  }
}

export const AdminNavActionsLayout = withStyles(styles)(AdminNavActions);
