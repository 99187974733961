import * as React from "react";
// Utils
import {
  useRoles,
} from "@utils/roles";
// Components
import DashboardPageLayout from "@pages/dashboard/DashboardPageLayout";
import {
  Loading,
} from "@components/Loading/Loading";

/*
 * Functional Component that is responsible for:
 * - providing data for the dashboard page
 */
export const DashboardPageData: React.FC = (): React.ReactElement => {
  const {
    roleList,
    loading,
  } = useRoles();

  if (loading) return (<Loading/>);

  return (
    <DashboardPageLayout
      roleList={roleList}
    />
  );
};
