declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

import {
  pendingTasksReducer,
} from "react-redux-spinner";
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import {
  reducer as formReducer,
} from "redux-form";
import thunkMiddleware from "redux-thunk";
import data from "./data";
import user from "./user";

import opportunities from "@reducers/opportunity";
import profile from "@reducers/profile";
import globals from "@reducers/global";

export interface IAppState {
  data: any;
  pendingTasks;
  user: any;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  data: data.reducer,
  form: formReducer,
  pendingTasks: pendingTasksReducer,
  user: user.reducer,

  ...opportunities,
  ...profile,
  ...globals,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === "AUTH_LOGOUT") {
    newState = undefined;
  }

  return appReducer(newState, action);
};

// Create store
export const store = createStore<IAppState, any, any, any>(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      // loggerMiddleware,
    ),
  ),
);
