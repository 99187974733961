import * as React from "react";

import {
  Button,
  withStyles,
} from "@material-ui/core";

interface IProps {
  classes: {
    input: any;
    button: any;
  };
  onInputChange: (e: any) => Promise<any>;
}

const styles = (theme) => ({
  button: {
    backgroundColor: "#FFD300",
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
});

/**
 * Simple Video input component.
 * Presents a video input. On supported devices it will allow in-browser video recording
 */
class VideoInput extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      onInputChange,
    } = this.props;

    return (
      <React.Fragment>
        <input
          type="file"
          accept="video/*"
          capture='user'
          className={classes.input}
          id="video-button-file"
          onChange={async (e) => await onInputChange(e)}
        />
        <label htmlFor="video-button-file">
          <Button component="span" className={classes.button}>
            Upload
          </Button>
        </label>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VideoInput);
