import styled from "styled-components";
import {
  grey,
} from "@material-ui/core/colors";
import {
  Button,
  MenuItem,
} from "@material-ui/core";

export const Toolbar = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  background: ${grey[100]};
  @media (min-width: 960px) {
    width: 960px;
  }
  @media (min-width: 600px) and (max-width: 960px) {
    width: 83.333%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${grey[600]};
  padding: 0rem 0.5rem;
  margin-left: auto;
  input {
    text-align: center;
  }
`;

export const FilterIconButton = styled(Button)`
  font-size: 1.0rem !important;
  color: ${grey[600]} !important;
  font-weight: 400 !important;
`;

export const MenuItemGradient = styled(MenuItem)`
`;
