import {
  Theme,
} from "@material-ui/core";

export const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      overflowX: "hidden",
      overflowY: "scroll",
      alignItems: "inherit",
    },
  },
  contentWrapper: {
    [theme.breakpoints.up("md")]: {
      width: "960px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "600px",
    },
    [theme.breakpoints.only("xs")]: {
      boxSizing: "border-box",
      background: "#FFF",
    },
  },
});
