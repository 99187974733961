import * as Joi from "@hapi/joi";
import {
  ISkill,
} from "@models/skill";
import {
  getLimitFromError,
} from "@models/validationHelpers";
import {
  ITask,
  TaskSchema,
} from "@models/task";
import {
  DefaultStringSchema,
  DefaultTitleSchema,
  IdArraySchema,
  IdSchema,
  IId,
  IIdArray,
} from "@models/validationHelpers/constants";


//
// Constants
//
export const EXPERIENCE_MAX_SKILLS = 12;
export const EXPERIENCE_MIN_SKILLS = 0;

export const EXPERIENCE_MAX_TASKS = 5;
export const EXPERIENCE_MIN_TASKS = 1;

//
// Interfaces
//
export interface IExperience {
  id: IId;
  opportunity_type: IId;
  title: string;
  summary: string;
  company: string;
  tasks: ITask[];
  from: Date;
  to: Date;
}

export interface IExperienceWithRelations extends IExperience {
  skills: ISkill[];
}

export interface ICreateExperience extends ICreateExperienceRequest {
  experienceIsOngoing: boolean;
}

export interface ICreateExperienceRequest {
  opportunity_type_id: IId;
  title: string;
  summary: string;
  company: string;
  tasks: ITask[];
  from: Date;
  to?: Date;
  skill_ids: IIdArray;
}

//
// Schema Definitions
//
export const ExperienceTasksSchema = Joi.array().items(
  TaskSchema,
).min(EXPERIENCE_MIN_TASKS).max(EXPERIENCE_MAX_TASKS).error((errors) => {
  errors.forEach((err) => {
    switch (err.type) {
    case "array.min":
      err.message = `Your task list should have at least ${getLimitFromError(err)} task(s)!`;
      break;
    case "array.max":
      err.message = `Your task list should have at most ${getLimitFromError(err)} tasks!`;
      break;
    }
  });
  return errors;
});

// When creating an experience,
export const SkillIdsFromExperienceSchema = IdArraySchema.min(EXPERIENCE_MIN_SKILLS).max(EXPERIENCE_MAX_SKILLS).error((errors) => {
  errors.forEach((err) => {
    switch (err.type) {
    case "array.min":
      err.message = `Your skill list should have at least ${getLimitFromError(err)} skill(s)!`;
      break;
    case "array.max":
      err.message = `Your skill list should have at most ${getLimitFromError(err)} skills!`;
      break;
    default:
      break;
    }
  });
  return errors;
});

export const CreateExperienceSchema = Joi.object().keys({
  opportunity_type_id: IdSchema.required(),
  title: DefaultTitleSchema.required(),
  summary: DefaultStringSchema.required(),
  company: DefaultTitleSchema.required(),
  tasks: ExperienceTasksSchema.required(),
  from: Joi.date().iso().required(),
  to: Joi.date().iso().allow(null),
  skill_ids: SkillIdsFromExperienceSchema.required(),
});
