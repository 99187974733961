import * as React from "react";
// Styles
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
// Components
import {
  Loading,
} from "@components/Loading/Loading";

//
// Styles
//

const styles = (theme: Theme) => ({
  root: {
    display: "flex" as "flex",
    flex: 1,
    flexDirection: "column" as "column",
  },
  centerContainer: {
    display: "flex" as "flex",
    flex: 1,
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    padding: theme.spacing.unit,
  },
});

//
// Interfaces
//

export interface IDataListItem {
  id: string;
  text: string;
  detailText?: string;
}

export interface IDataList {
  loading: boolean;
  items: IDataListItem[];
  listItemProps?: (item: IDataListItem) => object;
  renderItemStart?: (item: IDataListItem) => React.ReactElement;
  renderItemEnd?: (item: IDataListItem) => React.ReactElement;
  renderItemSecondaryAction?: (item: IDataListItem) => React.ReactElement;
  emptyMessage?: string;
}

interface IExtendedDataList extends IDataList {
  classes: {
    centerContainer: any;
    root: any;
  }
}

/**
 * Generic list component.
 * @param {IExtendedDataList} props
 * @constructor
 */
const DataList: React.FC<IExtendedDataList> = (props: IExtendedDataList) => {
  const {
    classes,
    emptyMessage,
    items,
    listItemProps,
    loading,
    renderItemStart,
    renderItemEnd,
    renderItemSecondaryAction,
  } = props;

  const defaultEmptyMessage: string = "No items";

  return (
    <Paper className={classes.root}>
      {!loading &&
      <List>
        {
          items.map((item: IDataListItem) => {
            return (
              <ListItem
                key={String(item.id)}
                color="primary"
                {...(listItemProps ? listItemProps(item) : undefined)}
              >
                {renderItemStart && renderItemStart(item)}
                <ListItemText primary={item.text} secondary={item.detailText}/>
                {renderItemEnd && renderItemEnd(item)}
                {renderItemSecondaryAction &&
                <ListItemSecondaryAction>
                  {renderItemSecondaryAction(item)}
                </ListItemSecondaryAction>
                }
              </ListItem>
            );
          })
        }
      </List>
      }
      <div className={classes.centerContainer}>
        {loading ? <Loading/> : undefined}
        {
          !loading &&
          items.length <= 0 &&
          <Typography>
            {emptyMessage || defaultEmptyMessage}
          </Typography>
        }
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DataList);
