import styled from "styled-components";

export const GoBack = styled.span`
  @media(max-width: 600px) {
    display: none;
  }
`;

export const HeaderText = styled.span`
  display: ${(p) => p.mobile ? "none" : "inline-block"}

  @media(max-width: 600px) {
    display: ${(p) => p.mobile ? "inline-block" : "none"}
  }
`;
