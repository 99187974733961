const initialState = {};

const SET_DATA = "set_data";

export function setData(data) {
  return {
    data,
    type: SET_DATA,
  };
}

function setDataHandler(state, {
  data,
}) {
  return data;
}

const actionHandlers = {
  [SET_DATA]: setDataHandler,
};

const reducer = (state = initialState, action: any = {}) => {
  let newState = state;
  if (typeof actionHandlers[action.type] === "function") {
    newState = actionHandlers[action.type](state, action);
  }
  return newState;
};

export default {
  initialState,
  reducer,
};
