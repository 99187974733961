import * as React from "react";
import {
  CircularProgress,
  Paper,
  withStyles,
} from "@material-ui/core";
import GenericPlayer from "@components/VideoPlayer/GenericPlayer";

interface IProps {
  videoPresent: boolean;
  playerHeight: number;
  playerWidth: number;
  src?: string;
  videoError?: boolean;
  videoLoading?: boolean;
}

interface IPropsWithStyles extends IProps {
  classes: {
    warning: any;
    player: any;
  };
}

const styles = (theme) => ({
  warning: {
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  player: {
    paddingBottom: theme.spacing.unit * 2,
  },
});

/**
 * Input:   src, and videoErrors.
 * Output:  if loading,   displays a loading message
 *          if errors,    displays a warning message.
 *          if no errors, displays a video player.
 */
class VideoPlayer extends React.Component<IPropsWithStyles> {
  renderPlayer = () => {
    const {
      src,
      playerHeight,
      playerWidth,
      classes,
    } = this.props;

    if (!src) {
      return null;
    }

    return (
      <div className={classes.player}>
        <GenericPlayer
          src={src}
          playerHeight={playerHeight}
          playerWidth={playerWidth}
        />
      </div>
    );
  };

  renderWarning = () => {
    const {
      classes,
    } = this.props;

    return (
      <Paper
        className={classes.warning}
        style={{
          textAlign: "center",
          color: "grey",
        }}
      >
        <p>
          This video is currently being processed
        </p>
      </Paper>
    );
  };

  renderLoading = () => {
    const {
      classes,
    } = this.props;

    return (
      <div
        className={classes.warning}
        style={{
          textAlign: "center",
          color: "grey",
        }}
      >
        <CircularProgress/>
      </div>
    );
  };

  render() {
    const {
      videoError,
      videoLoading,
      videoPresent,
    } = this.props;

    if (!videoPresent) {
      return null;
    } else if (videoLoading) {
      return this.renderLoading();
    } else if (videoError) {
      return this.renderWarning();
    } else {
      return this.renderPlayer();
    }
  }
}

export default withStyles(styles)(VideoPlayer);
