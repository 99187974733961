import * as React from "react";
import {
  Redirect,
  Route,
  RouteProps,
} from "react-router";
// Utils
import {
  ERole,
  hasRequiredRole,
  useRoles,
} from "@utils/roles";
// Components
import {
  Loading,
} from "@components/Loading/Loading";

interface IProps extends RouteProps{
  requiredRoles: ERole | ERole[];
}

/**
 * Ensures user has required roles
 * @param {ERole | ERole[]} requiredRoles
 * @param {any} rest
 * @constructor
 */
export const PrivateRoute: React.FC<IProps> = ({
  requiredRoles,
  ...rest
}: IProps): React.ReactElement => {
  const {
    roleList,
    loading,
  } = useRoles();

  if (loading) return (<Loading/>);

  if (!hasRequiredRole(roleList, requiredRoles)) {
    return <Redirect to="/"/>;
  }

  return (<Route {...rest} />);
};
