import * as React from "react";
import {
  getCurrentSession,
} from "@utils/auth";

interface IProps {
  checking?: React.ReactNode;
  noAuth: React.ReactNode;
  children: React.ReactNode;
}

class AuthRequired extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      checking: "<p>Checking Authentication</p>",
      isAuthenticated: null,
      noAuth: "<p>Authentication Required</p>",
      ...props,
    };
  }

  public async componentDidMount() {
    try {
      await getCurrentSession();
      await this.setState({
        isAuthenticated: true,
      });
    } catch (e) {
      await this.setState({
        isAuthenticated: false,
      });
    }
  }

  public render() {
    const {
      checking, children, isAuthenticated, noAuth,
    } = this.state;
    switch (isAuthenticated) {
    case true:
      return children;
    case false:
      return noAuth;
    default:
      return checking;
    }
  }
}

export default AuthRequired;
