import ApolloClient from "apollo-client";
import {
  createHttpLink,
} from "apollo-link-http";
import {
  setContext,
} from "apollo-link-context";
import {
  InMemoryCache,
} from "apollo-cache-inmemory";
import {
  getAccessToken,
} from "@utils/auth";
import {
  API_URL,
} from "@utils/env";

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
});

const authLink = setContext(async (_, {
  headers,
}) => {
  // get the authentication token
  const token = await getAccessToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient(({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
}));
