import * as React from "react";

import {
  Redirect,
} from "react-router-dom";
// Components
import AuthRequired from "@components/AuthRequired";
import OrganisationDashboardPage from "@pages/organisation/OrganisationDashboardPage";
import SchoolDashboardPage from "@pages/school/SchoolDashboardPage";
import StudentDashboardPage from "@pages/student/StudentDashboardPage";
// Utils
import {
  ERole,
  ERROR_UNRECOGNISED_ROLE,
  hasOrganisationRole,
  hasSchoolRole,
  hasStudentRole,
} from "@utils/roles";

interface IProps {
  roleList: ERole[];
}

class DashboardPageLayout extends React.Component<IProps, any> {
  public renderDashboard = () => {
    const {
      roleList,
    } = this.props;

    let dashboard: any = null;
    if (hasStudentRole(roleList)) {
      dashboard = <StudentDashboardPage/>;
    } else if (hasOrganisationRole(roleList)) {
      dashboard = <OrganisationDashboardPage/>;
    } else if (hasSchoolRole(roleList)) {
      dashboard = <SchoolDashboardPage/>;
    } else {
      console.info(ERROR_UNRECOGNISED_ROLE, ", redirecting to login", roleList);
    }

    if (!dashboard) {
      return this.renderRedirect();
    }

    return (
      <AuthRequired noAuth={<Redirect to="/signin"/>}>
        {dashboard}
      </AuthRequired>
    );
  };

  public renderRedirect = () => {
    return <Redirect to="/signin"/>;
  };

  public render() {
    return this.renderDashboard();
  }
}

export default DashboardPageLayout;
