import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

class NewContact extends React.Component<any, any> {
  state = {
    accountName: "",
    fullName: "",
    accountEmail: "",
    password: "",
  };

  public generatePassword = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let password = "Gr";
    for (let i = 0; i < 8; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    this.setState({
      password,
    });
  };

  public handleChange = (field) => ({
    target: {
      value,
    },
  }) => {
    this.setState({
      [field]: value,
    });
  };

  render() {
    const {
      open, onClose, onChange,
    } = this.props;
    const {
      accountName,
      fullName,
      accountEmail,
      password,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Invite industry partner
        </DialogTitle>
        <DialogContent style={{
          maxWidth: "376px",
        }}>
          <TextField
            placeholder="Account Name"
            value={accountName}
            fullWidth
            style={{
              background: "rgba(0, 0, 0, 0.076)",
              marginBottom: "12px",
            }}
            onChange={this.handleChange("accountName")}
          />
          <TextField
            placeholder="Full Name"
            value={fullName}
            fullWidth
            style={{
              background: "rgba(0, 0, 0, 0.076)",
              marginBottom: "12px",
            }}
            onChange={this.handleChange("fullName")}
          />
          <TextField
            placeholder="Account email"
            fullWidth
            value={accountEmail}
            style={{
              background: "rgba(0, 0, 0, 0.076)",
              marginBottom: "12px",
            }}
            onChange={this.handleChange("accountEmail")}
          />

          <Button
            style={{
              background: "rgba(0, 0, 0, 0.076)",
              marginBottom: "12px",
            }}
            fullWidth
            onClick={this.generatePassword}
          >
            GENERATE PASSWORD
          </Button>

          <TextField
            placeholder="Temporary password"
            value={password}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            style={{
              background: "rgba(0, 0, 0, 0.076)",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" autoFocus>
            Cancel
          </Button>
          <Button onClick={onChange(this.state)} color="primary" disabled={
            !password || !accountEmail || !accountName || !fullName
          }>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewContact;
