import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
// Styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  withStyles,
} from "@material-ui/core";
// Utils
import {
  AWS_S3_VIDEO_INPUT_BUCKET,
} from "@utils/env";
import {
  postApplication,
  updateApplicationVideoKey,
} from "@utils/api";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  IApplicationQuestion,
} from "@models/opportunity";
// Components
import {
  AlertLevel,
} from "@components/global.model";
import VideoResponseDialogContent
  from "@pages/opportunity/OpportunityPage/SubmitApplication/VideoResponseDialogContent";
import TextResponseDialogContent from "@pages/opportunity/OpportunityPage/SubmitApplication/TextResponseDialogContent";
import VideoUploadToS3 from "@components/UploadVideoInputComponent/utils/VideoUploadToS3";
import {
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";

/*
 * INTERFACES
 */
interface IProps {
  onSave: () => Promise<void>;
  onClose: () => void;
  open: boolean;
  studentId: string;
  opportunityId: number;
  applicationQuestions: IApplicationQuestion[];
  videoTimeLimit: number;
}

interface IPropsWithHOC extends IProps {
  classes?: any;
  dispatch: Dispatch;
}

interface IState {
  videoResponseSelected: boolean;
  applicationQuestionResponses: Map<number, string>;
}

/*
 * STYLES
 */
const styles = (theme: Theme) => ({
  paper: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  contentButton: {
    marginTop: theme.spacing.unit,
    width: "100%",
  },
});

/**
 * Dialog to submit a student application.
 * Allows for text or video response
 */
class SubmitApplicationModal extends React.Component<IPropsWithHOC, IState> {
  constructor(props) {
    super(props);
    this.state = {
      videoResponseSelected: true,
      applicationQuestionResponses: new Map<number, string>(),
    };
  }

  /**
   * Creates a new application
   * @param responses
   * @return true if successful, otherwise false
   */
  createApplication = async (responses: Map<number, string>): Promise<boolean> => {
    const {
      dispatch,
      opportunityId,
      onSave,
    } = this.props;

    try {
      await postApplication(opportunityId, responses);
      await onSave();
    } catch (e) {
      dispatch(newAlert({
        level: AlertLevel.ERROR,
        body: "Uh oh! Unable to submit application. Have you already applied?",
      }));
      return false;
    }

    return true;
  };

  /**
   * Try submit the selected video
   * @param video -
   */
  async handleVideoSubmit(video: IVideoInput) {
    // If video no selected, do nothing
    if (!video.file) {
      return;
    }

    // Create application
    const {
      dispatch,
      opportunityId,
      studentId,
      onClose,
    } = this.props;

    const createApplicationResult = await this.createApplication(new Map<number, string>());
    if (!createApplicationResult) {
      return;
    }

    try {
      // Upload video under path "application/opportunityID/student-ID"
      const uploader = new VideoUploadToS3({
        bucket: AWS_S3_VIDEO_INPUT_BUCKET || "grandshake-video-input",
      });
      const streamingKey: string = await uploader.uploadFile(
        video.file,
        `application/${opportunityId}/${studentId}`,
        dispatch,
      );
      await updateApplicationVideoKey(opportunityId, streamingKey);

      // Successful application
      dispatch(newAlert({
        level: AlertLevel.SUCCESS,
        body: "Application submitted successfully",
      }));
    } catch (e) {
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: "Uh oh! Video upload was unsuccessful",
        },
      ));
    }

    onClose();
  }

  handleTextSubmit = async (responses: { answers: string[] }) => {
    const {
      dispatch,
      applicationQuestions,
      onClose,
    } = this.props;

    // Convert responses into a map
    const responseMap: Map<number, string> = new Map<number, string>();
    for (let i = 0; i < applicationQuestions.length; i++) {
      responseMap.set(applicationQuestions[i].id, responses.answers[i]);
    }

    // Submit application
    const createApplicationResult = await this.createApplication(responseMap);
    if (!createApplicationResult) {
      return;
    }

    // Successful application
    dispatch(newAlert({
      level: AlertLevel.SUCCESS,
      body: "Application submitted",
    }));

    onClose();
  };

  // Toggles response type between video and text
  toggleResponseType() {
    this.setState((prev) => ({
      videoResponseSelected: !prev.videoResponseSelected,
    }));
  }

  // Render video response screen
  renderVideoOption() {
    const {
      applicationQuestions,
      videoTimeLimit,
    } = this.props;

    return (
      <VideoResponseDialogContent
        timeLimit={videoTimeLimit}
        applicationQuestions={applicationQuestions}
        onSubmit={this.handleVideoSubmit.bind(this)}
      />
    );
  }

  renderTextOption() {
    const {
      applicationQuestions,
    } = this.props;

    return (
      <TextResponseDialogContent
        applicationQuestions={applicationQuestions}
        onSubmit={this.handleTextSubmit.bind(this)}
      />
    );
  }

  public render() {
    const {
      open,
      classes,
      onClose,
    } = this.props;

    const {
      videoResponseSelected,
    } = this.state;

    const formBody: React.ReactNode = videoResponseSelected ? this.renderVideoOption() : this.renderTextOption();

    return (
      <React.Fragment>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          onClose={onClose}
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            Submit Interest
          </DialogTitle>

          <DialogContent>
            {formBody}
          </DialogContent>

          <DialogActions>
            <Button
              className={classes.contentButton}
              onClick={onClose}>
              cancel
            </Button>
            <Button
              className={classes.contentButton}
              onClick={this.toggleResponseType.bind(this)}>
              Switch response type
            </Button>
          </DialogActions>
        </Dialog>


      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  studentId: state.user && state.user.attributes && state.user.attributes.sub,
});

export default connect(mapStateToProps)(withStyles(styles)(SubmitApplicationModal));
