import {
  grey,
} from "@material-ui/core/colors";
import * as React from "react";
import styled from "styled-components";
// Component
import Layout from "@components/Layout";
import OpportunityListNew from "@pages/opportunity/components/OpportunityList";
// Utils
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
import {
  IOpportunityWithRelations,
} from "@models/opportunity";
import {
  fetchOpportunitiesNew,
} from "@actions/opportunity";

const Dashboard = styled.section`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${grey[100]};

  @media (max-width: 600px) {
    background: #FFF;
  }
`;

interface IProps {
  dispatch: Dispatch,
  opportunities: IOpportunityWithRelations[],
  loading: boolean;
}

interface IState {
}

class DashboardComponent extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props);
  }

  public componentDidMount() {
    // TODO Check cache first before doing this.
    this.props.dispatch(fetchOpportunitiesNew());
  }

  public render() {
    return (
      <Layout page="opportunityList">
        <Dashboard>
          <OpportunityListNew
            opportunities={this.props.opportunities}
            showDuration
            showAge
            showExploreButton
            showCreateOpportunity
          />

        </Dashboard>
      </Layout>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  opportunities: state.opportunities.opportunitiesNew,
  loading: state.globals.loading,
});

export default connect(
  mapStateToProps,
)(DashboardComponent);
