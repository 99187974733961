import * as React from "react";
import * as moment from "moment";
import Layout from "@components/Layout";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MuiThemeProvider,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Add,
  Edit,
  Face,
  KeyboardArrowLeft,
  Phone,
  School,
  Timer,
} from "@material-ui/icons";
import {
  grey,
  indigo,
  red,
} from "@material-ui/core/colors";

import {
  addExperienceToStudent,
  getProfileAction,
  updateProfileAction,
  updateUserAction,
} from "@actions/profile";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";

import EditSkillsModal from "@components/edit.skills.modal";
import {
  DataLoader,
} from "@components/DataLoader";
import PickSubjectsModal from "@components/pick.subjects.modal";
import NewExperienceModal from "@components/new.experience.modal";
import {
  newAlert,
} from "@actions/global";
import {
  withRouter,
} from "react-router";
import EditProfileMain from "@components/edit.profile.main";
import {
  AlertLevel,
} from "@components/global.model";

import {
  Base64,
} from "js-base64";
import {
  getProfilePictureUrl,
  putProfilePicture,
} from "@components/ProfilePicture/utils";
import {
  easyToReadFormat,
} from "@utils/dates";
import {
  GET_PROFILE_ERROR_MESSAGE,
  SkillsIHaveSchema,
  SkillsIWantSchema,
  SubjectsITakeSchema,
} from "@models/profile";
import {
  ISubject,
} from "@models/subject";
import {
  CreateExperienceSchema,
  ICreateExperience,
  ICreateExperienceRequest,
} from "@models/Experience";

declare interface IStudentWithRelations {
  id: string;
  school_id: number;
  intro: string;
  dob: Date;
  grade: number;
  gender_id: number;
  profile_picture: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  school_name: string;
  skill_has: any[];
  skill_wants: any[];
  experiences: any[];
  subjects: any[];
  completeness: any;
}

interface IProps {
  classes: any;
  dispatch: Dispatch;
  loading: boolean;
  student: IStudentWithRelations;
  history: any;
  canEdit: boolean;
}

interface IState {
  loading: boolean;
  profilePictureUrl: string | undefined;
  editStudent: IStudentWithRelations | null;
  editMain: boolean;
  editSubjects: boolean;
  editSkillsHave: boolean;
  editSkillsWant: boolean;
  editPassword: boolean;
  editExperience: boolean;
  editNewExperience: boolean;
  actionInProgress: boolean;
  showFinishedModal: boolean;
  openExperience: number;
}

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: indigo.A700,
    },
    secondary: red,
  },
});

const styles = (theme: Theme): any => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  profilePicture: {
    width: 96,
    height: 96,
  },
  sectionTwoChips: {
    marginTop: theme.spacing.unit,
  },
  profileChip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  card: {
    width: 320,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1.0rem",
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing.unit * 2,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundPosition: "0 0",
  },
  cardGroupOne: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  nonEditable: {
    opacity: 0.5,
  },
});

const progressPhrases: string[] = [
  "Tell us a little bit about yourself.",
  "Add some skills that you possess.",
  "Add some skills that you want to gain from future opportunities.",
  "Add some subjects that you're studying. Optionally, you can add some previous work experience you've had.",
];


class ProfilePage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      profilePictureUrl: undefined,
      editStudent: null,
      editMain: false,
      editSubjects: false,
      editSkillsHave: false,
      editSkillsWant: false,
      editPassword: false,
      editExperience: false,
      editNewExperience: false,
      actionInProgress: false,
      openExperience: -1,
      showFinishedModal: false,
    };
  }

  private async fetchProfilePicture(s3Key: string) {
    const {
      dispatch,
    } = this.props;

    try {
      // Try get profile picture
      const profilePictureURL: string | undefined = await getProfilePictureUrl(s3Key);

      // Update state with profile picture url
      this.setState({
        profilePictureUrl: profilePictureURL,
      });
    } catch (err) {
      console.error(err);
      dispatch(newAlert({
        level: AlertLevel.WARNING,
        body: "Uh oh! There was an issue while retrieving your profile picture",
      }));
    }
  }

  public async componentDidMount(): Promise<void> {
    const {
      dispatch,
      student,
    } = this.props;

    // If no student, fetch student details
    if (Object.keys(student).length == 0) {
      this.setState({
        loading: true,
      });

      dispatch(getProfileAction());
    } else {
      this.setState({
        loading: false,
        editStudent: student,
      });

      if (student.profile_picture) {
        await this.fetchProfilePicture(student.profile_picture);
      }
    }
  }

  public async UNSAFE_componentWillReceiveProps(props: IProps): Promise<void> {
    const {
      student,
    } = props;

    if (student) {
      this.setState({
        loading: false,
        editStudent: student,
      });

      if (student.profile_picture) {
        await this.fetchProfilePicture(student.profile_picture);
      }
    }
  }

  public scrollTop = (): void => {
    window.scrollTo(0, 0);
  };

  public editMain = (): void => {
    this.setState({
      ...this.state,
      editMain: true,
    });
  };

  public cancel = (): void => {
    const student: any = this.props.student;
    this.setState({
      ...this.state,
      editMain: false,
      editSkillsHave: false,
      editPassword: false,
      editSkillsWant: false,
      editSubjects: false,
      editExperience: false,
      editNewExperience: false,
      editStudent: student,
    });
  };

  public saveProfile = (): void => {
    const {
      dispatch,
    }: { dispatch: Dispatch, student: IStudentWithRelations } = this.props;
    const newStudent: any = this.state.editStudent;
    //  set state here to set editMain to be false
    this.setState({
      ...this.state,
      editMain: false,
      editSkillsHave: false,
      editSkillsWant: false,
      editSubjects: false,
      actionInProgress: true,
    }, async () => {
      try {
        //  if it resolves, then the profile will be different as
        //  it gets updated in the action, and props will deal with it
        await dispatch(updateProfileAction(newStudent));
      } catch (e) {
        //  if it rejects, revert it to the initial state.
        this.setState({
          ...this.state,
          editStudent: newStudent,
        });
      } finally {
        this.setState({
          ...this.state,
          actionInProgress: false,
        });
      }
    });
  };

  public changePassword = async (): Promise<void> => {
    this.setState({
      ...this.state,
      editPassword: false,
      actionInProgress: true,
    });

    try {
      //  TODO seems like Max just removed this. I have no idea wtf this is about.
      this.props.dispatch(newAlert({
        level: AlertLevel.SUCCESS,
        body: "Successfully updated password. Please relog in now.",
      }));
    } catch (e) {
      if (e.code === "LimitExceededException") {
        this.props.dispatch(newAlert({
          level: AlertLevel.ERROR,
          body: "Too many attempts. Please try again later.",
        }));
      } else if (e.code === "NotAuthorizedException") {
        this.props.dispatch(newAlert({
          level: AlertLevel.ERROR,
          body: "Incorrect old password.",
        }));
        this.setState({
          ...this.state,
          editPassword: true,
        });
      } else {
        this.props.dispatch(newAlert({
          level: AlertLevel.ERROR,
          body: "Uh oh! Something went wrong when updating your password.",
        }));
      }
    } finally {
      this.setState({
        ...this.state,
        actionInProgress: false,
      });
    }
  };

  public editSection = (section: string): void => {
    this.setState({
      ...this.state,
      [section]: true,
    });
  };

  public saveNewExperience = (experience: ICreateExperience): void => {
    const {
      dispatch,
    } = this.props;

    const {
      editStudent,
    } = this.state;

    const newExperience: ICreateExperienceRequest = {
      opportunity_type_id: experience.opportunity_type_id,
      title: experience.title,
      summary: experience.summary,
      company: experience.company,
      from: experience.from,
      to: !experience.experienceIsOngoing && experience.to ? experience.to : undefined,
      skill_ids: experience.skill_ids,
      tasks: experience.tasks,
    };

    this.setState(
      {
        editNewExperience: false,
        actionInProgress: true,
      },
      async (): Promise<void> => {
        try {
          if (editStudent !== null) {
            await dispatch(addExperienceToStudent(newExperience));
          } else {
            throw GET_PROFILE_ERROR_MESSAGE;
          }
        } catch (e) {
          await this.setState({
            editStudent: this.props.student,
          });
        } finally {
          await this.setState({
            actionInProgress: false,
          });
        }
      },
    );
  };

  public updateProfile = (key: string, value: any): void => {
    const newProfile: any = Object.assign({}, this.state.editStudent);
    newProfile[key] = value;
    this.setState({
      ...this.state,
      editStudent: newProfile,
    });
  };

  public setOpenExperience = (id: any): void => {
    this.setState({
      ...this.state,
      openExperience: id,
    });
  };

  public dismissFinishedModal = (): void => {
    this.setState({
      ...this.state,
      showFinishedModal: false,
    });
  };

  public updateMain = (formData: any): void => {
    //  This is a special update, as it concerns some data attached
    //  to the fundamental user. As a consequence, this will be
    //  split into two api calls, one for the student,
    //  and the other for the user.
    const student = this.state.editStudent;
    //  used to prevent linting errors of possibly null object
    if (!student) return;

    //  This model is used for updating the student
    const newStudent: any = {
      ...student,
      intro: formData.intro,
      dob: formData.dob,
      grade: formData.grade,
      phone: formData.phone,
      profile_picture: formData.profilePicture,
    };

    //  This model is used for updating user
    const newUser: any = {
      id: student.id,
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone: formData.phone,
      email: student.email,
    };

    this.setState({
      ...this.state,
      editMain: false,
      actionInProgress: true,
    }, async (): Promise<void> => {
      const dispatch: Dispatch = this.props.dispatch;
      try {
        //  if the profile picture has changed, upload new picture
        if (formData.profilePicture instanceof File && this.state.editStudent) {
          try {
            const fileName: string = Base64.encode(formData.profilePicture.name + "-" + this.state.editStudent.id) + "." + formData.profilePicture.type.split("/")[1];
            await putProfilePicture(fileName, formData.profilePicture);

            dispatch(newAlert({
              level: AlertLevel.SUCCESS,
              body: "Updated profile picture",
            }));
            newStudent.profile_picture = fileName;
          } catch (e) {
            dispatch(newAlert({
              level: AlertLevel.WARNING,
              body: "Uh oh! There was an issue uploading your profile picture.",
            }));
            throw Error("There was an issue uploading your profile picture.");
          }
        }

        // Send new updated values for the student's user and student attributes
        // Order is important - user data must be sent first so it gets returned by the profile update
        await dispatch(updateUserAction(newUser));
        await dispatch(updateProfileAction(newStudent));

        dispatch(newAlert({
          level: AlertLevel.WARNING,
          body: "Profile successfully updated",
        }));
      } catch (e) {
        dispatch(newAlert({
          level: AlertLevel.WARNING,
          body: "Uh oh! An error occurred while updating your profile.",
        }));

        this.setState({
          ...this.state,
          editStudent: this.props.student,
        });
      } finally {
        this.setState({
          ...this.state,
          actionInProgress: false,
        });
      }
    });
  };

  public render(): React.ReactNode {
    const {
      classes,
    } = this.props;
    const {
      editStudent, loading,
    } = this.state;
    if (!editStudent) return <div></div>;
    const hasEditStudentLoaded: boolean = editStudent !== null && Object.keys(editStudent).length > 0;
    const name: string = hasEditStudentLoaded ? editStudent.first_name + " " + editStudent.last_name : "";
    const intro: string = hasEditStudentLoaded ? editStudent.intro : "";
    const grade: string = hasEditStudentLoaded ? "Grade " + editStudent.grade : "";
    const school: string = hasEditStudentLoaded ? editStudent.school_name : "";
    const subjects: ISubject[] = hasEditStudentLoaded ? editStudent.subjects : [];
    const skillsIHave: any[] = hasEditStudentLoaded ? editStudent.skill_has : [];
    const skillsIWant: any[] = hasEditStudentLoaded ? editStudent.skill_wants : [];
    const experiences: any[] = hasEditStudentLoaded ? editStudent.experiences : [];
    const profilePicture: any = hasEditStudentLoaded ? editStudent.profile_picture : null;
    const phone = hasEditStudentLoaded ? editStudent.phone : null;
    const dob = hasEditStudentLoaded ? editStudent.dob : null;
    const initialMainData: any = {
      firstName: hasEditStudentLoaded ? editStudent.first_name : "",
      lastName: hasEditStudentLoaded ? editStudent.last_name : "",
      intro,
      grade: hasEditStudentLoaded ? editStudent.grade : 0,
      gender_id: hasEditStudentLoaded ? editStudent.gender_id : -1,
      phone: hasEditStudentLoaded ? editStudent.phone : -1,
      dob,
      profilePicture,
    };

    const completeness: any = hasEditStudentLoaded ? editStudent.completeness : {
      about: false,
      subjects: false,
      skill_has: false,
      skill_wants: false,
      overall: false,
    };

    const percentageComplete: number = completeness ? (
      (completeness.about ? 25 : 0) +
      (completeness.subjects ? 25 : 0) +
      (completeness.skill_has ? 25 : 0) +
      (completeness.skill_wants ? 25 : 0)
    ) : 0;

    /*
     * Notes: 9/9/19 - WA-277
     * As a part of the "guided profile setup feature", the user is required to step through the
     * different sections of their profile, completing one section before moving onto the next.
     * At the top of the page is a button that suggests to the user which section is to be completed next
     *
     * The following code is used to achieve this goal. At the time of writing, a bug exists. It means
     * that the page doesn't update when a user completes a section of their profile.
     *
     * The temp fix for this bug is to make that "guide" button refresh the page to force the page to update.
     */
    let currentStep: number = -1;
    const currentButton: any = <Button onClick={() => window.location.reload()}>
      Progress to next section
    </Button>;


    switch (true) {
    case !completeness.about:
      currentStep = 0;
      // currentButton = <Button onClick={this.editMain}>Add basic info</Button>
      break;
    case !completeness.skill_has:
      currentStep = 1;
      // currentButton = (
      //   <Button onClick={(): void => this.editSection("editSkillsHave")}>
      //     Add some skills
      //   </Button>
      // );
      break;
    case !completeness.skill_wants:
      currentStep = 2;
      // currentButton = (
      //   <Button onClick={(): void => this.editSection("editSkillsWant")}>
      //     Add some skills
      //   </Button>
      // );
      break;
    case !completeness.subjects:
      currentStep = 3;
      // currentButton = (
      //   <Button onClick={(): void => this.editSection("editSubjects")}>
      //     Add subjects
      //   </Button>
      // );
      break;
    default:
      currentStep = 4;
      // currentButton = null;
    }
    return (
      <MuiThemeProvider theme={theme}>
        <Layout>
          <DataLoader required>
            <div className={classes.root}>
              {loading ? (<CircularProgress size={48}/>) :
                (<React.Fragment>

                  <EditSkillsModal
                    open={this.state.editSkillsHave}
                    pickedSkills={skillsIHave}
                    onChange={(values: any[]) => this.updateProfile("skill_has", values)}
                    onClose={this.cancel}
                    onSave={this.saveProfile}
                    schema={SkillsIHaveSchema}
                  />

                  <EditSkillsModal
                    open={this.state.editSkillsWant}
                    pickedSkills={skillsIWant}
                    onChange={(values: any[]) => this.updateProfile("skill_wants", values)}
                    onClose={this.cancel}
                    onSave={this.saveProfile}
                    schema={SkillsIWantSchema}
                  />

                  <PickSubjectsModal
                    open={this.state.editSubjects}
                    selectedSubjects={subjects}
                    schema={SubjectsITakeSchema}
                    onChange={(values: ISubject[]) => this.updateProfile("subjects", values)}
                    onClose={this.cancel}
                    onSave={this.saveProfile}
                  />

                  {/*
                  <AddContactModal
                    open={this.state.editEmergencyContacts}
                    onCancel={this.cancel}
                    onClose={this.cancel}
                    onSave={this.saveNewContact}
                  />
                  */}

                  <NewExperienceModal
                    open={this.state.editNewExperience}
                    onClose={this.cancel}
                    onSave={this.saveNewExperience}
                    schema={CreateExperienceSchema}
                  />

                  <EditProfileMain
                    open={this.state.editMain}
                    onCancel={this.cancel}
                    onSave={this.updateMain}
                    initialData={initialMainData}
                  />

                  {/*
                  <ChangePasswordModal
                    open={this.state.editPassword}
                    onSave={this.changePassword}
                    onClose={this.cancel}
                  />
                  */}

                  {/* PROFILE COMPLETE MODAL */}
                  <Dialog
                    open={this.state.showFinishedModal}
                    onClose={this.dismissFinishedModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Congratulations! You're all done."}
                    </DialogTitle>

                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        You've finished up your profile. Now you can begin applying for opportunities.
                        You'll be able to apply for any of the opportunities that appear on the listings
                        page.
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                      <Button color="primary" autoFocus onClick={this.dismissFinishedModal}>
                        Okay!
                      </Button>
                    </DialogActions>
                  </Dialog>


                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "1.0rem",
                  }}>
                    <div style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: "1.0rem",
                    }}>
                      <Typography variant="display1">
                        Profile
                      </Typography>
                    </div>
                    <Button onClick={() => this.props.history.push("/")}>
                      <KeyboardArrowLeft style={{
                        marginRight: "0.5rem",
                      }}/>
                      Back
                    </Button>
                  </div>

                  {/* GUIDED PROFILE SETUP*/}
                  {(currentStep < 4) &&
                  <Card style={{
                    flex: 1,
                    marginBottom: "1.0rem",
                    width: "100%",
                  }} raised={true}>
                    <LinearProgress variant="determinate" value={percentageComplete}/>
                    <CardHeader
                      title="Complete your profile"
                      subheader="Before you can apply for any opportunities, we need to know a little bit more about you."
                      style={{
                        paddingBottom: "0.5rem",
                      }}
                    />

                    <CardContent style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}>
                      <Typography variant="caption" style={{
                        paddingBottom: "0.5rem",
                      }}
                      >
                              Start by completing the available sections. Once populated those sections, click the
                              “progress to next section” button.
                        <br/>
                        {" "}
                              This will allow you to continue building up your profile
                      </Typography>
                      <Typography variant="caption" style={{
                        fontWeight: 500,
                      }}>
                              Next up:
                        {progressPhrases[currentStep]}
                      </Typography>
                    </CardContent>

                    <CardActions>
                      {currentButton}
                    </CardActions>
                  </Card>
                  }

                  {/* General Details*/}
                  <div className={classes.cardGroupOne}>
                    <Card className={classes.card} raised={true}>
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar}>
                            {name.split(" ").map((s) => s.substring(0, 1)).join("").toUpperCase()}
                          </Avatar>
                        }
                        action={
                          <IconButton onClick={this.editMain}>
                            <Edit/>
                          </IconButton>
                        }
                        title={name}
                        subheader="Joined this year"
                      />
                      {this.state.profilePictureUrl != null && !this.state.profilePictureUrl.includes("/public/null?") ?
                        <CardMedia
                          className={classes.media}
                          image={this.state.profilePictureUrl}/> :
                        (
                          <CardContent
                            className={classes.media}
                          >

                            <Typography>
                              No profile picture added yet.
                            </Typography>
                          </CardContent>
                        )}
                      <CardContent style={{
                        flex: 1,
                      }}>

                        <Typography>
                          {intro ? intro : "No headline added yet."}
                        </Typography>

                      </CardContent>
                      <CardActions className={classes.actions} disableActionSpacing style={{
                        paddingLeft: "24px",
                        display: "inline",
                      }}>
                        <Chip className={classes.profileChip} label={grade} avatar={
                          <Avatar>
                            <Face/>
                          </Avatar>
                        }/>

                        <Chip className={classes.profileChip} label={school} avatar={
                          <Avatar>
                            <School/>
                          </Avatar>
                        }/>

                        {phone &&
                        (<Chip className={classes.profileChip} label={phone} avatar={
                          <Avatar>
                            <Phone/>
                          </Avatar>
                        }/>)
                        }

                        {dob &&
                        (<Chip className={classes.profileChip} label={moment(dob).format(easyToReadFormat).toString()}
                          avatar={
                            <Avatar>
                              <Timer/>
                            </Avatar>
                          }/>)
                        }
                      </CardActions>
                    </Card>

                    {/* SKILLS I HAVE*/}
                    <div style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}>
                      <Card style={{
                        marginBottom: "1.0rem",
                      }} raised={true} className={!completeness.about ? classes.nonEditable : null}>
                        <CardContent>
                          <CardHeader
                            style={{
                              padding: 0,
                              paddingBottom: "0.5em",
                              paddingRight: "0.5rem",
                            }}
                            action={

                              <IconButton onClick={(): void => this.editSection("editSkillsHave")}
                                disabled={!completeness.about}>
                                <Edit/>
                              </IconButton>
                            }
                            title={"Skills I have"}
                            subheader="Skills I have acquired from school or other activites"
                          />

                          <div className={classes.sectionTwoChips}>
                            {skillsIHave.map((skill: any): React.ReactNode => (
                              <Chip key={skill.id}
                                className={classes.profileChip} label={skill.name}/>
                            ))}
                            {skillsIHave.length === 0 && (
                              <Typography variant="subheading">
                                No skills added yet.
                              </Typography>
                            )}
                          </div>
                        </CardContent>
                      </Card>

                      {/* SKILLS I WANT*/}
                      <Card style={{
                        flex: 1,
                      }} raised={true} className={!completeness.skill_has ? classes.nonEditable : null}>
                        <CardContent>
                          <CardHeader
                            style={{
                              padding: 0,
                              paddingBottom: "0.5em",
                              paddingRight: "0.5rem",
                            }}
                            action={
                              <IconButton onClick={(): void => this.editSection("editSkillsWant")}
                                disabled={!completeness.skill_has}>
                                <Edit/>
                              </IconButton>
                            }
                            title={"Skills I want"}
                            subheader="Skills I am looking to acquire in future"
                          />

                          <div className={classes.sectionTwoChips}>
                            {skillsIWant.map((skill: any): React.ReactNode => (
                              <Chip key={skill.id}
                                className={classes.profileChip} label={skill.name}/>
                            ))}
                            {skillsIWant.length === 0 && (
                              <Typography variant="subheading">
                                No skills added yet.
                              </Typography>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>

                  {/* PRIOR EXPERIENCE*/}
                  <Card
                    style={{
                      flex: 1,
                      marginTop: "1.0rem",
                      width: "100%",
                      maxHeight: "320px",
                    }}
                    raised={true}
                    className={!completeness.skill_wants ? classes.nonEditable : null}
                  >
                    <CardContent>
                      <CardHeader
                        style={{
                          padding: 0,
                          paddingBottom: "0.5em",
                          paddingRight: "0.5rem",
                        }}
                        action={
                          <IconButton onClick={(): void => this.editSection("editNewExperience")}
                            disabled={!completeness.skill_wants}>
                            <Add/>
                          </IconButton>
                        }
                        title={"Prior experience"}
                        subheader="My previous work experience"
                      />

                      <List dense={true}>
                        {experiences.map((experience: any, index: number): React.ReactNode => (
                          <ListItem key={experience.id} style={{
                            paddingLeft: 0,
                          }}>
                            <Avatar style={{
                              width: 32,
                              height: 32,
                            }} color={grey[400]}>
                              {index + 1}
                            </Avatar>
                            <ListItemText primary={experience.title} secondary={experience.company}/>
                            <ListItemSecondaryAction>
                              <Chip
                                label={experience.opportunity_type}/>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>

                      {experiences.length === 0 && <Typography variant="subheading">
                          No prior experience added yet.
                      </Typography>}

                    </CardContent>
                  </Card>

                  {/* SUBJECTS*/}
                  <Card
                    style={{
                      flex: 1,
                      marginTop: "1.0rem",
                      width: "100%",
                      maxHeight: "320px",
                    }}
                    raised={true}
                    className={!completeness.skill_wants ? classes.nonEditable : null}
                  >
                    <CardContent>
                      <CardHeader
                        style={{
                          padding: 0,
                          paddingBottom: "0.5em",
                          paddingRight: "0.5rem",
                        }}
                        action={
                          <IconButton onClick={(): void => this.editSection("editSubjects")}
                            disabled={!completeness.skill_wants}>
                            <Edit/>
                          </IconButton>
                        }
                        title={"Subjects"}
                        subheader="Subjects I am currently enrolled in or have studied previously"
                      />
                      <List style={{
                        maxHeight: "172px",
                        overflow: "scroll",
                      }} dense={true}>
                        {subjects.map((subject: any, index: number): React.ReactNode => (
                          <React.Fragment key={subject.id}>
                            <ListItem style={{
                              paddingLeft: 0,
                            }}>
                              <Avatar style={{
                                width: 32,
                                height: 32,
                              }}>
                                {index + 1}
                              </Avatar>
                              <ListItemText primary={subject.name}/>
                            </ListItem>
                          </React.Fragment>
                        ))}
                        {subjects.length === 0 && <Typography variant="subheading">
                            No subjects added yet.
                        </Typography>}
                      </List>
                    </CardContent>
                  </Card>

                  {/* ACCOUNT MANAGEMENT*/}
                  <Card
                    style={{
                      flex: 1,
                      marginTop: "1.0rem",
                      width: "100%",
                      marginBottom: "1.0rem",
                    }}
                    raised={true}
                  >
                    <CardContent>
                      <CardHeader
                        style={{
                          padding: 0,
                          paddingBottom: "0.5em",
                          paddingRight: "0.5rem",
                        }}
                        title={"Account"}
                        subheader="Change your password, or deactivate your account."
                      />
                      <Button variant="raised" style={{
                        marginRight: "0.5rem",
                      }} color="primary" onClick={(): void => this.editSection("editPassword")}>
                        Change password
                      </Button>
                      <Button variant="raised" color="secondary" disabled>
                        Deactivate account
                      </Button>
                    </CardContent>
                  </Card>

                  {this.state.actionInProgress &&
                  <CircularProgress
                    style={{
                      position: "fixed",
                      bottom: "1.0rem",
                      left: "1.0rem",
                    }}
                    size={48}
                    color="secondary"/>
                  }
                </React.Fragment>)}
            </div>
          </DataLoader>
        </Layout>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  user: state.user,
  student: state.student || null,
  loading: state.globals.loading,
  subjects: state.data.subjects || {},
});

export default withRouter((withStyles(styles)(connect(mapStateToProps)(ProfilePage))) as any);
