import * as Yup from "yup";
import {
  YupDefaultTitleSchema,
} from "@models/validationHelpers/constants";

//
// Constants
//

export const ERROR = {
  GET_ORGANISATION: "Unable to get organisation",
  GET_ORGANISATION_LIST: "Unable to fetch list of organisations",
  CREATE_ORGANISATION: "Unable to create organisation",
  CREATE_PARTNERSHIP: "Unable to create partnership",
};

export const SUCCESS = {
  CREATE_ORGANISATION: "Successfully created organisation",
  CREATE_PARTNERSHIP: "Successfully created partnership",
};

//
// Interfaces
//

export interface IOrganisation extends ICreateUpdateOrganisation {
  id: number;
}

export interface ICreateUpdateOrganisation {
  name: string;
}

//
// Schemas
//

export const organisationSchema = Yup.object().shape({
  name: YupDefaultTitleSchema
    .required("Name Required"),
});
