import {
  Typography,
} from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
// Components
import ThankyouList from "@components/ThankyouList";

const ThankyouPage = styled.section``;

const thankyouItems = [
  "Keep a close eye on your email account. Check spam just incase!",
  "Your school officer will get in touch with you within the next few days.",
  "If your school is not on the list, leave it in our good hands and we will talk to them.",
  "Share with your friends",
];

class ThankyouPageComponent extends React.Component<any, any> {
  public render() {
    return (
      <ThankyouPage>
        <Typography variant="headline" gutterBottom>
          That's all for now!
        </Typography>
        <Typography
          style={{
            color: "rgba(0,0,0,0.6",
          }}>
          Next steps for you...
        </Typography>
        <ThankyouList secondary items={thankyouItems}/>
      </ThankyouPage>
    );
  }
}

export default ThankyouPageComponent;
