import {
  getSchoolContacts,
  getSchoolContactsSearch,
} from "@utils/api";
import {
  Dispatch,
} from "redux";
import {
  newAlert,
  setLoading,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";

export const getContacts = (limit: number, offset: number): any => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const contacts: any | any[] = await getSchoolContacts(limit, offset);
    dispatch(setLoading(false));
    return contacts;
  } catch (e) {
    console.error(e);

    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "An error occured when getting contacts",
    }));
  }
};
export const getContactsSearch = (searchString: string, limit: number, offset: number): any => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const contacts: any | any[] = await getSchoolContactsSearch(searchString, limit, offset);
    dispatch(setLoading(false));
    return contacts;
  } catch (e) {
    console.error(e);

    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "An error occured when getting contacts",
    }));
  }
};
