import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Provider,
} from "react-redux";
import {
  Router,
} from "react-router-dom";
import {
  ApolloProvider,
} from "@apollo/react-hooks";

import {
  store,
} from "@models/store";
import {
  history,
} from "@utils/history";

import App from "./app/App";
import "./index.scss";
import {
  client,
} from "./graphql";
// Configure AWS Amplify
import "@utils/api";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ApolloProvider client={client}>
        <App/>
      </ApolloProvider>
    </Router>
  </Provider>,
  document.getElementById("root"),
);
