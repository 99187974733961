import {
  gql,
} from "apollo-boost";
import {
  useQuery,
} from "@apollo/react-hooks";

//
// Constants
//

export enum ERole {
  STUDENT = "student",
  ORGANISATION = "organisation",
  SCHOOL = "school",
  ADMIN = "admin",
}

export const ERROR_COULD_NOT_GET_USER_ROLES: string = "Could not get user roles";
export const ERROR_UNRECOGNISED_ROLE: string = "Unrecognised role";

/**
 * Returns a list of the current user's roles
 * @param {string[] | undefined} roleList
 * @return {Promise<ERole[]>} role
 */
export const parseRoleList = (roleList: string[] | undefined): ERole[] => {
  const userRoles: ERole[] = [];
  if (roleList == null) return userRoles;

  for (const role of roleList) {
    switch (role) {
    case ERole.STUDENT:
      userRoles.push(ERole.STUDENT);
      break;
    case ERole.ORGANISATION:
      userRoles.push(ERole.ORGANISATION);
      break;
    case ERole.SCHOOL:
      userRoles.push(ERole.SCHOOL);
      break;
    case ERole.ADMIN:
      userRoles.push(ERole.ADMIN);
      break;
    default:
      console.error("parseRoleList - Unknown role:", role);
    }
  }

  return userRoles;
};


interface IUseRoles {
  loading: boolean;
  roleList: ERole[];
}

/**
 * Gets the roles of the current user.
 * @return {IUseRoles} Empty array on error, otherwise list of roles
 */
export const useRoles = (): IUseRoles => {
  const GET_SELF_ROLE_LIST = gql`
        query GetSelfRoles
        {
            getSelf {
                id
                roleList
            }
        }
    `;

  const {
    loading,
    error,
    data,
  } = useQuery(GET_SELF_ROLE_LIST, {
    onError: (error) => {
      console.error(ERROR_COULD_NOT_GET_USER_ROLES, error.message);
    },
  });

  if (error) {
    return {
      loading: false,
      roleList: [],
    };
  }

  return {
    loading,
    roleList: loading ? [] : parseRoleList(data.getSelf.roleList),
  };
};

/**
 * Checks that the user has the required roles, if any are given.
 * @param {ERole[]} userRoles - list of user roles
 * @param {ERole | ERole[] | undefined} requiredRoles - list of required roles
 * @return {boolean} True if no require roles given or if user has at least one of the required roles.
 * @return {boolean} False if user doesn't have any matching roles.
 */
export const hasRequiredRole = (userRoles: ERole[], requiredRoles?: ERole | ERole[]): boolean => {
  let hasRequiredRole: boolean = false;

  if (requiredRoles == null) {
    hasRequiredRole = true;
  } else if (!Array.isArray(requiredRoles)) {
    if (userRoles.includes(requiredRoles)) {
      hasRequiredRole = true;
    }
  } else if (Array.isArray(requiredRoles)) {
    for (const role of requiredRoles) {
      if (userRoles.includes(role)) {
        hasRequiredRole = true;
      }
    }
  }

  return hasRequiredRole;
};

/**
 * Returns true if the given user has the Student role
 * @param {ERole[]} roleList
 * @return {boolean}
 */
export const hasStudentRole = (roleList: ERole[]): boolean => {
  return hasRequiredRole(roleList, ERole.STUDENT);
};

/**
 * Returns true if the given user has the Organisation role
 * @param {ERole[]} roleList
 * @return {boolean}
 */
export const hasOrganisationRole = (roleList: ERole[]): boolean => {
  return hasRequiredRole(roleList, ERole.ORGANISATION);
};

/**
 * Returns true if the given user has the School role
 * @param {ERole[]} roleList
 * @return {boolean}
 */
export const hasSchoolRole = (roleList: ERole[]): boolean => {
  return hasRequiredRole(roleList, ERole.SCHOOL);
};

/**
 * Returns true if the given user has the Admin role
 * @param {ERole[]} roleList
 * @return {boolean}
 */
export const hasAdminRole = (roleList: ERole[]): boolean => {
  return hasRequiredRole(roleList, ERole.ADMIN);
};
