import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";
import * as React from "react";

import styled from "styled-components";
// Components
import {
  connect,
} from "react-redux";
import * as moment from "moment";
import {
  easyToReadFormat,
} from "@utils/dates";
import {
  getLastLocation,
} from "@utils/local.storage";

const OpportunityItemWrapper = styled.div`
  margin: 2rem 0;
`;

const OpportunityDetail = styled(Paper)`
  margin: 2rem 0;
  padding: 2rem;
`;

const SectionTitle = styled(Typography)`
  && {
    color: ${grey[400]};
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

const Description = styled.div`
  margin-bottom: 1.5rem;
`;

const ListItem = styled(Grid)`
  border-bottom: 1px ${grey[300]} solid;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const ListTitle = styled(Typography)`
  && {
    color: ${grey[900]};
    font-weight: 500;
    font-size: 1rem;
  }
`;

interface IProps {
  opportunity: any;
  data: any;
}

class OpportunityItemComponent extends React.Component<IProps, any> {
  public render() {
    const opportunity = this.props.opportunity;

    const data: any = this.props.data;
    const skills: any[] = data.skills ? data.skills.filter((skill) => opportunity.skillIds.includes(skill.id)) : [];
    const types: any[] = data.opportunity_types ? data.opportunity_types.filter((type) => opportunity.opportunityTypeIds.includes(type.id)) : [];
    let typeString = "";
    types.forEach((type) => {
      typeString += `${type.name}, `;
    });
    typeString = typeString.substr(0, typeString.length - 2);

    const startDate = opportunity.startDate ? opportunity.startDate.toString() : "";
    const endDate = opportunity.endDate ? opportunity.endDate.toString() : "";
    const closeDate = opportunity.closeDate ? opportunity.closeDate.toString() : "";

    return (
      <OpportunityItemWrapper>
        <OpportunityDetail elevation={1}>

          <Grid container>
            <Grid item xs={7}>
              <Typography variant="title" gutterBottom>
                {opportunity.title}
              </Typography>

              <SectionTitle gutterBottom>
                About the role
              </SectionTitle>
              <Description>
                <Typography>
                  {opportunity.description}
                </Typography>
              </Description>

              <ListItem container>
                <Grid item xs={5}>
                  <ListTitle>
                    Type of opportunity
                  </ListTitle>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    {typeString}
                  </Typography>
                </Grid>
              </ListItem>

              <ListItem container>
                <Grid item xs={5}>
                  <ListTitle>
                    Where
                  </ListTitle>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    {getLastLocation()}
                  </Typography>
                </Grid>
              </ListItem>

              <ListItem container>
                <Grid item xs={5}>
                  <ListTitle>
                    Applications close
                  </ListTitle>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    {moment(closeDate).format(easyToReadFormat)}
                  </Typography>
                </Grid>
              </ListItem>

              <ListItem container>
                <Grid item xs={5}>
                  <ListTitle>
                    Start Date
                  </ListTitle>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    {moment(startDate).format(easyToReadFormat)}
                  </Typography>
                </Grid>
              </ListItem>

              <ListItem container>
                <Grid item xs={5}>
                  <ListTitle>
                    End Date
                  </ListTitle>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    {moment(endDate).format(easyToReadFormat)}
                  </Typography>
                </Grid>
              </ListItem>


            </Grid>

          </Grid>

        </OpportunityDetail>

        <OpportunityDetail elevation={1}>
          <SectionTitle gutterBottom>
            What will I be doing?
          </SectionTitle>
          <ul>
            {opportunity.tasks && opportunity.tasks.map((task, i) => String(task).length > 0 && (
              <li key={i}>
                <Typography gutterBottom>
                  {task}
                </Typography>
              </li>
            ))}
          </ul>

        </OpportunityDetail>

        <OpportunityDetail elevation={1}>
          <SectionTitle gutterBottom>
            Skills I will be exposed to
          </SectionTitle>
          <ul>
            {skills.map((skill, i) => (
              <li key={i}>
                <Typography gutterBottom>
                  {skill ? skill.name : ""}
                </Typography>
              </li>
            ))}
          </ul>
        </OpportunityDetail>

      </OpportunityItemWrapper>
    );
  }
}

const mapStateToProps = (state: any) => ({
  data: state.data,
});

export default connect(mapStateToProps)(OpportunityItemComponent);
