import {
  deleteOpportunityNew,
  getOpportunitiesNew,
  getOpportunityNew,
  IHTTPResponse,
  updateOpportunity,
} from "@utils/api";
import {
  Dispatch,
} from "redux";

import {
  newAlert,
  setLoading,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";
import {
  IOpportunityWithRelations,
  Opportunity,
} from "@models/opportunity";
import {
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";

export const UPDATE_FILTERS: string = "UPDATE_FILTERS";
export const UPDATE_EXPLORE_VIEW: string = "UPDATE_EXPLORE_VIEW";
export const SET_OPPORTUNITY: string = "SET_OPPORTUNITY";
export const UPDATE_EDIT_MODE: string = "UPDATE_EDIT_MODE";
export const SET_OPPORTUNITIES: string = "SET_OPPORTUNITIES";
export const UPDATE_PERMISSIONS: string = "UPDATE_PERMISSIONS";

export const updateFilters = (filterOptions: any): any => {
  return {
    filterOptions,
    type: UPDATE_FILTERS,
  };
};

export const updateExploreView = (exploreView: number): any => {
  return {
    exploreView,
    type: UPDATE_EXPLORE_VIEW,
  };
};

export const sendUpdateOpportunity = (opportunity: Opportunity): any => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));

    // prepare data
    opportunity.skillIds = opportunity.skills.map((skill) => skill.id);
    delete opportunity.skills;

    opportunity.timePeriodIds = opportunity.time_periods.map((period) => period.id);

    await updateOpportunity(opportunity.id, opportunity);
    dispatch(newAlert({
      level: AlertLevel.SUCCESS,
      body: "Successfully updated the opportunity",
    }));
    dispatch(fetchOpportunitiesNew());
  } catch (e) {
    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "An error occurred while attempting to update the opportunity",
    }));
    dispatch(setLoading(false));
  }
};

export const setEditMode = (editMode: boolean): any => {
  return {
    editMode,
    type: UPDATE_EDIT_MODE,
  };
};


/** ****
 *
 *
 *   NEW OPPORTUNITY ACTIONS
 *
 *
 */

//  ! TODO Move this to the appropriate models folder
export interface ICreateOpportunityFormData {
  skillsToAdd: any;

  title: string;
  description: string;
  extraInformation: string;

  startDate?: Date;
  closeDate?: Date;
  endDate?: Date;
  ongoing: boolean,

  tasks: string[],
  workingHours: number[],

  locationId?: number | null;

  applicationQuestionIds: number[];
  prerequisitesIds: number[];
  otherPrerequisite: string, // If other is selected, provide prerequisite text

  opportunityTypeIds: number[],
  skillIds: number[],
  timePeriodIds: number[],
  shareWith: number[];
  pathToLocalVideo: IVideoInput,

}

export const SET_OPPORTUNITIES_NEW: string = "SET_OPPORTUNITIES_NEW";
export const SET_OPPORTUNITY_NEW: string = "SET_OPPORTUNITY_NEW";
export const UPDATE_APPLICATION_STATUS: string = "UPDATE_APPLICATION_STATUS";
export const updateApplicationStatus = (student_id: string, status: number): any => async (dispatch: Dispatch, getState: any): Promise<any> => {
  const state: any = getState();

  return updateStatusOfApplication(state.opportunities.opportunityNew.id, student_id, status);
};

/**
 * Redux Action: Returns object that represents an update to `opportunitiesNew` field with the given list of opportunities
 * @param {IOpportunityWithRelations[]} opportunitiesNew - the new list of opportunities
 */
export const setOpportunitiesNew = (opportunitiesNew: IOpportunityWithRelations[]): any => {
  return {
    opportunitiesNew,
    type: SET_OPPORTUNITIES_NEW,
  };
};

/**
 * Redux Action: Returns object that represents an update to `opportunityNew` field with the given opportunity
 * This field stores the "focused" opportunity.
 * @param {IOpportunityWithRelations} opportunityNew - the new
 */
export const setOpportunityNew = (opportunityNew: IOpportunityWithRelations | null): any => {
  return {
    opportunityNew,
    type: SET_OPPORTUNITY_NEW,
  };
};

/**
 * Returns the selected opportunity
 * Checks the redux store for any cached versions of the select opp.
 * If not in cache, it makes an API call to the backend and returns result
 * If the API call is unsuccessful, a snackbar is displayed with an error message
 * @param {number} id - opportunity id
 */
export const fetchOpportunityNew = (id: number): any => async (dispatch: Dispatch, getState: any): Promise<any> => {
  const state: any = getState();

  //  List of cached opportunities in global state from redux
  const opportunitiesInGlobalState: IOpportunityWithRelations[] = state.opportunities.opportunitiesNew;

  //  Cached opportunity in global state from redux. This would be the focused opportunity (if any)
  const opportunityFocusedInGlobalState: IOpportunityWithRelations | null = state.opportunities.opportunityNew;

  //  If the currently focused opportunity in global state has the correct id, do nothing.
  if (opportunityFocusedInGlobalState && opportunityFocusedInGlobalState.id === id) return;

  //  If there exists some cached opportunities in global state, look for one with the correct id
  if (opportunitiesInGlobalState.length) {
    //  Filter all opportunities in global state to only match the id provided in url.
    //  In the event that there is one (there should never be more than one)
    //  return it and update global state via reducer

    const opportunitiesInGlobalStateWithId: IOpportunityWithRelations[] =
      opportunitiesInGlobalState.filter((opportunity: IOpportunityWithRelations) => opportunity.id === id);

    //  return to update happens here
    if (opportunitiesInGlobalStateWithId.length) {
      dispatch(setOpportunityNew(opportunitiesInGlobalStateWithId[0])); // there should only ever be one
      return;
    }
  }
  //  If the block made it to here, it means that we need to find an opportunity by the id provided in the
  //  url. Do so below.
  dispatch(setLoading(true));

  try {
    const response: IHTTPResponse<IOpportunityWithRelations> = await getOpportunityNew(id);
    //  The api will return an array, however we should only expect one result
    dispatch(setOpportunityNew(response.data[0]));
  } catch (e) {
    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "An error occurred when fetching that opportunity :(",
    }));
  } finally {
    dispatch(setLoading(false));
  }
};

/**
 * Fetches a list of opportunities from the api.
 * Then, it dispatches an action to place them into the redux store
 */
export const fetchOpportunitiesNew = (): any => async (dispatch: Dispatch): Promise<any> => {
  try {
    dispatch(setLoading(true));

    const response: IHTTPResponse<IOpportunityWithRelations> = await getOpportunitiesNew();

    dispatch(setOpportunitiesNew(response.data));
  } catch (e) {
    console.error(e);
    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "An error occurred when fetching all opportunities",
    }));
  } finally {
    dispatch(setLoading(false));
  }
};

/**
 * An action that attempts to remove the selected opportunity.
 * Shows snackbar on success or failure
 * @param {number} id - opportunity id
 */
export const removeOpportunity = (id: number): (dispatch: Dispatch, getState: any) => Promise<void> => async (dispatch: Dispatch, getState: any): Promise<void> => {
  await dispatch(setLoading(true));

  // Attempt to delete opportunity, show snackbar if failure
  let successfullyDeleted: boolean = false;
  try {
    await deleteOpportunityNew(id);
    successfullyDeleted = true;
  } catch (e) {
    const errorMessage = "An error occurred while attempting to delete this opportunity";
    dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: errorMessage,
    }));
  }

  if (successfullyDeleted) {
    // Update local cache with list of opportunities
    dispatch(fetchOpportunitiesNew());

    // If this opportunity was the currently "focused" (cached) opportunity, clear the cached opportunity
    const state = getState();
    const focusedOpportunity: IOpportunityWithRelations | null = state.opportunities.opportunityNew;
    if (!!focusedOpportunity && focusedOpportunity.id === id) {
      dispatch(setOpportunityNew(null));
    }

    // Show success snackbar
    dispatch(newAlert({
      level: AlertLevel.SUCCESS,
      body: "Successfully deleted the opportunity",
    }));
  }

  await dispatch(setLoading(false));
};
