import * as React from "react";
// Styles
import {
  grey,
} from "@material-ui/core/colors";
import {
  withStyles,
} from "@material-ui/core/styles";
import {
  styles,
} from "./styles";
import {
  Layout,
} from "./styled";
// Components
import {
  FilterToolbar,
} from "@components/FilterToolbar";
import {
  NavData,
} from "@components/Nav/NavData";

interface IProps {
  background?: string;
  children?: React.ReactNode;
  greyOut?: boolean;
  nav?: React.ReactNode;
  onlyActive?: boolean;
  page: string;
  classes: any;
}

class LayoutComponent extends React.Component<IProps> {
  public render() {
    const {
      classes,
      children,
      background,
      nav,
      onlyActive,
      page,
    } = this.props;

    return (
      <Layout background={background || grey[100]}>
        {nav || <NavData/>}
        <div className={classes.root}>
          {(page === "opportunityList") && <FilterToolbar onlyActive={onlyActive}/>}
          <div className={classes.contentWrapper}>
            {children}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles)(LayoutComponent);
