import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
// Utils
import {
  hasAdminRole,
  hasOrganisationRole,
  hasSchoolRole,
  hasStudentRole,
  useRoles,
} from "@utils/roles";
// Models
import {
  IStudentWithRelations,
} from "@models/profile";
// Components
import Drawer from "@pages/opportunity/components/OpportunityList/Drawer";

interface IProps {
  student: IStudentWithRelations;
  dispatch: Dispatch;
  classes: any;
}
/*
 * Functional Component that is responsible for:
 * - providing data for the drawer component
 */
const DrawerDataFunction: React.FC<IProps> = (props: IProps): React.ReactElement<IProps> => {
  const {
    roleList,
  } = useRoles();

  // Get user roles - used for permissions and the student already applied check
  const isStudent: boolean = hasStudentRole(roleList);
  const isOrganisationUser: boolean = hasOrganisationRole(roleList);
  const isSchoolUser: boolean = hasSchoolRole(roleList);
  const isAdminUser: boolean = hasAdminRole(roleList);

  return (
    <Drawer
      classes={props.classes}
      student={props.student}
      dispatch={props.dispatch}
      canCreateOpportunity={isOrganisationUser}
      canViewAdminDashboard={isAdminUser}
      canViewContacts={isSchoolUser}
      canViewProfile={isStudent}
    />
  );
};

const mapStateToProps = (state) => ({
  student: state.student,
});

export const DrawerData = connect(mapStateToProps)(DrawerDataFunction);
