import {
  amber,
  green,
  red,
} from "@material-ui/core/colors";
import * as React from "react";

import styled from "styled-components";

const WebPreview = styled.div`
  border: 1px #cccccc solid;
  border-radius: 4px;
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px #cccccc solid;
  border-radius: 4px 4px 0 0;
  height: 30px;
  padding: 0 10px;
  background: #eeeeee;
`;

const TitleButton = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 10px;
  background: ${(props: any) => props.color};
`;

const TitleAddressBar = styled.div`
  background: white;
  flex: 1;
  border: 1px #cccccc solid;
  height: 14px;
  border-radius: 2px;
`;

const Content = styled.div``;

interface IProps {
  children: React.ReactNode;
  style: any;
}

class WebPreviewComponent extends React.Component<IProps, any> {
  public render() {
    const {
      children, style,
    } = this.props;
    return (
      <WebPreview style={style}>
        <TitleBar>
          <TitleButton color={red[700]}/>
          <TitleButton color={amber[700]}/>
          <TitleButton color={green[700]}/>
          <TitleAddressBar/>
        </TitleBar>
        <Content>
          {children}
        </Content>
      </WebPreview>
    );
  }
}

export default WebPreviewComponent;
