import * as React from "react";
import {
  Button,
  DialogContentText,
  Paper,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  IApplicationQuestion,
} from "@models/opportunity";
import {
  Form,
  Formik,
} from "formik";
import * as Yup from "yup";

/*
 * INTERFACES
 */
interface IProps {
  applicationQuestions: IApplicationQuestion[];
  onSubmit: ({
    answers,
  }: { answers: string[] }) => void;
}

interface IPropsWithStyles extends IProps {
  classes: any;
}

/*
 * STYLES
 */
const styles = (theme: Theme) => ({
  paper: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  contentButton: {
    marginTop: theme.spacing.unit,
    width: "100%",
  },
});

class TextResponseDialogContent extends React.Component<IPropsWithStyles> {
  render() {
    const {
      classes,
      applicationQuestions,
      onSubmit,
    } = this.props;

    if (!applicationQuestions) {
      return (

        <Paper className={classes.paper}>
          <Typography variant="subheading">
            No video questions given.
          </Typography>
        </Paper>

      );
    }

    const schema = Yup.object().shape({
      answers: Yup.array(
        Yup.string()
          .required("Answer Required")
          .max(300, "Response must be less than 300 words")
          .trim(),
      )
        .min(applicationQuestions.length)
        .max(applicationQuestions.length),
    });

    return (
      <Formik
        initialValues={{
          answers: applicationQuestions.map(() => ""),
        }}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
        render={
          ({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <Form>
                <DialogContentText>
                  {" "}
                  Pre screening Questionnaire:
                  {" "}
                </DialogContentText>
                <DialogContentText variant={"caption"}>
                  To be successful with your application, record a text response to each question
                </DialogContentText>

                {
                  applicationQuestions.map((question, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Paper className={classes.paper}>
                          <Typography variant="subheading">
                            <strong>
                              Question
                              {index + 1}
                              :
                            </strong>
                            {" "}
                            {question.question}
                          </Typography>
                        </Paper>

                        <TextField
                          className={classes.textField}
                          label={`Question ${index + 1} Answer`}
                          name={`answers.${index}`}
                          error={errors.answers && !!errors.answers[index]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.answers[index]}
                          helperText={(errors.answers && errors.answers[index]) && errors.answers[index]}
                          fullWidth
                        />
                      </React.Fragment>
                    );
                  })
                }

                <div>
                  <Button
                    className={classes.contentButton}
                    color="primary"
                    onClick={() => handleSubmit()}>
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }
        }

      />

    );
  }
}

export default withStyles(styles)(TextResponseDialogContent);
