import * as React from "react";
import {
  useState,
} from "react";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import {
  gql,
} from "apollo-boost";
import {
  useMutation,
} from "@apollo/react-hooks";
// Actions
import {
  newAlert,
} from "@actions/global";
// Utils
import {
  asyncForEach,
} from "@utils/array";
// Models
import {
  ERROR,
  SUCCESS,
} from "@models/organisation";
import {
  AlertLevel,
} from "@components/global.model";
// Components
import AddPartnershipsDialogLayout
  from "@components/Organisation/Partnerships/AddPartnershipsDialog/AddPartnershipsDialogLayout";
import {
  GET_SCHOOL_PARTNER_LIST_FOR_ORGANISATION,
} from "@components/Organisation/Partnerships/AddPartnershipsList/AddPartnershipsListData";
import {
  GET_ORGANISATION,
} from "@pages/admin/organisation/AdminOrganisationData";

//
// Constants
//

const CREATE_PARTNERSHIP = gql`
    mutation AddPartnershipsDialogData_CreatePartnership($organisationId: Int!, $schoolId: Int!) {
        createPartnership(partnership: {
            organisation: {
                id: $organisationId
            }
            school: {
                id: $schoolId
            }
        }) {
            organisation {
                id
            }
        }

    }
`;

//
// Interfaces
//

interface IProps {
  organisation: any;
  open: boolean;
  onClose: () => void;
}

interface IExtendedProps extends IProps {
  dispatch: Dispatch;
}

/**
 * Provides the AddPartnershipsDialog with needed data and mutations to add partnerships for
 * the given org
 * TODO: Add graphql types. https://grandshake.atlassian.net/browse/WA-458
 * @param {IExtendedProps} props
 * @return {React.ReactElement}
 */
const addPartnershipsDialogData: React.FC<IExtendedProps> = (props) => {
  const {
    dispatch,
    organisation,
    open,
    onClose,
  } = props;

  const [selectedSchoolIdList, setSelectedSchoolIdList] = useState<number[]>([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  // Mutation to create partnerships with school for the given organisation
  const [createPartnershipMutation] = useMutation(CREATE_PARTNERSHIP, {
    refetchQueries: () => [
      {
        query: GET_ORGANISATION,
        variables: {
          id: organisation.id,
        },
      },
      {
        query: GET_SCHOOL_PARTNER_LIST_FOR_ORGANISATION,
        variables: {
          organisationId: organisation.id,
        },
      },
    ],
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR.CREATE_PARTNERSHIP,
        },
      ));
    },
    onCompleted: () => {
      dispatch(newAlert(
        {
          level: AlertLevel.INFO,
          body: SUCCESS.CREATE_PARTNERSHIP,
        },
      ));
    },
  });

  // Creates partnership with each of the selected schools
  const createPartnershipsForSelectedSchools = async (idList: number[]) => {
    await asyncForEach(idList, async (id: number) => await createPartnershipMutation({
      variables: {
        organisationId: organisation.id,
        schoolId: id,
      },
    }));
  };

  const handleSubmit = async () => {
    await createPartnershipsForSelectedSchools(selectedSchoolIdList);
    setConfirmationDialogOpen(false);
    setSelectedSchoolIdList([]);
    onClose();
  };

  return (
    <AddPartnershipsDialogLayout
      organisation={organisation}
      selectedSchoolIdList={selectedSchoolIdList}
      onSelectedSchoolIdListChange={setSelectedSchoolIdList}
      confirmationDialogOpen={confirmationDialogOpen}
      setConfirmationDialogOpen={setConfirmationDialogOpen}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default connect()(addPartnershipsDialogData);
