import * as React from "react";
// Styles
import {
  Button,
  Theme,
  withStyles,
} from "@material-ui/core";
// Components
import LayoutComponent from "@components/Layout";
import MainPanel from "@components/MainPanel";
import DataTable, {
  ITableColumn,
} from "@components/Generic/DataTable";
import CreateOrganisationData from "@components/Organisation/CreateOrganisation/CreateOrganisationData";

//
// Styles
//
const styles = (theme: Theme) => ({
  button: {
    marginBottom: theme.spacing.unit,
  },
});

//
// Constants
//

const columns: ITableColumn[] = [
  {
    id: "name",
    Header: "Name",
  },
];

//
// Interfaces
//

interface IProps {
  data: any[];
  loading: boolean;
  createOrganisationDialogOpen: boolean;
  setCreateOrganisationDialogOpen: (open: boolean) => void;
  onOrganisationClick: (id: number) => void;
}

interface IExtendedProps extends IProps {
  classes: {
    button: any;
  }
}

/**
 * Layout for the admin organisation list
 */
class AdminOrganisationListLayout extends React.Component<IExtendedProps> {
  /**
   * Render
   * @return {React.Component}
   */
  render() {
    const {
      classes,
      loading,
      data,
      createOrganisationDialogOpen,
      setCreateOrganisationDialogOpen,
      onOrganisationClick,
    } = this.props;

    return (
      <LayoutComponent>
        <MainPanel title={"Organisations"}>
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={() => setCreateOrganisationDialogOpen(true)}
          >
            Add Organisation
          </Button>

          <CreateOrganisationData
            open={createOrganisationDialogOpen}
            onClose={() => setCreateOrganisationDialogOpen(false)}
          />

          <DataTable
            columns={columns}
            loading={loading}
            data={data}
            onRowPress={onOrganisationClick}
          />
        </MainPanel>
      </LayoutComponent>
    );
  }
}

export default withStyles(styles)(AdminOrganisationListLayout);
