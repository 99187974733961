import * as React from "react";
// Components
import {
  AdminNavActionsLayout,
} from "@components/Nav/AdminNavActions/AdminNavActionsLayout";

/*
 * Functional Component that is responsible for:
 * - providing data for the admin nav component
 */
export const AdminNavActionsData: React.FC = (): React.ReactElement => {
  return (
    <AdminNavActionsLayout />
  );
};
