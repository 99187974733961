/**
 * For each item in the array it will call the async callback
 * Returns only after all async callbacks have resolved
 * @param {array} array
 * @param {function} callback
 * @return {Promise<void>}
 */
export const asyncForEach = async <T>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => Promise<void>,
): Promise<void> => {
  const callbacks = array.map((item, index) => callback(item, index, array));
  await Promise.all(callbacks);
};
