import * as Joi from "@hapi/joi";
import {
  ISkill,
} from "@models/opportunity";
import {
  ISubject,
  SubjectSchema,
} from "@models/subject";
import {
  SkillSchema,
} from "@models/skill";
import {
  getLimitFromError,
} from "@models/validationHelpers";
import {
  IExperienceWithRelations,
} from "@models/Experience";
import {
  IUser,
} from "@models/user";

//
// Constants
//
export const STUDENT_PROFILE_MAX_SKILLS_I_HAVE = 12;
export const STUDENT_PROFILE_MIN_SKILLS_I_HAVE = 0;

export const STUDENT_PROFILE_MAX_SKILLS_I_WANT = 12;
export const STUDENT_PROFILE_MIN_SKILLS_I_WANT = 0;

export const STUDENT_PROFILE_MAX_SUBJECTS = 12;
export const STUDENT_PROFILE_MIN_SUBJECTS = 0;

export const GET_PROFILE_ERROR_MESSAGE = "Uh oh! Error retrieving profile.";

//
// Interfaces
//
export interface IStudentState {

}

export interface IStudent extends IUser {
  school_id: number;
  intro: string;
  dob: Date;
  grade: number;
  gender_id: number;
  profile_picture: string;

  school_name: string;
}

export interface IStudentCompleteness {
  about: boolean;
  subjects: boolean;
  skill_has: boolean;
  skill_wants: boolean;
  overall: boolean;
}

export interface IStudentWithRelations extends IStudent {
  skill_has: ISkill[];
  skill_wants: ISkill[];
  subjects: ISubject[];
  experiences: IExperienceWithRelations[];
  completeness?: IStudentCompleteness;
}

export interface ICreateUpdateStudent {
  id: string;
  school_id: number;

  intro: string;
  dob: string;
  grade: number;
  gender_id: number;
  profile_picture: string;

  skill_has: ISkill[],
  skill_wants: ISkill,
  subjects: ISubject[],
}

//
// Schema Definitions
//
export const SkillsIHaveSchema = Joi.array().items(
  SkillSchema,
).min(STUDENT_PROFILE_MIN_SKILLS_I_HAVE).max(STUDENT_PROFILE_MAX_SKILLS_I_HAVE).required().error((errors) => {
  errors.forEach((err) => {
    switch (err.type) {
    case "array.min":
      err.message = `Your skill list should have at least ${getLimitFromError(err)} skill(s)!`;
      break;
    case "array.max":
      err.message = `Your skill list should have at most ${getLimitFromError(err)} skills!`;
      break;
    default:
      break;
    }
  });
  return errors;
});

export const SkillsIWantSchema = Joi.array().items(
  SkillSchema,
).min(STUDENT_PROFILE_MIN_SKILLS_I_WANT).max(STUDENT_PROFILE_MAX_SKILLS_I_WANT).required().error((errors) => {
  errors.forEach((err) => {
    switch (err.type) {
    case "array.min":
      err.message = `Your skill list should have at least ${getLimitFromError(err)} skill(s)!`;
      break;
    case "array.max":
      err.message = `Your skill list should have at most ${getLimitFromError(err)} skills!`;
      break;
    }
  });
  return errors;
});

export const SubjectsITakeSchema = Joi.array().items(
  SubjectSchema,
).min(STUDENT_PROFILE_MIN_SUBJECTS).max(STUDENT_PROFILE_MAX_SUBJECTS).required().error((errors) => {
  errors.forEach((err) => {
    switch (err.type) {
    case "array.min":
      err.message = `Your subject list should have at least ${getLimitFromError(err)} subject(s)!`;
      break;
    case "array.max":
      err.message = `Your subject list should have at most ${getLimitFromError(err)} subjects!`;
      break;
    }
  });
  return errors;
});

export const CreateStudentSchema: Joi.ObjectSchema = Joi.object().keys({

  id: Joi.string().min(3).max(255).required(),
  school_id: Joi.number().min(1).required(),

  intro: Joi.string().min(0).max(255).required(),
  dob: Joi.date().required(),
  grade: Joi.number().min(1).max(12).required(),
  gender_id: Joi.number().min(0).max(2).required(),
  profile_picture: Joi.string().min(0).max(255).required(),

  skill_has: SkillsIHaveSchema,
  skill_wants: SkillsIWantSchema,
  subjects: SubjectsITakeSchema,
});
