import * as React from "react";
import {
  withRouter,
} from "react-router";
import {
  connect,
} from "react-redux";
import * as moment from "moment-timezone";
import {
  Button,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import {
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import OpportunityListItem from "@pages/opportunity/components/OpportunityListItem";
import {
  Burger,
  OpportinityListContainer,
} from "./styled";
import {
  styles,
  theme,
} from "./styles";
import {
  DrawerData,
} from "./DrawerData";
import {
  IOpportunityWithRelations,
} from "@models/opportunity";

moment.tz("Australia/Brisbane");

interface IProps {
  classes: any;
  opportunities: IOpportunityWithRelations[];
  filterOptions?: any;
  showAge?: boolean;
  showApplicants?: boolean;
  showApplyButton?: boolean;
  onlyActive?: boolean;
  showDuration?: boolean;
  showExploreButton?: boolean;
  showLocation?: boolean;
  showCreateOpportunity?: boolean;
  history: any;
}

interface IState {
  open: number;
  filteredOpportunities: IOpportunityWithRelations[];
  drawerShown: boolean;
}

class OpportunityListComponent extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props);
    this.state = {
      open: -1,
      filteredOpportunities: props.opportunities || [],
      drawerShown: false,
    };
  }

  //  OPTIMISE THIS FUNCTIONALITY PLS
  public filterOpportunities(opportunities: IOpportunityWithRelations[], filterOptions?: any): IOpportunityWithRelations[] {
    console.error("Filter not implmented", filterOptions);
    return opportunities;
    // if(!filterOptions) return opportunities;
    /*
    let filteredOpportunities: IOpportunityWithRelations[] = opportunities.filter((opportunity: IOpportunityWithRelations): {} => {

      const timeDiff: number = moment(opportunity.date_close).diff(moment(), 'days');

      let daysStillOpen: string = '';
      let openStatus: number = -1;

      switch (true) {
        case timeDiff === 0: { daysStillOpen = 'Closes today'; openStatus = 0; } break;
        case timeDiff < 0: { daysStillOpen = 'Closed'; openStatus = 1; } break;
        case timeDiff > 0: { daysStillOpen = timeDiff.toString() + ' days'; openStatus = 2; }
      }

      return {
        ...opportunity,
          daysStillOpen,
          openStatus,
      };
    });

    if (!filterOptions) {
      this.setState({
        ...this.state,
        open: -1,

        filteredOpportunities: filteredOpportunities.filter(opp => this.props.onlyActive ? (opp.openStatus == 0 || opp.openStatus == 2) : true),

      });
    } else {
      filteredOpportunities = filteredOpportunities.filter((opportunity: any): any => {

        let include: boolean = true;

        if (filterOptions.opportunity_types.length !== 0) {
          filterOptions.opportunity_types.forEach(type => include = include && opportunity.opportunityTypes.map(t => t.id).includes(type))
        }
        if (filterOptions.searchString !== '') {
          include = include &&
            opportunity.title.toLowerCase().includes(filterOptions.searchString.toLowerCase());
        }
        if (filterOptions.active || this.props.onlyActive) {
          include = include &&
            (opportunity.openStatus === 0 ||
              opportunity.openStatus === 2);
        }
        if (filterOptions.closed) {
          include = include &&
            opportunity.openStatus === 1;
        }
        return include;
      });

      this.setState({
        open: -1,
        filteredOpportunities,
      });

  } */
  }

  public UNSAFE_componentWillReceiveProps(props: IProps): void {
    this.setState({
      ...this.state,
      filteredOpportunities: this.filterOpportunities(props.opportunities, props.filterOptions),
    });
  }

  public componentDidMount(): void {
    this.setState({
      ...this.state,
      filteredOpportunities: this.filterOpportunities(this.props.opportunities, this.props.filterOptions),
    });
  }

  public setOpen = (id: number): void => {
    this.setState({
      ...this.state,
      open: id,
    });
  };

  // FIXME refactor this to use separate component
  public toggleDrawer = () => {
    this.setState({
      drawerShown: true,
    });
  };

  public closeDrawer = () => {
    this.setState({
      drawerShown: false,
    });
  };

  public render() {
    const {
      classes, ...showFlags
    } = this.props;
    const {
      open, drawerShown, filteredOpportunities,
    } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <SwipeableDrawer
          open={drawerShown}
          onOpen={this.toggleDrawer}
          onClose={this.closeDrawer}
        >
          <div className={classes.drawer}>
            <DrawerData classes={classes}/>
          </div>
        </SwipeableDrawer>
        <OpportinityListContainer>
          <div className={classes.root}>
            <Burger
              onClick={this.toggleDrawer}
            />
            <Typography
              variant="display1"
              className={classes.pageHeaderText}
            >
              Opportunities
            </Typography>
          </div>
          {!filteredOpportunities.length &&
          <div className={classes.noOpportunities}>
            <Typography
              variant="subheading"
              className={classes.noOpportunities}
            >
                  No opportunities to show.
            </Typography>
            {this.props.showCreateOpportunity &&
            <Button
              onClick={() => this.props.history.push("/opportunity/new")}
              variant="raised"
              style={{
                marginTop: "1.0rem",
                alignSelf: "flex-start",
              }}
            >
                Create an opportunity
            </Button>
            }
          </div>}
          {filteredOpportunities.length > 0 && filteredOpportunities.map((opportunity) =>
            <OpportunityListItem
              key={opportunity.id}
              opportunity={opportunity}
              setOpen={this.setOpen}
              open={open === opportunity.id}
              {...showFlags}
            />,
          )}
        </OpportinityListContainer>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    filterOptions: state.opportunities.filterOptions || null,
    loading: state.opportunities.loading || null,
  };
};

const OpportunityList = connect(
  mapStateToProps,
)(OpportunityListComponent);

export default withRouter(withStyles(styles)(OpportunityList as unknown as any));
