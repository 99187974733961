import * as React from "react";
import LayoutComponent from "@components/Layout";
import MainPanel from "@components/MainPanel";

/**
 * Layout for the admin dashboard
 */
export default class AdminDashboardLayout extends React.Component {
  /**
   * Render
   * @return {React.Component}
   */
  render() {
    return (
      <LayoutComponent>
        <MainPanel title="Dashboard">
          ...
        </MainPanel>
      </LayoutComponent>
    );
  }
}
