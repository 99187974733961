import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface IProps {
  open: boolean;
  onClose: (e: any) => any;
  onChange: (e: any) => any;
}

const DeleteModal = ({
  open, onClose, onChange,
}: IProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>
      {"Are you sure?"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {"Are you sure you want to remove \
            this opportunity? This is an irreversable action."}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Back
      </Button>
      <Button onClick={onChange} color="secondary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteModal;
