import * as React from "react";
import {
  gql,
} from "apollo-boost";
import {
  useMutation,
} from "@apollo/react-hooks";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  ERROR_CREATE_ORGANISATION_USER,
  SUCCESS_CREATE_ORGANISATION_USER,
} from "@models/organisationUser";
import {
  ICreateUser,
} from "@models/user";
import {
  IOrganisation,
} from "@models/organisation";
import {
  AlertLevel,
} from "@components/global.model";
// Components
import UserDialog from "@components/User/UserDialog";

//
// Constants
//

const CREATE_ORGANISATION_USER = gql`
    mutation CreateOrganisationUser(
        $organisationId: Int!,
        $firstName: String!,
        $lastName: String!,
        $email: String!,
        $username: String!,
        $phone: String,
        $password: String!,
    ) {
        createOrganisationUser(organisationUser: {
            organisation: {
                id: $organisationId,
            },
            user: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                username: $username
                phone: $phone
                password: $password
            }
        }) {
            organisation {
                id
            }
        }
    }
`;

//
// Interfaces
//

interface IProps {
  organisation?: IOrganisation;
  open: boolean;
  onClose: () => void;
}

interface IExtendedProps extends IProps{
  dispatch: Dispatch;
}

/**
 * Provides queries and mutations needed for a create user dialog
 * @param {IOrganisation | undefined} organisation
 * @param {boolean} open
 * @param {function} onClose
 * @param {Dispatch} dispatch
 * @return {React.ReactElement | null}
 */
const createOrganisationData: React.FC<IExtendedProps> = ({
  organisation,
  open,
  onClose,
  dispatch,
}: IExtendedProps): React.ReactElement | null=> {
  const [createOrganisationUserMutation] = useMutation(CREATE_ORGANISATION_USER, {
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR_CREATE_ORGANISATION_USER,
        },
      ));
    },
    onCompleted: () => {
      dispatch(newAlert(
        {
          level: AlertLevel.INFO,
          body: SUCCESS_CREATE_ORGANISATION_USER,
        },
      ));

      onClose();
    },
  });

  // Should do nothing if no organisation is given
  if (organisation == null) return null;

  const createUser = (user: ICreateUser) => createOrganisationUserMutation({
    variables: {
      organisationId: organisation.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      username: user.username,
      phone: user.phone,
      password: user.password,
    },
  });

  return (
    <UserDialog
      open={open}
      onClose={onClose}
      onSubmit={createUser}
      initialValues={{
        // eslint-disable-next-line camelcase
        first_name: "",
        // eslint-disable-next-line camelcase
        last_name: "",
        email: "",
        username: "",
        phone: "",
        password: "",
        confirmPassword: "",
      }}
    />
  );
};

export default connect()(createOrganisationData);
