export enum AlertLevel {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export interface AlertInfo {
  level: AlertLevel;
  body: string | React.ReactNode;
}

export interface Alert extends AlertInfo {
  timestamp: Date;
  action?: React.ReactNode;
}

//  Determines on the current opportunity what the user can
//  or cannot do

export interface GlobalsState {
  alertHistory: Alert[];
  mostRecentAlert: Alert | null;
  loading: boolean;
}
