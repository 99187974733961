import * as React from "react";
import Amplify, {
  Storage,
} from "aws-amplify";
import {
  LinearProgress,
} from "@material-ui/core";
import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_REGION,
} from "@utils/env";
import uuid from "uuid/v1";
import {
  newAlert,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";


interface Uploader {
  uploadFile: (file: File, path: string) => Promise<string>;
}

interface Props {
  bucket: string;
}

class VideoUploadToS3 implements Uploader {
  constructor(props: Props) {
    Amplify.configure({
      Storage: {
        bucket: props.bucket,
        region: AWS_REGION,
        identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
      },
    });
  }

  /**
   * Tool to upload a video to S3.
   * This may thow an error if anything goes wrong with the upload.
   * @param {File} file - video to upload
   * @param {string} path - bucket path for video - file will be renamed to a generated UUID
   * @param {Dispatch} dispatch - optional: redux dispatch for snackbar with progress
   * @return {string} video streaming key
   */
  public uploadFile = async (file: File, path: string, dispatch?): Promise<string> => {
    const key = `${path}/${uuid()}`;

    await Storage.put(key, file, {
      progressCallback(progress) {
        // If dispatch given, show progress in a snackbar
        if (dispatch) {
          const percentage = (progress.loaded / progress.total) * 100;

          if (percentage < 100) {
            dispatch(newAlert(
              {
                level: AlertLevel.INFO,
                body:
                  (
                    <React.Fragment>
                      <p>
                        Video Upload Status
                      </p>
                      <LinearProgress variant='determinate' value={percentage}/>
                    </React.Fragment>
                  ),
              },
            ));
          } else {
            dispatch(newAlert({
              level: AlertLevel.SUCCESS,
              body: "Video Upload Successful",
            }),
            );
          }
        }
      },
    });

    return `${key}_360.m3u8`;
  }
}

export default VideoUploadToS3;
