import * as React from "react";
// Styles
import {
  LogoStyled,
  StyledAppBar,
  StyledBar,
  StyledToolbar,
} from "./styled";
// Images
import logo from "@public/images/logo.svg";
import DefaultNavActionsData from "@components/Nav/DefaultNavActions/DefaultNavActionsData";
import {
  AdminNavActionsData,
} from "@components/Nav/AdminNavActions/AdminNavActionsData";

interface IProps {
  showAdminNav: boolean;
}

interface IState {
  active: boolean;
  userMenuAnchorEl?: EventTarget;
  contactMenuAnchorEl?: EventTarget;
}

/**
 * Base level Nav Bar: Adds logo and styling
 */
class NavComponent extends React.Component<IProps, IState> {
  /**
   * Render
   * @return {React.Component}
   */
  public render() {
    const {
      showAdminNav,
    } = this.props;

    return (
      <StyledAppBar position="static" elevation={0}>
        <StyledToolbar>
          <StyledBar>
            <LogoStyled src={logo}/>
            {
              showAdminNav ?
                <AdminNavActionsData /> :
                <DefaultNavActionsData />
            }
          </StyledBar>
        </StyledToolbar>
      </StyledAppBar>
    );
  }
}

export default NavComponent;
