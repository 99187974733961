import * as React from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";
import {
  withStyles,
} from "@material-ui/core/styles";
import {
  StoreMallDirectory,
} from "@material-ui/icons";
import styled from "styled-components";
// Utils
import {
  schoolSearchNew,
} from "@utils/api";
import {
  ISchool,
} from "@models/school";

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${grey[500]};
`;

const styles: any = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
  },
  suggestion: {
    display: "block",
  },
  suggestionsContainerOpen: {
    left: 0,
    marginTop: theme.spacing.unit,
    position: "absolute",
    right: 0,
    zIndex: 1,
    backgroundColor: "white",
  },
  suggestionsList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
});

interface IProps {
  classes: any;
  showIcon: boolean;
  label: string;
  onChange: (e: any) => void;
}

interface IState {
  loading: boolean;
  suggestions: ISchool[];
  value: string;
}

/**
 * This class enables the user to search for a school
 * using some or all of the school name
 */
class SchoolSearchComponent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      suggestions: [],
      value: "",
    };
  }

  /*
   * Decides if it should ask and present suggestions.
   */
  shouldRenderSuggestions = (value) => {
    return value.trim().length > 2;
  };

  /**
   * Will be called every time you need to recalculate suggestions.
   * @param {object} schoolNamePrefix
   */
  onSuggestionsFetchRequested: (schoolNamePrefix: { value: string, reason: string }) => Promise<void> = async (schoolNamePrefix: { value: string, reason: string }) => {
    await this.setState({
      loading: true,
    });
    const fetchResult = await schoolSearchNew(schoolNamePrefix.value);
    const suggestions: ISchool[] = fetchResult.data;
    const suggestionsLength: number = suggestions.length;

    await this.setState({
      suggestions: suggestionsLength === 0 ? [] : suggestions,
      loading: false,
    });
  };
  /*
   * Is called when the input value changes, updates local state value
   */
  onChange = (event, {
    newValue,
  }) => {
    this.setState({
      value: newValue,
    });
  };

  /**
   * Called when the user selects an input
   * @param event
   * @param suggestion
   */
  onSuggestionSelected = (event, {
    suggestion,
  }) => {
    this.setState({
      value: this.getSuggestionValue(suggestion),
    });

    this.props.onChange(suggestion);
  };

  /**
   * Will be called every time we need to clear the suggestions
   */
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  /**
   * Get display value for a given suggestion
   * @param {ISchool} suggestion
   */
  getSuggestionValue: (suggestion: ISchool) => string = (suggestion) => {
    return `${suggestion.name}`;
  };

  /**
   * Renders the and style the input
   * @param inputProps - comes from autosuggest component. You can add extra items from there
   */
  public renderInput = (inputProps) => {
    const {
      loading,
    } = this.state;
    const {
      ref, ...other
    } = inputProps;

    return (
      <TextField
        error={inputProps.error}
        fullWidth
        label={inputProps.label || "Search for a school"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconWrapper>
                {loading ? <CircularProgress size={24}/> : <StoreMallDirectory/>}
              </IconWrapper>
            </InputAdornment>
          ),
          inputRef: ref,
          ...other,
        }}
      />
    );
  };

  /**
   * Renders a suggestion. Bolds the matching part of the text
   * @param {ILocation} suggestion
   * @param query
   * @param isHighlighted
   */
  renderSuggestion = (suggestion, {
    query, isHighlighted,
  }) => {
    const suggestionText = this.getSuggestionValue(suggestion);
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => {
            return part.highlight ?
              (
                <span key={String(index)} style={{
                  fontWeight: 500,
                }}>
                  {part.text}
                </span>
              ) :
              (
                <strong key={String(index)} style={{
                  fontWeight: 300,
                }}>
                  {part.text}
                </strong>
              );
          })}
        </div>
      </MenuItem>
    );
  };

  /**
   * Renders a collection of suggestions
   * @param options
   */
  renderSuggestionsContainer = (options) => {
    const {
      containerProps, children,
    } = options;

    return (
      <Paper style={{
        maxHeight: 400,
        overflow: "auto",
      }} square {...containerProps}>
        {children}
      </Paper>
    );
  };

  public render() {
    const {
      classes,
      label,
    } = this.props;
    const {
      value, suggestions,
    } = this.state;

    const inputProps = {
      label,
      onChange: this.onChange,
      value,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionContainer={this.renderSuggestionsContainer}
        renderInputComponent={this.renderInput}
        // style
        inputProps={inputProps}
        theme={{
          container: classes.container,
          suggestion: classes.suggestion,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
        }}
        highlightFirstSuggestion
        focusInputOnSuggestionClick={false}
      />
    );
  }
}

export default withStyles(styles)(SchoolSearchComponent);
