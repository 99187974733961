import * as React from "react";
import Amplify, {
  Storage,
} from "aws-amplify";
import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_S3_VIDEO_OUTPUT_BUCKET,
} from "@utils/env";
import VideoStreamPlayer from "./index";

interface IProps {
  storageKey: string | null;
  playerHeight: number;
  playerWidth: number;
}

interface IState {
  videoLoading: boolean;
  videoError: boolean;
  src?: string;
}

const VIDEO_RETRY_INTERVAL = 5000;

/**
 * Input:   Storage Key
 * Output:  Checks to see if video exists and passes on information to VideoStreamPlayer.
 */
class StreamFromStorageKey extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      videoLoading: true,
      videoError: false,
    };

    Amplify.configure({
      Storage: {
        bucket: AWS_S3_VIDEO_OUTPUT_BUCKET,
        region: AWS_REGION,
        identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
      },
    });
  }

  /**
   * Attempts to get the request video from S3.
   * @param {string | null} videoPath
   */
  async findVideoResource(videoPath: string | null): Promise<void> {
    if (!videoPath) {
      return;
    }

    try {
      // get folder path and see if video exists
      const endPos = videoPath.lastIndexOf("/");

      if (endPos === -1) {
        throw Error("Couldn't find the video's folder path from the video key");
      }

      const folderPath = videoPath.slice(0, endPos);
      const folderKeys = await Storage.list(`${folderPath}/`);
      const videoFound = folderKeys.filter(({
        key,
      }) => key === videoPath);

      if (videoFound.length < 1) {
        throw Error("Video with given name is unavailable");
      }

      // Get streaming URL using presigned URL returned from s3
      const videoURL: any = await Storage.get(videoPath);

      this.setState({
        src: videoURL,
        videoLoading: false,
        videoError: false,
      });
    } catch (error) {
      // Something went wrong
      console.log(error);
      this.setState({
        videoError: true,
        src: undefined,
        videoLoading: false,
      });

      // Try again after a timeout
      setTimeout(() => this.findVideoResource(videoPath), VIDEO_RETRY_INTERVAL);
    }
  }

  /**
   * Component did mount: Attempts to find video
   */
  async componentDidMount(): Promise<void> {
    const {
      storageKey,
    } = this.props;

    await this.findVideoResource(storageKey);
  }

  /**
   * Render
   * @return {JSX.Element}
   */
  render() {
    const {
      src,
      videoLoading,
      videoError,
    } = this.state;

    const {
      storageKey,
    } = this.props;

    return (
      <VideoStreamPlayer
        {...this.props}
        videoPresent={storageKey != null}
        src={src}
        videoError={videoError}
        videoLoading={videoLoading}
      />);
  }
}

export default StreamFromStorageKey;
