import {
  Paper,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";

import styled from "styled-components";

export const FormPaper: any = styled(Paper)`
  padding: 4rem;
  @media(max-width: 768px) {
    padding: 2rem;
  }
`;

export const FormActions: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FormPageContainer: any = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormPage: any = styled.div`
  display: ${(props: any) => props.show ? "block" : "none"};
  width: 100%;
`;

export const FormControlContainer: any = styled.div`
  margin: 1rem auto;
  && * {
    ${(props: any) => props.error && `
      color: #f44336 !important;
    `}
  }
  && *:before {
    ${(props: any) => props.error && `
      border-color: #f44336 !important;
    `}
  }
`;

export const FormLabel: any = styled.label`
  color: #757575;
  line-height: 1.1875em;
`;

export const IconWrapper: any = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${grey[500]};
`;
