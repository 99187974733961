import * as React from "react";
import AnimateHeight from "react-animate-height";
import {
  FormError,
  FormErrors,
} from "./styled";

interface IProps {
  formErrors: any;
}

class FormErrorsComponent extends React.Component<IProps, any> {
  public render() {
    const {
      formErrors,
    } = this.props;
    const height = Object.keys(formErrors).length > 0 ? "auto" : 0;
    return (
      <AnimateHeight height={height}>
        <FormErrors>
          {Object.keys(formErrors).map((field) => (
            <FormError key={field}>
              {formErrors[field]}
            </FormError>
          ))}
        </FormErrors>
      </AnimateHeight>
    );
  }
}

export default FormErrorsComponent;
