import {
  Dispatch,
} from "redux";
import {
  addExperienceNew,
  getStudentNew,
  IHTTPResponse,
  updateStudentNew,
  updateUser,
} from "@utils/api";
import {
  AlertLevel,
} from "@components/global.model";
import {
  newAlert,
  setLoading,
} from "@actions/global";
import {
  IUpdateUserRequest,
  IUser,
} from "@models/user";
import {
  GET_PROFILE_ERROR_MESSAGE,
  ICreateUpdateStudent,
  IStudentWithRelations,
} from "@models/profile";
import {
  ICreateExperienceRequest,
} from "@models/Experience";

export const SET_STUDENT = "SET_STUDENT";

export const setStudent = (student: any): any => ({
  type: SET_STUDENT,
  student,
});

/**
 * Action that gets the current student's profile.
 * Updates the redux store with the student as the focused student
 * if found, returns that student
 */
export const getProfileAction = (): any => async (dispatch: Dispatch): Promise<IStudentWithRelations | void> => {
  await dispatch(setLoading(true));
  try {
    const studentResponse: IHTTPResponse<IStudentWithRelations> = await getStudentNew();
    const student: IStudentWithRelations = studentResponse.data[0];

    await dispatch(setStudent(student));

    return student;
  } catch (e) {
    console.error(e);
    await dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: GET_PROFILE_ERROR_MESSAGE,
    }));
  } finally {
    await dispatch(setLoading(false));
  }
};

/**
 * Action that gets the a student's profile by id
 * Updates the redux store with the found student as the focuesd student.
 * if found, returns the found student
 * @param {string} id - id of the student being requested
 */
export const getStudentAction = (id: string): any => async (dispatch: Dispatch): Promise<IStudentWithRelations | void> => {
  await dispatch(setLoading(true));
  try {
    // Find student by Id
    const studentResponse: IHTTPResponse<IStudentWithRelations> = await getStudentNew(id);
    const student: IStudentWithRelations = studentResponse.data[0];

    // Update redux store
    await dispatch(setStudent(student));

    return student;
  } catch (e) {
    console.error(e);
    await dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: GET_PROFILE_ERROR_MESSAGE,
    }));
  } finally {
    await dispatch(setLoading(false));
  }
};

/**
 * Callable by a user: makes an API call to update fields of that user.
 * @param {IUpdateUserRequest} newUserData
 * @return {Promise<IUser | void>} User, if found. otherwise, void
 */
export const updateUserAction = (newUserData: IUpdateUserRequest): any => async (dispatch: Dispatch): Promise<IUser | void> => {
  await dispatch(setLoading(true));
  try {
    const userDataToUpdate = {
      first_name: newUserData.first_name,
      last_name: newUserData.last_name,
      phone: newUserData.phone,
    };

    // Update user
    const userResponse: IHTTPResponse<IUser> = await updateUser(userDataToUpdate);
    const user: IUser = userResponse.data[0];

    return user;
  } catch (e) {
    console.error(e);
    await dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "Uh oh! Error updating profile.",
    }));
  } finally {
    await dispatch(setLoading(false));
  }
};

/**
 * Callable by a student: makes an API call to update fields on their profile.
 * (To update "user" values of a student, call the update userAction)
 * @param {ICreateUpdateStudent} student
 * @return the updated student. If not found, void
 */
export const updateProfileAction = (student: ICreateUpdateStudent): any => async (dispatch: Dispatch): Promise<ICreateUpdateStudent | void> => {
  await dispatch(setLoading(true));
  try {
    //  Transform data object here by stripping unnecessary data.
    const studentModel: ICreateUpdateStudent = {
      id: student.id,
      school_id: student.school_id,
      intro: student.intro,
      dob: student.dob,
      grade: student.grade,
      gender_id: student.gender_id,
      profile_picture: student.profile_picture,
      skill_has: student.skill_has,
      skill_wants: student.skill_wants,
      subjects: student.subjects,
    };

    // Update profile of student
    const studentResponse: IHTTPResponse<ICreateUpdateStudent> = await updateStudentNew(studentModel);
    const updatedStudent: ICreateUpdateStudent = studentResponse.data[0];

    // Update redux
    await dispatch(setStudent(updatedStudent));

    return updatedStudent;
  } catch (e) {
    console.error(e);
    await dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "Uh oh! Error updating profile.",
    }));
    return;
  } finally {
    await dispatch(setLoading(false));
  }
};

/**
 * Callable by a student to add an experience to their profile
 * Will add experience to student in db
 * Update redux state with updated student data
 * return updated student data
 * @param {ICreateExperienceRequest} experience - experience to add to profile
 */
export const addExperienceToStudent = (experience: ICreateExperienceRequest): any => async (dispatch: Dispatch): Promise<IStudentWithRelations | void> => {
  await dispatch(setLoading(true));
  try {
    // Add new experience
    const updatedStudentResponse: IHTTPResponse<IStudentWithRelations> = await addExperienceNew(experience);
    const student: IStudentWithRelations = updatedStudentResponse.data[0];

    // Update redux
    await dispatch(setStudent(student));

    await dispatch(newAlert({
      level: AlertLevel.SUCCESS,
      body: "Successfully added new work experience.",
    }));

    return student;
  } catch (e) {
    console.error(e);
    await dispatch(newAlert({
      level: AlertLevel.ERROR,
      body: "Uh oh! Error adding new work experience.",
    }));
  } finally {
    await dispatch(setLoading(false));
  }
};
