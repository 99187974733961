import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

declare interface IProps {
  onClose(): void;

  onStartNew(): void;

  open: boolean;
}

export const ContinueModal: React.SFC<IProps> = (props: IProps): React.ReactElement<any> => (
  <Dialog
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">
      Continue opportunity?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        It seems you were working on a previous opportunity. Would you like to continue, or start again?
      </DialogContentText>

    </DialogContent>
    <DialogActions>
      <Button onClick={props.onStartNew} color="primary">
        Start again
      </Button>
      <Button onClick={props.onClose} color="primary">
        Continue
      </Button>
    </DialogActions>
  </Dialog>
);

