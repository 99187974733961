import * as Yup from "yup";
// Models
import {
  YupDefaultPhoneSchema,
  YupDefaultEmailSchema,
  YupDefaultPasswordSchema,
  YupDefaultStringSchema,
} from "@models/validationHelpers/constants";

//
// Constants
//

const initialState = null;

const SET_USER = "set_user";

export const ERROR_COULD_NOT_FIND_SELF: string = "Could not find the current user";

//
// Interfaces
//

interface IBaseUser {
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
  email: string; //  immutable
  username: string; //  immutable
  phone?: string;
}

export interface ICreateUser extends IBaseUser {
  password: string;
  confirmPassword: string;
}

export interface IUserInput {
  id: string;
}

export interface IUser extends IUserInput, IBaseUser {}
export interface IUpdateUser extends IUserInput, Partial<IBaseUser> {}


// This type represents the user data returned by Cognito
export interface IAuthenticatedUser {
  id: string;
  username: string;
  attributes: {
    sub: string;
    email: string;
    ["custom:role"]: string;
  };
}

export interface IUpdateUserRequest {
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
  phone: string;
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

function setUserHandler(state, {
  user,
}) {
  return user;
}

const actionHandlers = {
  [SET_USER]: setUserHandler,
};

const reducer = (state = initialState, action: any = {}) => {
  let newState = state;
  if (typeof actionHandlers[action.type] === "function") {
    newState = actionHandlers[action.type](state, action);
  }
  return newState;
};

//
// Schemas
//

export const createUserSchema = Yup.object().shape({
  // eslint-disable-next-line camelcase
  first_name: YupDefaultStringSchema
    .required("First Name Required"),
  // eslint-disable-next-line camelcase
  last_name: YupDefaultStringSchema
    .required("Last Name Required"),
  email: YupDefaultEmailSchema
    .required("Email Required"),
  username: YupDefaultStringSchema
    .required("Username Required"),
  phone: YupDefaultPhoneSchema,
  password: YupDefaultPasswordSchema
    .required("Password Required"),
  confirmPassword: Yup.string()
    .required("Confirm Password Required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export default {
  initialState,
  reducer,
};
