import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface IProps {
  open: boolean;
  handleConfirm: () => Promise<void> | void;
  handleClose: () => Promise<void> | void;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationDialog: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    handleConfirm,
    handleClose,
    title = "Confirm action",
    message = "",
    confirmText = "Confirm",
    cancelText = "Cancel",
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {cancelText}
        </Button>

        <Button onClick={handleConfirm} color={"primary"}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
