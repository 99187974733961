import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from "@material-ui/core";
import {
  get,
} from "lodash";
import * as React from "react";
import {
  connect,
} from "react-redux";
import styled from "styled-components";
// Components
import {
  IMultipageFormProps,
  IMultipageFormState,
  MultipageFormPage,
} from "@components/FormComponents";
import {
  FormControlContainer,
  FormLabel,
} from "@components/FormComponents/styled";
import OptionButtons from "@components/OptionButtons";
import SuburbSearch from "@components/SuburbSearch";
// Containers
import {
  DataLoader,
} from "@components/DataLoader";
import TasksList from "./TasksList";
import SkillsList from "./SkillsList";
import WorkingSchedule from "./WorkingSchedule";
import WorkingDates from "./WorkingDates";

const NewOpportunityFormPage1 = styled.div``;

interface IProps extends IMultipageFormProps {
  data: any;
  formData: any;
  formErrors: any;
  addItem: (e: any) => any;
  onChange: (e: any) => any;
  onChangeArray: (e: any) => any;
}

interface IState extends IMultipageFormState {
}

class KeyDetails extends MultipageFormPage<IProps, IState> {
  public render() {
    const {
      formErrors,
      addItem,
      onChange,
      onChangeArray,
    } = this.props;
    const data = this.props.data || {};
    const formData = this.props.formData || {
      description: "",
      opportunityTypeIds: [],
      prerequisitesIds: [],
      timePeriodIds: [],
      title: "",
      workingHours: 1,
    };

    const {
      opportunityTypeIds,
      timePeriodIds,
      prerequisitesIds,
      tasks,
    } = formData;

    return (
      <NewOpportunityFormPage1>
        <FormControlContainer>
          <FormControl fullWidth error={!!formErrors.opportunityTypeIds}>
            <FormLabel>
              Type of opportunity
            </FormLabel>
            <FormHelperText>
              Type of opportunity
            </FormHelperText>
            <DataLoader required>
              <OptionButtons
                multiple
                selected={opportunityTypeIds || []}
                options={get(data, "opportunity_types", {})}
                onChange={onChangeArray("opportunityTypeIds")}
                confirmationRequired
              />
            </DataLoader>
          </FormControl>
        </FormControlContainer>

        <FormControlContainer>
          <FormControl fullWidth error={!!formErrors.title}>
            <InputLabel htmlFor="title">
              Title
            </InputLabel>
            <Input
              id="title"
              type={"text"}
              value={this.props.formData.title}
              onChange={onChange("title")}
              autoComplete="off"
            />
            <FormHelperText>
              For example: Retail assistant (up to 64 characters)
            </FormHelperText>
          </FormControl>
        </FormControlContainer>

        <FormControlContainer>
          <FormControl fullWidth error={!!formErrors.description}>
            <InputLabel htmlFor="description">
              Short introduction
            </InputLabel>
            <Input
              id="description"
              type={"text"}
              multiline
              value={this.props.formData.description}
              onChange={onChange("description")}
              autoComplete="off"
            />
            <FormHelperText>
              Catch their attention with a short description of the role (up to 600 characters)
            </FormHelperText>
          </FormControl>
        </FormControlContainer>

        <TasksList
          tasks={tasks}
          onChange={onChange}
          formErrors={formErrors}
          addItem={addItem("tasks")}
        />

        <SkillsList
          data={data}
          onChangeArray={onChangeArray}
          formErrors={formErrors}
          formData={formData}
        />

        <FormControlContainer error={!!formErrors.locationId} style={{
          margin: "75px 0",
        }}>
          <InputLabel htmlFor="description">
            Location
          </InputLabel>
          <FormControl fullWidth>
            <SuburbSearch
              id="suburb"
              type={"text"}
              onChange={onChange("locationId")}
              showIcon={false}
            />
          </FormControl>
          <FormHelperText>
            Enter suburb or postcode
          </FormHelperText>
        </FormControlContainer>

        <FormControlContainer>
          <FormControl fullWidth error={!!formErrors.timePeriodIds}>
            <FormLabel>
              When
            </FormLabel>
            <FormHelperText>
              When will the person need to attend
            </FormHelperText>
            <DataLoader required>
              <OptionButtons
                multiple
                selected={timePeriodIds}
                options={get(data, "attendance_types", {})}
                onChange={onChangeArray("timePeriodIds")}
              />
            </DataLoader>
          </FormControl>
        </FormControlContainer>

        <WorkingSchedule
          data={data}
          formData={formData}
          formErrors={formErrors}
          onChange={onChange}
        />

        <FormControlContainer>
          <FormControl fullWidth error={!!formErrors.prerequisitesIds}>
            <FormLabel>
              Prerequisites
            </FormLabel>
            <FormHelperText>
              Select from a list of key factors influencing the role
            </FormHelperText>
            <DataLoader required>
              <OptionButtons
                multiple
                selected={prerequisitesIds}
                options={get(data, "prerequisites", {})}
                onChange={onChangeArray("prerequisitesIds")}
              />
              {prerequisitesIds.includes(8) && (
                <TextField
                  style={{
                    marginTop: "0.5rem",
                  }}
                  id="other-prerequisite"
                  label="Other"
                  value={this.props.formData.otherPrerequisite}
                  error={!!formErrors.prerequisitesIds}
                  onChange={onChange("otherPrerequisite")}
                  helperText="Enter a different prerequisite"
                />
              )}
            </DataLoader>
          </FormControl>
        </FormControlContainer>

        <WorkingDates
          formErrors={formErrors}
          formData={formData}
          onChange={onChange}
        />
      </NewOpportunityFormPage1>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeyDetails);
