import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
import {
  useMutation,
  useQuery,
} from "@apollo/react-hooks";
import {
  gql,
} from "apollo-boost";
import {
  hasOrganisationRole,
  useRoles,
} from "@utils/roles";
import {
  IAuthenticatedUser,
} from "@models/user";
import {
  applicationListFragments,
  ApplicationListLayout,
} from "./ApplicationListLayout";
import {
  newAlert,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";

declare interface IProps {
  opportunityId: number;
  history: any;
  user: IAuthenticatedUser;
}

interface ExtendedIProps extends IProps {
  dispatch: Dispatch;
}

const GET_APPLICATIONS_QUERY = gql`
    query GetApplicationListQuery($id: Int!)
    {
        getApplicationList(opportunity: {id: $id}) {
            ...ApplicationListApplication
        }
    }
    ${applicationListFragments.application}
`;

const UPDATE_APPLICATION_STATUS_MUTATION = gql`
    mutation UpdateAppplicationStatusMutation($application: ApplicationInput, $status: ApplicationStatusInput) {
        updateApplicationStatus(application: $application, status: $status) {
            id
            name
            transitionName
        }
    }
`;

/*
 * Functional Component that is responsible for:
 * - fetching applications
 * - providing mutations to the layout component.
 * - Error handling via dialogs
 * TODO: Generate types using apollo codegen
 */
const ApplicationListComponent: React.FC<ExtendedIProps> = (props: ExtendedIProps): React.ReactElement<any> => {
  const {
    opportunityId,
    dispatch,
  } = props;

  const {
    loading: getApplicationsLoading,
    error: getApplicationsError,
    data: getApplicationsData,
  } = useQuery(GET_APPLICATIONS_QUERY, {
    variables: {
      id: opportunityId,
    },
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: "Unable to fetch list of applications",
        },
      ));
    },
  });

  const [updateApplicationStatusMutation] = useMutation(UPDATE_APPLICATION_STATUS_MUTATION, {
    refetchQueries: () => [
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          id: opportunityId,
        },
      },
    ],
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: "Unable to update application status",
        },
      ));
    },
    onCompleted: (data) => {
      dispatch(newAlert(
        {
          level: AlertLevel.INFO,
          body: `Updated application status to "${data.updateApplicationStatus.name}"`,
        },
      ));
    },
  });

  const {
    roleList,
  } = useRoles();

  const applicationList: any =
    !getApplicationsError &&
    !getApplicationsLoading ?
      getApplicationsData.getApplicationList :
      null;

  const updateApplicationStatus = (studentId: string, statusId: number) => updateApplicationStatusMutation({
    variables: {
      application: {
        opportunity: {
          id: opportunityId,
        },
        student: {
          id: studentId,
        },
      },
      status: {
        id: statusId,
      },
    },
  });

  const onProfileClick = (studentId: string) => {
    props.history.push({
      pathname: `/students/${studentId}`,
      state: {
        opportunityId: props.opportunityId,
        viaApplications: true,
      },
    },
    );
  };

  return (
    <ApplicationListLayout
      applications={applicationList}
      applicationListLoading={getApplicationsLoading}
      applicationListError={getApplicationsError}
      onProfileClick={onProfileClick}
      canUpdateApplicationStatus={hasOrganisationRole(roleList)}
      updateApplicationStatus={updateApplicationStatus}
    />
  );
};

export const ApplicationList = connect()(ApplicationListComponent);
