import {
  createMuiTheme,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  red,
} from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2626F7",
    },
    secondary: red,
  },
});

export const styles = (t: Theme): any => createStyles({
  root: {
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "1.0rem",
    [theme.breakpoints.only("xs")]: {
      marginTop: "5px",
      alignItems: "center",
      justifyContent: "stretch",
      height: "56px",
      marginBottom: t.spacing.unit,
      background: "#FFF",
      boxShadow: "0 1px 2px #FFD300",
      flexDirection: "row",
    },
  },
  noOpportunities: {
    alignSelf: "flex-start",
    [theme.breakpoints.only("xs")]: {
      background: "#FFF",
    },
  },
  pageHeaderText: {
    [theme.breakpoints.only("xs")]: {
      display: "inline-block",
      fontWeight: 400,
      verticalAlign: "middle",
      fontSize: "20px",
      fontFamily: "Raleway, sans-serif",
    },
  },

  drawer: {
    width: "319px",
  },
  drawerIcon: {
    marginRight: "15px",
    opacity: 0.64,
  },
  drawerText: {
    color: "rgba(0, 0, 0, 0.38)",
  },
  button: {
    justifyContent: "flex-start",
    padding: "20px 15px",
    color: "#000DFF",
    fontWeight: 400,
  },
  logo: {
    position: "absolute",
    bottom: "15px",
    left: "15px",
    height: "16px",
    maxHeight: "16px",
    width: "auto",
  },
});
