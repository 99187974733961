import * as React from "react";
import {
  FormControl,
  FormHelperText,
  Input,
} from "@material-ui/core";
import {
  FormControlContainer,
  FormLabel,
} from "@components/FormComponents/styled";

interface IProps {
  tasks: Array<string>;
  onChange: (v: any) => undefined;
  formErrors: any;
  addItem: () => void;
}

const MAX_NUMBER_OF_TASKS = 12;

class TasksList extends React.Component<IProps, any> {
  render() {
    const {
      tasks,
      formErrors,
      onChange,
      addItem,
    } = this.props;

    return (
      <FormControlContainer error={!!formErrors.tasks} style={{
        marginTop: "36px",
        marginBottom: "50px",
      }}>
        <FormLabel gutterBottom>
          List a few tasks/activities the person attending will perform
        </FormLabel>
        <FormHelperText style={{
          marginBottom: "1rem",
        }}>
          Please list up to twelve tasks or activities (up to 300 characters each)
        </FormHelperText>
        {tasks.map((task, i) => (
          <FormControl fullWidth error={!!formErrors.tasks} key={i}>
            <Input
              type={"text"}
              onChange={onChange(`tasks[${i}]`)}
              autoComplete="off"
              style={{
                marginBottom: "1rem",
              }}
              value={task}
            />
          </FormControl>
        ))}
        {
          (tasks && tasks.length < MAX_NUMBER_OF_TASKS) ? (
            <FormHelperText
              onClick={addItem}
              style={{
                cursor: "pointer",
              }}
            >
              Add another
            </FormHelperText>
          ) : null
        }
      </FormControlContainer>
    );
  }
}

export default TasksList;
