//
// Constants
//

export const ERROR = {
  GET_SCHOOL_LIST: "Could not get list of schools",
};

//
// Interfaces
//
export interface ISchool {
  id: number;
  name: string;
}
