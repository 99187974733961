import * as React from "react";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import * as Joi from "@hapi/joi";
import * as moment from "moment";
import {
  Moment,
} from "moment";
import {
  AppBar,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Theme,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Cancel,
  Close,
} from "@material-ui/icons";
import {
  grey,
  red,
} from "@material-ui/core/colors";
import {
  newAlert,
} from "@actions/global";
import {
  AlertLevel,
} from "./global.model";
import DatePicker from "./DatePicker";
import Layout from "./Layout";
import {
  EXPERIENCE_MAX_SKILLS,
  EXPERIENCE_MAX_TASKS,
  EXPERIENCE_MIN_TASKS,
  ICreateExperience,
} from "@models/Experience";
import {
  IOpportunityType,
} from "@models/opportunity";
import {
  ITask,
} from "@models/task";
import {
  ISkill,
} from "@models/skill";
import {
  IIdArray,
} from "@models/validationHelpers/constants";

interface IProps {
  open: boolean;
  data: any;
  schema: Joi.SchemaLike;
  onSave: any;
  onClose: any;

  // Props from HOCs
  classes: any;
  dispatch: Dispatch;
}

export interface IState extends ICreateExperience {
  errors: any;
}

const styles = (theme: Theme) => ({
  root: {
    width: "960px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: theme.spacing.unit,
    },
  },
  formControl: {
    margin: "1.0rem 0",
  },
  periodSection: {
    display: "flex",
    flexDirection: "row",
    flexBasis: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  selectedSkillsContainer: {
    width: "100%",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    borderBottom: `1px solid ${grey[800]}`,
  },
  skillsContainer: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class NewExperienceModal extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      opportunity_type_id: -1,
      title: "",
      summary: "",
      company: "",
      to: new Date(moment().subtract(1, "day").toISOString()),
      from: new Date(moment().subtract(2, "day").toISOString()),
      experienceIsOngoing: false,
      tasks: [""],
      skill_ids: [],
    };
  }

  public handleTypeChange = (event: any): void => {
    const {
      data: {
        opportunity_types,
      },
    } = this.props;

    const type: IOpportunityType = opportunity_types.find((type) => type.id === event.target.value);

    this.setState((previousState) => ({
      opportunity_type_id: type.id,
      errors: {
        ...previousState.errors,
        opportunity_type_id: false,
      },
    }));
  };

  public handleTitleChange = (event: any): void => {
    const title: string = event.target.value;

    this.setState((previousState) => ({
      title,
      errors: {
        ...previousState.errors,
        title: false,
      },
    }));
  };

  public handleSummaryChange = (event: any): void => {
    const summary: string = event.target.value;

    this.setState((previousState) => ({
      summary,
      errors: {
        ...previousState.errors,
        summary: false,
      },
    }));
  };

  public handleCompanyChange = (event: any): void => {
    const company: string = event.target.value;

    this.setState((previousState) => ({
      company,
      errors: {
        ...previousState.errors,
        company: false,
      },
    }));
  };

  public handleFromChange = (value: Moment): void => {
    this.setState((previousState) => ({
      from: new Date(value.toISOString()),
      errors: {
        ...previousState.errors,
        from: false,
      },
    }));
  };

  public handleToChange = (value: Moment): void => {
    this.setState((previousState) => ({
      to: new Date(value.toISOString()),
      errors: {
        ...previousState.errors,
        to: false,
      },
    }));
  };

  public toggleExperienceIsOngoingChange = (): void => {
    this.setState((previousState) => ({
      experienceIsOngoing: !this.state.experienceIsOngoing,
      errors: {
        ...previousState.errors,
        to: false,
      },
    }));
  };

  public handleTaskChange = (event: any): void => {
    const taskIndex: number = parseInt(event.target.id, 10);
    const taskValue: string = event.target.value;

    const newTasks: ITask[] = [...this.state.tasks];
    newTasks[taskIndex] = taskValue;

    this.setState((previousState) => ({
      tasks: newTasks,
      errors: {
        ...previousState.errors,
        tasks: false,
      },
    }));
  };

  public addTaskInput = (): void => {
    const newTasks: ITask[] = [...this.state.tasks];
    newTasks.push("");

    this.setState((previousState) => ({
      tasks: newTasks,
      errors: {
        ...previousState.errors,
        tasks: false,
      },
    }));
  };

  public removeTask = (index: number): void => {
    const newTasks = [...this.state.tasks];
    newTasks.splice(index, 1);
    this.setState({
      tasks: newTasks,
    });
  };

  public addSkill = (skill: ISkill): void => {
    const {
      skill_ids,
    } = this.state;

    let alreadyAdded: boolean = false;

    // Check and see if we have already added the skill
    const matchingSkills: IIdArray = skill_ids.filter((skill_id) => skill_id === skill.id);
    if (matchingSkills.length != 0) {
      alreadyAdded = true;
    }

    if (alreadyAdded) {
      this.props.dispatch(newAlert({
        level: AlertLevel.WARNING,
        body: "You've already added this skill.",
      }));
      return;
    }

    // Create new skills list
    const newSkillIds = [...skill_ids];
    newSkillIds.push(skill.id);

    this.setState((previousState) => ({
      skill_ids: newSkillIds,
      errors: {
        ...previousState.errors,
        skills: false,
      },
    }));
  };

  public removeSkill = (skill: any): void => {
    const {
      skill_ids,
    } = this.state;

    // Accept all old skills, except the one with a matching id
    const newSkills: IIdArray = skill_ids.filter((skill_id) => skill_id !== skill);

    this.setState({
      skill_ids: newSkills,
    });
  };

  public checkPropsAndSave = () => {
    const {
      dispatch,
      schema,
    } = this.props;

    // Validate and generate errors for form
    const newExperience: IState = {
      ...this.state,
    };
    const errors: any = {};

    const fromDate = moment(newExperience.from);
    const toDate = moment(newExperience.to);
    if (!newExperience.opportunity_type_id || newExperience.opportunity_type_id === -1) errors.opportunity_type_id = true;
    if (newExperience.title === "" || !newExperience.title) errors.title = true;
    if (newExperience.summary === "" || !newExperience.summary) errors.summary = true;
    if (newExperience.company === "" || !newExperience.company) errors.company = true;
    if (!newExperience.from) errors.from = true;
    if (!newExperience.experienceIsOngoing && !newExperience.to) errors.to = true;
    if (fromDate && toDate.to && toDate.diff(fromDate) < 0 && !newExperience.experienceIsOngoing) {
      errors.from = true;
      errors.to = true;
    }
    let tasksError: boolean = true;
    newExperience.tasks.forEach((task: string): void => {
      if (task !== "") {
        tasksError = false;
      }
    });
    if (tasksError) errors.tasks = true;
    if (newExperience.skill_ids.length === 0) errors.skills = true;

    // If form errors, alert user and show those errors
    if (Object.keys(errors).length) {
      this.setState(
        {
          errors,
        },
        () => {
          dispatch(newAlert({
            level: AlertLevel.ERROR,
            body: "Something may be missing from your experience.",
          }));
        },
      );
      return;
    }

    // Use schema for validation - same schema used in new API backend
    const experienceToValidate: any = {
      ...this.state,
    };
    delete experienceToValidate.experienceIsOngoing;
    delete experienceToValidate.errors;
    const {
      error,
    } = Joi.validate(experienceToValidate, schema);

    if (error) {
      dispatch(newAlert({
        level: AlertLevel.WARNING,
        body: error.message,
      }));
      return;
    }

    // Submit form if no errors found
    const experience: IState = {
      ...this.state,
    };
    delete experience.errors;

    this.props.onSave(experience);
  };

  public render() {
    const {
      classes,
      data: {
        skills,
        opportunity_types,
      },
      onClose,
    } = this.props;

    const {
      errors,
    } = this.state;

    const skillList: ISkill[] = skills || [];
    const selectedSkillList: ISkill[] = this.state.skill_ids.map((skillId) => skillList.find((skill) => skill.id === skillId) || {
      id: -1,
      name: "unknown",
    });
    const opportunityTypeList: any[] = opportunity_types || [];

    return (
      <Dialog
        open={this.props.open}
        fullScreen
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        onClose={this.props.onClose}
      >
        {/* TOP DIALOG BAR */}
        <AppBar className={classes.appBar} color="primary">
          <Toolbar style={{
            display: "flex",
          }}>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <Cancel/>
            </IconButton>

            <Typography variant="title" color="inherit" style={{
              flex: 1,
            }}>
              Prior experience
            </Typography>

            <Button color="inherit" onClick={this.checkPropsAndSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>

        {/* FORM */}
        <Layout greyOut>
          <DialogContent>
            <Typography
              variant="headline"
              style={{
                paddingTop: "1.0rem",
              }}
            >
              Add new work experience
            </Typography>

            <DialogContentText>
              Below, tell us a bit about the work experience you've had in the past.
            </DialogContentText>

            <form autoComplete="off" style={{
              marginTop: "1.0rem",
            }}>
              {/* Experience Type */}
              <FormControl className={classes.formControl} style={{
                width: "100%",
              }} error={errors.opportunity_type_id}>
                <InputLabel htmlFor="experience-type" required>
                  Type
                </InputLabel>
                <Select
                  fullWidth
                  required
                  value={this.state.opportunity_type_id}
                  onChange={this.handleTypeChange}
                  inputProps={{
                    name: "experience-type",
                    id: "experience-type",
                  }}
                >
                  {
                    opportunityTypeList.map((type: any): React.ReactNode => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))
                  }
                </Select>

                <FormHelperText>
                  Select from the dropdown what best describes the type of work experience you had
                </FormHelperText>
                {
                  errors.opportunity_type_id && <FormHelperText>
                      Please select a valid type
                  </FormHelperText>
                }
              </FormControl>

              {/* Title */}
              <FormControl className={classes.formControl} style={{
                width: "100%",
              }} error={errors.title}>
                <TextField
                  required
                  id="required"
                  label="Title"
                  error={errors.title}
                  helperText="What was this experience?"
                  value={this.state.title}
                  onChange={this.handleTitleChange}
                  fullWidth
                  margin="dense"
                />
                {
                  errors.title && <FormHelperText>
                      Please enter a valid title
                  </FormHelperText>
                }
              </FormControl>

              {/* Summary */}
              <FormControl className={classes.formControl} style={{
                width: "100%",
              }} error={errors.summary}>
                <TextField
                  required
                  id="required"
                  error={errors.summary}
                  label="Short summary"
                  helperText={255 - this.state.summary.length + " characters left"}
                  multiline
                  value={this.state.summary}
                  onChange={this.handleSummaryChange}
                  fullWidth
                  margin="dense"
                />
                {
                  errors.summary && <FormHelperText>
                      Please select a valid summary
                  </FormHelperText>
                }
              </FormControl>

              {/* Company */}
              <FormControl className={classes.formControl} style={{
                width: "100%",
              }} error={errors.company}>
                <TextField
                  required
                  id="required"
                  label="Company"
                  error={errors.company}
                  helperText="Who was this for?"
                  value={this.state.company}
                  onChange={this.handleCompanyChange}
                  fullWidth
                  margin="dense"
                />
                {
                  errors.company && <FormHelperText>
                      Please select a company
                  </FormHelperText>
                }
              </FormControl>

              {/* From and To Dates */}
              <div className={classes.periodSection}>
                <FormControl
                  className={classes.formControl}
                  style={{
                    flex: 1,
                    marginRight: "1.0rem",
                  }}
                  error={errors.to || errors.from}
                >
                  <DatePicker
                    id="date-from"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="What date did you start?"
                    disableFuture
                    value={this.state.from}
                    onChange={this.handleFromChange}
                    error={errors.from}
                    style={{
                      width: "100%",
                    }}
                    format="YYYY-MM-DD"
                  />
                  <DatePicker
                    id="date-to"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="What date did you stop working here?"
                    disabled={this.state.experienceIsOngoing}
                    disableFuture
                    error={errors.to}
                    value={this.state.to}
                    onChange={this.handleToChange}
                    style={{
                      width: "100%",
                      marginTop: "1.0rem",
                    }}
                    format="YYYY-MM-DD"
                  />
                  {
                    (errors.from || errors.to) &&
                    <FormHelperText>
                        Please select a valid date range. The starting date cannot be greater than the ending date
                    </FormHelperText>
                  }
                </FormControl>

                {/* Is Ongoing Experience */}
                <FormControl className={classes.formControl}>
                  <Typography variant="caption">
                    Or
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.experienceIsOngoing}
                        onChange={this.toggleExperienceIsOngoingChange}
                        value="checkedB"
                        color="primary"
                      />
                    }
                    label="I am currently working here"
                  />
                </FormControl>
              </div>

              {/* Tasks */}
              <FormControl
                className={classes.formControl}
                style={{
                  marginTop: "1.0rem",
                  width: "100%",
                }}
                error={errors.tasks}
              >
                <Typography variant="subheading">
                  Tasks
                </Typography>
                <Typography variant="caption">
                  Select up to
                  {" "}
                  {EXPERIENCE_MAX_TASKS}
                  {" "}
                  tasks you completed on a regular basis
                </Typography>
                <List>
                  {this.state.tasks.map((value: string, index: number): React.ReactNode => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText>
                          <TextField
                            placeholder={"Task " + (index + 1)}
                            value={value}
                            fullWidth
                            id={index.toString()}
                            error={errors.tasks}
                            required={index === 0}
                            onChange={this.handleTaskChange}
                          />
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Button
                            disabled={this.state.tasks.length <= EXPERIENCE_MIN_TASKS}
                            onClick={() => this.removeTask(index)}
                          >
                            <Close/>
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
                <Button
                  variant="outlined"
                  disabled={this.state.tasks.length >= EXPERIENCE_MAX_TASKS}
                  onClick={this.addTaskInput}
                >
                  Add a task
                </Button>
                {
                  errors.tasks && <FormHelperText>
                      Please select at least one task
                  </FormHelperText>
                }
              </FormControl>

              <FormControl
                className={classes.formControl}
                style={{
                  marginTop: "1.0rem",
                  width: "100%",
                }}
                error={errors.skills}
              >
                <Typography variant="subheading">
                  Skills
                </Typography>
                <div className={classes.selectedSkillsContainer} style={errors.skills ? {
                  borderColor: red[500],
                } : {}}>
                  {
                    selectedSkillList.map((skill: any, index: number): React.ReactNode => (
                      <Chip
                        label={skill.name}
                        key={skill.id}
                        color="primary"
                        avatar={
                          <Avatar>
                            {index + 1}
                          </Avatar>
                        }
                        onDelete={() => this.removeSkill(skill.id)}
                        style={{
                          marginRight: "0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      />
                    ))
                  }
                </div>

                {/* Skills */}
                <div style={{
                  display: "flex",
                  width: "100%",
                }}>
                  <Typography
                    style={{
                      flex: 1,
                    }}
                    variant="caption"
                  >
                    Select up to
                    {" "}
                    {EXPERIENCE_MAX_SKILLS}
                    {" "}
                    skills you were exposed to
                  </Typography>

                  <Typography variant="caption">
                    {
                      this.state.skill_ids.length < EXPERIENCE_MAX_SKILLS ?
                        `add ${EXPERIENCE_MAX_SKILLS - this.state.skill_ids.length} more` :
                        "You're all done!"
                    }
                  </Typography>
                </div>

                <div className={classes.skillsContainer}>
                  {
                    skillList.map((skill: any): React.ReactNode => (
                      <Chip
                        label={skill.name}
                        key={skill.id}
                        onClick={(
                          this.state.skill_ids.length >= EXPERIENCE_MAX_SKILLS) ?
                          (() => {
                          }) :
                          (() => this.addSkill(skill))}
                        style={{
                          marginRight: "0.5rem",
                          marginBottom: "0.5rem",
                          opacity: (this.state.skill_ids.length >= EXPERIENCE_MAX_SKILLS) ? 0.5 : 1.0,
                        }}
                      />
                    ))}
                </div>
                {
                  errors.skill_ids && <FormHelperText>
                      Please select at least one skill
                  </FormHelperText>
                }
              </FormControl>

            </form>

          </DialogContent>
        </Layout>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state.data,
});

export default withStyles(styles)(connect(mapStateToProps)(NewExperienceModal));
