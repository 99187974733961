export const NEW_ALERT: string = "NEW_ALERT";
export const SET_LOADING: string = "SET_LOADING";
import {
  Alert,
  AlertInfo,
} from "@components/global.model";

export const newAlert = (alertInfo: AlertInfo, action?: React.ReactNode): any => {
  const alert: Alert = {
    ...alertInfo,
    timestamp: new Date(),
    action,
  };

  return {
    alert,
    type: NEW_ALERT,
  };
};

export const setLoading = (loading: boolean): any => {
  return {
    loading,
    type: SET_LOADING,
  };
};

export const authLogoutAction: any = (): any => ({
  type: "AUTH_LOGOUT",
});
