import * as React from "react";
import {
  gql,
} from "apollo-boost";
// Components
import DataTable, {
  ITableColumn,
} from "@components/Generic/DataTable";

//
// Constants
//

export const organisationSchoolPartnershipsFragments = {
  school: gql`
        fragment SchoolPartnershipsTable_School on School {
            id
            name
        }
    `,
};

const columns: ITableColumn[] = [
  {
    id: "name",
    Header: "Name",
  },
];

//
// Interfaces
//

interface IProps {
  loading: boolean;
  schoolData: any[],
}

/**
 * TODO: add graphql types. https://grandshake.atlassian.net/browse/WA-457
 * Shows a list of partnerships for the given organisation
 * @param {IProps} props
 * @return {React.ReactElement}
 */
const SchoolPartnershipList: React.FC<IProps> = (props) => {
  const {
    loading,
    schoolData,
  } = props;

  return (
    <DataTable
      loading={loading}
      columns={columns}
      title="School Partnerships"
      data={schoolData}
      emptyMessage="No partnerships found"
    />
  );
};

export default SchoolPartnershipList;
