import {
  createMuiTheme,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  grey,
  red,
} from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2626F7",
    },
    secondary: red,
  },
});

export const styles = (t: Theme): any => createStyles({
  rootBar: {
    display: "flex",
    flexBasis: "100%",
    flexDirection: "row",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
    marginTop: t.spacing.unit * 2,
    marginBottom: t.spacing.unit,
    [theme.breakpoints.only("xs")]: {
      marginTop: "22px",
      marginBottom: "2px",
      background: "#FFF",
    },
  },
  pageHeader: {
    [theme.breakpoints.only("xs")]: {
      boxShadow: "0 1px 2px #FFD300",
    },
  },
  pageHeaderText: {
    display: "inline-block",
    fontWeight: 400,
    verticalAlign: "middle",
    fontSize: "20px",
    fontFamily: "Raleway, sans-serif",
    marginLeft: "12px",
    [theme.breakpoints.only("xs")]: {
      marginLeft: 0,
    },
  },
  subHeader: {
    [theme.breakpoints.only("xs")]: {
      padding: "13px 16px 12px",
    },
  },
  backToListing: {
    color: grey[500],
    fontWeight: 400,
    letterSpacing: "1.2px",
    [theme.breakpoints.only("xs")]: {
      background: "#FFF",
      height: "56px",
      maxHeight: "56px",
    },
  },
  opportunityTypeButton: {
    height: "36px",
    boxShadow: "none",
    marginRight: "24px",
    color: "#FFFFFF",
    [theme.breakpoints.only("xs")]: {
      marginTop: t.spacing.unit,
      fontSize: "14px",
    },
  },
  callToOpen: {
    width: "12px",
    height: "12px",
  },
  rootButton: {
    height: "36px",
    boxShadow: "none",
    background: "#000DFF",
    color: "#FFFFFF",
    fontWeight: 400,
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.only("xs")]: {
      marginTop: t.spacing.unit,
    },
  },
  cancel: {
    display: "inline-block",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.38)",
    marginRight: "12px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  remove: {
    display: "inline-block",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.38)",
    marginRight: "12px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  save: {
    display: "inline-block",
    fontSize: "14px",
    color: "#2626F7",
    [theme.breakpoints.only("xs")]: {
      position: "absolute",
      top: "9px",
      right: 0,
    },
  },
});
