import * as React from "react";

import styled from "styled-components";

import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  Email,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
// Data
import {
  formDataDefault,
  IconWrapper,
  SignupFormControl,
} from "./index";

const SignupFormPage1 = styled.section``;

interface IProps {
  formData: any;
  formErrors: any;
  onChange: (e: any) => any;
  validateForm: (e: any) => any;
}

class SignupFormPage1Component extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      formData: formDataDefault,
      formErrors: {},
      showPassword: false,
      ...props,
    };
  }

  public handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  public handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  public render() {
    const {
      onChange, formErrors,
    } = this.props;
    const {
      firstName, lastName, email, password,
    } = this.props.formData;
    return (
      <SignupFormPage1>
        <Typography variant="headline" gutterBottom>
          Create your account
        </Typography>
        <Typography variant="subheading">
          The first step toward the future you love.
        </Typography>

        <SignupFormControl>
          <FormControl fullWidth error={!!formErrors.firstName}>
            <InputLabel htmlFor="firstName">
              First Name
            </InputLabel>
            <Input
              id="firstName"
              type={"text"}
              value={firstName}
              onChange={onChange("firstName")}
            />
          </FormControl>
        </SignupFormControl>

        <SignupFormControl>
          <FormControl fullWidth error={!!formErrors.lastName}>
            <InputLabel htmlFor="lastName">
              Last Name
            </InputLabel>
            <Input
              id="lastName"
              type={"text"}
              value={lastName}
              onChange={onChange("lastName")}
            />
          </FormControl>
        </SignupFormControl>

        <SignupFormControl>
          <FormControl fullWidth error={!!formErrors.email}>
            <InputLabel htmlFor="email">
              Email
            </InputLabel>
            <Input
              id="email"
              type={"text"}
              value={email}
              onChange={onChange("email")}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconWrapper>
                    <Email/>
                  </IconWrapper>
                </InputAdornment>
              }
            />
          </FormControl>
        </SignupFormControl>

        <SignupFormControl>
          <FormControl fullWidth error={!!formErrors.password}>
            <InputLabel htmlFor="password">
              Password
            </InputLabel>
            <Input
              id="password"
              type={this.state.showPassword ? "text" : "password"}
              value={password}
              onChange={onChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    <IconWrapper>
                      {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconWrapper>
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </SignupFormControl>

      </SignupFormPage1>
    );
  }
}

export default SignupFormPage1Component;
