import * as React from "react";
import {
  Grid,
  MobileStepper,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

interface IProps {
  currentPage: number;
  width: string;
}

const style = {
  boxShadow: "0 9px 18px rgba(0, 0, 0, 0.18)",
  paddingTop: "56px",
};

const STEPS = [
  {
    label: "Key details",
    text: "This section is for the most important things young people need to know about the opportunity",
  },
  {
    label: "Prescreening",
    text: "Selected up to three questions for applications to submit a video response",
  },
  {
    label: "Share With",
    text: "Here you can select the schools you wish to share this opportunity with",
  },
  {
    label: "Upload Video",
    text: "Here you may optionally upload a video to share with students who are interested in the opportunity",
  },
  {
    label: "Preview",
    text: "Please review and confirm the opportunity listing, before it goes live",
  },
];

interface IProps {
  currentPage: number;
  width: string;
}

const Content = ({
  currentPage,
  width,
}: IProps) => ["lg"].includes(width) ? (
  <Grid
    container
    alignItems="stretch"
    style={{
      position: "fixed",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 0,
      width: "256px",
    }}
  >
    <Paper
      square
      style={style}
    >
      <Stepper
        orientation="vertical"
        activeStep={currentPage - 1}
      >
        {
          STEPS.map(({
            label, text,
          }) => {
            return (
              <Step key={label}>
                <StepLabel>
                  {label}
                </StepLabel>
                <StepContent
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {text}
                </StepContent>
              </Step>
            );
          })
        }
      </Stepper>
    </Paper>
  </Grid>
) : (
  <MobileStepper
    variant="dots"
    steps={STEPS.length}
    position="bottom"
    activeStep={currentPage - 1}
    nextButton={<span></span>}
    backButton={<span></span>}
  />
);

class CreatingSteps extends React.Component<IProps, any> {
  render() {
    const {
      currentPage, width,
    } = this.props;

    return (
      <Content
        currentPage={currentPage}
        width={width}
      />
    );
  }
}

export default withWidth()(CreatingSteps);
