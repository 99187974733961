import * as React from "react";
import {
  Button,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import {
  Build,
} from "@material-ui/icons";

interface IProps {
  open: any;
  onClose: any;
}

const LocalAlert = ({
  open,
  onClose,
}: IProps) => (
  <Snackbar
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    open={open}
    onClose={onClose}
    style={{
      top: "85px",
    }}
  >
    <SnackbarContent
      aria-describedby="client-snackbar"
      style={{
        background: "#FFD300",
      }}
      message={
        <span id="client-snackbar">
          <Build
            style={{
              verticalAlign: "middle",
              marginRight: "14px",
              color: "#000",
            }}
          />
          <span
            style={{
              verticalAlign: "middle",
              color: "#000",
              fontSize: "14px",
            }}
          >
            New feature coming soon
          </span>
        </span>
      }
      action={[
        <Button
          key="close"
          onClick={onClose}
        >
          OKAY
        </Button>,
      ]}
    />
  </Snackbar>
);

export default LocalAlert;
