import * as React from "react";
import {
  gql,
} from "apollo-boost";
import {
  useMutation,
} from "@apollo/react-hooks";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  ERROR,
  ICreateUpdateOrganisation,
  SUCCESS,
} from "@models/organisation";
// Components
import {
  AlertLevel,
} from "@components/global.model";
import OrganisationDialog from "@components/Organisation/OrganisationDialog";
import {
  GET_ORGANISATION_LIST,
} from "@pages/admin/organisationList/AdminOrganisationListData";

//
// Constants
//

const CREATE_ORGANISATION = gql`
    mutation CreateOrganisation($name: String!) {
        createOrganisation(organisation: {name: $name}) {
            id
            name
        }
    }
`;

//
// Interfaces
//

interface IProps {
  open: boolean;
  onClose: () => void;
}

interface IExtendedProps extends IProps {
  dispatch: Dispatch;
}

const createOrganisationData: React.FC<IExtendedProps> = ({
  open,
  onClose,
  dispatch,
}: IExtendedProps) => {
  const [createOrganisationMutation] = useMutation(CREATE_ORGANISATION, {
    refetchQueries: () => [
      {
        query: GET_ORGANISATION_LIST,
      },
    ],
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR.CREATE_ORGANISATION,
        },
      ));
    },
    onCompleted: () => {
      dispatch(newAlert(
        {
          level: AlertLevel.INFO,
          body: SUCCESS.CREATE_ORGANISATION,
        },
      ));

      onClose();
    },
  });

  const createOrganisation = (organisation: ICreateUpdateOrganisation) => createOrganisationMutation({
    variables: {
      name: organisation.name,
    },
  });

  return (
    <OrganisationDialog
      open={open}
      onClose={onClose}
      onSubmit={createOrganisation}
      initialValues={{
        name: "",
      }}
    />
  );
};

export default connect()(createOrganisationData);
