import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  get,
} from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import OptionButtons from "@components/OptionButtons";
import {
  FormControlContainer,
  FormLabel,
} from "@components/FormComponents/styled";
import {
  DataLoader,
} from "@components/DataLoader";

class EditPrerequisitesModalComponent extends React.Component<any, any> {
  render() {
    const {
      data,
      prerequisites,
      open,
      onClose,
      onChange,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update prerequisites
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below, choose new prerequisites.
          </DialogContentText>

          <FormControlContainer>
            <FormControl fullWidth>
              <FormLabel>
                Prerequisites
              </FormLabel>
              <FormHelperText>
                Select from a list of key factors influencing the role
              </FormHelperText>
              <DataLoader required>
                <OptionButtons
                  multiple
                  selected={prerequisites.map(({
                    id,
                  }) => id)}
                  options={get(data, "opportunity.prerequisites", {})}
                  onChange={onChange}
                />
              </DataLoader>
            </FormControl>
          </FormControlContainer>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onClose(true)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps)(EditPrerequisitesModalComponent);
