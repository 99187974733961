import * as React from "react";
import {
  createStyles,
  Input,
  Typography,
  withStyles,
} from "@material-ui/core";

type Size = 0 | 1 | 2;

interface IProps {
  edit: boolean;
  value?: string;
  size?: Size;
  disabled?: boolean;
  multiline?: boolean;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  autoFocus?: boolean;
  onChange?: (any) => void;
}

interface IState {

}

const styles = (): any => createStyles({
  root: {
    display: "flex",
  },
});

class FieldEditable extends React.Component<IProps, IState> {
  public render() {
    const {
      edit,
      value,
      size,
      disabled,
      multiline,
      fullWidth,
      disableUnderline,
      onChange,
      autoFocus,
    } = this.props;

    const variant = size == 0 ? "headline" : (size == 1 ? "subheading" : "body1");
    const editSize = size == 0 ? 1.5 : (size == 1 ? 1.0 : 0.8);

    return edit ?
      <Input
        type="text"
        disableUnderline={disableUnderline}
        multiline={multiline}
        fullWidth={fullWidth}
        rows={multiline ? 3 : 1}
        disabled={disabled}
        style={{
          fontSize: editSize + "rem",
          marginRight: fullWidth ? "1.0rem" : 0,
        }}
        placeholder={value}
        autoFocus={autoFocus}
        onChange={onChange}
      >
      </Input> :
      <Typography
        variant={variant}
      >
        {value}
      </Typography>;
  }
}

"";
export default withStyles(styles)(FieldEditable);
