import styled from "styled-components";
import {
  Dehaze,
} from "@material-ui/icons";

export const OpportinityListContainer = styled.div`
  margin-top: 1.0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    background: #FFF;
  }
`;

export const Burger = styled(Dehaze)`
  display: none !important;

  @media (max-width: 600px) {
    display: inline-block !important;
    margin-left: 20px;
    margin-right: 35px;
  }
`;
