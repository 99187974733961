import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  createStyles,
  withStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

import {
  Alert,
} from "@components/global.model";

interface IProps {
  newAlert: Alert;
  classes: any;
}

interface IState {
  open: boolean;
}

const styles = (): any => createStyles({});

class AlertHandlerComponent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  public UNSAFE_componentWillReceiveProps() {
    this.setState({
      open: true,
    });
  }

  public handleClose = () => {
    this.setState({
      open: false,
    });
  };
  public handleClick = () => {
    this.setState({
      open: true,
    });
  };

  public render() {
    const {
      classes, newAlert,
    } = this.props;
    const defaultAction: React.ReactNode[] = [
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={this.handleClose}
      >
        <CloseIcon/>
      </IconButton>,
    ];
    const action: React.ReactNode | React.ReactNode[] = newAlert ? (newAlert.action ? newAlert.action :
      defaultAction) : defaultAction;

    return (
      <React.Fragment>
        <span></span>
        {newAlert && <Snackbar
          anchorOrigin={{
            vertical: "bottom",

            horizontal: "left",

          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">
            {newAlert.body}
          </span>}
          action={action}
        />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    newAlert: state.globals.mostRecentAlert || null,
  };
};

export const AlertHandler = connect(
  mapStateToProps,
)(withStyles(styles)(AlertHandlerComponent));
