import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import SuburbSearch from "@components/SuburbSearch";

interface IState {
  searchString: string;
  location: any;
}

class EditLocationModal extends React.Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      location: null,
    };
  }

  public onChange = (value) => {
    this.props.onChange(value);
  };

  public render() {
    const onChange = this.onChange;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update location
        </DialogTitle>
        <DialogContent
          style={{
            minHeight: "220px",
          }}
        >
          <DialogContentText>
            Below, enter the new location for the opportunity.
          </DialogContentText>
          <SuburbSearch
            id="suburb"
            type={"text"}
            onChange={onChange}
            showIcon={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={this.props.onClose(true)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditLocationModal;
