export const APP_ENV = process.env.APP_ENV;
export const API_URL = process.env.API_URL;
export const AWS_REGION = process.env.AWS_REGION;
export const AWS_COGNITO_IDENTITY_POOL_ID = process.env.AWS_COGNITO_IDENTITY_POOL_ID;
export const AWS_COGNITO_USER_POOL_ID = process.env.AWS_COGNITO_USER_POOL_ID;
export const AWS_COGNITO_USER_POOL_CLIENT_ID = process.env.AWS_COGNITO_USER_POOL_CLIENT_ID;
export const AWS_S3_PROFILE_PICTURE_BUCKET = process.env.AWS_S3_PROFILE_PICTURE_BUCKET;
export const AWS_S3_VIDEO_OUTPUT_BUCKET = process.env.AWS_S3_VIDEO_OUTPUT_BUCKET;
export const AWS_S3_VIDEO_INPUT_BUCKET = process.env.AWS_S3_VIDEO_INPUT_BUCKET;

function validateEnv(): void {
  const envParams = {
    APP_ENV,
    API_URL,
    AWS_REGION,
    AWS_COGNITO_IDENTITY_POOL_ID,
    AWS_COGNITO_USER_POOL_ID,
    AWS_COGNITO_USER_POOL_CLIENT_ID,
    AWS_S3_PROFILE_PICTURE_BUCKET,
    AWS_S3_VIDEO_OUTPUT_BUCKET,
    AWS_S3_VIDEO_INPUT_BUCKET,
  };
  const missingParams: string[] = [];
  const envKeys = Object.keys(envParams);
  envKeys.forEach((param) => {
    if (!envParams[param] && envParams[param] !== "" && envParams[param] !== false) {
      missingParams.push(param);
    }
  });
  if (missingParams.length) {
    const errorMsg = `Missing environment parameters: ${missingParams.join(", ")}`;
    console.error(errorMsg);
    throw Error(errorMsg);
  }
}

validateEnv();
