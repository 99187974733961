import * as React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  compose,
} from "redux";
import styled from "styled-components";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
// Utils
import {
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_ADMIN_ORGANISATION_ID,
  ROUTE_ADMIN_ORGANISATIONS,
} from "@utils/routes";
import {
  ERole,
} from "@utils/roles";
// Components
import {
  withUser,
} from "@components/Auth/UserLoader";
import {
  AlertHandler,
} from "@components/alert.handler.tsx";
import {
  PrivateRoute,
} from "@components/Auth/PrivateRoute";
import {
  StudentsPage,
} from "@pages/students/StudentsPageData";
import {
  DashboardPageData,
} from "@pages/dashboard/DashboardPageData";
import ErrorPage from "@pages/auth/ErrorPage";
import SigninPage from "@pages/auth/SigninPage";
import NewOpportunityPage from "@pages/opportunity/NewOpportunityPage";
import OpportunityPage from "@pages/opportunity/OpportunityPage/OpportunityPageData";
import StudentSignupPage from "@pages/student/StudentSignupPage";
import ProfilePage from "@pages/profile/ProfilePage";
import ContactsPage from "@pages/contacts/ContactsPage";
import StudentsProfilePage from "@pages/students/StudentsProfilePage";
import AdminDashboardPageData from "@pages/admin/dashboard/AdminDashboardData";
import AdminOrganisationListData from "@pages/admin/organisationList/AdminOrganisationListData";
import AdminOrganisationData from "@pages/admin/organisation/AdminOrganisationData";

const App = styled.section`
  min-height: 100vh;
  display: flex;
  flex: 1;
`;

class AppComponent extends React.Component {
  public render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App>
          {/* <DataLoader>*/}
          <Switch>
            <Route exact path="/signin" component={SigninPage}/>
            <Route exact path="/student/signup" component={StudentSignupPage}/>
            <Route exact path="/" component={withUser(DashboardPageData)}/>
            <Route exact path="/opportunity/new" component={withUser(NewOpportunityPage)}/>
            <Route exact path="/opportunity/:id" component={withUser(OpportunityPage)}/>
            <Route exact path="/profile" component={withUser(ProfilePage)}/>
            <Route exact path="/contacts" component={withUser(ContactsPage)}/>
            <Route exact path="/students" component={withUser(StudentsPage)}/>
            <Route exact path="/students/:id" component={withUser(StudentsProfilePage)}/>
            <PrivateRoute
              exact
              path={ROUTE_ADMIN_DASHBOARD}
              component={withUser(AdminDashboardPageData)}
              requiredRoles={ERole.ADMIN}
            />
            <PrivateRoute
              exact
              path={ROUTE_ADMIN_ORGANISATIONS}
              component={withUser(AdminOrganisationListData)}
              requiredRoles={ERole.ADMIN}
            />
            <PrivateRoute
              exact
              path={ROUTE_ADMIN_ORGANISATION_ID}
              component={withUser(AdminOrganisationData)}
              requiredRoles={ERole.ADMIN}
            />
            <Route component={ErrorPage}/>
          </Switch>
          {/* </DataLoader>*/}
          <AlertHandler/>
        </App>
      </MuiPickersUtilsProvider>
    );
  }
}


export default compose(
  withRouter,
)(AppComponent);
