import * as React from "react";
import {
  Avatar,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import * as moment from "moment-timezone";

interface IProps {
  classes: any;
  dateOpen: any;
  organisation: any;
}

const Organisation = ({
  classes,
  dateOpen,
  organisation,
}: IProps) => (
  <div className={classes.organisationBlock}>
    <Divider className={classes.divider}/>
    <Grid container spacing={24}>
      <Grid item xs={3}>
        <Avatar
          style={{
            border: "1px solid #FFD300",
            width: "58px",
            height: "58px",
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Typography
          variant="headline"
          component="h3"
          className={classes.organisationName}
        >
          {organisation.name}
        </Typography>
        {/* <Typography
          variant="body1"
          component="p"
          className={classes.organisationDescription}
        >
        </Typography>*/}
        <Typography
          variant="caption"
          component="p"
          className={classes.opportunityPosted}
        >
          {`Posted ${moment(dateOpen).fromNow()}`}
        </Typography>
      </Grid>
    </Grid>
  </div>
);

export default Organisation;
