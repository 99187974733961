import * as React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";

interface IProps {
  classes: any;
  contacts: any[];
  page: number;
  onChangePage: any;
  openContactsMore: any;
  loading: boolean;
}

interface IState {

}

const styles = (): any => ({
  root: {},
  table: {
    marginBottom: "1.0rem",
  },
});

interface IPropsMoreButton {
  id: any;
  openContactsMore: (id: any) => any;
}

const MoreButton = (props: IPropsMoreButton) => (
  <Button onClick={() => props.openContactsMore(props.id)} style={{
    marginLeft: "-1.0rem",
  }}>
    More
  </Button>
);

export class ContactsTable extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    const {
      classes, contacts, loading,
    } = this.props;

    return (
      <Paper style={{
        opacity: loading ? 0.5 : 1.0,
      }}>
        <TablePagination
          component="div"
          count={contacts.length ? parseInt(contacts[0].total_rows) : 0}
          rowsPerPage={20}
          page={this.props.page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}

          rowsPerPageOptions={[]}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={this.props.onChangePage}
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Organisation
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => {
              return (
                <TableRow key={contact.contact_id}>
                  <TableCell component="th" scope="row">
                    {`${contact.first_name} ${contact.last_name}`}
                  </TableCell>
                  <TableCell>
                    {contact.organisation_name || "Not specified"}
                  </TableCell>
                  <TableCell>
                    {contact.email}
                  </TableCell>
                  <TableCell>
                    <MoreButton id={contact.contact_id} openContactsMore={this.props.openContactsMore}/>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

    );
  }
}

export default withStyles(styles)(ContactsTable);
