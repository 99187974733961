import * as React from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  Dialpad,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
// Components
import {
  FormControlContainer,
  IconWrapper,
} from "@components/FormComponents/styled";

interface IProps {
  formData: any;
  formErrors: any;
  forgotPasswordReset?: boolean;
  onChange: (e: any) => any;
}

class SigninFormPage2Component extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      formData: {
        code: "",
        ...props.formData || {},
      },
    };
  }

  public handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  public handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  public render() {
    const {
      onChange,
      forgotPasswordReset,
    } = this.props;

    const {
      code,
      newPassword,
    } = this.props.formData;

    const {
      showPassword,
    } = this.state;

    return (
      <div>

        <Typography variant="title" gutterBottom>
          Validate your account
        </Typography>
        <Typography variant="display1">
          Enter the code from your email
        </Typography>

        <FormControlContainer>
          <FormControl fullWidth>
            <InputLabel htmlFor="code">
              Validation Code
            </InputLabel>
            <Input
              id="code"
              type={"text"}
              value={code}
              onChange={onChange("code")}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconWrapper>
                    <Dialpad/>
                  </IconWrapper>
                </InputAdornment>
              }
            />
          </FormControl>
          {forgotPasswordReset && (
            <FormControl fullWidth style={{
              marginTop: "0.5rem",
            }}>
              <InputLabel htmlFor="code">
                New password
              </InputLabel>
              <Input
                id="newPassword"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={onChange("newPassword")}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      <IconWrapper>
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconWrapper>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </FormControlContainer>

      </div>
    );
  }
}

export default SigninFormPage2Component;
