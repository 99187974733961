import * as React from "react";
// Styles
import {
  Theme,
  withStyles,
} from "@material-ui/core";
// Components
import MainTitle from "@components/MainTitle";

//
// Styles
//

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    display: "flex" as "flex",
    flexDirection: "column" as "column",
  },
});

//
// Interfaces
//

interface IProps {
  title?: string | React.ReactElement;
  children: React.ReactChildren,
}

interface IExtendedProps extends IProps {
  classes: {
    root: any;
  };
}

/**
 * Represents the main part of the screen. Sets up root as a flex parent
 * @param {any} classes
 * @param {any} children
 * @param {string | React.ReactElement} title
 * @constructor
 */
const MainPanel: React.FC<IExtendedProps> = ({
  classes,
  children,
  title,
}: IExtendedProps) => {
  return (
    <div className={classes.root}>
      {
        title &&
        <MainTitle>
          {title}
        </MainTitle>
      }
      {children}
    </div>
  );
};

export default withStyles(styles)(MainPanel);
