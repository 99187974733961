import * as React from "react";
// Styles
import {
  Checkbox,
} from "@material-ui/core";
// Components
import DataList, {
  IDataList,
  IDataListItem,
} from "@components/Generic/DataList/index";

//
// Interfaces
//

export interface IDataListWithSelection extends IDataList {
  selectedIdList: string[];
  onSelectedIdListChange: (selectedIdList: string[]) => void;
}

/**
 * Adds checkboxes and selection to the DataList
 * @param {IDataListWithSelection} props
 * @constructor
 */
const DataListWithSelection: React.FC<IDataListWithSelection> = (props)=> {
  const {
    renderItemStart,
    selectedIdList,
    listItemProps,
  } = props;

  const handleToggle = (item: IDataListItem) => {
    const {
      selectedIdList,
      onSelectedIdListChange,
    } = props;

    const newSelectedIDs = selectedIdList.includes(item.id) ?
      selectedIdList.filter((id) => id !== item.id) :
      [...selectedIdList, item.id];
    onSelectedIdListChange(newSelectedIDs);
  };

  const handleRenderItemStart = (item: IDataListItem) => {
    const selected = selectedIdList.includes(item.id);

    return (
      <React.Fragment>
        {/* Render other nodes for the start of the item if specified */}
        {renderItemStart && renderItemStart(item)}
        {/* Render select box */}
        <Checkbox
          disableRipple
          checked={selected}
          tabIndex={-1}
        />
      </React.Fragment>
    );
  };

  const handleListItemProps = (item: IDataListItem) => {
    return {
      ...(listItemProps ? listItemProps(item) : {}),
      button: true,
      onClick: () => handleToggle(item),
    };
  };

  return (
    <DataList
      {...props}
      renderItemStart={handleRenderItemStart}
      listItemProps={handleListItemProps}
    />
  );
};

export default DataListWithSelection;
