import * as React from "react";
import {
  Avatar,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  withStyles,
} from "@material-ui/core";

import {
  IMultipageFormProps,
  IMultipageFormState,
  MultipageFormPage,
} from "@components/FormComponents";
import {
  FormControlContainer,
} from "@components/FormComponents/styled";
import {
  yellow,
} from "@material-ui/core/colors";
import {
  getAvailableSchools,
} from "@utils/api";

interface IProps extends IMultipageFormProps {
  classes: any;
  formData: any;
  formErrors: any;
  onChange: (e: any) => any;
  onChangeArray: (e: any) => any;
}

interface IState extends IMultipageFormState {
  availableSchools: any[];
}

const styles: any = (theme: Theme): any => createStyles({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  listItem: {
    cursor: "pointer",
  },
  listItemSelected: {
    cursor: "pointer",
    background: yellow[600],
  },
});

class ShareWithPage extends MultipageFormPage<IProps, IState> {
  state = {
    availableSchools: [],
  };

  public async componentDidMount() {
    const availableSchools: any = await getAvailableSchools();
    this.setState({
      ...this.state,
      availableSchools: availableSchools.data,
    });
  }

  public updateShareWith = (id: number) => {
    const shareWith: any[] = this.props.formData.shareWith;
    let found = false;
    for (let i = 0; i < shareWith.length; i++) {
      if (shareWith[i] === id) {
        shareWith.splice(i, 1);
        found = true;
        break;
      }
    }
    if (!found) {
      shareWith.push(id);
    }
    this.props.onChangeArray("shareWith")({
      target: {
        value: shareWith,
      },
    });
  };

  public render() {
    const {
      classes,
    } = this.props;
    const schools: any = this.state.availableSchools || [];

    return (
      <form className={classes.root}>
        <FormControlContainer>
          <FormControl fullWidth>
            <FormLabel>
              Share with schools
            </FormLabel>
            <FormHelperText>
              Select the schools that you wish to share this opportunity with
            </FormHelperText>
          </FormControl>
        </FormControlContainer>
        <List>
          {schools.map((school, i) => (
            <React.Fragment key={i}>
              <ListItem
                className={this.props.formData.shareWith.includes(school.id) ? classes.listItemSelected : classes.listItem}
                onClick={() => this.updateShareWith(school.id)}
                key={school.id}
              >
                <ListItemAvatar>
                  <Avatar>
                    {i + 1}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  {school.name}
                </ListItemText>
              </ListItem>
              <Divider/>
            </React.Fragment>

          ))}
        </List>
      </form>
    );
  }
}

const StyledComponent = withStyles(styles)(ShareWithPage);

export default (StyledComponent);
