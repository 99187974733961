import * as React from "react";
import VideoInput from "./VideoInput";
import GenericVideoFilePlayer from "../VideoPlayer/GenericVideoFilePlayer";
import {
  IVideoInput,
} from "./GenericVideoInput";
import {
  Button,
  withStyles,
} from "@material-ui/core";

interface IProps {
  video: IVideoInput; // Source URL of selected video
  playerHeight?: number; // Height of video player
  playerWidth?: number; // Width of video player
  onChange: (e: IVideoInput) => Promise<any>; // onChange(e) - gets called by video input upon input change
  onReset: () => Promise<any>; // Gets called when the reset button is pressed.
  onDurationFound?: (duration: number) => any; // Callback to get video duration
}

interface IPropsWithStyles extends IProps {
  classes: {
    reset: any;
    root: any;
  };
}

const styles = (theme) => ({
  reset: {
    marginTop: theme.spacing.unit,
  },
  root: {
    textAlign: "center" as "center",
  },
});

/**
 * This component provides switch capabilities between the video input and video player.
 * - When a src URL is provided, the component tries to play the video.
 * - When no src URL is given, it displays a video input.
 */
class VideoInputPlayerSwitch extends React.Component<IPropsWithStyles> {
  constructor(props) {
    super(props);
  }

  handleDurationFound(duration: number) {
    const {
      onDurationFound,
    } = this.props;

    if (onDurationFound && typeof onDurationFound == "function") {
      onDurationFound(duration);
    }
  }

  render() {
    const {
      classes,
      video,
      playerHeight,
      playerWidth,
      onChange,
      onReset,
    } = this.props;

    const videoPlayer = (
      <div className={classes.root}>
        <div>
          <GenericVideoFilePlayer
            // @ts-ignore - always set by default props
            playerHeight={playerHeight}
            // @ts-ignore - always set by default props
            playerWidth={playerWidth}
            video={video}
            onDurationFound={this.handleDurationFound.bind(this)}
          />
        </div>
        <div>
          <Button
            onClick={onReset}
            className={classes.reset}
            style={{
              width: playerWidth,
            }}
          >
            Clear Video
          </Button>
        </div>
      </div>
    );

    const videoInput = (
      <VideoInput onInputChange={onChange}/>
    );

    return video.videoSelected ? videoPlayer : videoInput;
  }
}

export default withStyles(styles)(VideoInputPlayerSwitch);
