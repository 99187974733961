import * as React from "react";
import styled from "styled-components";
// Component
import Layout from "@components/Layout";
import OpportunityListNew from "@pages/opportunity/components/OpportunityList";
// Utils
import {
  Button,
  Snackbar,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";
import {
  Link,
} from "react-router-dom";

import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
import {
  getProfileAction,
} from "@actions/profile";
import {
  fetchOpportunitiesNew,
} from "@actions/opportunity";
import {
  IOpportunityWithRelations,
} from "@models/opportunity";

const Dashboard: any = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${grey[100]};
`;

interface IProps {
  opportunities: IOpportunityWithRelations[];
  profile: any;
  dispatch: Dispatch;
}

class DashboardComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.props.dispatch(fetchOpportunitiesNew());


    if (!this.props.profile) {
      this.props.dispatch(getProfileAction());
    }
  }

  public render() {
    const {
      profile,
    } = this.props;
    const profileComplete: boolean = profile ? profile.completeness.overall : false;

    return (
      <Layout page="opportunityList" onlyActive>
        <Dashboard>
          <OpportunityListNew
            opportunities={this.props.opportunities}
            showDuration
            showAge
            showExploreButton
            showApplyButton
            onlyActive
          />
        </Dashboard>
        {this.props.profile && !profileComplete &&
        <Snackbar
          open={true}
          message={<span>
          Your profile isn't quite complete.
            <Link to="profile">
              <Button color="secondary">
                Complete now
              </Button>
            </Link>
          </span>}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        />
        }
      </Layout>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  student: state.student,
  opportunities: state.opportunities.opportunitiesNew,
});

export default connect(
  mapStateToProps,
)(DashboardComponent);
