import * as React from "react";
import {
  useState,
} from "react";
import {
  gql,
} from "apollo-boost";
import {
  useQuery,
} from "@apollo/react-hooks";
import {
  Dispatch,
} from "redux";
import {
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  ERROR,
  IOrganisation,
} from "@models/organisation";
import {
  AlertLevel,
} from "@components/global.model";
// Components
import AdminOrganisationLayout, {
  adminOrganisationFragments,
} from "@pages/admin/organisation/AdminOrganisationLayout";
import {
  organisationSchoolPartnershipsFragments,
} from "@components/Organisation/Partnerships/SchoolPartnershipsTable";

//
// Constants
//

export const GET_ORGANISATION = gql`
    query AdminOrganisationListGetOrganisationList($id: Int!) {
        getOrganisation(organisation: {id: $id}) {
            id
            ...AdminOrganistion
            schoolPartnerList {
                id
                ...SchoolPartnershipsTable_School
            }
        }
    }
    ${adminOrganisationFragments.organisation}
    ${organisationSchoolPartnershipsFragments.school}
`;

//
// Interfaces
//

interface IMatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  dispatch: Dispatch;
}

/**
 * TODO: Add graphql types. https://grandshake.atlassian.net/browse/WA-454
 * @param {Dispatch} dispatch
 * @param {any} match
 * @constructor
 */
const AdminOrganisationData: React.FC<IProps> = ({
  dispatch,
  match,
}: IProps): React.ReactElement => {
  // Get organisation id from route
  const organisationId: number = Number(match.params.id);

  const {
    loading,
    error,
    data,
  } = useQuery(GET_ORGANISATION, {
    variables: {
      id: organisationId,
    },
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR.GET_ORGANISATION,
        },
      ));
    },
  });

  const [createOrganisationUserDialogOpen, setCreateOrganisationUserDialogOpen] = useState<boolean>(false);
  const [addPartnershipDialogOpen, setAddPartnershipDialogOpen] = useState<boolean>(false);

  const organisation: IOrganisation | undefined =
    !error &&
    data &&
    data.getOrganisation ?
      data.getOrganisation :
      undefined;

  return (
    <AdminOrganisationLayout
      loading={loading}
      organisation={organisation}
      createOrganisationUserDialogOpen={createOrganisationUserDialogOpen}
      setCreateOrganisationUserDialogOpen={setCreateOrganisationUserDialogOpen}
      addPartnershipDialogOpen={addPartnershipDialogOpen}
      setAddPartnershipDialogOpen={setAddPartnershipDialogOpen}
    />
  );
};

export default withRouter(AdminOrganisationData);
