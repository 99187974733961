import * as React from "react";
// Styles
import {
  Avatar,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  withStyles,
} from "@material-ui/core";
import {
  Link,
} from "@utils/link";
import {
  HideOnSmall,
  NavActions,
  StyledAdd,
  StyledAssignment,
  StyledChat,
  StyledNotifications,
  StyledPeople,
} from "../styled";
import {
  AccountCircle,
  ExitToApp,
  Face,
  Settings,
} from "@material-ui/icons";
// Utils
import {
  signOut,
} from "@utils/auth";
import {
  history,
} from "@utils/history";
import {
  ROUTE_ADMIN_DASHBOARD,
} from "@utils/routes";


const styles = (theme: Theme): any => ({
  navIcon: {
    marginRight: theme.spacing.unit,
    opacity: 0.38,
    color: "#000DFF",
  },
  navButton: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: 400,
  },
});

interface IProps {
  classes: {
    navIcon: any,
    navButton: any,
  }
  canCreateOpportunity: boolean;
  canViewAdminDashboard: boolean;
  canViewContacts: boolean;
  canViewProfile: boolean;
  profilePictureUrl?: string;
  userMenuAnchor,
  contactMenuAnchor,
  setUserMenuAnchor,
  setContactMenuAnchor,
}

/**
 * Default Nav Bar: to be used for the main app
 */
export class DefaultNavActions extends React.Component<IProps> {
  private handleSignOut = async (): Promise<void> => {
    await signOut();
  };

  private goToProfile = (): void => {
    history.push("/profile");
  };

  private gotToAdminDashboard = (): void => {
    history.push(ROUTE_ADMIN_DASHBOARD);
  };

  private goToStudentContacts = (): void => {
    history.push("/students");
  };

  /* TODO: WA-246: https://grandshake.atlassian.net/browse/WA-246 */
  private goToIndustryContacts = (): void => {
    history.push("/contacts");
  };

  /**
   * Render
   * @return {React.Component}
   */
  render() {
    const {
      classes,
      canCreateOpportunity,
      canViewAdminDashboard,
      canViewContacts,
      canViewProfile,
      profilePictureUrl,
      userMenuAnchor,
      contactMenuAnchor,
      setUserMenuAnchor,
      setContactMenuAnchor,
    } = this.props;

    const userMenuOpen = Boolean(userMenuAnchor);
    const contactMenuOpen = Boolean(contactMenuAnchor);

    return (
      <NavActions>
        {/* CREATE */}
        {
          canCreateOpportunity && (
            <Button
              className={classes.navButton}
              component={Link("/opportunity/new")}
            >
              <StyledAdd/>
              <HideOnSmall>
                CREATE
              </HideOnSmall>
            </Button>
          )
        }

        {/* LISTING */}
        <Button
          className={classes.navButton}
          component={Link("/")}
        >
          <StyledAssignment/>
          <HideOnSmall>
            LISTING
          </HideOnSmall>
        </Button>

        {/* CONTACTS*/}
        {
          canViewContacts && (
            <React.Fragment>
              <Button
                className={classes.navButton}
                onClick={(e) => setContactMenuAnchor(e.currentTarget)}
              >
                <StyledPeople/>
                <HideOnSmall>
                  CONTACTS
                </HideOnSmall>
              </Button>

              <Menu
                id="menu-contact"
                anchorEl={contactMenuAnchor || undefined}
                open={contactMenuOpen}
                onClose={() => setContactMenuAnchor(undefined)}
              >
                <MenuItem onClick={this.goToStudentContacts}>
                  <ListItemIcon>
                    <Face/>
                  </ListItemIcon>
                  Students
                </MenuItem>
                {/* TODO: WA-246: https://grandshake.atlassian.net/browse/WA-246 */}
                {/* <MenuItem onClick={this.goToIndustryContacts}>*/}
                {/*  <ListItemIcon>*/}
                {/*    <Business/>*/}
                {/*  </ListItemIcon>*/}
                {/*  Industry*/}
                {/* </MenuItem>*/}
                }
              </Menu>
            </React.Fragment>
          )
        }

        <StyledChat/>
        <StyledNotifications/>

        {/* AVATAR & USER MENU */}
        <Avatar
          onClick={(e) => setUserMenuAnchor(e.currentTarget)}
          style={{
            marginLeft: "0.5rem",
            border: "1px solid #FFD300",
            width: "42px",
            height: "42px",
          }}
          src={profilePictureUrl}
        >
          {profilePictureUrl ? null : (
            <AccountCircle
              style={{
                fontSize: "64px",
              }}
            />
          )}
        </Avatar>
        <Menu
          id="menu-appbar"
          anchorEl={userMenuAnchor || undefined}
          open={userMenuOpen}
          onClose={() => setUserMenuAnchor(undefined)}
        >
          {
            canViewProfile && (
              <MenuItem onClick={this.goToProfile}>
                <ListItemIcon>
                  <Face/>
                </ListItemIcon>
                Profile
              </MenuItem>
            )
          }
          {
            canViewAdminDashboard && (
              <MenuItem onClick={this.gotToAdminDashboard}>
                <ListItemIcon>
                  <Settings/>
                </ListItemIcon>
                Administration
              </MenuItem>
            )
          }

          <MenuItem onClick={this.handleSignOut}>
            <ListItemIcon>
              <ExitToApp/>
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </NavActions>
    );
  }
}

export const DefaultNavActionsLayout = withStyles(styles)(DefaultNavActions);
