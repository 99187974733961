import * as React from "react";
import {
  useState,
} from "react";
import {
  ApolloError,
  FetchResult,
  gql,
} from "apollo-boost";
import * as moment from "moment";
import {
  Avatar,
  Button,
  Card,
  Chip,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Book,
  ExpandMore,
  Search,
  Timer,
  VerifiedUser,
} from "@material-ui/icons";
import {
  easyToReadFormat,
} from "@utils/dates";
import StreamFromStorageKey from "@components/VideoPlayer/VideoStreamPlayer/StreamFromStorageKey";
import {
  ConfirmationDialog,
} from "@components/Generic/ConfirmationDialog";

const style = (theme: Theme) => ({
  root: {
    display: "flex" as "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column" as "column",
    },
  },
  applicationList: {
    flex: 1,
  },
  searchPanel: {
    padding: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    maxHeight: "95px",
  },
  applicationPanel: {
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  applicationSummaryPanel: {
    display: "flex" as "flex",
  },
  applicantOverview: {
    flex: 1,
    alignItems: "center" as "center",
  },
  applicantNameAndIcon: {
    flex: 1,
    display: "flex" as "flex",
  },
  applicationNameText: {
    flex: 1,
    marginLeft: theme.spacing.unit * 2,
  },
  player: {
    margin: "auto" as "auto",
  },
  statusSelect: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  chip: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

export const applicationListFragments = {
  application: gql`
        fragment ApplicationListApplication on Application {
            status {
                id
                name
            }
            availableStatusTransitions {
                id
                name
                transitionName
            }
            student {
                id
                user {
                    firstName
                    lastName
                }
                school {
                    id
                    name
                }
                grade
            }
            videoResponse
            textResponseList {
                response
                applicationQuestion {
                    id
                    question
                }
            }
            createdAt
        }
    `,
};

interface IProps {
  applications: any[];
  applicationListLoading: boolean;
  applicationListError?: ApolloError;
  canUpdateApplicationStatus: boolean;
  updateApplicationStatus: (studentId: string, statusId: number) => Promise<FetchResult>;
  onProfileClick: (studentId: string) => void;
  children?: React.ReactNode;
}

interface IPropsWithStyles extends IProps {
  classes: any;
}

interface IProposedStatusChange {
  studentId: string;
  status: IStatus;
}

interface IStatus {
  id: number;
  name: string;
  transitionName: string;
}

/*
 * Functional Component that is responsible for:
 * - Defines a fragment that outlines what data it displays (includes on ids on all types that have them)
 * - Presenting a list of applications
 * - Styling
 * TODO: Generate types using apollo codegen
 */
export const ApplicationListLayout = withStyles(style)((props: IPropsWithStyles) => {
  const {
    applications,
    applicationListLoading,
    applicationListError,
    canUpdateApplicationStatus,
    onProfileClick,
    updateApplicationStatus,
    classes,
  } = props;

  // Setup handlers for confirmation dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [proposedStatusUpdate, setProposedStatusUpdate] = useState<IProposedStatusChange | undefined>();

  const handleSelect = (studentId: string, status: IStatus) => {
    setProposedStatusUpdate({
      studentId,
      status,
    });
    setConfirmDialogOpen(true);
  };

  const handleConfirm = async () => {
    if (proposedStatusUpdate != null) {
      await updateApplicationStatus(proposedStatusUpdate.studentId, proposedStatusUpdate.status.id);
    }
    setConfirmDialogOpen(false);
  };

  // Edge cases
  if (applicationListLoading) return (<CircularProgress size={48}/>);
  if (applicationListError || applications.length === 0) {
    return (<Typography>
      No applications to show yet.
    </Typography>);
  }

  return (
    <div className={classes.root}>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={confirmDialogOpen}
        handleConfirm={handleConfirm}
        handleClose={() => setConfirmDialogOpen(false)}
        title='Confirm change'
        message={`Update application status to "${proposedStatusUpdate && proposedStatusUpdate.status.name ? proposedStatusUpdate.status.name : ""}"?`}
        confirmText='Yes'
      />

      {/* Application List */}
      <div className={classes.applicationList}>
        {
          applications.map((application: any) => {
            return (
              <ExpansionPanel
                className={classes.applicationPanel}
                key={application.student_id}
              >
                <ExpansionPanelSummary
                  className={classes.applicationSummaryPanel}
                  expandIcon={<ExpandMore/>}
                  aria-controls={`application-content-${application.student.id}-header`}
                  id={`application-content-${application.student.id}-header`}
                >
                  {/* Avatar & name */}
                  <div className={classes.applicantNameAndIcon}>
                    <Avatar>
                      {application.student.user.firstName[0]}
                      {application.student.user.lastName[0]}
                    </Avatar>
                    <div className={classes.applicationNameText}>
                      <Typography variant="subheading">
                        {application.student.user.firstName}
                        {" "}
                        {application.student.user.lastName}
                      </Typography>
                      <Typography>
                        {application.student.school.name}
                      </Typography>
                    </div>
                  </div>

                  {/* Applicant Overview */}
                  <div className={classes.applicantOverview}>
                    <Chip
                      className={classes.chip}
                      avatar={<Avatar>
                        <Book/>
                      </Avatar>}
                      label={`Grade ${application.student.grade}`}
                    />
                    <Chip
                      className={classes.chip}
                      avatar={<Avatar>
                        <Timer/>
                      </Avatar>}
                      label={`${moment(application.createdAt).format(easyToReadFormat)}`}
                    />
                    <Chip
                      className={classes.chip}
                      avatar={<Avatar>
                        <VerifiedUser/>
                      </Avatar>}
                      label={`Status: ${application.status.name}`}
                    />
                  </div>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className={classes.applicationDetailPanel}>
                  {/* Video Response*/}
                  {application.videoResponse && (
                    <div className={classes.player}>
                      <StreamFromStorageKey
                        storageKey={application.videoResponse}
                        playerWidth={620}
                        playerHeight={414}
                      />
                    </div>
                  )}

                  {/* Text Responses*/}
                  {application.textResponseList && (
                    <div>
                      {
                        application.textResponseList.map((textResponse) => {
                          return (
                            <div key={textResponse.applicationQuestion.id} style={{
                              width: "100%",
                            }}>
                              <Typography variant="subheading">
                                {textResponse.applicationQuestion.question}
                              </Typography>
                              <Typography variant="body1">
                                {textResponse.response}
                              </Typography>
                            </div>
                          );
                        })
                      }
                    </div>
                  )}
                </ExpansionPanelDetails>

                <ExpansionPanelActions>
                  <FormControl>
                    {
                      canUpdateApplicationStatus &&
                      <Select
                        value={application.status.id}
                        onChange={(e) => {
                          const statusId = Number(e.target.value);
                          const statusList: IStatus[] = application.availableStatusTransitions.filter((status: IStatus) => status.id === statusId);
                          handleSelect(application.student.id, statusList[0]);
                        }}
                        name="status"
                      >
                        <MenuItem value={application.status.id} disabled>
                          {application.status.name}
                        </MenuItem>
                        {
                          application.availableStatusTransitions.map((status: IStatus) => {
                            return (
                              <MenuItem key={status.id} value={status.id}>
                                {status.transitionName}
                              </MenuItem>
                            );
                          })
                        }
                      </Select>
                    }
                  </FormControl>

                  <Button
                    onClick={() => onProfileClick(application.student.id)}
                  >
                    View Profile
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
            );
          })
        }
      </div>

      {/* SEARCH PANEL */}
      <Card className={classes.searchPanel}>
        <Typography variant="subheading">
          Advanced Search
        </Typography>
        <FormControl>
          <InputLabel htmlFor="adornment-search">
            Search
          </InputLabel>
          <Input
            id="adornment-search"
            value=''
            onChange={() => {
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Search"
                  onClick={() => {
                  }}
                >
                  <Search/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Card>
    </div>
  );
});
