import {
  SET_OPPORTUNITIES,
  SET_OPPORTUNITIES_NEW,
  SET_OPPORTUNITY,
  SET_OPPORTUNITY_NEW,
  UPDATE_EDIT_MODE,
  UPDATE_EXPLORE_VIEW,
  UPDATE_FILTERS,
  UPDATE_PERMISSIONS,
} from "@actions/opportunity";

const opportunities = (state = {
  opportunitiesNew: [],
  opportunityNew: null,
  permissions: {
    canSubmitApplication: false,
    canEditOpportunity: false,
    canDeleteOpportunity: false,
    canViewApplications: false,
  },
}, action) => {
  switch (action.type) {
  case UPDATE_FILTERS:
    return {
      ...state,
      filterOptions: {
        active: action.filterOptions.active,
        closed: action.filterOptions.closed,
        opportunityTypes: action.filterOptions.opportunityTypes,
        searchString: action.filterOptions.searchString,
      },
    };
  case UPDATE_PERMISSIONS:
    return {
      ...state,
      permissions: {
        ...state.permissions,
        ...action.permissions,
      },
    };
  case UPDATE_EXPLORE_VIEW:
    return {
      ...state,
      exploreView: action.exploreView,
    };
  case SET_OPPORTUNITY:
    return {
      ...state,
      opportunity: action.opportunity,
    };
  case UPDATE_EDIT_MODE:
    return {
      ...state,
      editMode: action.editMode,
    };
  case SET_OPPORTUNITIES: {
    return {
      ...state,
      opportunityList: action.opportunities,
    };
  }
  case SET_OPPORTUNITIES_NEW: {
    return {
      ...state,
      opportunitiesNew: action.opportunitiesNew,
    };
  }
  case SET_OPPORTUNITY_NEW: {
    return {
      ...state,
      opportunityNew: action.opportunityNew,
    };
  }
  default:
    return state;
  }
};

export default {
  opportunities,
};
