import * as React from "react";

import {
  grey,
  indigo,
} from "@material-ui/core/colors";
import styled from "styled-components";

const ProgressBar: any = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProgressBarItem: any = styled.div`
  flex: 1;
  height: 6px;
  border: 1px white solid;
  background: ${(props: any) => props.highlighted ? indigo.A700 : grey[400]}
`;

interface IProps {
  max: number;
  value: number;
}

class ProgressBarComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      max: 4,
      value: 1,
      ...props,
    };
  }

  public renderItems = () => {
    const items: any = [];
    for (let i = 1; i <= this.props.max; i++) {
      items.push(
        <ProgressBarItem
          key={i}
          value={i}
          highlighted={this.props.value >= i}
        />,
      );
    }
    return items;
  };

  public render() {
    return (
      <ProgressBar>
        {this.renderItems()}
      </ProgressBar>
    );
  }
}

export default ProgressBarComponent;
