import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import * as React from "react";
import {
  Mail,
  People,
  Phone,
  Work,
} from "@material-ui/icons";

interface IProps {
  open: boolean;
  onClose: any;
  contact: any;
}

export const ContactsMore = (props: IProps) => (
  <Dialog
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {props.contact ?
      <React.Fragment>
        <DialogTitle id="alert-dialog-title">
          {props.contact.first_name}
          {" "}
          {props.contact.last_name}
        </DialogTitle>

        <DialogContent>
          <div>
            <List>

              <ListItem>
                <ListItemIcon>
                  <People/>
                </ListItemIcon>
                <ListItemText>
                  {props.contact.organisation_name}
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Work/>
                </ListItemIcon>
                <ListItemText>
                  {props.contact.position}
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Mail/>
                </ListItemIcon>
                <ListItemText>
                  {props.contact.email}
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Phone/>
                </ListItemIcon>
                <ListItemText>
                  {props.contact.phone_number}
                </ListItemText>
              </ListItem>

            </List>
          </div>
        </DialogContent>
      </React.Fragment> :
      <div></div>
    }
  </Dialog>
);
