import * as React from "react";
// Styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
// Models
import {
  IOrganisation,
} from "@models/organisation";
// Components
import {
  ConfirmationDialog,
} from "@components/Generic/ConfirmationDialog";
import AddPartnershipsListData from "@components/Organisation/Partnerships/AddPartnershipsList/AddPartnershipsListData";

//
// Interfaces
//

interface IProps {
  open: boolean;
  organisation: IOrganisation;
  selectedSchoolIdList: number[];
  onSelectedSchoolIdListChange: (idList: number[]) => void;
  confirmationDialogOpen: boolean;
  setConfirmationDialogOpen: (open: boolean) => void;
  onClose: () => void;
  onSubmit: () => void;
}

/**
 * TODO: Add Graphql Types. https://grandshake.atlassian.net/browse/WA-458
 * @param {IProps} props
 * @constructor
 */
const AddPartnershipsDialogLayout: React.FC<IProps> = (props) => {
  const {
    organisation,
    selectedSchoolIdList,
    onSelectedSchoolIdListChange,
    confirmationDialogOpen,
    setConfirmationDialogOpen,
    open,
    onClose,
    onSubmit,
  } = props;

  const onSave = selectedSchoolIdList.length > 0 ? () => setConfirmationDialogOpen(true) : onClose;

  return (
    <>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        handleConfirm={onSubmit}
        handleClose={() => setConfirmationDialogOpen(false)}
        title="Confirm Change"
        message={`Add selected schools as partners of ${organisation.name}`}
        confirmText="Confirm"

      />

      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          Add Partners of
          {" "}
          {organisation.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following schools are yet to partner:
          </DialogContentText>

          <AddPartnershipsListData
            organisation={organisation}
            selectedSchoolIdList={selectedSchoolIdList}
            onSelectedSchoolIdListChange={onSelectedSchoolIdListChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPartnershipsDialogLayout;
