import {
  amber,
} from "@material-ui/core/colors";
import * as React from "react";
import styled from "styled-components";
// Components
import SigninForm from "@pages/user/SigninForm";

const SigninPage = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${amber[400]};
`;

class SigninPageComponent extends React.Component<any, any> {
  public render() {
    return (
      <SigninPage>
        <SigninForm/>
      </SigninPage>
    );
  }
}

export default SigninPageComponent;
