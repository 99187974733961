import * as React from "react";
import {
  gql,
} from "apollo-boost";
// Style
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";

interface IProps {
  studentList: any[];
  loading: boolean;
  onStudentClick: (studentId: string) => void;
}

interface IExtendedProps extends IProps {
  classes: any;
}

const styles = () => ({
  table: {
    marginBottom: "1.0rem",
  },
  tableHeader: {
    color: grey[500],
  },
});

export const studentsPageTableFragments = {
  student: gql`
        fragment StudentsTableStudent on Student {
            id
            user {
                firstName
                lastName
                email
            }
            grade
        }
    `,
};

export class StudentsTable extends React.Component<IExtendedProps> {
  public render() {
    const {
      classes,
      studentList,
      loading,
      onStudentClick,
    } = this.props;

    if (loading) return (<CircularProgress size={48}/>);

    return (
      <Paper style={{
        opacity: loading ? 0.5 : 1.0,
      }}>
        {/* Pagination: https://grandshake.atlassian.net/browse/WA-403 */}
        {/* <TablePagination*/}
        {/*  component="div"*/}
        {/*  count={studentList.length ? parseInt(students[0].total_rows) : 0}*/}
        {/*  rowsPerPage={20}*/}
        {/*  page={this.props.page}*/}
        {/*  backIconButtonProps={{*/}
        {/*    "aria-label": "Previous Page",*/}
        {/*  }}*/}

        {/*  rowsPerPageOptions={[]}*/}
        {/*  nextIconButtonProps={{*/}
        {/*    "aria-label": "Next Page",*/}
        {/*  }}*/}
        {/*  onChangePage={this.props.onChangePage}*/}
        {/* />*/}

        <Toolbar>
          <Typography variant={"headline"}>
            Students
          </Typography>
        </Toolbar>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  className={classes.tableHeader}
                >
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  className={classes.tableHeader}
                >
                  Grade
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  className={classes.tableHeader}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  className={classes.tableHeader}
                >
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {studentList.map((student) => {
              return (
                <TableRow key={student.id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body1">
                      {`${student.user.firstName} ${student.user.lastName}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {student.grade ? `Grade ${student.grade}` : "Not specified"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {student.user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => onStudentClick(student.id)}>
                      <Typography variant="body1">
                        View Profile
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

    );
  }
}

export default withStyles(styles)(StudentsTable);
