import {
  createMuiTheme,
  Theme,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";

export const theme: Theme = createMuiTheme({
  typography: {
    headline: {
      letterSpacing: "0.25",
      fontSize: "20px",
      fontFamily: [
        "Raleway",
        "Source Sans Pro",
      ].join(","),
    },
    body1: {
      letterSpacing: "0.5",
    },
  },
  palette: {
    background: {
      default: grey[100],
    },
  },
});
