import * as React from "react";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import {
  gql,
} from "apollo-boost";
import {
  useQuery,
} from "@apollo/react-hooks";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  AlertLevel,
} from "@components/global.model";
import {
  ERROR as OrganisationError,
  IOrganisation,
} from "@models/organisation";
import {
  ERROR as SchoolError,
} from "@models/school";
// Components
import AddPartnershipsListLayout, {
  AddPartnershipsListFragments,
} from "@components/Organisation/Partnerships/AddPartnershipsList/AddPartnershipsListLayout";

//
// Constants
//

export const GET_SCHOOL_LIST = gql`
    query AddPartnershipsListData_SchoolPartnerListForOrganisation {
        getSchoolList {
            id
            ...AddPartnershipsListSchool
        }
    }
    ${AddPartnershipsListFragments.school}
`;

export const GET_SCHOOL_PARTNER_LIST_FOR_ORGANISATION = gql`
    query AddPartnershipsListData_SchoolList($organisationId: Int!) {
        getOrganisation(organisation: {id: $organisationId}) {
            id
            schoolPartnerList {
                id
                ...AddPartnershipsListSchool
            }
        }
    }
    ${AddPartnershipsListFragments.school}
`;

//
// Interfaces
//

interface IProps {
  organisation: IOrganisation;
  selectedSchoolIdList: number[];
  onSelectedSchoolIdListChange: (idList: number[]) => void;
}

interface IExtendedProps extends IProps {
  dispatch: Dispatch;
}

/**
 * List of schools that the org could create new partnerships with
 * TODO: add graphql types. https://grandshake.atlassian.net/browse/WA-459
 * @param {IExtendedProps} props
 * @return {React.ReactElement}
 */
const addPartnershipsListData: React.FC<IExtendedProps> = (props) => {
  const {
    organisation,
    selectedSchoolIdList,
    onSelectedSchoolIdListChange,
    dispatch,
  } = props;

  // Get a list of all schools
  const {
    loading: getSchoolListLoading,
    data: getSchoolListData,
    error: getSchoolListError,
  } = useQuery(GET_SCHOOL_LIST, {
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: SchoolError.GET_SCHOOL_LIST,
        },
      ));
    },
  });

  const schoolList: any[] =
    !getSchoolListLoading &&
    !getSchoolListError ?
      getSchoolListData.getSchoolList :
      [];

  // Get list of partnered schools
  const {
    loading: getSchoolPartnerListForOrganisationLoading,
    data: getSchoolPartnerListForOrganisationData,
    error: getSchoolPartnerListForOrganisationError,
  } = useQuery(GET_SCHOOL_PARTNER_LIST_FOR_ORGANISATION, {
    variables: {
      organisationId: organisation.id,
    },
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: OrganisationError.GET_ORGANISATION,
        },
      ));
    },
  });

  const partneredSchoolList: any[] =
    !getSchoolPartnerListForOrganisationLoading &&
    !getSchoolPartnerListForOrganisationError ?
      getSchoolPartnerListForOrganisationData.getOrganisation.schoolPartnerList :
      [];
  const partneredSchoolIdList: any[] = partneredSchoolList.map((school) => school.id) || [];

  // Get list of available schools
  const availableSchools: any[] = schoolList.filter((school) => !partneredSchoolIdList.includes(school.id));

  return (
    <AddPartnershipsListLayout
      loading={getSchoolPartnerListForOrganisationLoading || getSchoolListLoading}
      availableSchoolList={availableSchools}
      selectedSchoolIdList={selectedSchoolIdList}
      onSelectedSchoolIdListChange={onSelectedSchoolIdListChange}
    />
  );
};

export default connect()(addPartnershipsListData);
