import * as React from "react";
import {
  get,
} from "lodash";
// Styles
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
// Components
import {
  Loading,
} from "@components/Loading/Loading";

//
// Styles
//

const styles = (theme: Theme) => ({
  root: {
    display: "flex" as "flex",
    flex: 1,
    flexDirection: "column" as "column",
  },
  titleContainer: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  emptyContainer: {
    display: "flex" as "flex",
    flex: 1,
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    padding: theme.spacing.unit,
  },
});

//
// Interfaces
//

export interface ITableColumn {
  Header: React.ReactNode;
  id: string;
  compact?: boolean;
  isNumeric?: boolean;
}

export interface IDataTable {
  title?: string;
  emptyMessage?: string;
  loading: boolean;
  data: Readonly<object>[];
  columns: ITableColumn[];
  idAccessor?: string;
  onRowPress?: (id: string) => void;
}

interface IExtendedDataTable extends IDataTable {
  classes: {
    emptyContainer: any;
    root: any;
    titleContainer: any;
  },
}

/**
 * A generic data table.
 * @param {IProps} props
 * @constructor
 */
const DataTable: React.FC<IExtendedDataTable> = (props: IExtendedDataTable) => {
  const {
    classes,
    emptyMessage,
    loading,
    data,
    columns,
    idAccessor,
    title,
    onRowPress,
  } = props;

  const defaultEmptyMessage: string = "No rows found";
  const defaultIdAccessor: string = "id";

  return (
    <Paper className={classes.root}>
      {
        title &&
        <div className={classes.titleContainer}>
          <Typography variant='headline'>
            {title}
          </Typography>
        </div>
      }
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                id={column.id}
              >
                {column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            !loading && data.map((row) => {
              const rowId = String(get(row, idAccessor || defaultIdAccessor));

              return (
                <TableRow
                  key={rowId}
                  hover={onRowPress != null}
                  onClick={onRowPress ? () => onRowPress(rowId) : undefined}
                >
                  {
                    columns.map((column) => {
                      const data = get(row, column.id);

                      return (
                        <TableCell
                          key={column.id}
                        >
                          {data}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>

      <div className={classes.emptyContainer}>
        {loading && <Loading/>}
        {
          !loading &&
          data.length === 0 &&
          <Typography>
            {emptyMessage || defaultEmptyMessage}
          </Typography>
        }
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DataTable);
