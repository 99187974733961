import {
  Storage,
} from "aws-amplify";
import {
  AWS_S3_PROFILE_PICTURE_BUCKET,
} from "@utils/env";

//
// Constants
//
export const PROFILE_PICTURE_MAX_SIZE = 2000000;
export const PROFILE_PICTURE_MAX_SIZE_ERROR_MESSAGE = "Profile pictures must be at most 2MB in size";

/**
 * Retreives the URL of the specified profile picture
 * Requires the AWS_S3_PROFILE_PICTURE_BUCKET env variable to be set
 * @param {string | undefined} s3Key - location of the profile picture in the s3 bucket
 */
export const getProfilePictureUrl = async (s3Key: string | undefined): Promise<string | undefined> => {
  if (s3Key == null) return undefined;
  // use pre-signed URL returned from s3
  const presignedURL: object = await Storage.get(s3Key, {
    bucket: AWS_S3_PROFILE_PICTURE_BUCKET,
  });
  return presignedURL.toString();
};

/**
 * Uploads the given profile picture under the given name
 * Requires the AWS_S3_PROFILE_PICTURE_BUCKET env variable to be set
 * @param {string} fileName - name of the profile picture
 * @param {File} file - profile picture file
 */
export const putProfilePicture = async (fileName: string, file: File): Promise<any> =>
  Storage.put(fileName, file, {
    contentType: file.type,
    bucket: AWS_S3_PROFILE_PICTURE_BUCKET,
  });
