import Amplify from "aws-amplify";
import axios, {
  AxiosResponse,
} from "axios";
import {
  get,
} from "lodash";

import {
  ICreateOpportunityFormData,
} from "@actions/opportunity";

import {
  IUpdateUserRequest,
  IUser,
} from "@models/user";
import {
  ILocation,
} from "@models/location";
import {
  ISchool,
} from "@models/school";
import {
  ICreateUpdateStudent,
} from "@models/profile";
import {
  IOpportunityWithRelations,
  Opportunity,
  OpportunityResponse,
} from "@models/opportunity";

import {
  API_URL,
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_COGNITO_USER_POOL_CLIENT_ID,
  AWS_COGNITO_USER_POOL_ID,
  AWS_REGION,
  AWS_S3_PROFILE_PICTURE_BUCKET,
} from "@utils/env";
import {
  getAccessToken,
} from "@utils/auth";

const API_METHOD_POST = "POST";
const API_METHOD_PUT = "PUT";
const API_METHOD_GET = "GET";

Amplify.configure({
  Auth: {
    identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO_USER_POOL_CLIENT_ID,
  },
  Storage: {
    bucket: AWS_S3_PROFILE_PICTURE_BUCKET,
    region: AWS_REGION,
    identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
  },
});

function getUrl(endpoint) {
  return API_URL + endpoint;
}

function apiCall(
  method,
  endpoint,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: any = {},
  data: any = {},
  headers: any = {},
): Promise<OpportunityResponse | any> {
  return new Promise((resolve, reject): Promise<void> => {
    const url = getUrl(endpoint);
    return getAccessToken()
      .then((accessToken) => {
        if (accessToken && !headers.Authorization) {
          headers.Authorization = `Bearer ${accessToken}`;
        }
        const request = {
          data,
          headers,
          method,
          url,
        };
        return axios(request);
      })
      .then((response: any) => {
        return resolve(get(response, "data"));
      })
      .catch((error) => {
        return reject(get(error, "response.data", error));
      });
  });
}

let dataLoading = false;
let dataLoaded = false;

export function getData() {
  if (dataLoaded || dataLoading) {
    return Promise.resolve();
  }
  dataLoading = true;

  return apiCall(API_METHOD_GET, "/data").then((results: any) => {
    dataLoaded = true;
    dataLoading = false;
    return results;
  });
}

export function createStudent(studentData) {
  return apiCall(API_METHOD_POST, "/student", {}, studentData);
}

export function updateStudent(id, authType, token, studentData) {
  const headers = {
    Authorization: `${authType} ${token}`,
  };
  return apiCall(API_METHOD_PUT, `/student/${id}`, {}, studentData, headers);
}

export function updateOpportunity(
  id: number,
  opportunityData: Opportunity,
): Promise<OpportunityResponse> {
  return apiCall(API_METHOD_PUT, `/opportunity/${id}`, {}, opportunityData);
}

export function createContact(body: any): Promise<any> {
  return apiCall(API_METHOD_POST, "/contact", {}, body);
}

export function getSchoolContacts(limit: number, page: number): Promise<any> {
  return apiCall(API_METHOD_GET, `/school/contacts?limit=${limit}&page=${page}`);
}

export function getSchoolContactsSearch(searchString: string, limit: number, page: number): Promise<any> {
  return apiCall(API_METHOD_GET, `/school/contacts/search?searchString=${searchString}&limit=${limit}&page=${page}`);
}

/** **
 *
 *
 * As part of overhauling the project (beginning with the api), we are currently retrieving resources from
 * two APIs. Everything past this point is using the currently static uri api. This will change later,
 * and soon enough everything should be pointing to this api instead.
 *
 *
 */

export enum EHTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface IHTTPResponse<T> {
  links: {};
  data: T[];
}

const apiCallNew = async (
  method: EHTTPMethod,
  endpoint: string,
  params?: any,
  data?: any,
  headers?: any,
): Promise<IHTTPResponse<any>> => {
  try {
    const url: string = `${API_URL}${endpoint}`;

    console.log(API_URL, url);

    const token = await getAccessToken();

    if (token) headers.Authorization = `Bearer ${token}`;

    const response: AxiosResponse<IHTTPResponse<any>> = await axios({
      data,
      headers,
      method,
      url,
    });

    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/*
 * Location
 */
export function locationSuburbSearchNew(suburb): Promise<IHTTPResponse<ILocation>> {
  return apiCallNew(EHTTPMethod.GET, `/location?prefix=${suburb}`, {}, {}, {});
}

/*
 * Applications
 */
export function updateApplicationVideoKey(opportunityId: number, videoKey: string): any {
  return apiCallNew(EHTTPMethod.PUT, `/application/${opportunityId}/video-key`, {}, {
    video_response: videoKey,
  }, {});
}

/*
 * Opportunities
 */
export function getOpportunitiesNew(): Promise<IHTTPResponse<IOpportunityWithRelations>> {
  return apiCallNew(EHTTPMethod.GET, "/opportunity", {}, {}, {});
}

export function getOpportunityNew(id: number): Promise<IHTTPResponse<IOpportunityWithRelations>> {
  return apiCallNew(EHTTPMethod.GET, `/opportunity/${id}`, {}, {}, {});
}

export function createOpportunityNew(formData: ICreateOpportunityFormData): Promise<IHTTPResponse<IOpportunityWithRelations>> {
  return apiCallNew(EHTTPMethod.POST, "/opportunity", {}, formData, {});
}

export function deleteOpportunityNew(id: number): Promise<IHTTPResponse<IOpportunityWithRelations>> {
  return apiCallNew(EHTTPMethod.DELETE, `/opportunity/${id}`, {}, {}, {});
}

export function updateOpportunityVideoKey(id: number, key: string): Promise<IHTTPResponse<IOpportunityWithRelations>> {
  return apiCallNew(EHTTPMethod.PUT, `/opportunity/${id}/video-key`, {}, {
    video_key: key,
  }, {});
}

export function postApplication(opportunityId: number, responses: Map<number, string>): any {
  const jsonResponses: any = {};
  responses.forEach((value, key) => jsonResponses[key] = value);

  return apiCallNew(EHTTPMethod.POST, `/opportunity/${opportunityId}/application`, {}, {
    responses: jsonResponses,
  }, {});
}

/*
 * Generic Data
 */
export function getDataNew(): Promise<IHTTPResponse<any>> {
  return apiCallNew(EHTTPMethod.GET, "/generic-data", {}, {}, {});
}

/*
 * School
 */
export function getAvailableSchools(): Promise<any> {
  return apiCallNew(EHTTPMethod.GET, "/school", {}, {}, {});
}

export function schoolSearchNew(schoolName?: string): Promise<IHTTPResponse<ISchool>> {
  return apiCallNew(EHTTPMethod.GET, `/school/search?prefix=${schoolName}`, {}, {}, {});
}

/*
 * Student
 */
export function getStudentNew(id?: any): Promise<any> {
  return id ? apiCallNew(EHTTPMethod.GET, `/student/${id}`, {}, {}, {}) : apiCallNew(EHTTPMethod.GET, "/student", {}, {}, {});
}

export function updateStudentNew(studentModel: ICreateUpdateStudent): Promise<any> {
  return apiCallNew(EHTTPMethod.PUT, "/student", {}, studentModel, {});
}

export function addExperienceNew(experience: any): Promise<any> {
  return apiCallNew(EHTTPMethod.POST, "/student/experience", {}, experience, {});
}

export function studentSignupNew(formData: any): Promise<any> {
  return apiCallNew(EHTTPMethod.POST, "/student/signup", {}, formData, {});
}

/*
 * User
 */
export function updateUser(newUserData: IUpdateUserRequest): Promise<IHTTPResponse<IUser>> {
  return apiCallNew(EHTTPMethod.PUT, "/user", {}, newUserData, {});
}

