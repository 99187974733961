import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface IProps {
  open: boolean;
  title: string;
  content: string;
  handleClose: (e: boolean) => any;
}

class Modal extends React.Component<IProps, any> {
  handleClose = (selected: boolean) => {
    const {
      handleClose,
    } = this.props;
    handleClose(selected);
  };

  render() {
    const {
      open,
      title,
      content,
    } = this.props;

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(false)} color="default">
            Close
          </Button>
          <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Modal;
