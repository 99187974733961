import * as React from "react";
import {
  Button,
} from "@material-ui/core";
import {
  CloudDownload,
  Delete,
  Edit,
  Favorite,
  Share,
} from "@material-ui/icons";

interface IProps {
  classes: any;
  canEdit: boolean;
  canDelete: boolean;
  onAlert: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ActionButtons = (props: IProps) => {
  const {
    classes,
    canEdit,
    canDelete,
    onAlert,
    onEdit,
    onDelete,
  } = props;

  console.error("onEdit not implemented", onEdit);

  return (
    <div className={classes.actionButtons}>
      <Button
        variant="fab"
        className={classes.actionButton}
        onClick={onAlert}
      >
        <Share/>
      </Button>

      <Button
        variant="fab"
        className={classes.actionButton}
        onClick={onAlert}
      >
        <Favorite/>
      </Button>

      <Button
        variant="fab"
        className={classes.actionButton}
        onClick={onAlert}
      >
        <CloudDownload/>
      </Button>

      {
        canEdit ? (
          <Button
            variant="fab"
            className={classes.actionButton}
            // Disabling until fixed - shows "new feature" alert
            // onClick={onEdit}
            onClick={onAlert}
          >
            <Edit/>
          </Button>
        ) : null
      }

      {
        canDelete ? (
          <Button
            variant="fab"
            className={classes.actionButton}
            onClick={onDelete}
          >
            <Delete/>
          </Button>
        ) : null
      }
    </div>
  );
};

export default ActionButtons;
