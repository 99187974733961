import * as React from "react";
import GenericPlayer from "./GenericPlayer";
import {
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";

interface IProps {
  video: IVideoInput;
  playerHeight: number;
  playerWidth: number;
  onDurationFound?: (duration: number) => any;
  onError?: () => any;
  onReady?: () => any;
}

interface IState {
  videoURL?: string;
}

/**
 * As opposed to other players, this video player takes a IVideoInput rather than a source URL
 * This is useful when state is stored externally to the video component
 * but you want the video component to worry about handling the url resources
 * It manages all resources
 */
class GenericVideoFilePlayer extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const {
      video: {
        file,
      },
    } = this.props;

    // Create URL if file actually exist
    if (file && file.name) {
      this.setState({
        videoURL: URL.createObjectURL(file),
      });
    }
  }

  // Update video URL
  componentDidUpdate(prevProps: IProps) {
    const {
      video,
    } = this.props;

    if (video.file != prevProps.video.file) {
      let url: string | undefined = undefined;

      if (this.state.videoURL) {
        URL.revokeObjectURL(this.state.videoURL);
      }

      if (video.file) {
        url = URL.createObjectURL(video.file);
      }

      this.setState({
        videoURL: url,
      });
    }
  }

  // Clean up URL if present
  componentWillUnmount(): void {
    if (this.state.videoURL) {
      URL.revokeObjectURL(this.state.videoURL);
    }
  }

  render() {
    const {
      playerWidth,
      playerHeight,
      onDurationFound,
      onError,
      onReady,
    } = this.props;

    const {
      videoURL,
    } = this.state;

    return (
      <GenericPlayer
        playerHeight={playerHeight}
        playerWidth={playerWidth}
        src={videoURL || ""}
        onReady={onReady}
        onError={onError}
        onDurationFound={onDurationFound}
      />
    );
  }
}

export default GenericVideoFilePlayer;
