import {
  grey,
} from "@material-ui/core/colors";
import * as React from "react";
import styled from "styled-components";

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  color: ${grey[800]};
  font-size: 0.9rem;
`;

const FooterLeft = styled.div``;
const FooterRight = styled.div``;

const FooterItem = styled.span`
  margin: 0 1rem;
`;

class FooterComponent extends React.Component<any, any> {
  public render() {
    return (
      <Footer>
        <FooterLeft>
          <FooterItem>
            Need a helping hand?
            {" "}
            <a
              href="mailto:help@grandshake.co"
              style={{
                color: "#000",
              }}>
              Email
            </a>
          </FooterItem>
        </FooterLeft>
        <FooterRight>
          <FooterItem>
            <a
              style={{
                color: "#000",
                textDecoration: "none",
              }}
              href="https://grandshake.co/legal/"
              target="_blank"
              rel='noreferrer noopener'
            >
              Legal
            </a>
          </FooterItem>
        </FooterRight>
      </Footer>
    );
  }
}

export default FooterComponent;
