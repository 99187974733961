import {
  withTheme,
} from "@material-ui/core/styles";
import * as React from "react";

import styled from "styled-components";

const ThankyouList: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ThankyouItem: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  &:after {
    content: '';
    clear: both;
  }
`;

const ThankyouItemNumberContainer = styled.div``;

const ThankyouItemNumber = styled.div`
  color: white;
  text-align: center;
  line-height: 48px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  float: left;
  background: #2626F7;
`;

const ThankyouItemValueContainer = withTheme()(styled.div`
  font-size: 1.1rem;
  position: relative;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    margin-top: 0.8rem;
    width: 40%;
    left: 0px;
    border-bottom: 1px solid #2626F7;
  }
` as any);

const ThankyouItemValue = styled.div``;

interface IProps {
  items: any[];
}

class ThankyouListComponent extends React.Component<IProps | any, any> {
  constructor(props) {
    super(props);
    this.state = {
      item: [],
      ...props,
    };
  }

  public renderItem(item, i, secondary) {
    return (
      <ThankyouItem key={i}>

        <ThankyouItemNumberContainer>
          <ThankyouItemNumber style={{
            background: secondary ? "#fff" : "#2626F7",
            border: secondary ? "1px solid  #2626F7" : "none",
            color: secondary ? "#000" : "#fff",
          }}>
            {i + 1}
          </ThankyouItemNumber>
        </ThankyouItemNumberContainer>

        <ThankyouItemValueContainer>
          <ThankyouItemValue>
            {item}
          </ThankyouItemValue>
        </ThankyouItemValueContainer>
      </ThankyouItem>
    );
  }

  public render() {
    const {
      items, secondary,
    } = this.state;

    return (
      <ThankyouList>
        {items.map((it, index) => this.renderItem(it, index, secondary))}
      </ThankyouList>
    );
  }
}

export default ThankyouListComponent;
