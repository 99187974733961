import {
  isEmpty,
  isNumber,
} from "lodash";

// eslint-disable-next-line no-useless-escape,max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validPassword = (password, text?) => {
  const passwordRegex = /^.*[A-Z]{1,}.*$/;
  if (!passwordRegex.test(password) || password.length < 8) {
    return text || [
      "Password must have at least 8 characters,",
      "1 uppercase letter",
    ].join(" ");
  }

  return null;
};

export const validEmail = (email, text?) => {
  if (!EMAIL_REGEX.test(email)) {
    return text || "Invalid email address";
  }
  return null;
};

export const validSchoolEmail = (email, text?) => {
  if (!EMAIL_REGEX.test(email)) {
    return text || "Invalid email address";
  }
  if (!email.includes("@eq.edu.au")) {
    return "Invalid eq.edu.au email address";
  }
  return null;
};

export const validName = (name, text?) => {
  if (!String(name).length) {
    return text || "Invalid name";
  }
};

export const validSchool = (school: any): boolean | string => {
  return (school.id === null || school.name === null) ? "Invalid school" : false;
};

export const isEmptyArray = (arr) => {
  let empty = true;
  for (const i in arr) {
    if (!isEmpty(arr[i]) || isNumber(arr[i])) {
      empty = false;
    }
  }
  return empty;
};

export default {
  isEmptyArray,
  validEmail,
  validName,
  validPassword,
};
