import * as React from "react";
import {
  useState,
} from "react";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import {
  useQuery,
} from "@apollo/react-hooks";
import {
  gql,
} from "apollo-boost";
// Utils
import {
  hasAdminRole,
  hasOrganisationRole,
  hasSchoolRole,
  hasStudentRole,
  useRoles,
} from "@utils/roles";
// Actions
import {
  newAlert,
} from "@actions/global";
// Model
import {
  AlertLevel,
} from "@components/global.model";
import {
  ERROR_COULD_NOT_FIND_SELF,
} from "@models/user";
// Components
import {
  DefaultNavActionsLayout,
} from "@components/Nav/DefaultNavActions/DefaultNavActionsLayout";
import {
  getProfilePictureUrl,
} from "@components/ProfilePicture/utils";

//
// Constants
//

const GET_STUDENT_PROFILE_PICTURE_QUERY = gql`
  query DefaultNavActionsGetStudentProfilePicture
  {
    getSelf {
      id
      student {
        id
        profilePicture
      }
    }
  }
`;

//
// Interfaces
//

interface IProps {
}

interface IExtendedProps extends IProps {
  dispatch: Dispatch;
}

/*
 * Functional Component that is responsible for:
 * - providing data for the nav component
 */
const DefaultNavActionsData: React.FC<IExtendedProps> = (props: IExtendedProps) => {
  const {
    dispatch,
  } = props;

  const {
    roleList,
  } = useRoles();

  const {
    error,
    data,
    loading,
  } = useQuery(GET_STUDENT_PROFILE_PICTURE_QUERY, {
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR_COULD_NOT_FIND_SELF,
        },
      ));
    },
  });

  const s3Key = (!loading && !error && data && data.getSelf && data.getSelf.student) ?
    data.getSelf.student.profilePicture : undefined;
  const [profilePictureUrl, setProfilePictureUrl] = useState<string | undefined>(undefined);

  React.useEffect(() => {
    const getProfilePicture = async () => {
      setProfilePictureUrl(await getProfilePictureUrl(s3Key));
    };

    getProfilePicture();
  }, [s3Key]);

  const [userMenuAnchor, setUserMenuAnchor] = useState<any | undefined>(undefined);
  const [contactMenuAnchor, setContactMenuAnchor] = useState<any | undefined>(undefined);

  // Get user roles - used for permissions and the student already applied check
  const isStudent: boolean = hasStudentRole(roleList);
  const isOrganisationUser: boolean = hasOrganisationRole(roleList);
  const isSchoolUser: boolean = hasSchoolRole(roleList);
  const isAdminUser: boolean = hasAdminRole(roleList);

  return (
    <DefaultNavActionsLayout
      canCreateOpportunity={isOrganisationUser}
      canViewAdminDashboard={isAdminUser}
      canViewContacts={isSchoolUser}
      canViewProfile={isStudent}
      profilePictureUrl={profilePictureUrl}
      userMenuAnchor={userMenuAnchor}
      contactMenuAnchor={contactMenuAnchor}
      setUserMenuAnchor={setUserMenuAnchor}
      setContactMenuAnchor={setContactMenuAnchor}
    />
  );
};

export default connect()(DefaultNavActionsData);
