import styled from "styled-components";

export const StyledList = styled.ul`
  margin: 0 1rem;
  padding: 0 1rem;
  list-style: none;
`;

export const StyledListItem = styled.li`
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  letter-spacing: 0.5px;

  &:before {
    content: "";
    border-color: transparent rgba(0, 0, 0, 0.6);
    border-style: solid;
    border-width: 0.25em 0 0.25em 0.35em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;
  }
`;
