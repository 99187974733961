import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import TasksList from "../NewForm/TasksList";

class EditTasksModal extends React.Component<any, any> {
  render() {
    const {
      open,
      tasks,
      onChange,
      addItem,
      onClose,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update tasks
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below, enter new tasks of the opportunity.
          </DialogContentText>

          <TasksList
            tasks={tasks}
            formErrors={{}}
            onChange={onChange}
            addItem={addItem}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onClose(true)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditTasksModal;
