import {
  ICreateOpportunityFormData,
} from "@actions/opportunity";

export const setNewOpportunityInProgress = (createOpportunityInProgress: ICreateOpportunityFormData, page: number): void => {
  localStorage.setItem("createOpportunityInProgress", JSON.stringify({
    formData: createOpportunityInProgress,
    page,
  }));
};
export const getNewOpportunityInProgress = (): {
  page: number,
  formData: ICreateOpportunityFormData,
} | null => {
  const serializedOpportunityData: string | null = localStorage.getItem("createOpportunityInProgress");
  return serializedOpportunityData ? JSON.parse(serializedOpportunityData) : null;
};
export const clearNewOpportunityInProgress = (): void => {
  localStorage.removeItem("createOpportunityInProgress");
};

//  ! This needs to be removed entirely, and instead replaced with just a straight up better location searcher
//  ! which doesn't have all of these horrid problems.
export const getLastLocation = (): string | null => {
  return localStorage.getItem("lastSearchedLocationLabel");
};
export const setLastLocation = (locationLabel: string): void => {
  localStorage.setItem("lastSearchedLocationLabel", locationLabel);
};
export const clearLastLocation = (): void => {
  localStorage.removeItem("lastSearchedLocationLabel");
};
