import {
  withTheme,
} from "@material-ui/core/styles";
import {
  get,
} from "lodash";
import * as React from "react";

import styled from "styled-components";

const Item: any = withTheme()(styled.div`
  border: 1px #656565 solid;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  margin: 5px;
  color: #656565;
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: white;
    background: ${(props: any) => get(props, "theme.palette.primary[500]")};
    border-color: ${(props: any) => get(props, "theme.palette.primary[500]")};
  }
` as any);

const ItemList = styled.div`
`;

interface IProps {
  items: any[] | any;
  onClick: (e: any) => any;
  label: string;
}

class ItemListComponent extends React.Component<IProps, any> {
  public onClickHandler = (value) => {
    const {
      onClick,
    } = this.props;
    return onClick && onClick(value);
  };

  public renderItem(value, label, index) {
    return <Item key={index} onClick={() => this.onClickHandler(value)}>
      {label}
    </Item>;
  }

  public renderItems(items) {
    const {
      label,
    } = this.props;
    if (Array.isArray(items)) {
      return items.map((item, index) => this.renderItem(item, item[label], index));
    }
    return Object.keys(items).map((key, index) => this.renderItem(key, items[key][label], index));
  }

  public render() {
    const {
      items,
    } = this.props;
    return (
      <ItemList>
        {this.renderItems(items)}
      </ItemList>
    );
  }
}

export default ItemListComponent;
