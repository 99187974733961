import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
import {
  get,
} from "lodash";
import {
  newAlert,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";
import UploadVideoComponent from "./index";

export interface IVideoInput {
  videoSelected: Boolean;
  timeLimit: number;
  file?: File;
  duration?: number;
}

export const getEmptyVideoInput = (timeLimit: number) => Object.assign({}, {
  videoSelected: false,
  timeLimit: timeLimit || 60,
  file: undefined,
  duration: undefined,
});

interface IProps {
  playerHeight?: number; // height of video player
  playerWidth?: number; // width of video player
  onChange: (input: IVideoInput, timeLimit?: number) => any; // onChange(input) - passes current state of video input to
  video: IVideoInput; // State of the associated video
}

interface IExtendedProps extends IProps {
  dispatch: Dispatch;
}

class GenericVideoInput extends React.Component<IExtendedProps> {
  static defaultProps = {
    playerHeight: 240,
    playerWidth: 320,
    video: {
      videoSelected: false,
      timeLimit: 60,
    },
  };

  /**
   * Returns false if a video is selected but the file is missing. Otherwise, returns true.
   *
   * When used in forms, the video's state is written to local storage which stores text only; it cannot store files.
   * When the form is reloaded, it's values come from local storage. The file is lost and hence will exist but have no data.
   * @param input
   */
  private static isValidVideoInput(input: IVideoInput): boolean {
    if (input.videoSelected) {
      if (input.file && !input.file.name) {
        return false;
      }
    }
    return true;
  }

  /**
   * Handles the case where video is lost due to the form data being loade from local storage
   */
  private checkAndHandleLostVideo() {
    if (!GenericVideoInput.isValidVideoInput(this.props.video)) {
      // Video was lost
      this.handleReset();
      this.props.dispatch(newAlert({
        level: AlertLevel.ERROR,
        body: "Uh oh! Your video was lost. Please select it again.",
      }));
    }
  }

  constructor(props) {
    super(props);

    this.checkAndHandleLostVideo();
  }

  componentDidUpdate(): void {
    // Check to see if video was lost
    this.checkAndHandleLostVideo();
  }

  /**
   * Calls onChange with updated video state
   * @param event - input event from video input
   */
  handleInputChange = (event) => {
    const {
      onChange, video,
    } = this.props;

    // Get video details from given event
    const fileList = get(event, "target.files", []);
    const file = fileList[0];

    const videoInput: IVideoInput = Object.assign(video, {
      videoSelected: file != null,
      file,
    });

    onChange(videoInput);
  };

  /**
   * Calls onChange with updated video state
   * @param duration
   */
  handleDurationFound = (duration) => {
    const {
      onChange, video,
    } = this.props;

    const videoInput: IVideoInput = Object.assign(video, {
      duration,
    });

    onChange(videoInput);
  };

  /**
   * Calls the onChange method with the default state
   */
  handleReset = () => {
    const {
      onChange, video,
    } = this.props;

    const videoInput: IVideoInput = Object.assign(video, {
      videoSelected: false,
      duration: undefined,
      file: undefined,
    });

    onChange(videoInput);
  };

  render(): React.ReactElement<any> | undefined {
    const {
      playerHeight,
      playerWidth,
      video,
    } = this.props;

    return (
      <UploadVideoComponent
        video={video}
        playerHeight={playerHeight}
        playerWidth={playerWidth}
        onReset={this.handleReset.bind(this)}
        onChange={this.handleInputChange.bind(this)}
        onDurationFound={this.handleDurationFound}
      />
    );
  }
}

export default connect()(GenericVideoInput);
