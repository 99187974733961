import {
  createHashHistory,
} from "history";
import * as qs from "qs";

const history = createHashHistory();

function addLocationQuery(hist) {
  hist.location = Object.assign(
    hist.location,
    {
      query: qs.parse(hist.location.search, {
        ignoreQueryPrefix: true,
      }),
    },
  );
}

addLocationQuery(history);

history.listen(() => {
  addLocationQuery(history);
});

export {
  history,
};
