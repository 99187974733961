import * as React from "react";
import {
  Button,
  DialogContentText,
  Paper,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Videocam,
} from "@material-ui/icons";
import GenericVideoFilePlayer from "@components/VideoPlayer/GenericVideoFilePlayer";
import {
  getEmptyVideoInput,
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";
import {
  IApplicationQuestion,
} from "@models/opportunity";
import VideoPickerDialog from "@components/UploadVideoInputComponent/VideoPickerDialog";

/*
 * INTERFACES
 */
interface IProps {
  timeLimit: number;
  applicationQuestions: IApplicationQuestion[];
  onSubmit: (video: IVideoInput) => void;
}

interface IPropsWithStyles extends IProps {
  classes: any;
}

interface IState {
  videoPickerDialogOpen: boolean;
  video: IVideoInput;
}

/*
 * STYLES
 */
const styles = (theme: Theme) => ({
  paper: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
  contentButton: {
    marginTop: theme.spacing.unit,
    flex: 1,
    width: "100%",
  },
  player: {
    marginTop: theme.spacing.unit,
  },
});

/*
 * Handles presentation of video questions and allows user to select a video.
 * Passes video state to parent with the handleChange callback
 */
class VideoResponseDialogContent extends React.Component<IPropsWithStyles, IState> {
  constructor(props) {
    super(props);
    this.state = {
      videoPickerDialogOpen: false,
      video: getEmptyVideoInput(props.videoTimeLimit),
    };
  }

  // Handle change to video state and manage video URL object
  handleChange = (videoState: IVideoInput) => {
    this.setState({
      video: videoState,
    });
  };

  // Handle video picker dialog open
  handleDialogOpen = () => {
    this.setState({
      videoPickerDialogOpen: true,
    });
  };

  // Handle video picker dialog close
  handleDialogClose = (video: IVideoInput) => {
    this.handleChange(video);

    this.setState({
      videoPickerDialogOpen: false,
    });
  };

  render() {
    const {
      classes,
      applicationQuestions,
      onSubmit,
    } = this.props;

    const {
      video,
      videoPickerDialogOpen,
    } = this.state;

    const videoPicker = (
      <Button
        className={classes.contentButton}
        color="primary"
        onClick={this.handleDialogOpen}>
        <p>
          Upload
        </p>
        <Videocam/>
      </Button>
    );

    const videoPlayer = (
      <React.Fragment>
        <div className={classes.player}>
          <GenericVideoFilePlayer
            video={video}
            playerWidth={540}
            playerHeight={360}
          />
        </div>

        <Button
          className={classes.contentButton}
          onClick={() => this.handleChange(getEmptyVideoInput(video.timeLimit))}>
          Clear Video
        </Button>

        <Button
          className={classes.contentButton}
          color="primary"
          onClick={() => onSubmit(video)}>
          Submit
        </Button>
      </React.Fragment>
    );

    return (
      <div>
        <DialogContentText>
          {" "}
          Pre screening Questionnaire:
          {" "}
        </DialogContentText>
        <DialogContentText variant={"caption"}>
          To be successful with your application record and submit a
          {" "}
          {video.timeLimit}
          -second video response
        </DialogContentText>

        {/* Render video questions*/}
        {applicationQuestions && applicationQuestions.map((question, index) => (
          <React.Fragment key={question.id}>
            <Paper className={classes.paper}>
              <Typography variant="subheading">
                <strong>
                  Question
                  {index + 1}
                  :
                </strong>
                {" "}
                {question.question}
              </Typography>
            </Paper>
          </React.Fragment>
        ))}

        {/* Render video controls*/}
        {video.videoSelected ? videoPlayer : videoPicker}

        {/* Video Picker Dialog */}
        <VideoPickerDialog
          open={videoPickerDialogOpen}
          onClose={this.handleDialogClose}
          timeLimit={video.timeLimit}
        />
      </div>
    );
  }
}

export default withStyles(styles)(VideoResponseDialogContent);
