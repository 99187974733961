import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  blue,
  green,
  grey,
  indigo,
  orange,
  purple,
  red,
} from "@material-ui/core/colors";
import {
  createMuiTheme,
  createStyles,
  MuiThemeProvider,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Face,
  MoreHoriz,
  Timer,
} from "@material-ui/icons";
import {
  Link,
} from "@utils/link";
import * as React from "react";
import {
  withRouter,
} from "react-router";
import {
  IOpportunityType,
  IOpportunityWithRelations,
} from "@models/opportunity";
import VideoStreamFromStorageKey from "@components/VideoPlayer/VideoStreamPlayer/StreamFromStorageKey";

import * as moment from "moment-timezone";

moment.tz("Australia/Brisbane");

interface IProps {
  opportunity: IOpportunityWithRelations;
  showAge?: boolean;
  setOpen: (id: number) => void;
  showApplicants?: boolean;
  showApplyButton?: boolean;
  showDuration?: boolean;
  showExploreButton?: boolean;
  showLocation?: boolean
  history: any;
  open: boolean;
  classes: any;
}

interface IState {
  anchorEl: any | null;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: indigo.A700,
    },
    secondary: grey,
  },
});

const styles = (t: Theme): any => createStyles({
  root: {
    ...t.mixins.gutters(),
    marginBottom: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,

    width: "100%",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  chip: {
    margin: 0,
  },
  header: {
    display: "flex",
    alignItems: "center",
    flexBasis: "100%",
  },
  avatar: {
    marginRight: theme.spacing.unit * 2,
  },
  chipContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  heading: {
    flexBasis: "250px",
  },
  captions: {
    display: "flex",
    flexBasis: "300px",
    justifyContent: "space-between",
  },
  caption: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingRight: "0.5rem",
  },
  captionIcon: {
    fontSize: "0.75rem",
    color: grey[800],
    marginRight: "0.5rem",
  },
  aboutRole: {
    display: "flex",
    flexDirection: "column",
  },
  skills: {
    marginTop: theme.spacing.unit * 2,
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
  },
  helpLink: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
});

class OpportunityListItem extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  public setOpen = (event: any): void => {
    // the first check is essential since className here can be SVGString and no `includes` method exists
    if (event.target.className.includes) {
      if (!event.target.className.includes("prevent-toggle-height")) {
        const id: number = this.props.open ? -1 : this.props.opportunity.id;
        this.props.setOpen(id);
      }
    }
  };

  public handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  public handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  public gotoProfile = () => {
    this.props.history.push(`/opportunity/${this.props.opportunity.id}`);
  };

  public render() {
    const {
      classes,
    } = this.props;

    const opportunity: IOpportunityWithRelations = this.props.opportunity;
    const timeDiff: number = moment(opportunity.date_close).diff(moment(), "days");
    let timeDiffLabel: string = "";
    if (timeDiff <= 0) timeDiffLabel = "Closed";
    else timeDiffLabel = timeDiff + " Days";

    const OppAvatar = () => (
      <Avatar className={classes.avatar} style={{
        background: grey[500],
        width: 28,
        height: 28,
        fontSize: 18,
        marginRight: "0.5rem",
      }}>
        <Timer style={{
          fontSize: 18,
        }}/>
      </Avatar>
    );

    const menuOpen = Boolean(this.state.anchorEl);
    return (
      <MuiThemeProvider theme={theme}>
        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl || undefined}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          open={menuOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.gotoProfile}>
            <ListItemIcon>
              <Face/>
            </ListItemIcon>
            Explore
          </MenuItem>
        </Menu>
        <Card style={{
          width: "100%",
          marginBottom: "1.0rem",
        }} raised={true} onClick={this.setOpen}>
          <CardContent style={{
            padding: "1.0rem",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}>
              <Typography style={{
                flex: 1,
              }} variant="title">
                {opportunity.title}
              </Typography>
              <IconButton style={{
                width: 32,
                height: 32,
                marginRight: "0.5rem",
              }}>
                <MoreHoriz onClick={this.handleMenu}/>
              </IconButton>
              {opportunity.opportunity_types.map((type: IOpportunityType) => {
                //  TODO might want to fix this as it's slow and terrible
                let oppColor: any;
                switch (type.id) {
                case 1: {
                  oppColor = indigo[700];
                }
                  break;
                case 2: {
                  oppColor = red[700];
                }
                  break;
                case 3: {
                  oppColor = blue[700];
                }
                  break;
                case 4: {
                  oppColor = green[700];
                }
                  break;
                case 5: {
                  oppColor = purple[700];
                }
                  break;
                default: {
                  oppColor = orange[700];
                }
                }

                return (<Chip
                  key={type.id}
                  label={type.name}
                  className={classes.chip}
                  style={{
                    background: oppColor,
                    color: "#fff",
                    margin: "0 4px",
                  }}
                />);
              })}
            </div>

            <Typography variant="subheading">
              {opportunity.organisation.name}
            </Typography>

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "0.5rem",
            }}>
              <OppAvatar/>
              <Typography variant="caption" style={{
                flex: 1,
              }}>
                {timeDiffLabel}
              </Typography>
              <Typography variant="caption" style={{
                alignSelf: "flex-end",
                textTransform: "capitalize",
              }}>
                {opportunity.location.suburb}
              </Typography>
            </div>
          </CardContent>

          <Collapse in={this.props.open} timeout="auto" unmountOnExit>
            <CardContent>
              <VideoStreamFromStorageKey
                playerWidth={550}
                playerHeight={310}
                storageKey={opportunity.video}
              />
              <div className={classes.aboutRole}>
                <Typography variant="body2">
                  About the role
                </Typography>
                <Typography variant="body1">
                  {opportunity.description}
                </Typography>
              </div>

              {(opportunity.skills.length > 0) && (<div className={classes.skills}>
                <Typography variant="body2">
                  Skills I will be exposed to
                </Typography>
                <div>
                  {opportunity.skills.map((skill) => (
                    <Chip key={skill.id} label={skill.name} style={{
                      marginRight: "0.5rem",
                      marginTop: "0.5rem",
                    }}/>
                  ))}
                </div>
              </div>)}
              <div style={{
                marginTop: "0.5rem",
              }}>
                <Button style={{
                  marginRight: "0.5rem",
                }} component={Link(`/opportunity/${opportunity.id}`)}>
                  Explore
                </Button>
              </div>
            </CardContent>
          </Collapse>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(OpportunityListItem) as any);
