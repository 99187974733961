import styled from "styled-components";
import {
  Link,
} from "react-router-dom";

export const CustomNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  background: #FFD300;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

export const CustomNavTite = styled.span`
  color: #000;
  font-size: 1.4rem;
  font-weight: 300;
  margin-left: 60px;
`;

export const CloseButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: left;
  color: #000;
  font-size: 2rem;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 16px;
  left: 18px;
`;
