import * as Joi from "@hapi/joi";

//
// Interface Definitions
//
export interface ISubject {
  id: number;
  name: string;
}

//
// Schema Definitions
//
export const SubjectSchema = Joi.object().keys({
  id: Joi.number().min(1),
  name: Joi.string().min(1).max(255),
});
