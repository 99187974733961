import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  createStyles,
  FormControl,
  FormHelperText,
  FormLabel,
  Theme,
  withStyles,
} from "@material-ui/core";
import {
  IMultipageFormProps,
  IMultipageFormState,
  MultipageFormPage,
} from "@components/FormComponents";
import {
  FormControlContainer,
} from "@components/FormComponents/styled";
import GenericVideoInput from "@components/UploadVideoInputComponent/GenericVideoInput";

interface IProps extends IMultipageFormProps {
  classes: any;
  data: any;
  formData: any;
  formErrors: any;
  addItem: (e: any) => any;
  onChange: (e: any) => any;
  onFileChange: (e: any) => any;
  onChangeArray: (e: any) => any;
}

interface IState extends IMultipageFormState {
}

const styles: any = (theme: Theme): any => createStyles({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  spacer: {
    marginTop: theme.spacing.unit * 2,
  },
});

class UploadVideoPage extends MultipageFormPage<IProps, IState> {
  public render() {
    const {
      classes,
      formData,
      onFileChange,
    } = this.props;

    return (
      <div className={classes.root}>
        <FormControlContainer>
          <FormControl>
            <FormLabel>
              Upload Video
            </FormLabel>
            <FormHelperText>
              Upload an engaging video to showcase the opportunity
            </FormHelperText>

            <div className={classes.spacer}/>

            <FormHelperText>
              Tips:
            </FormHelperText>
            <FormHelperText>
              1. Keep it short and simple (60 seconds)
            </FormHelperText>
            <FormHelperText>
              2. Upload a selfie-style mobile video or direct from your laptop
            </FormHelperText>
            <FormHelperText>
              3. Record the workplace, if possible
            </FormHelperText>
            <FormHelperText>
              See more tips at
              <a href={"https://granshake.co/tips"}>
                https://granshake.co/tips
              </a>
            </FormHelperText>

            <div className={classes.spacer}/>

            <div className={classes.root}>
              <GenericVideoInput
                playerHeight={240}
                playerWidth={320}
                onChange={onFileChange("pathToLocalVideo")}
                video={formData.pathToLocalVideo}
              />
            </div>
          </FormControl>
        </FormControlContainer>
      </div>
    );
  }
}

const StyledComponent = withStyles(styles)(UploadVideoPage);

const mapStateToProps = (state: any) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledComponent);
