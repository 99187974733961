import * as React from "react";
import {
  Button,
  Chip,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  StyledList,
  StyledListItem,
} from "./styled";

import EditTasksModal from "../components/modals/edit.tasks.modal";
import EditSkillsModal from "../components/modals/edit.skills.modal";
import EditPrerequisitesModal from "../components/modals/edit.prerequisites.modal";
import {
  IOpportunityWithRelations,
} from "@models/opportunity";

interface IProps {
  classes: any;
  editMode: boolean;
  opportunity: IOpportunityWithRelations;
  editOpportunity: IOpportunityWithRelations | null;
  commit: () => void;
  undo: () => void;
  onChange: (field: string, value: any) => void;
}

class Details extends React.Component<IProps, any> {
  state = {
    editTasksModal: false,
    editSkillsModal: false,
    editPrerequisitesModal: false,
  };

  public editTasksModalOpen = () => {
    this.setState({
      editTasksModal: true,
    });
  };

  public editTasksModalClose = (withUpdate) => () => {
    this.setState({
      editTasksModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  public editSkillsModalOpen = () => {
    this.setState({
      editSkillsModal: true,
    });
  };

  public editSkillsModalClose = (withUpdate) => () => {
    this.setState({
      editSkillsModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  public editPrerequisitesModalOpen = () => {
    this.setState({
      editPrerequisitesModal: true,
    });
  };

  public editPrerequisitesModalClose = (withUpdate) => () => {
    this.setState({
      editPrerequisitesModal: false,
    }, () => {
      if (withUpdate) {
        this.props.commit();
      } else {
        this.props.undo();
      }
    });
  };

  public editPrerequisitesModalOpen = () => {
    this.setState({
      editPrerequisitesModal: true,
    });
  };

  public editPrerequisitesModalClose = (withUpdate) => () => {
    this.setState({
      editPrerequisitesModal: false,
    }, () => {
      if (!withUpdate) {
        this.props.undo();
      }
    });
  };

  render() {
    const {
      classes,
      editMode,
      opportunity,
      editOpportunity,
      onChange,
    } = this.props;
    const {
      editTasksModal,
      editSkillsModal,
      editPrerequisitesModal,
    } = this.state;

    const tasks = editOpportunity === null ? (opportunity.tasks || []) : (editOpportunity.tasks || []);
    const skills = editOpportunity === null ? (opportunity.skills || []) : (editOpportunity.skills || []);
    const prerequisites = editOpportunity === null ? (opportunity.prerequisites || []) : (editOpportunity.prerequisites || []);

    return (
      <div className={classes.detailsSection}>
        <EditTasksModal
          open={editTasksModal}
          tasks={tasks}
          onChange={(key) => ({
            target: {
              value,
            },
          }) => {
            onChange(key, value);
          }}
          addItem={() => onChange("tasks", [...tasks, ""])}
          onClose={this.editTasksModalClose}
        />

        <EditSkillsModal
          open={editSkillsModal}
          skills={skills}
          onClose={this.editSkillsModalClose}
          onChange={(field) => (value) => {
            onChange(field, value);
          }}
        />

        <EditPrerequisitesModal
          open={editPrerequisitesModal}
          prerequisites={prerequisites}
          onClose={this.editPrerequisitesModalClose}
          onChange={(value) => {
            onChange("prerequisitesIds", value);
          }}
        />

        <Typography variant="subheading" component="p">
          <span>
Key tasks/activities
          </span>
          {
            editMode ? (
              <Button
                className={classes.updateButton}
                style={{
                  float: "right",
                }}
                onClick={this.editTasksModalOpen}
              >
                UPDATE
              </Button>
            ) : null
          }
        </Typography>
        <StyledList>
          {
            tasks.map((task, i) => (
              <StyledListItem key={i}>
                {task}
              </StyledListItem>
            ))
          }
        </StyledList>
        <Divider style={{
          margin: "24px 0",
        }}/>

        <Typography
          variant="subheading"
          component="p"
          style={{
            marginBottom: "1rem",
          }}
        >
          <span>
Skills I will be exposed to
          </span>
          {
            editMode ? (
              <Button
                className={classes.updateButton}
                style={{
                  float: "right",
                }}
                onClick={this.editSkillsModalOpen}
              >
                UPDATE
              </Button>
            ) : null
          }
        </Typography>
        {
          skills.map(({
            id, name,
          }) => (
            <Chip
              key={id}
              label={name}
              className={classes.chip}
            />
          ))
        }

        <Divider style={{
          margin: "24px 0",
        }}/>
        <Typography
          variant="subheading"
          component="p"
          style={{
            marginBottom: "1rem",
          }}
        >
          Prerequisites
          {
            editMode ? (
              <Button
                className={classes.updateButton}
                style={{
                  float: "right",
                }}
                onClick={this.editPrerequisitesModalOpen}
              >
                UPDATE
              </Button>
            ) : null
          }
        </Typography>
        {
          prerequisites.map(({
            id, name,
          }) => (
            <Chip
              key={id}
              label={id === 8 ? opportunity.other_prerequisite : name} // ID 8 means the other tag was selected
              className={classes.chip}
            />
          ))
        }
      </div>
    );
  }
}

export default Details;
