import * as React from "react";
import {
  withRouter,
} from "react-router";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import * as moment from "moment";
// Styles
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  createMuiTheme,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MuiThemeProvider,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Face,
  KeyboardArrowLeft,
  Phone,
  School,
  Timer,
} from "@material-ui/icons";
import {
  grey,
  indigo,
  red,
} from "@material-ui/core/colors";
// Utils
import {
  easyToReadFormat,
} from "@utils/dates";
// Actions
import {
  getStudentAction,
} from "@actions/profile";
import {
  newAlert,
} from "@actions/global";
// Models
import {
  IStudentWithRelations,
} from "@models/profile";
// Components
import Layout from "@components/Layout";
import {
  DataLoader,
} from "@components/DataLoader";
import {
  AlertLevel,
} from "@components/global.model";
import {
  getProfilePictureUrl,
} from "@components/ProfilePicture/utils";

interface IProps {
  classes: any;
  dispatch: Dispatch;
  loading: boolean;
  student: IStudentWithRelations;
  history: any;
  match: any;
  canEdit: boolean;
  location: any;
}

interface IState {
  loading: boolean;
  profilePictureUrl: string | undefined;
  editStudent: IStudentWithRelations | null;
  openExperience: number;
}

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: indigo.A700,
    },
    secondary: red,
  },
});

const styles = (theme: Theme): any => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  profilePicture: {
    width: 96,
    height: 96,
  },
  sectionTwoChips: {
    marginTop: theme.spacing.unit,
  },
  profileChip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  card: {
    width: 320,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1.0rem",
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing.unit * 2,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundPosition: "0 0",
  },
  cardGroupOne: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  nonEditable: {
    opacity: 0.5,
  },
});

class StudentsProfilePage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      profilePictureUrl: undefined,
      editStudent: null,
      openExperience: -1,
    };
  }

  private async fetchProfilePicture(s3Key: string) {
    const {
      dispatch,
    } = this.props;

    try {
      // Try get profile picture
      const profilePictureURL: string | undefined = await getProfilePictureUrl(s3Key);

      // Update state with profile picture URL
      this.setState({
        profilePictureUrl: profilePictureURL,
      });
    } catch (err) {
      console.error(err);
      dispatch(newAlert({
        level: AlertLevel.WARNING,
        body: "Uh oh! There was an issue while retrieving this student's profile picture",
      }));
    }
  }

  public async componentDidMount(): Promise<void> {
    const {
      dispatch,
      match,
      student,
    } = this.props;

    if (Object.keys(student).length == 0 || match.params.id !== student.id) {
      this.setState({
        loading: true,
      });

      dispatch(getStudentAction(match.params.id));
    } else {
      this.setState({
        loading: false,
        editStudent: student,
      });

      if (student.profile_picture) {
        await this.fetchProfilePicture(student.profile_picture);
      }
    }
  }

  public async UNSAFE_componentWillReceiveProps(props: IProps): Promise<void> {
    const {
      student,
    } = props;

    if (student) {
      this.setState({
        loading: false,
        profilePictureUrl: undefined,
        editStudent: student,
      });

      if (student.profile_picture) {
        await this.fetchProfilePicture(student.profile_picture);
      }
    }
  }

  /**
   * Pushes a new path onto history. If they came via the applications page, it will send them back.
   */
  public goBack = () => {
    const {
      history,
      location,
    } = this.props;

    const opportunityId: number | undefined = location && location.state && location.state.opportunityId;

    const viaStudentsPage: boolean = location && location.state && location.state.viaStudentsPage;
    const viaApplicationPage: boolean = opportunityId != null;

    let path: string = "/";

    if (viaApplicationPage) {
      path = `/opportunity/${opportunityId}`;
    } else if (viaStudentsPage) {
      path = "/students";
    }

    history.push({
      pathname: path,
      state: {
        viaApplications: viaApplicationPage,
      },
    });
  };

  public render(): React.ReactNode {
    const {
      classes,
    } = this.props;
    const {
      editStudent, loading,
    } = this.state;
    if (!editStudent) return <div/>;
    const hasEditStudentLoaded: boolean = Object.keys(editStudent).length > 0;
    const name: string = hasEditStudentLoaded ? editStudent.first_name + " " + editStudent.last_name : "";
    const intro: string = hasEditStudentLoaded ? editStudent.intro : "";
    const grade: string = hasEditStudentLoaded ? "Grade " + editStudent.grade : "";
    const school: string = hasEditStudentLoaded ? editStudent.school_name : "";
    const subjects: any[] = hasEditStudentLoaded ? editStudent.subjects : [];
    const skillsIHave: any[] = hasEditStudentLoaded ? editStudent.skill_has : [];
    const skillsIWant: any[] = hasEditStudentLoaded ? editStudent.skill_wants : [];
    const experiences: any[] = hasEditStudentLoaded ? editStudent.experiences : [];
    // const profilePicture: any = hasEditStudentLoaded ? editStudent.profile_picture : null;
    const phone = hasEditStudentLoaded ? editStudent.phone : null;
    const dob = hasEditStudentLoaded ? editStudent.dob : null;

    return (
      <MuiThemeProvider theme={theme}>
        <Layout>
          <DataLoader required>
            <div className={classes.root}>
              {loading ? (<CircularProgress size={48}/>) :
                (<React.Fragment>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "1.0rem",
                  }}>
                    <div style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: "1.0rem",
                    }}>
                      <Typography variant="display1">
                        Student Profile
                      </Typography>
                      <Typography variant="subheading">
                        This is a student's profile.
                      </Typography>
                    </div>
                    <Button onClick={this.goBack}>
                      <KeyboardArrowLeft style={{
                        marginRight: "0.5rem",
                      }}/>
                      Back
                    </Button>
                  </div>

                  <div className={classes.cardGroupOne}>
                    <Card
                      className={classes.card}
                      raised={true}
                    >
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar}>
                            {name.split(" ").map((s) => s.substring(0, 1)).join("").toUpperCase()}
                          </Avatar>
                        }
                        title={name}
                        subheader="Joined this year"
                      />
                      {this.state.profilePictureUrl != null && !this.state.profilePictureUrl.includes("/public/null?") ?
                        <CardMedia
                          className={classes.media}
                          image={this.state.profilePictureUrl}
                        /> :
                        (
                          <CardContent
                            className={classes.media}
                          >
                            <Typography>
                              No profile picture added yet.
                            </Typography>
                          </CardContent>
                        )}
                      <CardContent style={{
                        flex: 1,
                      }}>
                        <Typography>
                          {intro ? intro : "No headline added yet."}
                        </Typography>
                      </CardContent>
                      <CardActions
                        disableActionSpacing
                        className={classes.actions}
                        style={{
                          paddingLeft: "24px",
                          display: "inline",
                        }}
                      >
                        <Chip
                          className={classes.profileChip}
                          label={grade}
                          avatar={
                            <Avatar>
                              <Face/>
                            </Avatar>
                          }
                        />

                        <Chip
                          className={classes.profileChip}
                          label={school}
                          avatar={
                            <Avatar>
                              <School/>
                            </Avatar>
                          }
                        />

                        {phone &&
                        (<Chip
                          className={classes.profileChip}
                          label={phone}
                          avatar={
                            <Avatar>
                              <Phone/>
                            </Avatar>
                          }
                        />)}

                        {dob &&
                        (<Chip
                          className={classes.profileChip}
                          label={moment(dob).format(easyToReadFormat)}
                          avatar={
                            <Avatar>
                              <Timer/>
                            </Avatar>
                          }
                        />)}
                      </CardActions>
                    </Card>

                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Card
                        style={{
                          marginBottom: "1.0rem",
                        }}
                        raised={true}
                      >
                        <CardContent>
                          <CardHeader
                            style={{
                              padding: 0,
                              paddingBottom: "0.5em",
                              paddingRight: "0.5rem",
                            }}
                            title={"Skills I have"}
                            subheader="Skills I have acquired from school or other activites"
                          />

                          <div className={classes.sectionTwoChips}>
                            {skillsIHave.map((skill: any): React.ReactNode => (
                              <Chip
                                key={skill.id}
                                className={classes.profileChip}
                                label={skill.name}
                              />
                            ))}
                            {skillsIHave.length === 0 && (
                              <Typography variant="subheading">
                                No skills added yet.
                              </Typography>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                      <Card
                        style={{
                          flex: 1,
                        }}
                        raised={true}
                      >
                        <CardContent>
                          <CardHeader
                            style={{
                              padding: 0,
                              paddingBottom: "0.5em",
                              paddingRight: "0.5rem",
                            }}
                            title={"Skills I want"}
                            subheader="Skills I am looking to acquire in future"
                          />
                          <div className={classes.sectionTwoChips}>
                            {skillsIWant.map((skill: any): React.ReactNode => (
                              <Chip
                                key={skill.id}
                                className={classes.profileChip}
                                label={skill.name}
                              />
                            ))}
                            {skillsIWant.length === 0 && (
                              <Typography variant="subheading">
                                No skills added yet.
                              </Typography>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>

                  <Card
                    style={{
                      flex: 1,
                      marginTop: "1.0rem",
                      width: "100%",
                      maxHeight: "320px",
                    }}
                    raised={true}
                  >
                    <CardContent>
                      <CardHeader
                        style={{
                          padding: 0,
                          paddingBottom: "0.5em",
                          paddingRight: "0.5rem",
                        }}
                        title={"Prior experience"}
                        subheader="My previous work experience"
                      />

                      <List dense={true}>
                        {experiences.map((experience: any, index: number): React.ReactNode => (
                          <ListItem
                            key={experience.id}
                            style={{
                              paddingLeft: 0,
                            }}
                          >
                            <Avatar
                              style={{
                                width: 32,
                                height: 32,
                              }}
                              color={grey[400]}
                            >
                              {index + 1}
                            </Avatar>
                            <ListItemText
                              primary={experience.title}
                              secondary={experience.company}
                            />
                            <ListItemSecondaryAction>
                              <Chip label={experience.opportunity_type}/>
                            </ListItemSecondaryAction>
                          </ListItem>

                        ))}
                      </List>
                      {experiences.length === 0 &&
                      <Typography variant="subheading">
                          No prior experience added yet.
                      </Typography>}
                    </CardContent>
                  </Card>

                  <Card
                    style={{
                      flex: 1,
                      marginTop: "1.0rem",
                      width: "100%",
                      maxHeight: "320px",
                    }}
                    raised={true}
                  >
                    <CardContent>
                      <CardHeader
                        style={{
                          padding: 0,
                          paddingBottom: "0.5em",
                          paddingRight: "0.5rem",
                        }}
                        title={"Subjects"}
                        subheader="Subjects I am currently enrolled in or have studied previously"
                      />
                      <List
                        style={{
                          maxHeight: "172px",
                          overflow: "scroll",
                        }}
                        dense={true}
                      >
                        {subjects.map((subject: any, index: number): React.ReactNode => (
                          <React.Fragment key={subject.id}>
                            <ListItem style={{
                              paddingLeft: 0,
                            }}>
                              <Avatar style={{
                                width: 32,
                                height: 32,
                              }}>
                                {index + 1}
                              </Avatar>
                              <ListItemText primary={subject.name}/>
                            </ListItem>
                          </React.Fragment>
                        ))}
                        {subjects.length === 0 &&
                        <Typography variant="subheading">
                            No subjects added yet.
                        </Typography>
                        }
                      </List>
                    </CardContent>
                  </Card>
                </React.Fragment>)}
            </div>
          </DataLoader>
        </Layout>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  user: state.user,
  student: state.student || null,
  loading: state.globals.loading,
});

export default withRouter((withStyles(styles)(connect(mapStateToProps)(StudentsProfilePage))) as any);
