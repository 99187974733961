import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@material-ui/core";

interface IProps {
  open: boolean;
  onClose: (isOpen: boolean) => () => any;
  onChange: (e: any) => any;
  opportunity: any;
}

class EditWorkingHoursModal extends React.Component<IProps, any> {
  public render() {
    const {
      onChange,
      opportunity,
      open,
      onClose,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update working hours
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below, enter how many new hours per week do you expect.
          </DialogContentText>

          <Select
            style={{
              marginTop: "0.5rem",
            }}
            id="other-duration"
            value={opportunity.hours_per_week}
            onChange={({
              target: {
                value,
              },
            }) => onChange(value)}
            fullWidth
          >
            {
              [...Array(40).keys()].map((hour) => (
                <MenuItem key={hour} value={hour + 1}>
                  {hour + 1}
                </MenuItem>
              ))
            }
          </Select>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onClose(true)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditWorkingHoursModal;
