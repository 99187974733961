import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
import * as Joi from "@hapi/joi";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  ISubject,
} from "@models/subject";
import {
  STUDENT_PROFILE_MAX_SUBJECTS,
  STUDENT_PROFILE_MIN_SUBJECTS,
} from "@models/profile";
import {
  newAlert,
} from "@actions/global";
import {
  AlertLevel,
} from "./global.model";

interface IProps {
  open: boolean;
  availableSubjects: ISubject[];
  selectedSubjects: ISubject[];
  schema: Joi.SchemaLike; // Joi schema object
  onChange: (event: any) => void;
  onSave: () => void;
  onClose: () => void;

  // Provided by HOC's
  classes: any;
  dispatch: Dispatch;
}

const styles = (theme: Theme) => ({
  root: {
    width: "960px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: theme.spacing.unit,
    },
  },
  dropdown: {
    width: "100%",
    marginBottom: theme.spacing.unit * 2,
  },
  subjectChip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  chipsContainer: {
    paddingTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
});

class PickSubjectsModal extends React.Component<IProps> {
  /**
   * Returns the list of selected subjects based on the array of selected ids
   * @param {number[]} idArray List of id's that correspond to the selected subjects
   */
  private listOfSubjectsFromSubjectIdArray = (idArray: number[]) => {
    const {
      availableSubjects,
    } = this.props;

    return availableSubjects.filter((subject) => idArray.includes(subject.id));
  };

  /**
   * Event handler for handle a change in the selected subjects
   * @param {number[]} event - an event that captures the selected subject id's
   */
  public handleChange = (event: any): void => {
    const subjectIds: number[] = event.target.value;

    const newSubjects: ISubject[] = this.listOfSubjectsFromSubjectIdArray(subjectIds);

    this.props.onChange(newSubjects);
  };

  /**
   * returns the list of selected subjects excluding the specified subject
   * @param {number} id
   */
  private removeSubjectFromSelectedSubjects = (id: number) => {
    const {
      selectedSubjects,
    } = this.props;

    return selectedSubjects.filter((subject) => subject.id !== id);
  };

  /**
   * Event handle for removing a subject
   * @param {number} id - subject to remove
   */
  public removeSubject = (id: number): void => {
    const newSubjects = this.removeSubjectFromSelectedSubjects(id);
    this.props.onChange(newSubjects);
  };

  /**
   * Event handle to save subjects
   * Performs validation on selected subjects before submitting.
   */
  public onSave = (): void => {
    const {
      selectedSubjects,
      dispatch,
      schema,
    } = this.props;

    // Use joi to validate
    const {
      error,
    } = Joi.validate(selectedSubjects, schema);

    if (error) {
      dispatch(newAlert({
        level: AlertLevel.WARNING,
        body: error.message,
      }));
      return;
    }

    this.props.onSave();
  };


  public render() {
    const {
      classes,
      availableSubjects,
      selectedSubjects,
    } = this.props;

    const selectedIds: number[] = selectedSubjects.map((subject: any): number => subject.id);

    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="form-dialog-title"
        onClose={this.props.onClose}
      >
        <DialogTitle id="form-dialog-title">
          Update your subjects
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Below, please enter the subjects that you are currently studying. You may select up to
            {" "}
            {STUDENT_PROFILE_MAX_SUBJECTS}
            .
          </DialogContentText>

          <form autoComplete="off">
            <FormControl className={classes.dropdown}>
              <InputLabel htmlFor="age-simple">
                Subjects
              </InputLabel>
              <Select
                value={selectedIds}
                onChange={this.handleChange}
                multiple
                inputProps={{
                  name: "subjects",
                  id: "subjects",
                }}
              >
                {availableSubjects.map((subject): React.ReactNode => (
                  <MenuItem key={subject.id} value={subject.id}>
                    {subject.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>

          <Typography variant="subheading">
            Selected subjects
          </Typography>

          {/* List of subjects */}
          <div className={classes.chipsContainer}>
            {
              selectedSubjects.map((subject: any): React.ReactNode => (
                <Chip
                  className={classes.subjectChip}
                  key={subject.id}
                  label={subject.name}
                  onDelete={() => this.removeSubject(subject.id)}
                />
              ))
            }

            {/* No subjects selected message*/}
            {
              selectedSubjects.length <= STUDENT_PROFILE_MIN_SUBJECTS &&
              (
                <Typography variant="caption">
                  Please select more subjects
                </Typography>
              )
            }
          </div>

        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.onSave}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any): any => {
  return ({
    availableSubjects: state.data.subjects || {},
  });
};


export default withStyles(styles)(connect(mapStateToProps)(PickSubjectsModal));
