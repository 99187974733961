import * as React from "react";
import {
  gql,
} from "apollo-boost";
// Components
import DataListWithSelection from "@components/Generic/DataList/withSelection";
import {
  IDataListItem,
} from "@components/Generic/DataList";

//
// Constants
//

export const AddPartnershipsListFragments = {
  school: gql`
        fragment AddPartnershipsListSchool on School {
            id
            name
        }
    `,
};

//
// Interfaces
//

interface IProps {
  loading: boolean;
  availableSchoolList: any[];
  selectedSchoolIdList: number[];
  onSelectedSchoolIdListChange: (ids: number[]) => void;
}

/**
 * Shows list of schools that the org could have new partnerships with
 * TODO: add graphql types. https://grandshake.atlassian.net/browse/WA-459
 * @param {IProps} props
 * @return {React.ReactElement}
 */
const AddPartnershipsListLayout: React.FC<IProps> = (props) => {
  const {
    loading,
    availableSchoolList,
    selectedSchoolIdList,
    onSelectedSchoolIdListChange,
  } = props;

  const items: IDataListItem[] = availableSchoolList.map((school) => {
    return {
      id: school.id,
      text: school.name,
    };
  });

  return (
    <DataListWithSelection
      loading={loading}
      items={items}
      selectedIdList={selectedSchoolIdList}
      onSelectedIdListChange={onSelectedSchoolIdListChange}
      emptyMessage={"No more schools available"}
    />
  );
};

export default AddPartnershipsListLayout;
