import * as React from "react";
import {
  Dispatch,
} from "redux";
// Styles
import {
  Avatar,
  Button,
} from "@material-ui/core";
import {
  AccountCircle,
  Add,
  Assignment,
  Chat,
  ExitToApp,
  NotificationsNone,
  People,
  Settings,
} from "@material-ui/icons";
// Images
import logo from "@public/images/logo.svg";
// Utils
import {
  history,
} from "@utils/history";
import {
  signOut,
} from "@utils/auth";
import {
  Link,
} from "@utils/link";
// Actions
import {
  getProfileAction,
} from "@actions/profile";
// Models
import {
  IStudentWithRelations,
} from "@models/profile";
// Components
import {
  getProfilePictureUrl,
} from "@components/ProfilePicture/utils";

interface IProps {
  canCreateOpportunity: boolean;
  canViewAdminDashboard: boolean;
  canViewContacts: boolean;
  canViewProfile: boolean;
  student: IStudentWithRelations;
  dispatch: Dispatch;
  classes: any;
}

interface IState {
  profilePictureUrl?: string;
}

class Drawer extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      profilePictureUrl: undefined,
    };
  }

  private async fetchProfilePicture(s3Key: string) {
    const profilePictureURL: string | undefined = await getProfilePictureUrl(s3Key);

    await this.setState({
      profilePictureUrl: profilePictureURL,
    });
  }

  async componentDidMount() {
    // to show avatar if exist
    const {
      canViewProfile,
      student,
      dispatch,
    } = this.props;

    if (canViewProfile) {
      if (student == null) {
        const fetchedProfile = await dispatch(getProfileAction());

        if (fetchedProfile) {
          await this.fetchProfilePicture(fetchedProfile.profile_picture);
        }
      } else {
        await this.fetchProfilePicture(student.profile_picture);
      }
    }
  }

  public handleSignOut = async (): Promise<void> => {
    await signOut();
  };

  public goToProfile = (): void => {
    history.push("/profile");
  };

  render() {
    const {
      classes,
      student,
      canCreateOpportunity,
      canViewAdminDashboard,
      canViewContacts,
      canViewProfile,
    } = this.props;
    const {
      profilePictureUrl,
    } = this.state;

    return (
      <React.Fragment>
        <div
          style={{
            height: "172px",
            background: "#FFD300",
          }}
        >
          <Avatar
            onClick={() => {
              if (canViewProfile) {
                this.goToProfile();
              }
            }}
            style={{
              position: "absolute",
              top: "45px",
              left: "24px",
              width: "64px",
              height: "64px",
            }}
            src={profilePictureUrl}
          >
            {profilePictureUrl ? null : (
              <AccountCircle
                style={{
                  fontSize: "76px",
                }}
              />
            )}
          </Avatar>
          {
            (student) ? (
              <span
                style={{
                  position: "absolute",
                  top: "125px",
                  left: "24px",
                  fontSize: "18px",
                  fontFamily: "Raleway, sans-serif",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {`${student.first_name || ""} ${student.last_name || ""}`}
              </span>
            ) : null
          }
        </div>
        <div>
          {
            canCreateOpportunity && (
              <React.Fragment>
                <Button
                  variant="flat"
                  fullWidth
                  className={classes.button}
                  component={Link("/opportunity/new")}
                >
                  <Add className={classes.drawerIcon}/>
                  <span className={classes.drawerText}>
                    CREATE
                  </span>
                </Button>
              </React.Fragment>
            )
          }
          <Button
            variant="flat"
            fullWidth
            className={classes.button}
            component={Link("/")}
          >
            <Assignment className={classes.drawerIcon}/>
            <span className={classes.drawerText}>
              LISTING
            </span>
          </Button>

          {
            canViewContacts && (
              <React.Fragment>
                <Button
                  variant="flat"
                  fullWidth
                  className={classes.button}
                  component={Link("/students")}
                >
                  <People className={classes.drawerIcon}/>
                  <span className={classes.drawerText}>
                    STUDENTS
                  </span>
                </Button>
                {/* TODO: WA-246: https://grandshake.atlassian.net/browse/WA-246 */}
                {/* <Button*/}
                {/*  variant="flat"*/}
                {/*  fullWidth*/}
                {/*  className={classes.button}*/}
                {/*  component={Link("/contacts")}*/}
                {/* >*/}
                {/*  <Business className={classes.drawerIcon}/>*/}
                {/*  <span className={classes.drawerText}>INDUSTRY CONTACTS</span>*/}
                {/* </Button>*/}
              </React.Fragment>
            )
          }

          {
            canViewAdminDashboard && (
              <Button
                variant="flat"
                fullWidth
                className={classes.button}
                component={Link("/admin")}
              >
                <Settings className={classes.drawerIcon}/>
                <span className={classes.drawerText}>
                  Administration
                </span>
              </Button>
            )
          }

          <Button
            variant="flat"
            fullWidth
            className={classes.button}
          >
            <Chat className={classes.drawerIcon}/>
            <span className={classes.drawerText}>
              MESSAGE
            </span>
          </Button>

          <Button
            variant="flat"
            fullWidth
            className={classes.button}
          >
            <NotificationsNone className={classes.drawerIcon}/>
            <span className={classes.drawerText}>
              NOTIFICATIONS
            </span>
          </Button>

          <Button
            variant="flat"
            fullWidth
            className={classes.button}
            onClick={this.handleSignOut}
          >
            <ExitToApp className={classes.drawerIcon}/>
            <span className={classes.drawerText}>
              SIGN OUT
            </span>
          </Button>
        </div>

        <img className={classes.logo} src={logo}/>
      </React.Fragment>
    );
  }
}

export default Drawer;
