import * as React from "react";

class ErrorPageComponent extends React.Component<any, any> {
  public render() {
    return (
      <section>
        Error
      </section>
    );
  }
}

export default ErrorPageComponent;
