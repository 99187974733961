import {
  NEW_ALERT,
  SET_LOADING,
} from "@actions/global";

import {
  GlobalsState,
} from "../components/global.model";

const defaultState: GlobalsState = {
  alertHistory: [],
  mostRecentAlert: null,
  loading: false,
};

const globals = (state = defaultState, action: any): GlobalsState => {
  switch (action.type) {
  case NEW_ALERT:
    return {
      ...state,
      alertHistory: state.alertHistory.concat([action.alert]),
      mostRecentAlert: action.alert,
    };
  case SET_LOADING:
    return {
      ...state,
      loading: action.loading,
    };
  default:
    return state;
  }
};

export default {
  globals,
};
