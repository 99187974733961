import * as React from "react";
import {
  get,
  set,
} from "lodash";
// Components
import _FormErrors from "@components/FormErrors";
import {
  FormPage,
} from "./styled";
import {
  ICreateOpportunityFormData,
} from "@actions/opportunity";
import {
  setLastLocation,
  setNewOpportunityInProgress,
} from "@utils/local.storage";
import {
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";

export const FormErrors = _FormErrors;

export interface IMultipageFormProps {
}

export interface IMultipageFormState {
}

export class MultipageFormPage<P extends IMultipageFormProps,
  S extends IMultipageFormState,
  > extends React.Component<P, S> {
  public validatePage() {
    return {};
  }

  public render() {
    return this.props.children;
  }
}

export interface IMultipageProps {
  children?: React.ReactNode;
}

export interface IMultipageState {
  currentPage: number;
  formData: ICreateOpportunityFormData;
  formErrors: any;
  pageCount?: null | number;
  responseData?: any;
  submitting: boolean;
  debounceId?: number;
}

export class MultipageForm<P extends IMultipageProps,
  S extends IMultipageState,
  > extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      formData: {},
      formErrors: {},
      pageCount: null,
      responseData: {},
      submitting: false,
      debounceId: -1,
      ...props,
    } as S;
  }

  public componentWillUnmount() {
    if (this.state.debounceId) {
      //  Save the changes before clearing the interval so that if they didn't
      //  let the debounce resolve even once, then it'll still be saved.
      setNewOpportunityInProgress(this.state.formData, this.state.currentPage);
      clearTimeout(this.state.debounceId);
    }
  }

  private debounce() {
    // Debounce logic here
    if (this.state.debounceId) clearTimeout(this.state.debounceId);
    const updateLocalStorageDebounced = setTimeout(() => {
      setNewOpportunityInProgress(this.state.formData, this.state.currentPage);
      this.setState({
        ...this.state,
        debounceId: -1,
      });
    }, 1000);


    this.setState({
      ...this.state,
      debounceId: updateLocalStorageDebounced,
    });
  }

  /**
   * Sets a particular field in local storage with the given video state.
   * @param field
   */
  public setFileFieldValue = (field) => (videoInput: IVideoInput) => {
    const value: IVideoInput = videoInput;

    // Create updated form data and update state
    const formData = {
      ...this.state.formData as {},
    };
    set(formData, field, value);
    this.setState({
      formData,
    }, () => {
      if (!Object.prototype.hasOwnProperty.call(formData, field)) return;
      this.debounce();
    });
  };

  public setFieldValue = (field) => (e) => {
    let value = get(e, "target.value", e);

    if (field === "locationId") {
      setLastLocation(`${value.suburb}, ${value.postcode}`);
      value = value.id;
    }

    const formData = {
      ...this.state.formData as {},
    };
    set(formData, field, value);
    this.setState({
      formData,
    }, () => {
      //  ! Hey look! another blatant hack. We really want to get rid of this asap. As mentioned,
      //  ! I anticipate replacing this whole thing as I think it's really stupid.

      //  ! If you don't know what this is, we use this superclass multiple times. Here I only
      //  ! want to update the oppcache in the localstorage if this is the create opp, which
      //  ! has the pathToLocalVideo property. Nothing else should.
      if (!Object.prototype.hasOwnProperty.call(formData, "pathToLocalVideo")) return;
      this.debounce();
    });
  };

  public addItemIntoArray = (field) => (e) => {
    const value = get(e, "target.value", "");
    const formData = {
      ...this.state.formData as {},
    };

    set(formData, field, [...formData[field], value]);
    this.setState({
      formData,
    });
  };

  public setArrayFieldValue = (field) => (e) => {
    let value = get(e, "target.value", e);

    if (field === "skillsToAdd") { // FIXME best practice from previous developer
      value = value.map((x) => x.toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" "),
      );
    }

    const formData = {
      ...this.state.formData as {},
    };

    set(formData, field, [...value]);
    this.setState({
      formData,
    }, () => {
      if (!Object.prototype.hasOwnProperty.call(formData, "pathToLocalVideo")) return;
      this.debounce();
    });
  };

  public backPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  public nextPage = (stepsToSkip = 0) => {
    if (typeof stepsToSkip !== "number") stepsToSkip = 0;

    const {
      pageCount,
    } = this.state;

    if (!this.validateForm()) {
      return false;
    }

    if (!pageCount || this.state.currentPage < pageCount) {
      this.setState({
        currentPage: this.state.currentPage + 1 + stepsToSkip,
      });
    }

    return;
  };

  public validateForm = () => true;

  public handleApiError = (e) => {
    const {
      error,
    } = e; // TODO: make this error handling nicer
    const postError = error === "User Exists" ? "email" : "postError";
    const errorMessage = error ? error : "Error submitting form";
    const errorObj = typeof errorMessage === "string" ?
      {
        [postError]: errorMessage,
      } :
      errorMessage;
    const formErrors = {
      ...this.state.formErrors as {},
      ...errorObj,
    };
    this.setState({
      formErrors,
      submitting: false,
    });
  };

  public renderPages(pages, extras) {
    const pageComponents: any = [];
    const {
      currentPage, formData, formErrors, responseData,
    } = this.state;
    let i = 0;

    for (const pageKey of Object.keys(pages)) {
      const Page = pages[pageKey];
      pageComponents.push((
        <FormPage show={currentPage === i * 1 + 1} key={i}>
          <Page
            {...extras}
            formData={formData}
            formErrors={formErrors}
            addItem={this.addItemIntoArray}
            onChange={this.setFieldValue}
            onFileChange={this.setFileFieldValue}
            onChangeArray={this.setArrayFieldValue}
            responseData={responseData}
          />
        </FormPage>
      ));
      i = i + 1;
    }

    return pageComponents;
  }

  public render() {
    return this.props.children;
  }
}
