import * as React from "react";
import * as Joi from "@hapi/joi";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  AlertLevel,
} from "./global.model";
import {
  ISkill,
} from "@models/skill";
import {
  newAlert,
} from "@actions/global";
import OptionButtons from "@components/OptionButtons";
import ItemList from "@components/ItemList";

interface IProps {
  dispatch: Dispatch;
  onSave: () => void;
  onChange: (newSkills: ISkill[]) => void;
  onClose: () => any;

  open: boolean;
  skills: ISkill[];
  pickedSkills: Array<any>;
  schema?: Joi.SchemaLike; // Joi schema object
}

class EditSkillsModalComponent extends React.Component<IProps> {
  /**
   * Using the selected skill ids, call on change with a list of skill objects
   * @param values
   */
  public updateSelected = (values: number[]): void => {
    const newSkills: ISkill[] = this.props.skills.filter((skill) => values.includes(skill.id));
    this.props.onChange(newSkills);
  };

  /**
   * Validates the the selected fields, if a schema is given
   * Calls onSave when done,
   * Displays a snackbar if validation errors occur
   */
  public onSave = (): void => {
    const {
      dispatch,
      pickedSkills,
      schema,
      onSave,
    } = this.props;


    if (schema) {
      const {
        error,
      } = Joi.validate(pickedSkills, schema);

      if (error) {
        dispatch(newAlert({
          level: AlertLevel.WARNING,
          body: error.message,
        }));
        return;
      }
    } else {
      // Remains to ensure this works with legacy code
      if (pickedSkills.length === 0) {
        dispatch(newAlert({
          level: AlertLevel.WARNING,
          body: "You must select at least one skill",
        }));
        return;
      }
    }

    onSave();
  };

  public removeSkill = (oldSkill: ISkill) => {
    const {
      pickedSkills,
      onChange,
    } = this.props;

    const newSkills = pickedSkills.filter((skill) => skill.id !== oldSkill.id);

    onChange(newSkills);
  };

  public render() {
    const {
      skills,
      pickedSkills,
      onClose,
    } = this.props;
    const skillsDisabled: boolean = false;
    const selectedSkillIds: number[] = pickedSkills.map((skill) => skill.id);

    return (
      <Dialog
        open={this.props.open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update skills
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below, enter a new selection of skills
          </DialogContentText>

          <ItemList
            items={pickedSkills}
            label="name"
            onClick={this.removeSkill}
          />

          <br/>

          <DialogContentText>
            To remove selected skills, click on them: add up to
            {" "}
            {12 - pickedSkills.length}
            {" "}
            more
          </DialogContentText>

          <Grid container style={{
            margin: "2rem 0",
          }}>
            <Grid item xs={12} style={{
              padding: "0 1rem",
            }}>
              <OptionButtons
                size="small"
                color="primary"
                multiple
                disabled={skillsDisabled}
                selected={selectedSkillIds}
                onChange={this.updateSelected}
                options={skills}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.onSave} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  const skills = state && state.data && state.data.skills ? state.data.skills : null;

  return {
    skills,
  };
};

const EditSkillsModal = connect(mapStateToProps)(EditSkillsModalComponent);

export default EditSkillsModal;
