import * as React from "react";
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Cancel,
  Close,
  CloudUpload,
  Done,
} from "@material-ui/icons";
import {
  green,
  red,
} from "@material-ui/core/colors";
import Layout from "@components/Layout";
import DatePicker from "./DatePicker";
import {
  PROFILE_PICTURE_MAX_SIZE,
  PROFILE_PICTURE_MAX_SIZE_ERROR_MESSAGE,
} from "@components/ProfilePicture/utils";

interface IProps {
  classes: any;
  onCancel: () => any;
  onSave: (formData: IFormData) => any;
  open: boolean;
  initialData: IFormData;
}

interface IState {
  errors: any;
  formData: IFormData;
}

interface IFormData {
  firstName: string;
  lastName: string;
  intro: string;
  grade: number;
  dob: string;
  phone: string;
  gender_id: number;
  profilePicture: string | File | null;
}

const styles = (): any => ({
  root: {},
  formInput: {
    margin: "1.0rem 0",
  },
  fileUpload: {
    display: "flex",
    flexDirection: "row",
    margin: "1.0rem 0",
    alignItems: "center",
  },
  uploadButton: {
    marginRight: "1.0rem",
    width: "auto",
  },
});

const Transition = (props: any): React.ReactElement<any> => (
  <Slide direction="up" {...props} />
);

class EditProfileMain extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      errors: {},
      formData: {
        firstName: props.initialData.firstName ? props.initialData.firstName : "",
        lastName: props.initialData.lastName ? props.initialData.lastName : "",
        intro: props.initialData.intro ? props.initialData.intro : "",
        grade: props.initialData.grade ? props.initialData.grade : 0,
        dob: props.initialData.dob ? props.initialData.dob : "",
        phone: props.initialData.phone ? props.initialData.phone : "",
        gender_id: props.initialData.gender_id ? props.initialData.gender_id : -1,
        profilePicture: props.initialData.profilePicture ? props.initialData.profilePicture : null,
      },
    };
  }

  public UNSAFE_componentWillReceiveProps(props: IProps): void {
    this.setState({
      errors: {},
      formData: {
        firstName: props.initialData.firstName ? props.initialData.firstName : "",
        lastName: props.initialData.lastName ? props.initialData.lastName : "",
        intro: props.initialData.intro ? props.initialData.intro : "",
        grade: props.initialData.grade ? props.initialData.grade : 0,
        dob: props.initialData.dob ? props.initialData.dob : "",
        phone: props.initialData.phone ? props.initialData.phone : "",
        gender_id: props.initialData.gender_id ? props.initialData.gender_id : -1,
        profilePicture: props.initialData.profilePicture ? props.initialData.profilePicture : null,
      },
    });
  }

  public validateAndSave = (): void => {
    const formData: IFormData = {
      ...this.state.formData,
    };
    const errors: any = {};

    // Error if no profile picture has been selected
    if (formData.profilePicture == null) {
      errors.profilePicture = "Please select a valid profile picture";
    }

    if (!formData.intro || formData.intro.length === 0) {
      errors.headline = true;
    }

    if (!formData.firstName || formData.firstName.length === 0) {
      errors.firstName = true;
    }

    if (!formData.lastName || formData.lastName.length === 0) {
      errors.lastName = true;
    }

    if (formData.phone && (formData.phone.length !== 10 && formData.phone.length !== 0)) {
      errors.phone = true;
    }

    if (!formData.grade ||
      formData.grade === 0 ||
      (formData.grade !== 9 &&
        formData.grade !== 10 &&
        formData.grade !== 11 &&
        formData.grade !== 12)) {
      errors.grade = true;
    }
    if (formData.gender_id !== 0 && formData.gender_id !== 1 && formData.gender_id !== 2) {
      errors.gender = true;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        ...this.state,
        errors,
      });
      return;
    }

    this.props.onSave(formData);
  };

  public handleFieldChange = (field: string, value: any): void => {
    this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        [field]: false,
      },
      formData: {
        ...this.state.formData,
        [field]: value,
      },
    });
  };

  public handleFileUpload = (event: any): void => {
    const file: File = event.target.files[0];

    // Validate size of selected profile picture
    if (file.size > PROFILE_PICTURE_MAX_SIZE) {
      this.setState((previousState) => ({
        errors: {
          ...previousState.errors,
          profilePicture: PROFILE_PICTURE_MAX_SIZE_ERROR_MESSAGE,
        },
      }));
      return;
    }

    // Validate size of selected profile picture
    if (
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/png"
    ) {
      this.setState((previousState) => ({
        errors: {
          ...previousState.errors,
          profilePicture: "Profile pictures must be in a .jpg, .jpeg or .png format",
        },
      }));
      return;
    }

    // If no errors, update state with selected image
    this.setState((previousState) => ({
      formData: {
        ...previousState.formData,
        profilePicture: file,
      },
      errors: {
        ...previousState.errors,
        profilePicture: false,
      },
    }));
  };

  public render() {
    const classes: any = this.props.classes;
    const {
      formData, errors,
    }: { formData: IFormData, errors: any } = this.state;

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.onCancel}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton color="inherit" aria-label="Close" onClick={this.props.onCancel}>
              <Close/>
            </IconButton>
            <Typography variant="title" color="inherit" style={{
              flex: 1,
            }}>
              Update profile
            </Typography>
            <Button color="inherit" onClick={this.validateAndSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <input type="file" name="" id="" ref="hiddenUpload" onChange={this.handleFileUpload} style={{
          display: "none",
        }}/>
        <Layout style={{
          paddingTop: "1.0rem",
        }}>
          <Typography variant="headline" style={{
            marginTop: "1.0rem",
          }}>
            Update profile
          </Typography>
          <form>
            <TextField
              required
              fullWidth
              label="First name"
              error={errors.firstName}
              onChange={(event: any): void =>
                this.handleFieldChange("firstName", event.target.value)
              }
              value={formData.firstName}
              className={classes.formInput}
            />

            <TextField
              required
              fullWidth
              label="Last name"
              error={errors.lastName}
              onChange={(event: any): void =>
                this.handleFieldChange("lastName", event.target.value)
              }
              value={formData.lastName}
              className={classes.formInput}
            />

            <DatePicker
              id="dob-picker"
              className={classes.formInput}
              label="Date of Birth"
              type="date"
              required={true}
              keyboard
              disableFuture
              animateYearScrolling={false}
              value={formData.dob || null}
              error={errors.dob}
              style={{}}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(value: any): void =>
                this.handleFieldChange("dob", value)
              }
            />


            {/*  DISABLED GENDER FOR NOW
            <InputLabel required htmlFor="gender-simple">Gender</InputLabel>


              <Select
              value={formData.gender_id}
              fullWidth
              onChange={(event: any): void =>
                this.handleFieldChange("gender_id", event.target.value)
              }
              name="Gender"
              required
              error={errors.gender}
              className={classes.formInput}
              inputProps={{
                name: 'gender',
                id: 'gender-simple',
              }}
            >
              <MenuItem value={0}>Male</MenuItem>
              <MenuItem value={1}>Female</MenuItem>
              <MenuItem value={2}>Other</MenuItem>
            </Select>*/}

            <TextField
              required
              fullWidth
              label="A bit about yourself"
              error={errors.intro}
              className={classes.formInput}
              onChange={(event: any): void =>
                this.handleFieldChange("intro", event.target.value)
              }
              value={formData.intro}
            />

            <InputLabel required htmlFor="grade-simple">
              Grade
            </InputLabel>

            <Select
              fullWidth
              value={formData.grade}
              onChange={(event: any): void =>
                this.handleFieldChange("grade", event.target.value)
              }
              name="Grade"
              required
              error={errors.grade}
              className={classes.formInput}
              inputProps={{
                name: "grade",
                id: "grade-simple",
              }}
            >
              <MenuItem value={9}>
                9
              </MenuItem>
              <MenuItem value={10}>
                10
              </MenuItem>
              <MenuItem value={11}>
                11
              </MenuItem>
              <MenuItem value={12}>
                12
              </MenuItem>
            </Select>

            <TextField
              fullWidth
              className={classes.formInput}
              label="Contact number"
              onChange={(event: any): void =>
                this.handleFieldChange("phone", event.target.value)
              }
              placeholder='e.g. 0425000000'
              value={formData.phone}
              error={errors.phone}
            />

            <Typography variant="caption" style={{
              marginTop: "1.0rem",
            }}>
              Profile picture
            </Typography>
            <div className={classes.fileUpload}>
              <Button
                className={classes.uploadButton}
                onClick={() => this.refs.hiddenUpload.click()}
                variant="raised"
                color="primary"
              >
                <CloudUpload style={{
                  marginRight: "0.5rem",
                }}/>
                Upload
              </Button>
              {errors.profilePicture ? (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                  <Avatar style={{
                    backgroundColor: red[500],
                    marginRight: "1.0rem",
                    width: 30,
                    height: 30,
                  }}>
                    <Cancel/>
                  </Avatar>
                  {errors.profilePicture}
                </div>
              ) :
                formData.profilePicture ? (
                  typeof formData.profilePicture === "string" ?
                    formData.profilePicture :
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                      <Avatar style={{
                        backgroundColor: green[500],
                        marginRight: "1.0rem",
                        width: 30,
                        height: 30,
                      }}>
                        <Done/>
                      </Avatar>
                        Profile picture selected.
                    </div>

                ) :
                  "Choose a profile picture"
              }
            </div>

          </form>
        </Layout>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EditProfileMain);
