import {
  SET_STUDENT,
} from "@actions/profile";

import {
  IStudentState,
} from "../models/profile";

const defaultState: IStudentState = {};

const student = (state = defaultState, action: any): IStudentState => {
  switch (action.type) {
  case SET_STUDENT:
    return {
      ...state,
      ...action.student,
    };
  default:
    return state;
  }
};

export default {
  student,
};
