import * as React from "react";
// Components
import AdminDashboardLayout from "@pages/admin/dashboard/AdminDashboardLayout";

const AdminDashboardPageData: React.FC = (): React.ReactElement => {
  return (
    <AdminDashboardLayout />
  );
};

export default AdminDashboardPageData;
