import * as React from "react";
// Styles
import Typography from "@material-ui/core/Typography";
import {
  Theme,
  withStyles,
} from "@material-ui/core";

//
// Styles
//

const styles = (theme: Theme) => ({
  root: {
    marginBottom: theme.spacing.unit,
    flex: 1,
  },
});

//
// Interfaces
//

interface IProps {
  children: string,
}

interface IExtendedProps extends IProps{
  classes: {
    root: any;
  }
}

/**
 * The main title fo each page
 * @param {any} classes
 * @param {any} rest
 * @constructor
 */
const MainTitle: React.FC<IExtendedProps> = ({
  classes, ...rest
}: IExtendedProps) => (
  <div className={classes.root}>
    <Typography variant='display1' {...rest} />
  </div>
);

export default withStyles(styles)(MainTitle);
