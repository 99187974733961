export const ROUTE_DASHBOARD: string = "/";

//
// Admin
//
export const ROUTE_ADMIN_DASHBOARD: string = "/admin";
export const ROUTE_ADMIN_ORGANISATIONS: string = "/admin/organisations";
export const ROUTE_ADMIN_ORGANISATION: string = "/admin/organisation/";
export const ROUTE_ADMIN_ORGANISATION_ID: string = "/admin/organisation/:id";
export const ROUTE_ADMIN_SCHOOLS: string = "/admin/schools";
export const ROUTE_ADMIN_USERS: string = "/admin/users";
