import {
  Button,
  Paper,
} from "@material-ui/core";
import {
  grey,
  indigo,
  red,
} from "@material-ui/core/colors";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  ThumbUp,
} from "@material-ui/icons";
import {
  get,
  identity,
  isEmpty,
  pickBy,
} from "lodash";
import * as React from "react";
import styled from "styled-components";

import {
  history,
} from "@utils/history";
// Components
import {
  IMultipageProps,
  IMultipageState,
  MultipageForm,
} from "@components/FormComponents";
import FormErrors from "@components/FormErrors";
import ProgressButton from "@components/ProgressButton";
import Footer from "./Footer";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import ProgressBar from "./ProgressBar";
import ThankyouPage from "./ThankyouPage";
// Utils
import {
  studentSignupNew,
  updateStudent,
} from "@utils/api";
import {
  isEmptyArray,
  validEmail,
  validName,
  validPassword,
  validSchool,
  validSchoolEmail,
} from "@utils/validation";

const pages = [Page1, Page2, Page3, ThankyouPage];

// Data
export const formDataDefault = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  school: {
    id: null,
    name: null,
  },
  schoolEmail: "",
  schoolYear: "",
};

export const SignupFormControl = styled.div`
  margin: 2rem auto;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${grey[500]};
`;

const SignupFormContainer: any = styled.div`
  max-width: 600px;
  width: 60%;
  transition: width 100ms linear;
  @media(max-width: 768px) {
    margin-top: 2rem;
    width: 90%;
  }
`;

const SignupFormPaper: any = styled(Paper)`
  padding: 4rem;
  @media(max-width: 768px) {
    padding: 2rem;
  }
`;

const SignupFormPageContainer: any = styled.div`
  display: flex;
  flex-direction: row;
`;

const SignupFormPage: any = styled.div`
  display: ${(props: any) => props.show ? "block" : "none"};
  width: 100%;
`;

const SignupFormActions: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DoneBar: any = styled.div`
  width: 100%;
  height: 32px;
  background: #279B23;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbUpIcon = styled(ThumbUp)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const requiredFields = {
  2: {
    dob: "Date of Birth",
    location: "Suburb",
    schoolYear: "School Year",
  },
  3: {
    "school.id": "School",
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: indigo.A700,
    },
    secondary: red,
  },
});

interface IProps extends IMultipageProps {
  data?: any;
  formData?: any;
  formErrors?: any;
  onChange?: (e: any) => any;
  onChangeArray?: (e: any) => any;
  organisation?: any;
}

interface IState extends IMultipageState {
  currentPage: number;
  formData: any;
  formErrors: any;
  signupId: any;
  signupToken: any;
  submitting: boolean;
}

class SignupFormComponent extends MultipageForm<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      formData: this.props.formData || formDataDefault,
      formErrors: {},
      signupId: null,
      signupToken: null,
      submitting: false,
    };
  }

  public backButtonDisabled = () => {
    return this.state.currentPage === 1;
  };
  public showBackButton = () => {
    return this.state.currentPage > 1 && !this.isLastPage();
  };
  public nextButtonDisabled = () => {
    return this.isLastPage();
  };
  public backButtonClick = () => {
    this.backPage();
  };
  public nextButtonLoading = () => {
    return this.state.submitting;
  };
  public nextButtonClick = () => {
    if (this.state.currentPage === 3) {
      this.createStudent();
    } else {
      this.nextPage();
    }
  };
  public showNextButton = () => {
    return !this.isLastPage();
  };
  public showCloseButton = () => {
    return this.isLastPage();
  };
  public showPage = (pageNumber) => {
    return pageNumber === this.state.currentPage;
  };
  public isLastPage = () => {
    return this.state.currentPage === pages.length;
  };
  public requiredFields = (fields) => {
    const {
      formData,
    } = this.state;
    const required = {};
    if (!fields || !fields.length) {
      return required;
    }
    for (const field of Object.keys(fields)) {
      const fieldName = fields[field];
      const fieldData = get(formData, field);
      if (!fieldData) {
        required[field] = `${fieldName} is required`;
      } else if (fieldData.constructor === Array) {
        if (isEmptyArray(fieldData)) {
          required[field] = `${fieldName} is required`;
        }
      }
    }
    return required;
  };
  public validateForm = () => {
    const {
      currentPage,
    } = this.state;
    const requiredErrors = this.requiredFields(requiredFields[currentPage]);
    const {
      formData,
    } = this.state;

    const newErrors = Object.assign({
      email: validEmail(formData.email),
      firstName: validName(formData.firstName, "Invalid first name"),
      lastName: validName(formData.lastName, "Invalid last name"),
      school: this.state.currentPage === 2 ? validSchool(formData.school) : null,
      password: validPassword(formData.password),

      //  todo this is a hack, come up with a better way to deal with school email validation
      schoolEmail: this.state.currentPage === 2 ? validSchoolEmail(formData.schoolEmail) : null,
    }, requiredErrors);

    const formErrors = pickBy(newErrors, identity);
    this.setState({
      formErrors,
    });
    return isEmpty(formErrors);
  };
  public createStudent = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({
      submitting: true,
    });
    studentSignupNew({
      first_name: this.state.formData.firstName,
      last_name: this.state.formData.lastName,
      password: this.state.formData.password,
      email: this.state.formData.email.toLowerCase(),
      school_email: this.state.formData.schoolEmail.toLowerCase(),
      grade: parseInt(this.state.formData.schoolYear, 10),
      school_id: this.state.formData.school.id,
    })
      .then((response: any) => {
        return this.setState({
          currentPage: this.state.currentPage + 1,
          formErrors: {},
          signupId: response.data.id,
          signupToken: response.data.signupToken,
          submitting: false,
        });
      }).catch((e) => {
        const {
          error,
        } = e; // TODO: make this error handling nicer
        const postError = error === "User Exists" ? "email" : "postError";
        const errorMessage = error ? error : "Error submitting form";
        const formErrors = typeof errorMessage === "string" ?
          {
            [postError]: errorMessage,
          } : errorMessage;
        this.setState({
          formErrors,
          submitting: false,
        });
      });
  };
  public updateStudent = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({
      submitting: true,
    });
    updateStudent(this.state.signupId, "SignupToken", this.state.signupToken, this.state.formData)
      .then(() => {
        return this.setState({
          currentPage: this.state.currentPage + 1,
          formErrors: {},
          submitting: false,
        });
      }).catch(() => {
        this.setState({
          submitting: false,
        });
      });
  };
  public reset = () => {
    this.setState({
      currentPage: 1,
    });
  };

  public render() {
    const {
      currentPage, formData, formErrors,
    } = this.state;
    const isFirstPage = currentPage === 1;
    const isLastPage = this.isLastPage();
    return (
      <MuiThemeProvider theme={theme}>
        <SignupFormContainer>
          {this.state.currentPage === 4 ?
            <DoneBar>
              <ThumbUpIcon/>
              DONE
            </DoneBar> :
            <ProgressBar value={this.state.currentPage} max={3}/>
          }
          <SignupFormPaper elevation={0}>
            <SignupFormPageContainer>
              <SignupFormPage show={this.showPage(1)}>
                <Page1
                  formData={formData}
                  formErrors={formErrors}
                  onChange={this.setFieldValue}
                  validateForm={this.validateForm}
                />
              </SignupFormPage>
              <SignupFormPage show={this.showPage(2)}>
                <Page2 formData={formData}
                  formErrors={formErrors}
                  onChange={this.setFieldValue}
                  validateForm={this.validateForm}/>
              </SignupFormPage>
              <SignupFormPage show={this.showPage(3)}>
                <Page3 formData={formData} formErrors={formErrors} onChange={this.setFieldValue}/>
              </SignupFormPage>
              <SignupFormPage show={this.showPage(4)}>
                <ThankyouPage/>
              </SignupFormPage>
            </SignupFormPageContainer>
            <FormErrors formErrors={formErrors}/>

            <SignupFormActions style={{
              justifyContent: isLastPage ? "flex-end" : "space-between",
            }}>
              <Button
                size="large"
                disabled={this.backButtonDisabled()}
                onClick={this.backButtonClick}
                style={{
                  display: this.showBackButton() ? "inherit" : "none",
                }}
              >
                BACK
              </Button>
              <Button
                size="large"
                onClick={() => history.push("/signin")}
                style={{
                  display: isFirstPage ? "inherit" : "none",
                }}
              >
                Sign In
              </Button>

              {!isLastPage && <ProgressButton
                size="large"
                variant="raised"
                color="primary"
                disabled={this.nextButtonDisabled()}
                loading={this.nextButtonLoading()}
                onClick={this.nextButtonClick}
              >
                  NEXT
              </ProgressButton>
              }
              <Button
                size="large"
                variant="raised"
                color="primary"
                disabled={!isLastPage}
                onClick={() => history.push("/signin")}
                style={{
                  display: isLastPage ? "inherit" : "none",
                }}
              >
                CLOSE
              </Button>
            </SignupFormActions>

          </SignupFormPaper>

          <Footer/>

        </SignupFormContainer>
      </MuiThemeProvider>
    );
  }
}

export default SignupFormComponent;
