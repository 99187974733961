import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import GenericVideoInput, {
  getEmptyVideoInput,
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";

interface IProps {
  open: boolean;
  onClose: (video: IVideoInput) => void; // Selected Video
  timeLimit: number; // Seconds
  sizeLimit: number; // MB
}

interface IPropsWithStyles extends IProps {
  classes?: any;
}

interface IState {
  video: IVideoInput;
  validationError: boolean;
  durationError: boolean;
  sizeError: boolean;
}

const styles = (theme: Theme) => ({
  paper: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    backgroundColor: "#D3453A",
  },
  text: {
    color: "#FFF",
  },
  videoInput: {
    marginTop: theme.spacing.unit,
  },
});

/**
 * This used as a video file picker
 * Will ensure that the video sits within it's timelimit.
 * Returns the selected video via the onClose prop
 */
class VideoPickerDialog extends React.Component<IPropsWithStyles, IState> {
  static defaultProps = {
    sizeLimit: 100, // MB
  };

  constructor(props) {
    super(props);
    this.state = {
      video: getEmptyVideoInput(props.timeLimit),
      validationError: false,
      durationError: false,
      sizeError: false,
    };
  }

  /**
   * Returns true if there is an error
   */
  validate(): boolean {
    const {
      sizeLimit,
    } = this.props;

    const {
      video: {
        file,
        duration,
        timeLimit,
      },
    } = this.state;

    let durationError = false;

    // Update state for invalid video
    if (duration && timeLimit) {
      if (duration > timeLimit) {
        durationError = true;
      }
    }

    let sizeError = false;

    if (file && file.size) {
      const fileSize: number = (file.size / 1024) / 1024; // MB
      if (fileSize > sizeLimit) {
        sizeError = true;
      }
    }

    const validationError = sizeError || durationError;

    // Update state for valid video
    this.setState({
      durationError: durationError,
      sizeError: sizeError,
      validationError: validationError,
    });

    return validationError;
  }

  handleChange = (videoState: IVideoInput) => {
    this.validate();

    this.setState({
      video: videoState,
    });
  };

  handleSubmit = () => {
    if (!this.validate()) {
      this.props.onClose(this.state.video);

      this.setState({
        video: getEmptyVideoInput(this.props.timeLimit),
        validationError: false,
        durationError: false,
        sizeError: false,
      });
    }
  };

  renderValidationError() {
    const {
      classes,
    } = this.props;

    const {
      durationError,
      sizeError,
    } = this.state;

    const durationMessage = `Video exceeds time limit of ${this.state.video.timeLimit} seconds`;
    const sizeMessage = `Video exceeds size limit of ${this.props.sizeLimit} megabytes`;

    return (
      <div>
        <Paper
          className={classes.paper}
          elevation={0}>
          {
            durationError &&
            <Typography
              className={classes.text}
              variant="subheading">
              {durationMessage}
            </Typography>
          }
          {
            sizeError &&
            <Typography
              className={classes.text}
              variant="subheading">
              {sizeMessage}
            </Typography>
          }
        </Paper>
      </div>
    );
  }

  render() {
    const {
      classes,
      open,
      onClose,
    } = this.props;

    const {
      video,
      validationError,
    } = this.state;

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => onClose(getEmptyVideoInput(this.props.timeLimit))}>

          <DialogTitle>
            Video Upload
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              Record and submit a
              {video.timeLimit}
              -second video
            </DialogContentText>

            <div className={classes.videoInput}>
              <GenericVideoInput
                playerWidth={336}
                playerHeight={252}
                onChange={this.handleChange.bind(this)}
                video={this.state.video}
              />
            </div>
            {validationError ? this.renderValidationError() : null}
          </DialogContent>

          <DialogActions>
            <Button
              color="default"
              onClick={() => onClose(getEmptyVideoInput(this.props.timeLimit))}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={this.handleSubmit}
              disabled={!this.state.video.videoSelected || validationError}>
              Select
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VideoPickerDialog);
