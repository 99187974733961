import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DatePicker from "@components/DatePicker";
import {
  IOpportunityWithRelations,
} from "@models/opportunity";

interface IProps {
  open: boolean,
  opportunity: IOpportunityWithRelations;
  onChange: (date: any) => void;
  onClose: (isOpen: boolean) => () => void;
}

class EditStartDateModal extends React.Component<IProps, any> {
  public onChangeDate = (date) => {
    const {
      onChange,
    } = this.props;
    onChange(date.format("YYYY-MM-DD"));
  };

  render() {
    const {
      open,
      opportunity,
      onClose,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update start date
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Below, enter new start date of the opportunity.
          </DialogContentText>

          <DatePicker
            id="opportunityStartDate"
            type="date"
            required
            value={opportunity.date_start}
            onChange={this.onChangeDate}
            // style
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onClose(true)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditStartDateModal;
