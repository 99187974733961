import * as React from "react";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import {
  Dispatch,
} from "redux";
import * as moment from "moment-timezone";
import * as _ from "lodash";

import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  styles,
  theme,
} from "./styles";
import {
  updateOpportunityVideoKey,
} from "@utils/api";
import {
  AWS_S3_VIDEO_INPUT_BUCKET,
} from "@utils/env";
import {
  IOpportunityWithRelations,
} from "@models/opportunity";
import {
  fetchOpportunityNew,
  removeOpportunity,
  sendUpdateOpportunity,
} from "@actions/opportunity";
import {
  newAlert,
} from "@actions/global";
import Layout from "@components/Layout";
import {
  AlertLevel,
} from "@components/global.model";
import KeyInformation from "./KeyInformation";
import ActionButtons from "./ActionButtons";
import Organisation from "./Organisation";
import Details from "./Details";
import RootBar, {
  getOpportunityGradient,
} from "./RootBar";
import DeleteModal from "./DeleteModal";
import FieldEditable from "@components/field.editable";
import LocalAlert from "./LocalAlert";
import {
  ApplicationList,
} from "../OpportunityApplications/ApplicationListData";
import SubmitApplicationModal from "./SubmitApplication/SubmitApplicationModal";
import VideoUploadToS3 from "@components/UploadVideoInputComponent/utils/VideoUploadToS3";
import VideoStreamFromStorageKey from "@components/VideoPlayer/VideoStreamPlayer/StreamFromStorageKey";
import VideoPickerDialog from "@components/UploadVideoInputComponent/VideoPickerDialog";
import {
  getEmptyVideoInput,
  IVideoInput,
} from "@components/UploadVideoInputComponent/GenericVideoInput";
import GenericVideoFilePlayer from "@components/VideoPlayer/GenericVideoFilePlayer";
import {
  Loading,
} from "@components/Loading/Loading";

moment.tz("Australia/Brisbane");

interface IProps {
  hasAccessToProfile: boolean;
  canCreateOpportunity: boolean;
  canDeleteOpportunity: boolean;
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: (value: boolean) => void;
  canEditOpportunity: boolean;
  canReviewApplications: boolean;
  reviewingApplications: boolean;
  setReviewingApplications: (value: boolean) => void;
  canSubmitInterest: boolean;
  studentSubmittedInterest: boolean;
  submitInterestDialogOpen: boolean;
  setSubmitInterestDialogOpen: (value: boolean) => void;
  getApplicationListRefetch: () => Promise<void>;
}

interface IExtendedProps extends IProps {
  data: any;
  // Styles
  classes: any;
  // Redux
  dispatch: Dispatch;
  loading: boolean;
  opportunity: IOpportunityWithRelations;
  // Router
  history: any;
  match: any;
}

interface IState {
  alerted: boolean;
  editOpportunity: IOpportunityWithRelations | null;
  previouslySavedOpportunity: IOpportunityWithRelations | null;
  editMode: boolean;
  video: IVideoInput;
  videoPickerDialogOpen: boolean;
}

const getBackground = (opportunityTypes) => {
  const segmentPercentage = 100.0 / opportunityTypes.length;
  let i = 0;

  let finalGradient = "linear-gradient(to right, ";
  for (const opportunityType of opportunityTypes) {
    const stops = getOpportunityGradient(opportunityType.id);
    const stopPercentage = segmentPercentage / (stops.length - 1);
    let j = 0;

    for (const stop of stops) {
      finalGradient += `${stop} ${i * segmentPercentage + stopPercentage * j}%, `;
      j += 1;
    }

    i += 1;
  }

  // remove last ', ' characters
  finalGradient = finalGradient.slice(0, finalGradient.length - 2);
  return finalGradient + ")";
};

class OpportunityPageComponent extends React.Component<IExtendedProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      alerted: false,
      editOpportunity: null,
      previouslySavedOpportunity: null,
      editMode: false,
      video: getEmptyVideoInput(60),
      videoPickerDialogOpen: false,
    };
  }

  public componentDidMount(): void {
    const {
      dispatch,
      opportunity,
      match,
      history,
    } = this.props;
    const id: number = parseInt(match.params.id, 10);

    if (history.location.state) {
      this.setState({
        exploreApplications: history.location.state.viaApplications,
      });
    }

    // If the selected opportunity has been already been stored as the focused opportunity,
    // Then, we don't need to dispatch an action to focus this one
    if (opportunity && opportunity.id === id) return;

    dispatch(fetchOpportunityNew(id));
  }

  public goBack = () => {
    const {
      reviewingApplications,
      setReviewingApplications,
    } = this.props;

    if (reviewingApplications) {
      setReviewingApplications(false);
    } else {
      this.props.history.push("/");
    }
  };

  /**
   * Deletes opportunity.
   */
  public deleteOpportunity = async () => {
    const {
      dispatch,
      opportunity,
    } = this.props;

    await dispatch(removeOpportunity(opportunity.id));

    this.props.history.push("/");
  };

  /**
   * Toggles "on" edit mode with deep copy of opportunity object to have ability to revert
   * all changes.
   */
  public toggleEditMode = () => {
    const editOpportunity = this.props.opportunity;

    this.setState({
      editOpportunity,
      previouslySavedOpportunity: editOpportunity,
      editMode: true,
    });
  };

  /**
   * Shows yellow alert "non implemented yet"
   */
  public toggleAlert = () => {
    this.setState({
      alerted: true,
    });
  };

  /**
   * Hides yellow alert "non implemented yet"
   */
  public closeAlert = () => {
    this.setState({
      alerted: false,
    });
  };

  /**
   * Closes edit mode without any sync with server.
   */
  public cancelEditMode = () => {
    this.setState({
      editOpportunity: null,
      previouslySavedOpportunity: null,
      editMode: false,
    });
  };

  /**
   * Confirm local changes through modal component in edit mode.
   */
  public commit = () => {
    this.setState({
      previouslySavedOpportunity: this.state.editOpportunity,
    });
  };

  /**
   * Revert local changes through modal component in edit mode.
   */
  public undo = () => {
    this.setState({
      editOpportunity: this.state.previouslySavedOpportunity,
    });
  };

  /**
   * Saves changes when user clicks on "Save" button.
   */
  public saveChanges = () => {
    const {
      editOpportunity,
    } = this.state;

    if (editOpportunity) {
      this.handleVideoSubmit().then(() => {
        this.props.dispatch(
          sendUpdateOpportunity(
            editOpportunity,
          ),
        ).then(() => {
          this.setState({
            editOpportunity: null,
            editMode: false,
          });
        });
      });
    }
  };

  /**
   * The server expects to have skillIds and prerequisitesIds as array of numbers only,
   * skills and prerequisites array with names is just for displaying purposes.
   *
   * Tasks is stored in database as blob (array of string).
   */
  public handleChange = (field, value) => {
    const {
      data,
    } = this.props;

    const {
      editOpportunity,
    } = this.state;

    let previouslySavedOpportunity = _.cloneDeep(editOpportunity);

    if (editOpportunity) {
      if (field === "skills") {
        const skills = _.get(data, "opportunity.skillCategories[1].skills", {});
        if (Object.keys(skills).length) {
          // save ids to successfully PUT
          editOpportunity[field] = value;

          field = "skills";
          value = value.map((id) => ({
            id,
            ...skills[id],
          }));
        }

        // changes should be in packet, not single.
        previouslySavedOpportunity = _.cloneDeep(this.state.previouslySavedOpportunity);
      }

      if (field === "prerequisites") {
        const prerequisites = _.get(data, "opportunity.prerequisites", {});
        if (Object.keys(prerequisites).length) {
          // save ids to successfully PUT
          editOpportunity[field] = value;

          field = "prerequisites";
          value = value.map((id) => ({
            id,
            ...prerequisites[id],
          }));
        }

        // changes should be in packet, not single
        previouslySavedOpportunity = _.cloneDeep(this.state.previouslySavedOpportunity);
      }

      if (field === "prerequisitesIds") {
        const prerequisites = _.get(data, "opportunity.prerequisites", {});
        if (Object.keys(prerequisites).length) {
          // save ids to successfully PUT
          editOpportunity[field] = value;

          field = "prerequisites";
          value = value.map((id) => ({
            id,
            ...prerequisites[id],
          }));
        }

        // changes should be in packet, not single
        previouslySavedOpportunity = _.cloneDeep(this.state.previouslySavedOpportunity);
      }

      if (field === "prerequisitesIds") {
        const prerequisites = _.get(data, "opportunity.prerequisites", {});
        if (Object.keys(prerequisites).length) {
          // save ids to successfully PUT
          editOpportunity[field] = value;

          field = "prerequisites";
          value = value.map((id) => ({
            id,
            ...prerequisites[id],
          }));
        }
      }

      const changed = _.set(editOpportunity, field, value);

      this.setState({
        editOpportunity: {
          ...changed,
        },
        previouslySavedOpportunity: {
          ...previouslySavedOpportunity,
        },
      });
    }
  };

  handleVideoPickerDialogOpen = () => {
    this.setState({
      videoPickerDialogOpen: true,
    });
  };

  handleVideoPickerClose = (video: IVideoInput) => {
    this.setState({
      videoPickerDialogOpen: false,
      video: video,
    });
  };

  handleVideoReset = () => {
    this.setState({
      video: getEmptyVideoInput(60),
    });
  };

  handleVideoSubmit = async () => {
    // If video no selected, do nothing
    const {
      video,
    } = this.state;

    if (!video.videoSelected || !video.file) {
      return;
    }

    // Submit Video
    const {
      opportunity: {
        id,
      },
      dispatch,
    } = this.props;

    try {
      // Upload video under path "opportunity/opportunityID/"
      const uploader = new VideoUploadToS3({
        bucket: AWS_S3_VIDEO_INPUT_BUCKET || "grandshake-video-input",
      });

      const streamingKey: string = await uploader.uploadFile(
        video.file,
        `opportunity/${id}`,
        dispatch,
      );

      await updateOpportunityVideoKey(id, streamingKey);
    } catch (e) {
      // Show snackbar to indicate error
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: "Uh oh! Video upload was unsuccessful",
        },
      ));
    }
  };

  render() {
    const {
      opportunity,
      loading,
    } = this.props;

    if (loading) return (<Loading />);

    if (!opportunity) {
      return <div>
        Uh oh! Looks like the opportunity you're looking for doesn't exist.
      </div>;
    }

    const {
      classes,
      history,
      hasAccessToProfile,
      canCreateOpportunity,
      canEditOpportunity,
      canDeleteOpportunity,
      deleteDialogOpen,
      setDeleteDialogOpen,
      canReviewApplications,
      reviewingApplications,
      setReviewingApplications,
      canSubmitInterest,
      submitInterestDialogOpen,
      getApplicationListRefetch,
      setSubmitInterestDialogOpen,
      studentSubmittedInterest,
    } = this.props;

    const {
      alerted,
      editOpportunity,
      editMode,
      video,
      videoPickerDialogOpen,
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <Layout
          page="exploreOpportunity"
          noCreateOpportunity={!canCreateOpportunity}
          accessToProfile={hasAccessToProfile}
        >
          <LocalAlert
            open={alerted}
            onClose={this.closeAlert}
          />

          <div>
            <SubmitApplicationModal
              open={submitInterestDialogOpen}
              onClose={() => setSubmitInterestDialogOpen(false)}
              onSave={getApplicationListRefetch}
              opportunityId={opportunity.id}
              applicationQuestions={opportunity.application_questions}
              videoTimeLimit={60}
            />
            <DeleteModal
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              onChange={this.deleteOpportunity}
            />
            <RootBar
              goBack={this.goBack}
              deleteOpportunity={() => setDeleteDialogOpen(true)}
              editMode={editMode}
              cancelEditMode={this.cancelEditMode}
              opportunityTypes={opportunity.opportunity_types}
              canReviewApplications={canReviewApplications}
              reviewMode={reviewingApplications}
              reviewApplications={() => setReviewingApplications(true)}
              saveChanges={this.saveChanges}
              canSubmitInterest={canSubmitInterest}
              studentSubmittedInterest={studentSubmittedInterest}
              submitInterest={() => setSubmitInterestDialogOpen(true)}
            />
            {
              reviewingApplications ? (
                <ApplicationList
                  opportunityId={opportunity.id}
                  history={history}
                />
              ) :
                (
                  <Grid container spacing={24}>
                    <Grid item md={5}>
                      <Paper className={classes.paper}>
                        {/* TITLE*/}
                        {
                          editMode ? (
                            <FieldEditable
                              edit
                              value={editOpportunity && editOpportunity.title || ""}
                              size={0}
                              onChange={({
                                target: {
                                  value,
                                },
                              }) => this.handleChange("title", value)}
                            />
                          ) : (
                            <Typography variant="headline" component="h3">
                              {opportunity.title}
                            </Typography>
                          )
                        }
                        {/* DESCRIPTION */}
                        {
                          editMode ?
                            (
                              <FieldEditable
                                edit
                                value={editOpportunity && editOpportunity.description || ""}
                                size={2}
                                onChange={({
                                  target: {
                                    value,
                                  },
                                }) => this.handleChange("description", value)}
                              />
                            ) :
                            (
                              <Typography
                                variant="caption"
                                component="p"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                {opportunity.description}
                              </Typography>
                            )
                        }

                        <KeyInformation
                          classes={classes}
                          editMode={editMode}
                          editOpportunity={editOpportunity}
                          opportunity={opportunity}
                          onChange={this.handleChange}
                          undo={this.undo}
                          commit={this.commit}
                        />

                        {
                          editMode ? null : (
                            <ActionButtons
                              classes={classes}
                              canEdit={canEditOpportunity}
                              canDelete={canDeleteOpportunity}
                              onAlert={this.toggleAlert}
                              onEdit={this.toggleEditMode}
                              onDelete={() => setDeleteDialogOpen(true)}
                            />
                          )
                        }

                        {
                          (opportunity.organisation && !editMode) ? (
                            <Organisation
                              classes={classes}
                              dateOpen={opportunity.date_open}
                              organisation={opportunity.organisation}
                            />
                          ) : null
                        }

                        {
                          editMode ? null : (
                            <div
                              className={classes.paperAppendix}
                              style={{
                                background: getBackground(opportunity.opportunity_types),
                              }}
                            />
                          )
                        }
                      </Paper>
                    </Grid>
                    <Grid item md={7} className={classes.lastGridItem}>
                      {
                        editMode ?
                          (
                            <>
                              {
                                video.videoSelected ?
                                  (
                                    <>
                                      <Paper className={classes.paperUploadVideoButton}>
                                        <Button
                                          style={{
                                            width: "100%",
                                          }}
                                          onClick={this.handleVideoReset}
                                        >
                                          Clear Video
                                        </Button>
                                      </Paper>
                                      <GenericVideoFilePlayer
                                        video={video}
                                        playerHeight={310}
                                        playerWidth={550}
                                      />
                                    </>
                                  ) :
                                  (
                                    <>
                                      <Paper
                                        className={classes.paperUploadVideoButton + " " + classes.videoUploadButton}>
                                        <Button
                                          style={{
                                            width: "100%",
                                          }}
                                          onClick={this.handleVideoPickerDialogOpen}
                                        >
                                          Upload New Video
                                        </Button>
                                      </Paper>
                                      <VideoPickerDialog
                                        open={videoPickerDialogOpen}
                                        onClose={this.handleVideoPickerClose}
                                        timeLimit={60}
                                      />
                                    </>
                                  )
                              }
                            </>
                          ) :
                          (
                            <VideoStreamFromStorageKey
                              playerWidth={550}
                              playerHeight={310}
                              storageKey={opportunity.video}
                            />
                          )
                      }
                      <Paper className={classes.paper}>
                        <Details
                          classes={classes}
                          editMode={editMode}
                          editOpportunity={editOpportunity}
                          opportunity={opportunity}
                          onChange={this.handleChange}
                          commit={this.commit}
                          undo={this.undo}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                )
            }
          </div>
        </Layout>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.globals.loading || null,
    opportunity: state.opportunities.opportunityNew,
  };
};

const OpportunityPage = connect(mapStateToProps)(withRouter(OpportunityPageComponent));

export default withStyles(styles)(OpportunityPage);
