import * as React from "react";
import {
  useState,
} from "react";
import {
  gql,
} from "apollo-boost";
import {
  useQuery,
} from "@apollo/react-hooks";
import {
  Dispatch,
} from "redux";
import {
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
// Actions
import {
  newAlert,
} from "@actions/global";
// Models
import {
  ERROR,
} from "@models/organisation";
import {
  AlertLevel,
} from "@components/global.model";
// Utils
import {
  ROUTE_ADMIN_ORGANISATION,
} from "@utils/routes";
// Components
import AdminOrganisationListLayout from "@pages/admin/organisationList/AdminOrganisationListLayout";

//
// Constants
//

export const GET_ORGANISATION_LIST = gql`
    query AdminOrganisationListGetOrganisationList {
        getOrganisationList {
            id
            name
        }
    }
`;

//
// Interfaces
//

interface IProps extends RouteComponentProps {
  dispatch: Dispatch;
}

/**
 * TODO: Add graphql types. https://grandshake.atlassian.net/browse/WA-453
 * @param {Dispatch} dispatch
 * @param {History} history
 * @constructor
 */
const AdminOrganisationListData: React.FC<IProps> = ({
  dispatch,
  history,
}: IProps): React.ReactElement => {
  const [createOrganisationDialogOpen, setCreateOrganisationDialogOpen] = useState<boolean>(false);

  const {
    loading,
    error,
    data,
  } = useQuery(GET_ORGANISATION_LIST, {
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR.GET_ORGANISATION_LIST,
        },
      ));
    },
  });

  const orgList: any[] = !error && data && data.getOrganisationList ? data.getOrganisationList : [];

  const onOrganisationClick = (id: number) => {
    history.push(`${ROUTE_ADMIN_ORGANISATION}${id}`);
  };

  return (
    <AdminOrganisationListLayout
      createOrganisationDialogOpen={createOrganisationDialogOpen}
      setCreateOrganisationDialogOpen={setCreateOrganisationDialogOpen}
      loading={loading}
      data={orgList}
      onOrganisationClick={onOrganisationClick}
    />
  );
};

export default withRouter(AdminOrganisationListData);
