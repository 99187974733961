import * as React from "react";
// Style
import {
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  ChevronLeft,
} from "@material-ui/icons";
import {
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  grey,
} from "@material-ui/core/colors";
// Components
import Layout from "@components/Layout";
import StudentsTable from "@pages/students/components/StudentsTable";
import {
  theme,
} from "@utils/theme";

const styles = (theme) => ({
  pageTitle: {
    display: "inline-block",
    marginLeft: theme.spacing.unit,
    verticalAlign: "middle",
  },
  pageTitleBar: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

interface IProps {
  studentList: any[];
  loading: boolean;
  onStudentClick: (studentId: string) => void;
  onBackClick: () => void;
}

interface IExtendedProps extends IProps {
  classes: {
    pageTitle: any;
    pageTitleBar: any;
  };
}

class StudentsPageLayout extends React.Component<IExtendedProps> {
  render() {
    const {
      classes,
      loading,
      studentList,
      onStudentClick,
      onBackClick,
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Layout>
          <div className={classes.pageTitleBar}>
            <div>
              <Button
                style={{
                  color: grey[500],
                  fontWeight: 400,
                  letterSpacing: "1.2px",
                }}
                onClick={onBackClick}
              >
                <ChevronLeft style={{
                  marginRight: "0.5rem",
                }}/>
                Go Back
              </Button>

              <Typography
                variant="headline"
                className={classes.pageTitle}
              >
                Contacts
              </Typography>
            </div>

            {/* Search: https://grandshake.atlassian.net/browse/WA-247 */}
            {/* <div>*/}
            {/*    <TextField*/}
            {/*    value={this.state.search}*/}
            {/*    onChange={this.updateSearchValue}*/}
            {/*    onKeyPress={this.handleSearch}*/}
            {/*    style={{*/}
            {/*        verticalAlign: "middle",*/}
            {/*        display: "inline-block"*/}
            {/*    }}*/}
            {/*        placeholder="Search"*/}
            {/*        InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*            <InputAdornment position="start">*/}
            {/*            <Search />*/}
            {/*            </InputAdornment>*/}
            {/*        ),*/}
            {/*        }}*/}
            {/*    />*/}
            {/* </div>*/}

          </div>

          <StudentsTable
            studentList={studentList}
            loading={loading}
            onStudentClick={onStudentClick}
          />

        </Layout>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(StudentsPageLayout);

