import {
  IOrganisation,
} from "@models/organisation";

//
// Constants
//

export const ERROR_COULD_NOT_GET_APPLICATIONS_FOR_OPPORTUNITY: string = "Could not get applications for opportunity";

//
// Interfaces
//

export enum OpportunityStatus {
  APPROVED = "approved",
  PENDING = "pending",
}

export interface Organisation {
  id: number;
  name: string;
  email: string;
  description: string;
  locationId: number;
  picture: any;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
}

export interface Skill {
  id: number;
  name: string;
}

export interface TimePeriod {
  id: number;
  name: string;
}

export interface Location {
  id: number;
  address: string;
  country: string;
  postcode: number;
  state: string;
  suburb: string;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
}

export interface Contact {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  position: string;
  phoneNumber: string;
  picture: any;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
}

export interface OpportunityDuration {
  id: number;
  name: string;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
}

export interface OpportunityType {
  id: number;
  name: string;
  tooltip: string;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
}

export interface School {
  id: number;
  name: string;
}

export interface Opportunity {
  id: number;
  title: string;
  description: string;
  status: OpportunityStatus;
  extraInformation: string;
  startDate: string;
  endDate: string;
  openDate: string;
  closeDate: string;
  ongoing: boolean;
  locationId: number;
  duration: string | number;
  workingHours: number;
  opportunityTypeId: number;
  opportunityDurationId: number;
  contactId: number;
  organisationId: number;
  tasks: string[];
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  organisation: Organisation;
  skills: Skill[];
  time_periods: TimePeriod[];
  location: Location;
  contact: Contact;
  opportunityTypes: OpportunityType[];
  opportunityDuration: OpportunityDuration;
  schools: School[];
  applications: any;
  prerequisites: any;
  video: string;
}

export interface OpportunityResponse {
  status: number;
  data: Opportunity;
  error: any;
}


//
// Overhaul Interfaces
//

export interface IOpportunityWithRelations {
  id: number;

  title: string;
  description: string;
  tasks: string[];
  additional_skills: string[];
  video: string;
  hours_per_week: number;

  date_open: Date;
  date_close: Date | null;
  date_start: Date;
  date_end: Date | null;

  organisation: IOrganisation;

  location: {
    id: number;
    postcode: string;
    suburb: string;
  }
  skills: ISkill[];
  prerequisites: IPrerequisite[];
  other_prerequisite: string;
  attendance_types: IAttendanceType[];
  application_questions: IApplicationQuestion[];
  opportunity_types: IOpportunityType[];

}

export interface ISkill {
  id: number;
  name: string;
}

export interface IPrerequisite {
  id: number;
  name: string;
}

export interface IAttendanceType {
  id: number;
  name: string;
}

export interface IApplicationQuestion {
  id: number;
  question: string;
}

export interface IOpportunityType {
  id: number;
  name: string;
  tooltip?: string;
}

export enum ERole {
  STUDENT = "student",
  ORGANISATION = "organisation",
  SCHOOL = "school",
}
