import styled from "styled-components";
import {
  AppBar,
  Toolbar,
} from "@material-ui/core";
import {
  Add,
  Assignment,
  Chat,
  NotificationsNone,
  People,
} from "@material-ui/icons";

export const StyledAppBar = styled(AppBar)`
  color: rgba(0, 0, 0, 0.6);
  background: white;
`;

export const StyledToolbar = styled(Toolbar)`
  background: #fff;
  box-shadow: 0 1px 2px #FFD300;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
  z-index: 1000;

  @media (max-width: 600px) {
    height: 21px;
    min-height: 21px !important;
    background: #000DFF;
    box-shadow: none;
  }
`;

export const StyledBar = styled.div`
  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: space-between;
  height: 76px;

  @media (min-width: 960px) {
    width: 960px;
  }
`;

export const NavActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const LogoStyled = styled.img`
  margin: 0;
  padding: 0;
  height: 1.6rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const StyledAdd = styled(Add)`
  margin-right: 0.5rem;
  opacity: 0.38;
  color: #000DFF;

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

export const StyledAssignment = styled(Assignment)`
  margin-right: 0.5rem;
  opacity: 0.38;
  color: #000DFF;
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

export const StyledPeople = styled(People)`
  margin-right: 0.5rem;
  opacity: 0.38;
  color: #000DFF;
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

export const StyledChat = styled(Chat)`
  opacity: 0.38;
  color: #000DFF;
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

export const StyledNotifications = styled(NotificationsNone)`
  opacity: 0.38;
  color: #000DFF;
  margin-right: 0.5rem;
`;

export const HideOnSmall = styled.span`
  @media (max-width: 600px) {
    display: none;
  }
`;
