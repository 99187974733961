import * as React from "react";

import {
  Button,
} from "@material-ui/core";
import styled from "styled-components";

const SchoolYearButton = styled(Button)`
  flex: 1;
  && {
    margin: 1%;
  }
`;

interface IProps {
  year: number | string;
  onClick: (e: any) => any;
  selected: boolean;
}

class SchoolYearButtonComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      ...props,
    };
  }

  public render() {
    const {
      year, onClick, selected,
    } = this.props;
    return (
      <SchoolYearButton
        variant={selected ? "raised" : "outlined"}
        color="primary"
        size="large"
        onClick={() => onClick(`${year}`)}>
        Year
        {" "}
        {year}
      </SchoolYearButton>
    );
  }
}

export default SchoolYearButtonComponent;
