import * as React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";

import {
  FormControlContainer,
  FormLabel,
} from "@components/FormComponents/styled";
import {
  DataLoader,
} from "@components/DataLoader";

interface IProps {
  formErrors: any;
  formData: any;
  onChange: (e: any) => any;
  data: any;
}

class WorkingSchedule extends React.Component<IProps, any> {
  render() {
    const {
      onChange,
      formErrors,
    } = this.props;

    const data = this.props.data || {};
    console.error("Data field unused", data);

    const formData = this.props.formData || {
      duration: "1",
      opportunityDurationId: 1,
      workingHours: 1,
    };

    const {
      workingHours,
    } = formData;

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <FormControlContainer>
            <FormControl fullWidth error={!!formErrors.workingHours}>
              <FormLabel>
                Working hours
              </FormLabel>
              <FormHelperText>
                How many hours per week do you expect
              </FormHelperText>
              <DataLoader required>
                <Select
                  style={{
                    marginTop: "0.5rem",
                  }}
                  id="other-duration"
                  value={workingHours}
                  onChange={onChange("workingHours")}
                  fullWidth
                >
                  {
                    [...Array(40).keys()].map((hour) => (
                      <MenuItem key={hour} value={hour + 1}>
                        {hour + 1}
                      </MenuItem>
                    ))
                  }
                </Select>
              </DataLoader>
            </FormControl>
          </FormControlContainer>
        </Grid>
      </Grid>
    );
  }
}

export default WorkingSchedule;
