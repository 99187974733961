import * as Joi from "@hapi/joi";

//
// Type definitions
//
export type ITask = string;

//
// Schema Definitions
//
export const TaskSchema = Joi.string();
