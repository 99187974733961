import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  withRouter,
} from "react-router-dom";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add,
  ChevronLeft,
  Search,
} from "@material-ui/icons";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  grey,
} from "@material-ui/core/colors";

import {
  createContact,
} from "@actions/contacts";
import Layout from "@components/Layout";
import {
  AlertLevel,
} from "@components/global.model";
import {
  getUserRole,
} from "@utils/roles";
import {
  newAlert,
} from "@actions/global";
import NewContact from "./NewContact";
import {
  getContacts,
  getContactsSearch,
} from "@actions/school";
import {
  Dispatch,
} from "redux";
import ContactsTable from "./components/ContactsTable";
import {
  ContactsMore,
} from "./components/ContactsMore";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2626F7",
    },
  },
});

interface IProps {
  user: any;
  dispatch: Dispatch;
  history: any;
  loading: boolean;

}

interface IState {
  newContactModalShown: boolean;
  contactsMoreShown: boolean;
  search: string;
  page: number;
  contacts: any[];
  focusedContact: any;

}

class ContactsPage extends React.Component<IProps, IState> {
  state = {
    newContactModalShown: false,
    contactsMoreShown: false,
    search: "",
    page: 0,
    contacts: [],
    focusedContact: null,
  };

  // prevent to see the page for everyone who is not a school user
  UNSAFE_componentWillMount() {
    const {
      user,
    } = this.props;

    if (getUserRole(user) !== "school") {
      this.props.history.push("/");
    }
  }

  //  Initial data render. Use page: 0 and limit 20
  async componentDidMount() {
    const {
      dispatch,
    } = this.props;
    const contacts: any = await dispatch(getContacts(20, this.state.page));

    this.setState({
      ...this.state,
      contacts: contacts.data,
    });
  }

  //  Update value of search in state.
  updateSearchValue = (event) => {
    this.setState({
      ...this.state,
      search: event.target.value,
    });
  };
  //  Update value of page binded to table. Update the state, and rerequest data
  //  If the current search string is nonempty, then request data filtered.
  //  If the current search string is empty, then get the whole data
  //  ! this does introduce strange behaviour, if one add search string,
  //  ! doesn't search, but then switches page.
  onChangePage = (event, page) => {
    const {
      dispatch,
    } = this.props;

    this.setState({
      ...this.state,
      page,
    }, async () => {
      const contacts =
        this.state.search.length ?
          await dispatch(getContactsSearch(this.state.search, 20, this.state.page)) :
          await dispatch(getContacts(20, this.state.page));

      this.setState({
        ...this.state,
        contacts: contacts.data,
      });
    });
  };
  //  If new search, reset page number, and update with new data
  handleSearch = async (event) => {
    const {
      dispatch,
    } = this.props;
    if (event.key == "Enter") {
      const contacts =
        this.state.search.length ?
          await dispatch(getContactsSearch(this.state.search, 20, 0)) :
          await dispatch(getContacts(20, 0));

      this.setState({
        ...this.state,
        contacts: contacts.data,
        page: 0,
      });
    }
  };

  goBack = () => {
    this.props.history.push("/");
  };

  showModal = () => {
    this.setState({
      newContactModalShown: true,
    });
  };

  hideModal = () => {
    this.setState({
      newContactModalShown: false,
    });
  };

  addContact = (data) => () => {
    this.props.dispatch(createContact(data))
      .then(() => {
        this.hideModal();

        this.props.dispatch(newAlert({
          level: AlertLevel.SUCCESS,
          body: "New organisation user has successfully created",
        }));
      })
      .catch(() => {
        this.hideModal();

        this.props.dispatch(newAlert({
          level: AlertLevel.ERROR,
          body: "An error occured while creating new organisation user",
        }));
      });
  };
  openContactsMore = (id: number): void => {
    console.info(id);
    const contacts: any[] = this.state.contacts;
    let contact: any;
    for (const c of contacts) {
      if (c.contact_id == id) {
        contact = c;
        break;
      }
    }
    if (!contact) return;
    this.setState({
      ...this.state,
      focusedContact: contact,
      contactsMoreShown: true,
    });
  };
  closeContactsMore = (): void => {
    this.setState({
      ...this.state,
      focusedContact: null,
      contactsMoreShown: false,
    });
  };

  render() {
    const {
      newContactModalShown,
      contactsMoreShown,
      focusedContact,
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <Layout
          page="exploreContacts"
        >
          <NewContact
            open={newContactModalShown}
            onClose={this.hideModal}
            onChange={this.addContact}
          />

          <ContactsMore
            open={contactsMoreShown}
            onClose={this.closeContactsMore}
            contact={focusedContact}
          />


          { /* TODO so, move this to separate component as it's already described as RootBar component */}
          { /* But client said that this functionality is temporary and in future we will have brand new dashboard for the purposes */}
          <div
            style={{
              display: "flex",
              flexBasis: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <div>
              <Button
                style={{
                  color: grey[500],
                  fontWeight: 400,
                  letterSpacing: "1.2px",
                }}
                onClick={this.goBack}
              >
                <ChevronLeft style={{
                  marginRight: "0.5rem",
                }}/>
                {" "}
                Go Back
              </Button>
              <Typography
                variant="headline"
                component="p"
                style={{
                  display: "inline-block",
                  fontWeight: 400,
                  verticalAlign: "middle",
                  fontSize: "20px",
                  fontFamily: "Raleway, sans-serif",
                  marginLeft: "12px",
                }}
              >
                Contacts
              </Typography>
            </div>

            <div>
              <Button
                style={{
                  color: grey[500],
                  fontWeight: 400,
                  letterSpacing: "1.2px",
                }}
                onClick={this.showModal}
              >
                <Add
                  style={{
                    marginRight: "0.5rem",
                    color: "#000DFF",
                    opacity: 0.87,
                  }}
                />
                ADD NEW
              </Button>

              <TextField
                value={this.state.search}
                onChange={this.updateSearchValue}
                onKeyPress={this.handleSearch}
                style={{
                  verticalAlign: "middle",
                  display: "inline-block",
                  marginLeft: "1.0rem",
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search/>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <ContactsTable
            contacts={this.state.contacts || []}
            page={this.state.page}
            onChangePage={this.onChangePage}
            loading={this.props.loading}
            openContactsMore={this.openContactsMore}
          />

        </Layout>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    loading: state.globals.loading,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
  )(ContactsPage) as any,
);
