import * as React from "react";
import {
  Avatar,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  withStyles,
} from "@material-ui/core";

import {
  IMultipageFormProps,
  IMultipageFormState,
  MultipageFormPage,
} from "@components/FormComponents";
import {
  connect,
} from "react-redux";
import {
  FormControlContainer,
} from "@components/FormComponents/styled";
import {
  DataLoader,
} from "@components/DataLoader";
import {
  yellow,
} from "@material-ui/core/colors";

interface IProps extends IMultipageFormProps {
  classes: any;
  data: any;
  formData: any;
  formErrors: any;
  onChange: (e: any) => any;
  onChangeArray: (e: any) => any;
}

interface IState extends IMultipageFormState {
}

const styles: any = (theme: Theme): any => createStyles({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  listItem: {
    cursor: "pointer",
  },
  listItemSelected: {
    cursor: "pointer",
    background: yellow[600],
  },
});

class PrescreeningPage extends MultipageFormPage<IProps, IState> {
  public updateApplicationQuestions = (id: number) => {
    const applicationQuestions: any[] = this.props.formData.applicationQuestionIds;
    let found = false;
    for (let i = 0; i < applicationQuestions.length; i++) {
      if (applicationQuestions[i] === id) {
        applicationQuestions.splice(i, 1);
        found = true;
        break;
      }
    }
    if (!found) {
      applicationQuestions.push(id);
    }
    this.props.onChangeArray("applicationQuestionIds")({
      target: {
        value: applicationQuestions,
      },
    });
  };

  public render() {
    const {
      classes, data,
    } = this.props;
    const application_questions = data.application_questions || [];
    return (
      <form className={classes.root}>
        <DataLoader required>
          <FormControlContainer>
            <FormControl fullWidth>
              <FormLabel>
                Prescreening Questions
              </FormLabel>
              <FormHelperText>
                Select up to 3 questions from the list. Applicants will submit a short, 60 second video response
                attached to their application.
              </FormHelperText>
            </FormControl>
          </FormControlContainer>
          <List>
            {application_questions.map((application_question, i) => (
              <React.Fragment key={i}>
                <ListItem
                  className={this.props.formData.applicationQuestionIds.includes(application_question.id) ? classes.listItemSelected : classes.listItem}
                  onClick={() => this.updateApplicationQuestions(application_question.id)}
                  key={application_question.id}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {i + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    {application_question.question}
                  </ListItemText>
                </ListItem>
                <Divider/>
              </React.Fragment>

            ))}
          </List>
        </DataLoader>
      </form>
    );
  }
}

const StyledComponent = withStyles(styles)(PrescreeningPage);

const mapStateToProps = (state: any) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledComponent);
