import * as React from "react";
import {
  isEmpty,
} from "lodash";
import styled from "styled-components";
// Material Ui
import {
  Button,
  Typography,
} from "@material-ui/core";
import {
  grey,
  indigo,
  red,
} from "@material-ui/core/colors";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
// Components
import {
  FormErrors,
} from "@components/FormComponents";
import {
  FormActions,
  FormPage,
  FormPageContainer,
  FormPaper,
} from "@components/FormComponents/styled";
import ProgressButton from "@components/ProgressButton";
import Page1 from "./Page1";
import Page2 from "./Page2";
// Utils
import {
  history,
} from "@utils/history";
import {
  confirmSignUp,
  forgotPassword,
  forgotPasswordSubmit,
  signIn,
} from "@utils/auth";

const SigninFormContainer = styled.div`
  width: 60%;
  max-width: 600px;
  transition: width 100ms linear;
  @media(max-width: 768px) {
    margin-top: 2rem;
    width: 90%;
  }
`;

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: red,
  },
});

class SigninFormComponent extends React.Component<any, any> {
  public state = {
    currentPage: 1,
    forgotPassword: false,
    forgotPasswordReset: false,
    formData: {
      code: "",
      email: "",
      password: "",
      newPassword: "",
    },
    helperText: "",
    formErrors: {},
    submitting: false,
    error: false,
  };
  public setFieldValue = (field) => (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: e.target ? e.target.value : e,
      },
    });
  };

  public loginButtonClick = () => {
    const {
      email, password,
    } = this.state.formData;
    const formErrors: any = {};
    if (!email) {
      formErrors.email = "Email is required";
    }
    if (!password) {
      formErrors.password = "Password is required";
    }
    if (isEmpty(formErrors)) {
      this.setState({
        submitting: true,
      }, this.loginAction);
    } else {
      this.setState({
        formErrors,
      });
    }
  };

  public verifyButtonClick = () => {
    this.setState({
      submitting: true,
    }, this.verifyAction);
  };

  public loginAction = async () => {
    const {
      email,
      password,
    } = this.state.formData;

    try {
      await signIn(email, password);

      await this.setState({
        submitting: false,
      });

      this.gotoProfilePage();
    } catch (e) {
      switch (e.code) {
      case "UserNotConfirmedException":
        this.setState({
          currentPage: 2,
          submitting: false,
        });
        break;
      case "NotAuthorizedException":
      case "UserNotFoundException":
        this.setState({
          formErrors: {
            confirm: "Invalid username or password",
          },
          submitting: false,
        });
        break;
      default:
        console.error("Unknown error code for attempted auth", e);
        this.setState({
          formErrors: {
            confirm: "An unknown error occurred",
          },
          submitting: false,
        });
        break;
      }
    }
  };

  public verifyAction = async () => {
    const {
      code,
      email,
    } = this.state.formData;

    try {
      await confirmSignUp(email, code);

      await this.loginAction();
    } catch (e) {
      await this.setState((prevState) => ({
        formErrors: {
          ...prevState.formErrors,
          confirm: "An unknown error occurred",
        },
        submitting: false,
      }));
    }
  };

  public forgotPasswordClick = async () => {
    const {
      email,
    } = this.state.formData;

    await this.setState({
      submitting: true,
      error: false,
    });

    try {
      await forgotPassword(email);

      await this.setState({
        submitting: false,
        helperText: "Password reset instructions have been emailed to " + email,
        currentPage: 2,
        forgotPasswordReset: true,
      });
    } catch (e) {
      switch (e.code) {
      case "LimitExceededException":
        this.setState({
          submitting: false,
          error: true,
          helperText: "You've tried to reset too many times. Try again later.",
        });
        break;
      case "UserNotFoundException":
        this.setState({
          submitting: false,
          error: true,
          helperText: "This email address has not been registered with Grandshake",
        });
        break;
      case "InvalidParameterException":
        this.setState({
          submitting: false,
          error: true,
          helperText: "This user has still not been confirmed. Please check your inbox.",
        });
        break;
      default:
        this.setState({
          submitting: false,
          error: true,
          helperText: "An unknown error occurred. Please try again later.",
        });
        break;
      }
    }
  };

  public changePassword = async () => {
    const {
      code,
      email,
      newPassword,
    } = this.state.formData;

    // Check all details have been given
    if (!code || !email || !newPassword) {
      await this.setState({
        error: true,
        helperText: "Please enter a new password.",
      });
    }

    try {
      // Submit new password
      await forgotPasswordSubmit(email, code, newPassword);

      await this.setState((prevState) => ({
        error: false,
        currentPage: 1,
        forgotPassword: false,
        formData: {
          ...prevState.formData,
          password: "",
          newPassword: "",
          code: "",
        },
        forgotPasswordReset: false,
        helperText: "Successfully reset password. Login above.",
      }));
    } catch (e) {
      console.error("Forgot Password Submit", e);
      switch (e.code) {
      case "CodeMismatchException":
        this.setState({
          error: true,
          helperText: "Incorrect code. Please check your email again for the code.",
        });
        break;
      case "InvalidParameterException":
        this.setState({
          error: true,
          helperText: "Invalid password (Length min 8, an uppercase)",
        });
        break;
      case "InvalidPasswordException":
        this.setState({
          error: true,
          helperText: "Invalid password (Length min 8, an uppercase)",
        });
        break;
      case "ExpiredCodeException":
        this.setState({
          error: true,
          helperText: "Invalid code provided, please request a code again.",
        });
        break;
      }
    }
  };

  public gotoProfilePage() {
    history.push("/");
  }

  public toggleForgotPassword = () => {
    const forgotPassword = !this.state.forgotPassword;
    this.setState({
      ...this.state,
      forgotPassword,
      forgotPasswordReset: false,
      currentPage: this.state.forgotPasswordReset ? 1 : this.state.currentPage,
      error: false,
      helperText: "",
    });
  };

  public backPage = () => {
    this.setState({
      currentPage: this.state.currentPage - 1,
    });
  };

  public render() {
    const {
      currentPage, formData, formErrors, forgotPassword, submitting, forgotPasswordReset,
    } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <SigninFormContainer>

          <FormPaper elevation={0}>

            <FormPageContainer style={{
              flexDirection: "column",
              display: "flex",
            }}>
              <FormPage show={currentPage === 1}>
                <Page1
                  formData={formData}
                  formErrors={formErrors}
                  forgotPassword={forgotPassword}
                  toggleForgotPassword={this.toggleForgotPassword}
                  login={this.loginButtonClick}
                  onChange={this.setFieldValue}
                />
              </FormPage>
              <FormPage show={currentPage === 2}>
                <Page2
                  formData={formData}
                  formErrors={formErrors}
                  onChange={this.setFieldValue}
                  forgotPasswordReset={this.state.forgotPasswordReset}
                />
              </FormPage>
              {this.state.helperText.length > 0 && (<Typography variant="caption" style={{
                marginBottom: "1.5rem",
                color: this.state.error ? red[500] : grey[800],
              }}>
                {this.state.helperText}
              </Typography>)}
            </FormPageContainer>

            <FormErrors formErrors={formErrors}/>

            <FormActions>
              {(forgotPassword && !forgotPasswordReset) && (
                <React.Fragment>
                  <Button size="large" onClick={this.toggleForgotPassword}>
                    Sign In
                  </Button>
                  <ProgressButton
                    size="large"
                    variant="raised"
                    color="primary"
                    disabled={submitting}
                    loading={submitting}
                    onClick={this.forgotPasswordClick}
                  >
                    Forgot Password
                  </ProgressButton>
                </React.Fragment>
              )}
              {(forgotPassword && forgotPasswordReset) && (
                <React.Fragment>
                  <Button size="large" onClick={this.toggleForgotPassword}>
                    Sign In
                  </Button>
                  <ProgressButton
                    size="large"
                    variant="raised"
                    color="primary"
                    disabled={submitting}
                    loading={submitting}
                    onClick={this.changePassword}
                  >
                    Change password
                  </ProgressButton>
                </React.Fragment>
              )}
              {!forgotPassword && (currentPage === 1) && (
                <React.Fragment>
                  <Button size="large" onClick={() => history.push("/student/signup")}>
                    Sign Up
                  </Button>
                  <ProgressButton
                    size="large"
                    variant="raised"
                    color="primary"
                    disabled={submitting}
                    loading={submitting}
                    onClick={this.loginButtonClick}
                  >
                    Sign In
                  </ProgressButton>
                </React.Fragment>
              )}
              {!forgotPassword && currentPage === 2 && (
                <React.Fragment>
                  <Button size="large" onClick={this.backPage}>
                    Sign Up
                  </Button>
                  <ProgressButton
                    size="large"
                    variant="raised"
                    color="primary"
                    disabled={submitting}
                    loading={submitting}
                    onClick={this.verifyButtonClick}
                  >
                    Continue
                  </ProgressButton>
                </React.Fragment>
              )}
            </FormActions>
          </FormPaper>

        </SigninFormContainer>
      </MuiThemeProvider>
    );
  }
}

export default SigninFormComponent;
