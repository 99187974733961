import * as React from "react";
import {
  useState,
} from "react";
import {
  Dispatch,
} from "redux";
import {
  useQuery,
} from "@apollo/react-hooks";
import {
  gql,
} from "apollo-boost";
import {
  RouteComponentProps,
} from "react-router";
import {
  withRouter,
} from "react-router-dom";
// Models
import {
  hasOrganisationRole,
  hasSchoolRole,
  hasStudentRole,
  useRoles,
} from "@utils/roles";
import {
  ERROR_COULD_NOT_GET_APPLICATIONS_FOR_OPPORTUNITY,
} from "@models/opportunity";
// Actions
import {
  newAlert,
} from "@actions/global";
// Components
import {
  AlertLevel,
} from "@components/global.model";
import OpportunityPage from "./OpportunityPageLayout";

interface IMatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  dispatch: Dispatch;
}

// Used to check if a student has already applied
const GET_APPLICATION_LIST_QUERY = gql`
    query GetApplicationListQuery($id: Int!)
    {
        getApplicationList(opportunity: {id: $id}) {
            createdAt
        }
    }
`;

const OpportunityPageData: React.FC<IProps> = (props: IProps) => {
  const {
    match: {
      params: {
        id: opportunityId,
      },
    },
    location,
    dispatch,
  } = props;

  // Setup getApplicationListQuery
  const {
    loading: getApplicationListLoading,
    error: getApplicationListError,
    data: getApplicationListData,
    refetch: getApplicationListRefetch,
  } = useQuery(GET_APPLICATION_LIST_QUERY, {
    variables: {
      id: Number(opportunityId),
    },
    onError: (error) => {
      console.error(error.message);
      dispatch(newAlert(
        {
          level: AlertLevel.ERROR,
          body: ERROR_COULD_NOT_GET_APPLICATIONS_FOR_OPPORTUNITY,
        },
      ));
    },
  });

  // Get current user roles
  const {
    roleList,
  } = useRoles();

  // Came from profile page
  const toApplicationsPage = location && location.state && location.state.viaApplications === true;

  // Setup state for dialogs
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [submitInterestDialogOpen, setSubmitInterestDialogOpen] = useState(false);
  const [reviewingApplications, setReviewingApplications] = useState(toApplicationsPage);

  // Get user roles - used for permissions and the student already applied check
  const isStudent: boolean = hasStudentRole(roleList);
  const isOrgUser: boolean = hasOrganisationRole(roleList);
  const isSchoolUser: boolean = hasSchoolRole(roleList);

  // If student, check if they have already applied
  let studentSubmittedInterest: boolean = false;
  if (isStudent) {
    if (
      !getApplicationListLoading &&
      !getApplicationListError &&
      getApplicationListData &&
      getApplicationListData.getApplicationList &&
      getApplicationListData.getApplicationList.length != 0
    ) {
      studentSubmittedInterest = true;
    }
  }

  return (
    <OpportunityPage
      hasAccessToProfile={isStudent}
      canSubmitInterest={isStudent}
      studentSubmittedInterest={studentSubmittedInterest}
      getApplicationListRefetch={getApplicationListRefetch}
      submitInterestDialogOpen={submitInterestDialogOpen}
      setSubmitInterestDialogOpen={setSubmitInterestDialogOpen}
      canReviewApplications={isOrgUser || isSchoolUser}
      reviewingApplications={reviewingApplications}
      setReviewingApplications={setReviewingApplications}
      canCreateOpportunity={isOrgUser}
      canEditOpportunity={isSchoolUser || isOrgUser}
      canDeleteOpportunity={isOrgUser}
      deleteDialogOpen={deleteDialogOpen}
      setDeleteDialogOpen={setDeleteDialogOpen}
    />
  );
};

export default withRouter(OpportunityPageData);
