import {
  get,
} from "lodash";
import * as React from "react";
import {
  connect,
} from "react-redux";
import styled from "styled-components";
import moment from "moment";
import Amplify from "aws-amplify";

import {
  Button,
  Grid,
} from "@material-ui/core";
import {
  red,
} from "@material-ui/core/colors";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_S3_VIDEO_INPUT_BUCKET,
} from "@utils/env";
// Components
import {
  FormErrors,
  IMultipageProps,
  IMultipageState,
  MultipageForm,
} from "@components/FormComponents";
import {
  FormActions,
  FormPageContainer,
} from "@components/FormComponents/styled";
import ProgressButton from "@components/ProgressButton";
import {
  isEmptyArray,
} from "@utils/validation";
// Pages
import KeyDetails from "./KeyDetails";
import Preview from "./Preview";
import Prescreening from "./Prescreening";
import UploadVideo from "./UploadVideo";
import ShareWith from "./ShareWith";

import CreatingSteps from "./CreatingSteps";
//  TODO move this to the appropriate models directory.
import {
  ICreateOpportunityFormData,
} from "@actions/opportunity";
import {
  Dispatch,
} from "redux";
import {
  clearLastLocation,
  clearNewOpportunityInProgress,
  getNewOpportunityInProgress,
  setNewOpportunityInProgress,
} from "@utils/local.storage";
import {
  createOpportunityNew,
  IHTTPResponse,
  updateOpportunityVideoKey,
} from "@utils/api";
import {
  newAlert,
} from "@actions/global";
import {
  AlertLevel,
} from "@components/global.model";
import {
  withRouter,
} from "react-router";
import {
  ContinueModal,
} from "./ContinueModal";
import VideoUploadToS3 from "@components/UploadVideoInputComponent/utils/VideoUploadToS3";


const pages = [KeyDetails, Prescreening, ShareWith, UploadVideo, Preview];

const NewOpportunityForm = styled.div`
  padding-bottom: 3rem;
`;

const requiredFields = {
  1: {
    description: "Description",
    locationId: "Location",
    opportunityTypeIds: "Opportunity Type",
    prerequisitesIds: "Prerequisites",
    skillIds: "skillIds",
    timePeriodIds: "When",
    title: "Title",
    workingHours: "Working Hours",
    tasks: "tasks",
  },
  2: {
    applicationQuestionIds: "Application Questions",
  },
  3: {},
  4: {
    pathToLocalVideo: "pathToLocalVideo",
  },
  5: {},
};

const errorMessages = {
  description: "Short description required",
  locationId: "Location is required",
  opportunityTypeIdsMin: "At least one opportunity type is required",
  opportunityTypeIdsMax: "More than three opportunity types have been selected",
  applicationQuestionIds: "More than three application questions have been selected",
  prerequisitesIds: "\"Other\" prerequisite requires a value",
  skillIdsMin: "At least one skill is required",
  skillIdsMax: "More than twelve skills have been selected",
  tasksMin: "At least one task is required",
  tasksMax: "More than twelve tasks have been listed",
  timePeriodIds: "Time period is required",
  title: "Title is required",
  workingHours: "Working Hours is required",
  pathToLocalVideo: (limit: number) => `Selected video exceeds time limit of ${limit} seconds or the 100MB size limit`,
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2626F7",
    },
    secondary: red,
  },
});

interface IProps extends IMultipageProps {
  opportunityId: string;
  dispatch: Dispatch;
  history: any;
  user: any;
}

interface IState extends IMultipageState {
  organisation: any;
  opportunityId: string | null;
  responseData: any;
  formErrors: any;
  submitting: boolean;
  displayContinueModal: boolean;
}

const today = moment().startOf("day");
const tomorrow = today.clone().add(1, "day");

const defaultState: IState = {
  currentPage: 1,
  formData: {

    title: "",
    description: "",
    extraInformation: "",

    startDate: today.toISOString(),
    closeDate: tomorrow.toISOString(),
    endDate: today.toISOString(),
    ongoing: false,

    tasks: [],
    workingHours: [],

    locationId: null,

    prerequisitesIds: [],
    otherPrerequisite: "",
    applicationQuestionIds: [],
    opportunityTypeIds: [],
    skillIds: [],
    timePeriodIds: [],
    skillsToAdd: [],

    shareWith: [],

    pathToLocalVideo: {
      videoSelected: false,
      timeLimit: 60,
    },

  },
  formErrors: {},
  opportunityId: null,
  organisation: {},
  pageCount: pages.length,
  responseData: {},
  submitting: false,
  displayContinueModal: false,
};

class NewOpportunityFormComponent extends MultipageForm<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      ...props,
    };

    Amplify.configure({
      Storage: {
        bucket: AWS_S3_VIDEO_INPUT_BUCKET,
        region: AWS_REGION,
        identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
      },
    });
  }

  public componentDidMount() {
    //  check if anything exists in local storage or in redux
    const priorCreateOppCache: {
      page: number,
      formData: ICreateOpportunityFormData,
    } | null = getNewOpportunityInProgress();

    if (priorCreateOppCache) {
      this.setState({
        ...this.state,
        formData: priorCreateOppCache.formData,
        currentPage: priorCreateOppCache.page,
        displayContinueModal: true,
      });
    }
  }

  public requiredFields = (fields) => {
    const {
      formData,
    } = this.state;
    const required = {};

    for (const field of Object.keys(fields)) {
      const fieldData = get(formData, field);

      if (field === "opportunityTypeIds") {
        if (!Array.isArray(fieldData) || fieldData.length < 1) {
          required[field] = errorMessages["opportunityTypeIdsMin"];
        } else if (fieldData.length > 3) {
          required[field] = errorMessages["opportunityTypeIdsMax"];
        }
      }

      if (field === "prerequisitesIds" && fieldData.includes(8) && !formData.otherPrerequisite.length) {
        required[field] = errorMessages[field];
      }

      // If field is a video, validate it's length
      if (field === "pathToLocalVideo") {
        const videoSizeLimit: number = 100; // MB
        const videoTooBig = fieldData.file && ((fieldData.file.size / 1024) / 1024) > videoSizeLimit; // MB
        if ((fieldData.videoSelected && fieldData.duration > fieldData.timeLimit) || videoTooBig) {
          required[field] = errorMessages[field](fieldData.timeLimit);
        }
      }

      if (field === "skillIds") {
        if (!Array.isArray(fieldData) || fieldData.length < 1) {
          required[field] = errorMessages["skillIdsMin"];
        } else if (fieldData.length > 12) {
          required[field] = errorMessages["skillIdsMax"];
        }
      }

      if (field === "tasks") {
        if (!Array.isArray(fieldData) || fieldData.length < 1) {
          required[field] = errorMessages["tasksMin"];
        } else if (fieldData.length > 12) {
          required[field] = errorMessages["tasksMax"];
        }
      }

      if (field === "applicationQuestionIds") {
        if (!Array.isArray(fieldData) || fieldData.length > 3) {
          required[field] = errorMessages[field];
        }
      }

      if (!fieldData) {
        required[field] = errorMessages[field];
      } else if (fieldData.constructor === Array) {
        if (
          isEmptyArray(fieldData) &&
          field !== "prerequisitesIds" &&
          field !== "tasks" &&
          field !== "skillIds" &&
          field !== "applicationQuestionIds" &&
          field !== "opportunityTypeIds"
        ) {
          required[field] = errorMessages[field];
        }
      }
    }

    return required;
  };

  public validateForm = () => {
    const {
      currentPage,
    } = this.state;
    const formErrors = this.requiredFields(requiredFields[currentPage]);

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  };

  public updateInLocalStorage = () => {
    setNewOpportunityInProgress(this.state.formData, this.state.currentPage);
  };

  /**
   * Tool to upload a video belonging to an opportunity.
   * @param {File} file - video to upload
   * @param {number} key - bucket key for video
   */
  private uploadFile = async (file: File, key: string) => {
    const {
      dispatch,
    } = this.props;

    const uploader = new VideoUploadToS3({
      bucket: AWS_S3_VIDEO_INPUT_BUCKET || "grandshake-video-input",
    });

    return await uploader.uploadFile(file, key, dispatch);
  };

  public attemptSubmit() {
    const {
      formData,
    } = this.state;
    const dispatch: Dispatch = this.props.dispatch;

    //  TODO this is a temporary layer of transporting over the formData to the correct
    //  TODO payload. Will need to in future change the internal form fields to be of
    //  TODO correct name
    const createNewOpportunityData: any = {
      title: formData.title,
      description: formData.description,

      tasks: formData.tasks.filter((task) => task !== ""), // ensure no empty tasks are sent
      other_prerequisite: formData.otherPrerequisite,
      //  TODO implement in frontend
      date_open: "1970-01-01",
      date_close: formData.closeDate,
      date_start: formData.startDate,
      //  TODO implement in frontend
      date_end: formData.endDate,
      ongoing: formData.ongoing,
      hours_per_week: formData.workingHours,
      additional_skills: formData.skillsToAdd,
      share_with: formData.shareWith,
      location_id: formData.locationId,
      skill_ids: formData.skillIds,
      prerequisite_ids: formData.prerequisitesIds,
      attendance_type_ids: formData.timePeriodIds,
      application_question_ids: formData.applicationQuestionIds,
      opportunity_type_ids: formData.opportunityTypeIds,
    };

    this.setState({
      ...this.state,
      submitting: true,
    }, async () => {
      try {
        const newOpportunityResponse: IHTTPResponse<any> = await createOpportunityNew(createNewOpportunityData);
        const id: number = newOpportunityResponse.data[0].id;

        // Upload Video, if present
        const file = formData && formData.pathToLocalVideo && formData.pathToLocalVideo.file ? formData.pathToLocalVideo.file : null;

        if (file) {
          try {
            const videoPath: string = `opportunity/${id}`;
            const streamingKey = await this.uploadFile(file, videoPath);
            await updateOpportunityVideoKey(id, streamingKey);
          } catch (e) {
            dispatch(newAlert(
              {
                level: AlertLevel.ERROR,
                body: "Uh oh! Video upload was unsuccessful",
              },
            ));
          }
        }

        clearNewOpportunityInProgress();
        clearLastLocation();
        this.props.history.push(`/opportunity/${id}`);
      } catch (e) {
        console.error(e);

        dispatch(newAlert({
          level: AlertLevel.ERROR,
          body: "Uh oh! Something went wrong. Submit again?",
        }));
      }
    });
  }

  public gotoNextPage = () => {
    if (!this.validateForm()) return;

    if (this.state.currentPage === 5) {
      this.attemptSubmit();
      return;
    }

    this.setState({
      ...this.state,
      currentPage: this.state.currentPage + 1,
      formErrors: {},
    }, () => this.updateInLocalStorage());
  };

  public gotoPreviousPage = () => {
    this.setState({
      ...this.state,
      currentPage: this.state.currentPage - 1,
    }, () => this.updateInLocalStorage());
  };

  public startNew = () => {
    clearLastLocation();
    clearNewOpportunityInProgress();

    this.setState({
      ...this.state,
      ...defaultState,
    });
  };

  public continue = () => {
    this.setState({
      ...this.state,
      displayContinueModal: false,
    });
  };

  public render() {
    const {
      formErrors,
      currentPage,
      organisation,
      displayContinueModal,
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <ContinueModal
          open={displayContinueModal}
          onClose={this.continue}
          onStartNew={this.startNew}
        />
        <CreatingSteps currentPage={currentPage}/>
        <NewOpportunityForm>
          <Grid container justify="center">
            <Grid item xs={10}>
              <FormPageContainer>
                {this.renderPages(pages, {
                  organisation,
                })}
              </FormPageContainer>

              <FormErrors formErrors={formErrors}/>

              <FormActions
                style={{
                  marginTop: "4rem",
                  justifyContent: "flex-end",
                }}
              >
                <Button size="large" onClick={this.gotoPreviousPage}>
                  Previous
                </Button>

                <ProgressButton
                  size="large"
                  variant="raised"
                  color="primary"
                  disabled={false}
                  loading={false}
                  onClick={this.gotoNextPage}
                >
                  Next
                </ProgressButton>
              </FormActions>
            </Grid>
          </Grid>
        </NewOpportunityForm>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user || null,
  };
};

export default withRouter(connect(mapStateToProps)(NewOpportunityFormComponent) as unknown as any);
