import {
  Grid,
  Typography,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";

import * as React from "react";

import styled from "styled-components";
// Components
import WebPreview from "@components/WebPreview";
import OpportunityItem from "../OpportunityItem";

import {
  IMultipageFormProps,
  IMultipageFormState,
  MultipageFormPage,
} from "@components/FormComponents";

const NewOpportunityFormPage5 = styled.div``;

const OpportunityItemWrapper = styled(Grid)`
  background: ${grey[200]};
`;

interface IProps extends IMultipageFormProps {
  formData: any;
  formErrors: any;
  onChange: (e: any) => any;
  responseData: any;

}

interface IState extends IMultipageFormState {
  selectedDate?: any;
  selectedSkillCategory: string | number;
}

class NewOpportunityFormPage5Component extends MultipageFormPage<IProps, IState> {
  public render() {
    const {
      formData,
    } = this.props;

    return (
      <NewOpportunityFormPage5>
        <Typography variant="title" gutterBottom>
          Last step - Preview
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please review and confirm the opportunity listing, before it goes live.
        </Typography>

        <WebPreview style={{
          margin: "2rem 0",
        }}>
          <OpportunityItemWrapper container justify="center">
            <Grid item xs={10}>
              <OpportunityItem opportunity={formData}/>
            </Grid>
          </OpportunityItemWrapper>
        </WebPreview>
      </NewOpportunityFormPage5>
    );
  }
}

export default NewOpportunityFormPage5Component;
