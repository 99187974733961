import SchoolSearch from "@components/SchoolSearch";
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  grey,
} from "@material-ui/core/colors";
import {
  Email,
} from "@material-ui/icons";
import * as React from "react";
import styled from "styled-components";
// Components
import {
  FormControlContainer,
  IconWrapper,
} from "@components/FormComponents/styled";
import {
  formDataDefault,
} from "./index";
import SchoolYearButton from "./SchoolYearButton";

const SignupFormPage2 = styled.section``;

const SchoolYearButtons = styled.div`
  display: flex;
`;

const SignupFormLabel = styled.label`
  margin: 2rem 0 1rem 0;
  color: ${grey[600]};
`;

interface IProps {
  formErrors: any;
  formData: any;
  onChange: (e: any) => any;
  validateForm: (e: any) => any;
}

class SignupFormPage2Component extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      formData: formDataDefault,
      selectedDate: null,
      ...props,
    };
  }

  public render() {
    const {
      onChange, formErrors,
    } = this.props;
    const {
      schoolEmail, school, schoolYear,
    } = this.props.formData;

    return (
      <SignupFormPage2>
        <Typography variant="headline" gutterBottom>
          Connect with your school
        </Typography>
        <Typography variant="subheading">
          Help us connect you with your school.
        </Typography>
        <FormControlContainer error={!!formErrors["school"]} style={{
          marginBottom: "0.5rem",
        }}>
          <FormControl fullWidth>
            <SchoolSearch
              id="schoolName"
              required
              type={"text"}
              value={school.name}
              onChange={onChange("school")}
              label="Find my school"
            />
          </FormControl>
        </FormControlContainer>
        <Typography>
          Not on the list?
        </Typography>
        <FormControlContainer error={!!formErrors.schoolYear}>
          <SignupFormLabel>
            Current School Year
          </SignupFormLabel>
          <SchoolYearButtons style={{
            marginTop: "1rem",
          }}>
            <SchoolYearButton
              year={9}
              selected={schoolYear === "9"}
              onClick={(year) => onChange("schoolYear")(year)}
            />
            <SchoolYearButton
              year={10}
              selected={schoolYear === "10"}
              onClick={(year) => onChange("schoolYear")(year)}
            />
          </SchoolYearButtons>
          <SchoolYearButtons>
            <SchoolYearButton
              year={11}
              selected={schoolYear === "11"}
              onClick={(year) => onChange("schoolYear")(year)}
            />
            <SchoolYearButton
              year={12}
              selected={schoolYear === "12"}
              onClick={(year) => onChange("schoolYear")(year)}
            />
          </SchoolYearButtons>
        </FormControlContainer>

        <FormControlContainer style={{
          marginBottom: "0.5rem",
        }}>
          <FormControl fullWidth error={!!formErrors.schoolEmail}>
            <InputLabel htmlFor="email">
              School Email (eq.edu.au address)
            </InputLabel>
            <Input
              id="schoolEmail"
              type={"text"}
              value={schoolEmail}
              onChange={onChange("schoolEmail")}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconWrapper>
                    <Email/>
                  </IconWrapper>
                </InputAdornment>
              }
            />
          </FormControl>
        </FormControlContainer>
        <Typography style={{
          marginBottom: "0.5rem",
        }}>
          For verification purposes only
        </Typography>

      </SignupFormPage2>
    );
  }
}

export default SignupFormPage2Component;
