import {
  CircularProgress,
} from "@material-ui/core";
import * as React from "react";
import {
  connect,
} from "react-redux";
import {
  isEmpty,
} from "lodash";

import {
  setData as setDataMethod,
} from "@models/data";
import {
  getDataNew,
} from "@utils/api";

import SpinnerWrapper from "./styled";

interface IProps {
  children?: React.ReactNode;
  data?: any; // Redux
  setData?: (data: any) => any; // Redux
  required?: boolean;
}

class DataLoaderComponent extends React.Component<IProps, any> {
  public hydrateData() {
    const {
      data, setData,
    } = this.props;
    if (!isEmpty(data)) {
      return;
    }

    getDataNew()
      .then((results: any) => (setData && results) ? setData(results.data[0]) : null)
      .catch((e) => console.warn("DataLoader: error getting data", e));

    return;
  }

  public render() {
    this.hydrateData();
    const children: any = this.props.children || null;
    const required = this.props.required || false;
    const data = this.props.data || {};
    if (required) {
      return required && !isEmpty(data) ?
        children :
        <SpinnerWrapper>
          <CircularProgress size={48}/>
        </SpinnerWrapper>;
    }

    return children;
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setData(data) {
      return dispatch(setDataMethod(data));
    },
  };
};

export const DataLoader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataLoaderComponent);
