import {
  Paper,
} from "@material-ui/core";
import {
  Clear,
} from "@material-ui/icons";
import {
  get,
} from "lodash";
import * as React from "react";
// Components
import Layout from "@components/Layout";
import NewOpportunityForm from "../components/NewForm";
import {
  CloseButton,
  CustomNav,
  CustomNavTite,
} from "./styled";

const CustomNavComponent = (
  <CustomNav>
    <CustomNavTite>
      Create an opportunity
    </CustomNavTite>
    <CloseButton to="/">
      <Clear/>
    </CloseButton>
  </CustomNav>
);

interface IProps {
  match: any;
}

class NewOpportunityPage extends React.Component<IProps> {
  state = {
    notAuthorized: false,
  };

  public render() {
    const opportunityId = get(this.props, "match.params.id", null);

    return (
      <Layout nav={CustomNavComponent}>
        <Paper elevation={0}>
          <NewOpportunityForm opportunityId={opportunityId}/>
        </Paper>
      </Layout>
    );
  }
}

export default NewOpportunityPage;
