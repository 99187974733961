import * as React from "react";
import DatePicker from "material-ui-pickers/DatePicker";
import * as moment from "moment";

interface IProps {
  id: string;
  value: any;
  onChange: (v: any) => void;
  style: any;

  // TODO find proper props
  keyboard?: boolean;
  fullWidth?: boolean;
  type: string;
  label?: string;
  InputLabelProps: any;
  clearable?: boolean;
  required: boolean;
  error?: any;
  animateYearScrolling?: boolean;
  className?: string;
  minDate?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  shouldDisableDate?: (day: any) => boolean;
}

const DATE_PATTERN = "YYYY-MM-DD";

/**
 * WrappedDatePicker is designed to resolve issue with timezone offset.
 * Sometimes raw DatePicker can set the date to previous day because it is working with local time.
 */
class WrappedDatePicker extends React.Component<IProps, any> {
  wrappedOnChange = (date: moment.Moment) => {
    const {
      onChange,
    } = this.props;
    return onChange(
      moment.utc(moment(date).format(DATE_PATTERN), DATE_PATTERN),
    );
  };

  render() {
    return (
      <DatePicker
        format={DATE_PATTERN}
        {...this.props}
        onChange={this.wrappedOnChange}
      />
    );
  }
}

export default WrappedDatePicker;
