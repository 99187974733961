import * as React from "react";
import {
  Dispatch,
} from "redux";
import {
  connect,
} from "react-redux";
// Styles
import {
  grey,
} from "@material-ui/core/colors";
import styled from "styled-components";
// Models
import {
  IOpportunityWithRelations,
} from "@models/opportunity";
// Actions
import {
  fetchOpportunitiesNew,
} from "@actions/opportunity";
// Pages
import OpportunityListNew from "@pages/opportunity/components/OpportunityList";
// Components
import Layout from "@components/Layout";

const Dashboard = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${grey[100]};

  @media (max-width: 600px) {
    background: #FFF;
  }
`;

interface IProps {
  dispatch: Dispatch;
  opportunities: IOpportunityWithRelations;
  // There is more to add...
}

class DashboardComponent extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.props.dispatch(fetchOpportunitiesNew());
  }

  public render() {
    return (
      <Layout page="opportunityList" onlyActive>
        <Dashboard>
          <OpportunityListNew
            opportunities={this.props.opportunities}
            showDuration
            showAge
            showExploreButton
            showApplyButton
            onlyActive
          />
        </Dashboard>
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  opportunities: state.opportunities.opportunitiesNew,
});


export default connect(mapStateToProps)(DashboardComponent);
