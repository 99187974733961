import * as Joi from "@hapi/joi";
import * as Yup from "yup";

//
// Constants
//

export const ID_VALUE_MIN = 1;

// Default String
export const ITEM: string = "Item";
export const STRING_LENGTH_MIN = 0;
export const STRING_LENGTH_MAX = 255;
export const TITLE_LENGTH_MIN = 3;
export const TITLE_LENGTH_MAX = STRING_LENGTH_MAX;

// Password
export const PASSWORD: string = "Password";
export const PASSWORD_REGEX: RegExp = /(?=.*[a-z])(?=.*[A-Z])/;
export const PASSWORD_LENGTH_MIN: number = 8;
export const ERROR_PASSWORD_REGEX: string = "Must contain One Uppercase and One Lowercase Character";

// Email
export const EMAIL: string = "Email";
export const EMAIL_LENGTH_MIN = STRING_LENGTH_MIN;
export const EMAIL_LENGTH_MAX = STRING_LENGTH_MAX;
export const ERROR_INVALID_EMAIL: string = "Invalid Email";

// Phone
export const PHONE: string = "Phone Number";
export const PHONE_LENGTH_MIN = 8;
export const PHONE_LENGTH_MAX = 12;

export const errorItemTooLong: (item: string, limit: number) => string =
  (item: string, limit: number) => `${item} must be at most ${limit} characters`;
export const errorItemTooShort: (item: string, limit: number) => string =
  (item: string, limit: number) => `${item} must be at least ${limit} characters`;

//
// Type Definitions
//

export type IId = number;
export type IIdArray = IId[];

//
// Schema Definitions
//

// Joi
export const IdSchema = Joi.number().min(ID_VALUE_MIN);
export const IdArraySchema = Joi.array().items(
  IdSchema,
);
export const DefaultStringSchema = Joi.string().min(STRING_LENGTH_MIN).max(STRING_LENGTH_MAX);
export const DefaultTitleSchema = Joi.string().min(TITLE_LENGTH_MIN).max(TITLE_LENGTH_MAX);

// Yup
export const YupIdSchema = Yup
  .number()
  .min(ID_VALUE_MIN, `Id must not be less than ${ID_VALUE_MIN}`);

export const YupIdArraySchema = Yup.array().of(
  YupIdSchema,
);

export const YupDefaultStringSchema = Yup
  .string()
  .min(STRING_LENGTH_MIN, errorItemTooShort(ITEM, STRING_LENGTH_MIN))
  .max(STRING_LENGTH_MAX, errorItemTooLong(ITEM, STRING_LENGTH_MAX))
  .trim();

export const YupDefaultTitleSchema = Yup
  .string()
  .min(TITLE_LENGTH_MIN, errorItemTooShort(ITEM, TITLE_LENGTH_MIN))
  .max(TITLE_LENGTH_MAX, errorItemTooLong(ITEM, TITLE_LENGTH_MAX))
  .trim();

export const YupDefaultPasswordSchema = Yup
  .string()
  .matches(
    PASSWORD_REGEX,
    ERROR_PASSWORD_REGEX,
  )
  .min(PASSWORD_LENGTH_MIN, errorItemTooShort(PASSWORD, PASSWORD_LENGTH_MIN));

export const YupDefaultEmailSchema = Yup
  .string()
  .email(ERROR_INVALID_EMAIL)
  .min(EMAIL_LENGTH_MIN, errorItemTooShort(EMAIL, EMAIL_LENGTH_MIN))
  .max(EMAIL_LENGTH_MAX, errorItemTooLong(EMAIL, EMAIL_LENGTH_MAX))
  .trim();

export const YupDefaultPhoneSchema = Yup
  .string()
  .min(PHONE_LENGTH_MIN, errorItemTooShort(PHONE, PHONE_LENGTH_MIN))
  .max(PHONE_LENGTH_MAX, errorItemTooLong(PHONE, PHONE_LENGTH_MAX))
  .trim();
