import styled from "styled-components";
import {
  red,
} from "@material-ui/core/colors";

export const FormErrors = styled.ul`
  padding: 1rem;
  margin-bottom: 2rem;
  background: ${red[500]};
  border-radius: 3px;
`;

export const FormError = styled.li`
  margin-left: 2rem;
  color: white;
  line-height: 1.5rem;
`;
