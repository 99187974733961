import * as React from "react";
import ReactPlayer from "react-player";

interface IProps {
  src: string;
  playerHeight: number;
  playerWidth: number;
  onDurationFound?: (duration: number) => any;
  onError?: () => any;
  onReady?: () => any;
}

/**
 * Video player class.
 * It plays the source url given as props.
 */
class VideoPlayer extends React.Component<IProps, any> {
  render() {
    const {
      src,
      playerHeight,
      playerWidth,
      onDurationFound,
      onError,
      onReady,
    } = this.props;

    return (
      <div>
        <ReactPlayer
          controls
          width={playerWidth}
          height={playerHeight}
          url={src}
          onDuration={onDurationFound}
          onError={onError}
          onReady={onReady}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>
    );
  }
}

export default (VideoPlayer);
