import * as React from "react";
import {
  filter,
  get,
} from "lodash";
import {
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import {
  withTheme,
} from "@material-ui/core/styles";
import {
  FormControlContainer,
  FormLabel,
} from "@components/FormComponents/styled";
import styled from "styled-components";
import OptionButtons from "@components/OptionButtons";
// Containers
import {
  DataLoader,
} from "@components/DataLoader";

const themeColor = "theme.palette.primary[600]";

export const SkillsDialogueContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SkillCategoryButton: any = withTheme()(styled.div`
  margin: 0.4rem 1rem;
  padding: 0.7rem;
  border-bottom:
    ${(props: any) => props.selected ? `2px ${get(props, themeColor)} solid` : "2px transparent solid"};
  transition: all 100ms linear;
  cursor: pointer;
  color: #666666;
  &:hover {
  color: #333333;
  border-bottom:
  ${(props: any) => props.selected ? `2px ${get(props, themeColor)} solid` : "2px #cccccc solid"};
  }
` as any);

interface IProps {
  abilityToAdd: boolean;
  formErrors: any;
  onChangeArray: (e: any) => any;
  data: any;
  formData: any;
}

class SkillsList extends React.Component<IProps, any, any> {
  public removeSkill = (skillId) => {
    const {
      onChangeArray,
    } = this.props;
    const formData = this.props.formData || {};
    const {
      skillIds,
    } = formData;
    const newSkills = filter(skillIds, (o) => o !== parseInt(skillId, 10));
    onChangeArray("skillIds")(newSkills);
  };

  render() {
    const {
      formErrors,
      onChangeArray,
    } = this.props;

    const data = this.props.data || {};
    const formData = this.props.formData || {};

    const {
      skillIds,
    } = formData;

    const skillOptions = get(data, "skills", {});
    const skillsDisabled = skillIds.length > 11;

    return (

      <FormControlContainer>
        <FormControl fullWidth error={!!formErrors.skillIds}>
          <FormLabel>
            Select key skills/interest they will be exposed to
          </FormLabel>
          <FormHelperText>
            Add up to 12 (ideally) skills you may find relevant of this opportunity
          </FormHelperText>
        </FormControl>
        <DataLoader required>
          <OptionButtons
            size="small"
            multiple
            disabled={skillsDisabled}
            selected={skillIds}
            onChange={onChangeArray("skillIds")}
            options={skillOptions}
          />
        </DataLoader>
      </FormControlContainer>
    );
  }
}

export default SkillsList;
