import * as React from "react";

import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  Email,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import AnimateHeight from "react-animate-height";
import styled from "styled-components";
// Components
import {
  FormControlContainer,
  IconWrapper,
} from "@components/FormComponents/styled";

const SigninFormPage1 = styled.section``;

interface IProps {
  formData: any;
  formErrors: any;
  forgotPassword: boolean;
  login: () => any;
  toggleForgotPassword: (e: any) => any;
  onChange: (e: any) => any;
}

class SigninFormPage1Component extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
        ...props.formData || {},
      },
      showPassword: false,
    };
  }

  public checkEnter = (event: any): void => {
    const {
      key,
    }: { key: string } = event;
    const {
      login,
    }: { login: () => any } = this.props;
    if (key === "Enter") login();
  };

  public handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  public handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  public render() {
    const {
      formErrors, forgotPassword, onChange, toggleForgotPassword,
    } = this.props;
    const {
      email, password,
    } = this.props.formData;
    const forgotPasswordHiddenHeight = forgotPassword ? 0 : "auto";
    return (
      <SigninFormPage1>

        <Typography variant="headline" gutterBottom>
          Oh hey, welcome back!
        </Typography>
        <Typography variant="subheading">
          Sign in to continue
        </Typography>

        <FormControlContainer>
          <FormControl fullWidth error={!!formErrors.email}>
            <InputLabel htmlFor="email">
              Email
            </InputLabel>
            <Input
              id="email"
              type={"text"}
              value={email}
              onChange={onChange("email")}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconWrapper>
                    <Email/>
                  </IconWrapper>
                </InputAdornment>
              }
            />
          </FormControl>
        </FormControlContainer>

        <AnimateHeight height={forgotPasswordHiddenHeight}>
          <FormControlContainer>
            <FormControl fullWidth error={!!formErrors.password}>
              <InputLabel htmlFor="password">
                Password
              </InputLabel>
              <Input
                id="password"
                type={this.state.showPassword ? "text" : "password"}
                value={password}
                onChange={onChange("password")}
                onKeyPress={this.checkEnter}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      <IconWrapper>
                        {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconWrapper>
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText style={{
                textAlign: "right",
              }}>
                <Button size="small" onClick={toggleForgotPassword}>
                  Forgot Password?
                </Button>
              </FormHelperText>
            </FormControl>
          </FormControlContainer>
        </AnimateHeight>

      </SigninFormPage1>
    );
  }
}

export default SigninFormPage1Component;
