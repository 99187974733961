import * as React from "react";
import {
  Form,
  Formik,
} from "formik";
// Styles
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Theme,
  withStyles,
} from "@material-ui/core";
// Models
import {
  createUserSchema,
  ICreateUser,
} from "@models/user";

//
// Styles
//

const styles = (theme: Theme) => ({
  textField: {
    marginBottom: theme.spacing.unit,
  },
  actionContainer: {
    textAlign: "right" as "right",
  },
});

//
// Interfaces
//

interface IProps {
  initialValues: ICreateUser;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: ICreateUser) => void;
}

interface IExtendedProps extends IProps {
  classes: {
    textField: any;
    actionContainer: any;
  }
}

const OrganisationDialog: React.FC<IExtendedProps> = ({
  classes,
  initialValues,
  open,
  onClose,
  onSubmit,
}: IExtendedProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>
        Create User
      </DialogTitle>

      <DialogContent>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={createUserSchema}
          render={
            ({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form>
                  <TextField
                    className={classes.textField}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    name="first_name"
                    label={"First Name"}
                    fullWidth
                    error={!!errors && !!errors.first_name && !!touched && !!touched.first_name}
                    helperText={errors && errors.first_name}
                  />

                  <TextField
                    className={classes.textField}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    name="last_name"
                    label={"Last Name"}
                    fullWidth
                    error={!!errors && !!errors.last_name && !!touched && !!touched.last_name}
                    helperText={errors && errors.last_name}
                  />

                  <TextField
                    className={classes.textField}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    name="email"
                    label={"Email"}
                    fullWidth
                    error={!!errors && !!errors.email && !!touched && !!touched.email}
                    helperText={errors && errors.email}
                  />

                  <TextField
                    className={classes.textField}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    name="username"
                    label={"Username"}
                    fullWidth
                    error={!!errors && !!errors.username && !!touched && !!touched.username}
                    helperText={errors && errors.username}
                  />

                  <TextField
                    className={classes.textField}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    name="phone"
                    label={"Phone"}
                    fullWidth
                    error={!!errors && !!errors.phone && !!touched && !!touched.phone}
                    helperText={errors && errors.phone}
                  />

                  <TextField
                    className={classes.textField}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    name="password"
                    label={"Password"}
                    fullWidth
                    error={!!errors && !!errors.password && !!touched && !!touched.password}
                    helperText={errors && errors.password}
                  />

                  <TextField
                    className={classes.textField}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    label={"Confirm Password"}
                    fullWidth
                    error={!!errors && !!errors.confirmPassword && !!touched && !!touched.confirmPassword}
                    helperText={errors && errors.confirmPassword}
                  />

                  <div className={classes.actionContainer}>
                    <Button
                      color="default"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(OrganisationDialog);
