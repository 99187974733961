import * as React from "react";
import * as moment from "moment";
import {
  FormControl,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import DatePicker from "@components/DatePicker";

import {
  FormControlContainer,
  FormLabel,
} from "@components/FormComponents/styled";

interface IProps {
  formErrors: any;
  formData: any;
  onChange: (e: any) => any;
}

class WorkingDates extends React.Component<IProps, any> {
  private readonly today: moment.Moment;
  private readonly tomorrow: moment.Moment;

  constructor(props) {
    super(props);

    this.today = moment().startOf("day");
    this.tomorrow = this.today.clone().add(1, "day");
  }

  componentDidMount(): void {
    this.validateMinDates();
  }

  validateMinDates() {
    const formData = this.props.formData || {};
    const {
      startDate, closeDate, endDate,
    } = formData;

    const start = moment(startDate);
    const end = moment(endDate);
    const close = moment(closeDate);

    if (start && start.isBefore(this.today)) {
      this.onChangeDate("startDate")(this.today);
    }

    if (end && end.isBefore(this.today)) {
      this.onChangeDate("endDate")(this.today);
    }

    if (close && close.isBefore(this.tomorrow)) {
      this.onChangeDate("closeDate")(this.tomorrow);
    }
  }

  public onChangeDate = (field) => (date) => {
    const {
      onChange,
    } = this.props;
    onChange(field)(date.format("YYYY-MM-DD"));
  };

  render() {
    const {
      formErrors,
    } = this.props;

    const formData = this.props.formData || {};
    const {
      startDate, closeDate, endDate,
    } = formData;

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <FormControlContainer>
            <FormControl fullWidth error={!!formErrors.closeDate}>
              <FormLabel htmlFor="applicationCloseDate">
                Application close date
              </FormLabel>
              <FormHelperText>
                Please select when the application close date is
              </FormHelperText>
              <DatePicker
                id="applicationCloseDate"
                required
                type="date"
                value={closeDate}
                minDate={this.tomorrow}
                onChange={this.onChangeDate("closeDate")}
                // Enforce application closes tomorrow or later
                disablePast
                shouldDisableDate={(day: any) => day == this.today}
                // Styles
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: "100%",
                }}
              />
            </FormControl>
          </FormControlContainer>
        </Grid>
        <Grid item xs={6}>
          <FormControlContainer>
            <FormControl fullWidth error={!!formErrors.startDate}>
              <FormLabel htmlFor="opportunityStartDate">
                Opportunity start date
              </FormLabel>
              <FormHelperText>
                When does this opportunity start?
              </FormHelperText>
              <DatePicker
                id="opportunityStartDate"
                required
                type="date"
                value={startDate}
                minDate={this.today}
                onChange={this.onChangeDate("startDate")}
                // Enforce opportunity start today or later
                disablePast
                // Styles
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: "100%",
                }}
              />
            </FormControl>
          </FormControlContainer>
        </Grid>
        <Grid item xs={6}>
          <FormControlContainer>
            <FormControl fullWidth error={!!formErrors.endDate}>
              <FormLabel htmlFor="opportunityCloseDate">
                Opportunity end date
              </FormLabel>
              <FormHelperText>
                When does this opportunity end?
              </FormHelperText>
              <DatePicker
                id="opportunityCloseDate"
                required
                type="date"
                value={endDate}
                minDate={this.today}
                onChange={this.onChangeDate("endDate")}
                // Styles
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: "100%",
                }}
              />
            </FormControl>
          </FormControlContainer>
        </Grid>
      </Grid>
    );
  }
}

export default WorkingDates;
